import { useContext, useState } from 'react';
import {
  FaCloudUploadAlt,
  FaCut,
  FaFolderPlus,
  FaPaste,
  FaPencilAlt,
  FaTrash,
} from 'react-icons/fa';

import {
  ConfirmationDialog,
  FileInput,
  FilledButton,
} from '@ftrprf/tailwind-components';

import SingleInputDialog from '../../../components/Dialog/SingleInputDialog';

import { ContentActionContext } from '../../../providers/ContentActionProvider';

import useFormatMessage from '../../../hooks/useFormatMessage';
import usePathStack from '../../../hooks/usePathStack';

import { baseName } from '../../../utils/functions/path';

const ContentActionBar = ({ queryPath, root, files }) => {
  const t = useFormatMessage();
  const { relativePath } = usePathStack(root);

  const path = queryPath ? queryPath : relativePath;

  const {
    selectedItems,
    renameFile,
    deleteFiles,
    createFolder,
    clipBoardCopy,
    clipBoardCut,
    cutFiles,
    pasteFiles,
    notInPathRegex,
    uploadFiles,
  } = useContext(ContentActionContext);

  const [showRemoveFilesDialog, setShowRemoveFilesDialog] = useState(false);

  const [showSingleInputDialog, setShowSingleInputDialog] = useState(false);
  const [singleInputDialogAction, setSingleInputDialogAction] = useState();
  const [singleInputDialogTitle, setSingleInputDialogTitle] = useState();
  const [singleInputDialogActionMessage, setSingleInputDialogActionMessage] =
    useState();
  const [singleInputDialogActionLabel, setSingleInputDialogLabel] = useState();
  const [singleInputDialogDefaultValue, setSingleInputDialogDefaultValue] =
    useState();

  const clipBoardEmpty =
    clipBoardCopy.length === 0 && clipBoardCut.length === 0;

  const areFoldersSelected = selectedItems.some((el) =>
    files
      .filter(({ isFile }) => !isFile)
      .map(({ id }) => id)
      .includes(el),
  );

  return (
    <>
      <ConfirmationDialog
        content={
          <div>
            {t('content-manager.actions.delete.pay_attention')} <br />
            {t('content-manager.actions.delete.confirm_text')}
          </div>
        }
        isOpen={showRemoveFilesDialog}
        onDismiss={() => setShowRemoveFilesDialog(false)}
        onConfirm={() => {
          setShowRemoveFilesDialog(false);
          deleteFiles(selectedItems);
        }}
      />
      <SingleInputDialog
        isOpen={showSingleInputDialog}
        onDismiss={() => {
          setShowSingleInputDialog(false);
          setSingleInputDialogDefaultValue(null);
        }}
        onSubmit={(input) => {
          setShowSingleInputDialog(false);
          singleInputDialogAction(input);
          setSingleInputDialogDefaultValue(null);
        }}
        title={singleInputDialogTitle}
        actionText={singleInputDialogActionMessage}
        label={singleInputDialogActionLabel}
        defaultValue={singleInputDialogDefaultValue}
        errRegex={notInPathRegex}
        errRegexMessage="content-manager.actions.rename.path-exists"
      />

      <div className="flex mt-4 justify-end">
        <div className="flex flex-wrap gap-2">
          <FileInput onUpload={uploadFiles}>
            {(onOpenFileDialog) => (
              <FilledButton onClick={onOpenFileDialog}>
                <FaCloudUploadAlt className="mr-2" />
                {t('global.upload')}
              </FilledButton>
            )}
          </FileInput>
          <FilledButton
            accent
            iconBefore={FaFolderPlus}
            onClick={() => {
              setShowSingleInputDialog(true);
              setSingleInputDialogAction(
                () => (input) => createFolder(path.slice(0, -1), input),
              );
              setSingleInputDialogTitle(
                t('content-manager.actions.create_folder'),
              );
              setSingleInputDialogActionMessage(
                t('content-manager.actions.create'),
              );
              setSingleInputDialogLabel(t('global.folder'));
            }}
          >
            {t('content-manager.actions.create_folder')}
          </FilledButton>
          <FilledButton
            accent
            iconBefore={FaPencilAlt}
            disabled={selectedItems.length !== 1}
            onClick={() => {
              setShowSingleInputDialog(true);
              setSingleInputDialogDefaultValue(
                baseName(files.find(({ id }) => id === selectedItems[0]).path),
              );
              setSingleInputDialogAction(() => (input) => {
                renameFile(
                  selectedItems[0],
                  `${path === '/' ? '' : path}/${input}`,
                );
              });
              setSingleInputDialogTitle(
                t('content-manager.actions.rename-title'),
              );
              setSingleInputDialogActionMessage(
                t('content-manager.actions.rename'),
              );
              setSingleInputDialogLabel(t('global.name'));
            }}
          >
            {t('content-manager.actions.rename')}
          </FilledButton>
          <FilledButton
            accent
            iconBefore={FaCut}
            disabled={!clipBoardEmpty || areFoldersSelected}
            onClick={() =>
              cutFiles(
                selectedItems.map((id) => ({
                  id,
                  path: files.find((file) => file.id === id).path,
                })),
              )
            }
          >
            {t('content-manager.actions.cut')}
          </FilledButton>

          <FilledButton
            accent
            iconBefore={FaTrash}
            disabled={!selectedItems.length}
            onClick={() => setShowRemoveFilesDialog(true)}
          >
            {t('content-manager.actions.delete')}
          </FilledButton>
          {!clipBoardEmpty && (
            <FilledButton
              accent
              iconBefore={FaPaste}
              onClick={() => {
                pasteFiles(path);
              }}
            >
              {t('content-manager.actions.paste')}
            </FilledButton>
          )}
        </div>
      </div>
    </>
  );
};

export default ContentActionBar;

ContentActionBar.defaultProps = {
  files: [],
};
