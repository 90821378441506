import { useRef } from 'react';
import {
  FaCheck,
  FaExclamationTriangle,
  FaRegEdit,
  FaTimes,
} from 'react-icons/fa';
import Tooltip from '@reach/tooltip';
import { useFormik } from 'formik';
import { object } from 'yup';

import { Button, FilledButton } from '@ftrprf/tailwind-components';

import useCloseState from '../hooks/useCloseState';
import useFormatMessage from '../hooks/useFormatMessage';

import c from '../utils/functions/c';

import StyledIcon from './StyledIcon';

const InlineEdit = ({
  value: initialValue,
  onSubmit,
  className,
  validationSchema,
  name,
  ...props
}) => {
  const t = useFormatMessage();

  const formRef = useRef();
  const inputRef = useRef();

  const [isOpen, setIsOpen] = useCloseState(formRef, {
    // eslint-disable-next-line no-use-before-define
    onClose: () => formik.resetForm(),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      [name]: initialValue,
    },
    validationSchema:
      validationSchema &&
      object({
        [name]: validationSchema,
      }),
    onSubmit: ({ [name]: value }) => {
      inputRef.current.blur();
      setIsOpen(false);
      onSubmit(value);
    },
    onReset: () => {
      inputRef.current.blur();
      setIsOpen(false);
    },
  });

  return (
    <div className="relative w-full">
      <form
        className={c(
          'flex w-full group border-2',
          !isOpen &&
            'hover:bg-gray-200 transition-all duration-100 cursor-text border-transparent',
        )}
        ref={formRef}
        onClick={() => setIsOpen(true)}
        onSubmit={formik.handleSubmit}
      >
        <input
          {...props}
          ref={inputRef}
          className={c(
            className,
            'bg-transparent flex-grow outline-none appearance-none',
          )}
          value={formik.values[name]}
          name={name}
          onChange={formik.handleChange}
        />
        {isOpen ? (
          <div className="flex self-stretch my-auto">
            {formik.errors[name] && (
              <Tooltip label={t(formik.errors[name])}>
                <span className="text-orange-500 my-auto mx-4">
                  <FaExclamationTriangle />
                </span>
              </Tooltip>
            )}
            <FilledButton
              secondary
              title={t('global.cancel')}
              className="mx-1 self-stretch"
              onClick={formik.handleReset}
            >
              <FaTimes />
            </FilledButton>
            <FilledButton
              type="submit"
              title={t('global.save')}
              className="mx-1 self-stretch"
            >
              <FaCheck />
            </FilledButton>
          </div>
        ) : (
          <Button
            className="my-auto mx-4 text-gray-600 opacity-0 group-hover:opacity-100 absolute right-0 top-0 -mt-1 -mr-1"
            title={t('global.edit')}
          >
            <StyledIcon icon={FaRegEdit} className="text-accent-500 w-4 h-4" />
          </Button>
        )}
      </form>
    </div>
  );
};

InlineEdit.defaultProps = {
  initialValue: '',
  name: '',
  onSubmit: () => {},
};

export default InlineEdit;
