import { Children, cloneElement } from 'react';
import { FaTimes } from 'react-icons/fa';
import { Dialog as ReachDialog } from '@reach/dialog';

import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/c';
import getDialogStyle from '../../utils/getComponentStyle';

import en from './translations/en';
import nl from './translations/nl';

const dialogStyles = {
  CARD: 'bg-white shadow-base max-w-2xl flex flex-col w-full',
  MODAL: 'bg-white shadow-base flex flex-col min-h-1/4',
  UNSTYLED: 'w-full',
};

export const Dialog = ({ children, unstyled, modal, className, ...props }) => {
  const style = getDialogStyle(dialogStyles, { unstyled, modal }, 'CARD');

  return (
    <ReachDialog
      aria-label="Dialog"
      style={modal && { resize: 'both', overflow: 'auto' }}
      className={c(className, dialogStyles[style], 'min-w-96 rounded-lg')}
      {...props}
    >
      {children}
    </ReachDialog>
  );
};

export const DialogHeader = ({
  className,
  children,
  hasCloseButton,
  onDismiss,
}) => {
  const t = useFormatMessage();

  return (
    <div
      className={c(
        'p-4 border-b border-gray-300 text-xl font-bold relative',
        className,
      )}
    >
      {children}
      {hasCloseButton && onDismiss && (
        <button
          className="absolute top-0 right-0 p-2 text-base text-gray-300 hover:text-gray-400 transition-colors duration-100"
          onClick={onDismiss}
          aria-label={t('dialog.close')}
          title={t('dialog.close')}
        >
          <FaTimes />
        </button>
      )}
    </div>
  );
};

export const DialogContent = ({ className, children }) => (
  <div className={c('m-4', className)}>{children}</div>
);

export const DialogFooter = ({ className, children }) => {
  return (
    <div
      className={c(
        'p-4 text-right flex justify-end border-t border-gray-300',
        className,
      )}
    >
      {children}
    </div>
  );
};

export const DialogActions = ({ className, children }) => {
  return (
    <DialogFooter className={className}>
      {Children.map(children, (child) =>
        cloneElement(child, {
          className: c(className, 'ml-2'),
        }),
      )}
    </DialogFooter>
  );
};

export const DialogTranslations = {
  en,
  nl,
};
