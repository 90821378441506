import { useEffect, useState } from 'react';

import useFormatMessage from '../../hooks/useFormatMessage';

import { FilledButton } from '../buttons/FilledButton';
import { OutlineButton } from '../buttons/OutlineButton';

import { Dialog, DialogActions, DialogContent, DialogHeader } from './index';

export const ConfirmationDialog = ({
  onDismiss,
  onConfirm,
  content,
  title,
  isOpen,
  ...props
}) => {
  const t = useFormatMessage();
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDisabled(false);
    }
  }, [isOpen]);

  return (
    <Dialog onDismiss={onDismiss} isOpen={isOpen} {...props}>
      <DialogHeader>{title || t('confirmation-dialog.title')}</DialogHeader>
      {content && <DialogContent>{content}</DialogContent>}
      <DialogActions>
        <OutlineButton disabled={disabled} onClick={onDismiss}>
          {t('dialog.cancel')}
        </OutlineButton>
        <FilledButton
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            onConfirm();
          }}
        >
          {t('confirmation-dialog.confirm')}
        </FilledButton>
      </DialogActions>
    </Dialog>
  );
};
