import { Children, cloneElement } from 'react';

import c from '../../utils/functions/c';

const Table = ({ children, className, widths }) => {
  return (
    <div className={c('overflow-x-auto flex flex-grow flex-col', className)}>
      {Children.map(
        children,
        (child) =>
          child &&
          cloneElement(child, {
            widths,
          }),
      )}
    </div>
  );
};

export default Table;
