import { FaLock, FaPaperclip } from 'react-icons/fa';

import c from '../../utils/c';

const colors = [
  '#EF4444',
  '#10B981',
  '#06B6D4',
  '#84CC16',
  '#38BDF8',
  '#F43F5E',
  '#F59E0B',
  '#D946EF',
  '#8B5CF6',
  '#93C5FD',
];

export const SubjectCard = ({
  index: rawIndex,
  color: rawColor,
  title,
  hasContent,
  onDocument,
}) => {
  // When index is undefined, show the gray color
  const index = rawIndex === undefined ? 7 : rawIndex;

  const color = rawColor || colors[index % colors.length];

  return (
    <div className="flex w-full h-20 rounded overflow-hidden border border-gray-300 group">
      <div
        className={c(
          'h-20 w-20 flex-shrink-0 uppercase flex items-center justify-center text-2xl font-bold border-r border-gray-300',
          !hasContent && 'opacity-50',
        )}
        style={{
          color,
        }}
      >
        {title.charAt(0)}
      </div>
      <div
        className={c(
          'w-full flex items-center justify-center pl-4 transition-colors duration-200 relative',
          hasContent ? 'hover:bg-gray-200' : 'opacity-50',
        )}
      >
        {title && (
          <div className="flex-grow flex flex-col justify-center text-left text-lg leading-tight clamp-2">
            {title}
          </div>
        )}
        <div className="absolute top-0 right-0 flex mr-2 mt-2 transition-colors text-gray-400 hover:text-gray-500 focus:text-gray-500 cursor-pointer">
          {onDocument && (
            <button
              onClick={(e) => {
                e.preventDefault();
                onDocument(e);
              }}
            >
              <FaPaperclip />
            </button>
          )}
          {!hasContent && <FaLock className="text-sm" />}
        </div>
      </div>
    </div>
  );
};

SubjectCard.defaultProps = {
  hasContent: true,
};
