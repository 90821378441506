import { useEffect, useState } from 'react';
import { FaChevronRight, FaTimes } from 'react-icons/fa';

import { Input } from '@ftrprf/tailwind-components';

import useFormatMessage from './../hooks/useFormatMessage';
import useMultiSelect from './../hooks/useMultiSelect';

import c from '../utils/functions/c';

import ComponentTitle from './ComponentTitle';

const MultiSelectList = ({ items, members, setMembers }) => {
  const t = useFormatMessage();
  const [selectedItems, , addToSelection, deselectItem, deselectAll] =
    useMultiSelect();
  const selection = Object.keys(selectedItems);

  const [filteredItems, setFilteredItems] = useState(items);
  const [filteredMembers, setFilteredMembers] = useState(members);
  const [memberFilter, setMemberFilter] = useState('');

  useEffect(() => {
    if (items?.length > 0) {
      setFilteredItems(items);
    }
  }, [items]);

  return (
    <div className="mx-auto w-full flex justify-around">
      <div className="flex flex-col w-2/5">
        <ComponentTitle title={t('global.available')} />
        <Input
          type="text"
          placeholder={t('looking-for')}
          onChange={(e) => {
            setFilteredItems(
              items.filter(({ label }) =>
                label.toLowerCase().includes(e.target.value.toLowerCase()),
              ),
            );
          }}
        />
        <div className="h-32 overflow-y-auto">
          {filteredItems.map(({ id, label }) => {
            const checked = selection.includes(id);
            const isMember = members.includes(id);
            return (
              <div
                key={id}
                className={c(
                  'flex items-center border border-gray-500 -mb-px',
                  !isMember && 'cursor-pointer',
                )}
                onClick={() => {
                  if (!isMember) {
                    if (checked) {
                      deselectItem(id);
                    } else {
                      addToSelection(id);
                    }
                  }
                }}
              >
                <div
                  className={c(
                    'p-2 w-8',
                    !checked && 'bg-gray-300',
                    checked && 'bg-accent-500',
                    isMember && 'bg-accent-300',
                  )}
                >
                  <input
                    className="cursor-pointer"
                    type="checkbox"
                    checked={checked}
                    disabled={isMember}
                    readOnly
                  />
                </div>
                <p
                  className={c(
                    'ml-2 p-1 w-full',
                    !isMember && 'cursor-pointer',
                    isMember && 'text-gray-700',
                  )}
                >
                  {label}
                </p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex w-1/5 items-center justify-center text-white relative">
        <div
          className={c(
            'p-2 shadow-md',
            selection.length <= 0 && 'bg-accent-300',
            selection.length > 0 &&
              'bg-accent-500 cursor-pointer hover:shadow-none transform hover:translate-y-1 transition-transform duration-200',
          )}
          onClick={() => {
            const newMembers = [...new Set([...members, ...selection])];
            setMembers(newMembers);
            setMemberFilter('');
            setFilteredMembers(newMembers);
            deselectAll();
          }}
        >
          <FaChevronRight className="h-full w-8" />
          {selection?.length > 0 && (
            <div className="absolute px-2 bg-white text-accent-500 -top-1 -right-1 border border-accent-500 shadow-sm">
              <p>{selection.length}</p>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-col w-2/5">
        <ComponentTitle title={t('global.added')} />
        <Input
          type="text"
          placeholder={t('looking-for')}
          value={memberFilter}
          onChange={({ target: { value: filter } }) => {
            setMemberFilter(filter);
            setFilteredMembers(
              members.filter((member) =>
                items
                  .find(({ id }) => id === member)
                  .label.toLowerCase()
                  .includes(filter.toLowerCase()),
              ),
            );
          }}
        />
        <div className="h-32 overflow-y-auto">
          {filteredMembers.map((member) => {
            const label = items.find(({ id }) => member === id)?.label;
            return (
              <div
                className="flex items-center -mb-px p-2 border border-gray-500"
                key={member}
              >
                <p className="ml-1 w-full ellipsis">{label}</p>
                <FaTimes
                  className="cursor-pointer hover:text-gray-900"
                  onClick={() => {
                    const newMembers = [...members];
                    newMembers.splice(members.indexOf(member), 1);
                    setMembers(newMembers);
                    setMemberFilter('');
                    setFilteredMembers(newMembers);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MultiSelectList;

MultiSelectList.defaultProps = {
  items: [],
};
