import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { FIND_SCRATCH_EXERCISE } from '../../api/exercise';

import { EXERCISE } from '../../utils/constants/urls';

const STATUS = {
  LOADING: 'LOADING',
  ERROR: 'ERROR',
};

// Right now only fetch one version of a Scratch Exercise
const useExerciseQuery = (id, defaultVersionId) => {
  const [blob, setBlob] = useState();
  const [titleEN, setTitleEN] = useState('');
  const [titleNL, setTitleNL] = useState('');
  const [versionId, setVersionId] = useState(defaultVersionId);
  const [testPlanId, setTestPlanId] = useState();
  const [status, setStatus] = useState();
  const history = useHistory();

  const { data, loading, error, refetch } = useQuery(FIND_SCRATCH_EXERCISE, {
    variables: {
      id,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setStatus(STATUS.LOADING);

    if (!loading) {
      setTitleNL(data?.findExercise.titleNL);
      setTitleEN(data?.findExercise.titleEN);

      const defaultVersion =
        data?.findExercise.versions.find(
          (version) => version.id === Number(defaultVersionId),
        ) || data?.findExercise.versions[0];

      if (!defaultVersion) {
        return;
      }

      const { blobUri, id: versionId } = defaultVersion;
      history.replace(`${EXERCISE}/${id}/version/${versionId}`);

      fetch(blobUri)
        .then((response) => {
          if (response.status === 403) {
            refetch();
          } else {
            response.arrayBuffer().then((buffer) => {
              setVersionId(versionId);
              setTestPlanId(
                data.findExercise.versions.find((v) => v.id === versionId)
                  .testPlanId,
              );
              setBlob(new Blob([buffer]));
              setStatus();
            });
          }
        })
        .catch(() => setStatus(STATUS.ERROR));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loading,
    data,
    setBlob,
    setStatus,
    defaultVersionId,
    refetch,
    history,
    id,
  ]);

  return {
    loading: loading || status === STATUS.LOADING,
    error: error || status === STATUS.ERROR,
    setBlob,
    setTestPlanId,
    exercise: {
      ...(data?.findExercise || {}),
      blob,
      titleEN,
      titleNL,
      versionId,
      testPlanId,
      setTitleEN,
      setTitleNL,
      setVersionId,
      refetch,
    },
  };
};

export default useExerciseQuery;
