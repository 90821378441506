export const baseName = (path, extension) => {
  const base = path.substr(path.lastIndexOf('/') + 1);
  return extension ? `${base}.${extension}` : base;
};

export const splitExt = (path) => {
  const dotIdx = path.lastIndexOf('.');
  if (dotIdx === -1) {
    return [path, ''];
  }
  return [path.substr(0, dotIdx), path.substr(dotIdx + 1)];
};

export const isValidName = (name) => {
  if (!name) {
    return false;
  }

  const regex = /\\|\/|:|\*|\?|<|>|\./g;
  const found = name.match(regex);
  return found === null;
};

export const pathJoin = (...paths) => {
  let res = paths.join('/');
  res = res.replace(/\/{2,}/, '/');
  return res;
};
