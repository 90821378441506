import Collapsable from '../../../../components/Collapsable';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import GeneralSideBar from './GeneralSideBar';
import SideBarContent from './SideBarContent';
import SideBarContentManagerContainer from './SideBarContentManagerContainer';
import SteamsSideBar from './SteamsSideBar';

const Divider = () => <hr className="w-11/12 opacity-30 self-center mt-3" />;

const SlideBarCollapsable = ({ title, children, initialShown }) => {
  return (
    <Collapsable
      initialShown={initialShown}
      className="pr-3 pt-3 flex-start"
      trigger={<div className=" flex-grow font-medium">{title}</div>}
    >
      {children}
    </Collapsable>
  );
};

const SlideSideBar = ({ disabled, slideId }) => {
  const t = useFormatMessage();

  return (
    <SideBarContent className="p-3 " withGoBack={false}>
      <SlideBarCollapsable
        title={t('slide-sidebar.general')}
        initialShown={true}
      >
        <GeneralSideBar disabled={disabled} slideId={slideId} />
      </SlideBarCollapsable>
      <Divider />
      <SlideBarCollapsable
        title={t('slide-sidebar.steams')}
        initialShown={true}
      >
        <SteamsSideBar disabled={disabled} slideId={slideId} />
      </SlideBarCollapsable>
      <Divider />
      <SlideBarCollapsable
        title={t('slide-sidebar.files')}
        initialShown={false}
      >
        <SideBarContentManagerContainer />
      </SlideBarCollapsable>
    </SideBarContent>
  );
};

export default SlideSideBar;
