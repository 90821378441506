import { Input } from '@ftrprf/tailwind-components';

import Filterbar from '../../../components/Filterbar';
import Form from '../../../components/Form';

import useFormatMessage from '../../../hooks/useFormatMessage';

const ExerciseOverviewFilterBar = ({ onChange: onChangeHandler }) => {
  const t = useFormatMessage();

  return (
    <Form onChange={onChangeHandler}>
      {({ values, onChange, clear }) => (
        <Filterbar className="u-emulated-flex-gap justify-end" onClear={clear}>
          <div className="flex-grow min-w-56 max-w-md">
            <Input
              name="title"
              placeholder={t('content-overview.filters.title')}
              type="text"
              onChange={(event) =>
                onChange('title', event.target.value || null)
              }
              value={values.title || ''}
            />
          </div>
        </Filterbar>
      )}
    </Form>
  );
};

export default ExerciseOverviewFilterBar;
