import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import FormikInput from '../../../components/Form/FormikInput';

import useFormatMessage from '../../../hooks/useFormatMessage';

const ExerciseCreateDialog = ({ isOpen, onSubmit, onDismiss }) => {
  const t = useFormatMessage();

  const title = t('exercise-create-dialog.title');

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss} aria-label={title}>
      <Formik
        initialValues={{
          title: '',
        }}
        validateOnBlur={false}
        validationSchema={object({
          title: string().required('validation.required'),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <DialogHeader>{title}</DialogHeader>
            <DialogContent>
              <FormikInput
                type="text"
                name="title"
                label={t('exercise-create-dialog.label.title')}
              />
            </DialogContent>
            <DialogActions>
              <OutlineButton onClick={onDismiss}>
                {t('global.cancel')}
              </OutlineButton>
              <FilledButton type="submit" disabled={isSubmitting || !isValid}>
                {t('global.add')}
              </FilledButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default ExerciseCreateDialog;
