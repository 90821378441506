import useFormatMessage from '../../../../hooks/useFormatMessage';

import { QUESTION_TYPES } from '../../../slideviewer/constants';

import SlideViewerMultipleChoiceQuestion from './SlideViewerMultipleChoiceQuestion';
import SlideViewerOpenQuestion from './SlideViewerOpenQuestion';

const SlideViewerQuestion = ({ question, canSubmit }) => {
  const t = useFormatMessage();

  switch (question.type) {
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      return (
        <SlideViewerMultipleChoiceQuestion
          question={question}
          canSubmit={canSubmit}
        />
      );
    case QUESTION_TYPES.OPEN:
      return (
        <SlideViewerOpenQuestion question={question} canSubmit={canSubmit} />
      );
    default:
      return <div>{t('styled-slide-viewer.questions.not_supported_type')}</div>;
  }
};

export default SlideViewerQuestion;
