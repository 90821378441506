import { useContext } from 'react';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { ARCHIVE_LESSON_CONTENT } from '../../api/content';

import useFormatMessage from '../useFormatMessage';

import useFieldMutation from './useFieldMutation';

const useArchiveContentMutation = () => {
  const t = useFormatMessage();
  const [archiveMutate] = useFieldMutation(
    ARCHIVE_LESSON_CONTENT,
    'LessonContent',
  );
  const { addNotification } = useContext(NotificationContext);

  const archive = (id) => {
    return archiveMutate({ id }).then(({ type }) => {
      addNotification({
        type: 'success',
        content: t(`content-overview.${type}.archive.success`),
      });
    });
  };

  return archive;
};

export default useArchiveContentMutation;
