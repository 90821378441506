import { useCallback } from 'react';
import { useMutation } from '@apollo/client';

import {
  UPDATE_SCRATCH_EXERCISE,
  UPDATE_SCRATCH_EXERCISE_BLOB,
} from '../../api/exercise';

import useContentMutation from './useContentMutation';

const useExerciseMutation = () => {
  const { uploadFiles, uploading } = useContentMutation({
    path: '/scratch_exercise_images_all/',
  });
  const [
    updateExercise,
    { loading: updateExerciseLoading, error: errorExerciseLoading },
  ] = useMutation(UPDATE_SCRATCH_EXERCISE);
  const [
    updateExerciseBlob,
    { loading: updateBlobLoading, error: errorBlobLoading },
  ] = useMutation(UPDATE_SCRATCH_EXERCISE_BLOB);

  const uploadThumbnail = useCallback(
    (id, thumbnail) =>
      uploadFiles({
        files: [thumbnail],
        names: [`${id}.png`],
        showNotification: false,
      }).then(
        ({ data: { createContentFiles } }) => createContentFiles[0].blobUri,
      ),
    [uploadFiles],
  );

  const updateFullExercise = useCallback(
    ({ variables: { id, file, titleEN, titleNL, versionId, thumbnail } }) => {
      uploadThumbnail(id, thumbnail).then((thumbnailUrl) => {
        updateExercise({
          variables: { id, titleEN, titleNL, image: thumbnailUrl },
        });
        updateExerciseBlob({ variables: { versionId, file } });
      });
    },
    [updateExercise, updateExerciseBlob, uploadThumbnail],
  );

  return [
    updateFullExercise,
    {
      loading: updateExerciseLoading || updateBlobLoading || uploading,
      error: errorExerciseLoading || errorBlobLoading,
    },
  ];
};

export default useExerciseMutation;
