import { cloneElement, useRef } from 'react';

import useCloseState from '../hooks/useCloseState';

import c from '../utils/c';

export const DropdownMenu = ({ className, trigger, leftAligned, children }) => {
  const ref = useRef(null);

  const [isOpen, setIsOpen] = useCloseState(ref);

  const hide = () => setIsOpen(false);

  const triggerElement = trigger({ isOpen });

  return (
    <div ref={ref} className={c('relative inline-block', className)}>
      {cloneElement(triggerElement, {
        onClick: () => {
          if (triggerElement.props?.onClick) {
            triggerElement.props.onClick();
          }
          setIsOpen((o) => !o);
        },
      })}
      {isOpen && (
        <div
          className={c(
            leftAligned ? 'left-0' : 'right-0',
            'text-sm absolute z-30 min-w-full bg-white border-1 border-gray-500 shadow-base whitespace-nowrap rounded overflow-hidden p-1',
          )}
        >
          {children instanceof Function ? children({ hide }) : children}
        </div>
      )}
    </div>
  );
};

DropdownMenu.defaultProps = {
  leftAligned: false,
};
