import { useContext } from 'react';
import { useLazyQuery } from '@apollo/client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { FIND_EXERCISE_TEST_PLAN } from '../../api/exercise';

import useFormatMessage from '../useFormatMessage';

const useExerciseTestPlan = ({ exerciseVersionId, onCompleted }) => {
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);
  const [fetch, { data, loading, called }] = useLazyQuery(
    FIND_EXERCISE_TEST_PLAN,
    {
      variables: { exerciseVersionId },
      fetchPolicy: 'network-only',
      onCompleted: (data) =>
        data?.findExerciseTestPlanByExerciseVersionId &&
        onCompleted(data.findExerciseTestPlanByExerciseVersionId),
      onError: () =>
        addNotification({
          type: 'error',
          content: t('scratch.test-plan.error'),
        }),
    },
  );

  return {
    data: data?.findExerciseTestPlanByExerciseVersionId,
    loading,
    called,
    fetchExerciseTestPlan: fetch,
  };
};

useExerciseTestPlan.defaultProps = {
  onCompleted: () => {},
};

export default useExerciseTestPlan;
