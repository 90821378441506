import { useState } from 'react';
import { useQuery } from '@apollo/client';

const SCROLL_PERCENTAGE = 0.2;

const useInfiniteScrollQuery = (query, { variables } = { variables: {} }) => {
  const [page, setPage] = useState(0);

  const {
    data,
    loading,
    error,
    fetchMore: apolloFetchMore,
  } = useQuery(query, {
    variables: { page: page, ...variables },
  });

  const maxPages = data?.findAllExercises.pages - 1;

  const onInfiniteScroll = ({ target }) => {
    const THRESHOLD =
      target.clientHeight * SCROLL_PERCENTAGE + target.clientHeight;
    const scrollBottom = target.scrollHeight - target.scrollTop;

    if (scrollBottom < THRESHOLD && page < maxPages) {
      apolloFetchMore({ page: page + 1 });
      setPage((prev) => prev + 1);
    }
  };

  const fetchMore = ({ variables }) => {
    if (variables.filter) {
      setPage(0);
    }

    apolloFetchMore({ variables: { page: 0, ...variables } });
  };

  return { onInfiniteScroll, data, loading, error, fetchMore };
};

export default useInfiniteScrollQuery;
