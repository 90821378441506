const STEAMS_SCHOOLS = [
  '128', // FTRPRF
  '40', // VRT
];

const isBetaUser = (user) => {
  if (process.env.REACT_APP_BETA_ORGANIZATION_ID === '128') {
    // PROD, needs multiple organisations quick, so this dirty fix for that
    const isSteams = STEAMS_SCHOOLS.indexOf(user?.organization?.id) > -1;

    return isSteams;
  }

  return process.env.REACT_APP_BETA_ORGANIZATION_ID === user?.organization?.id;
};

export default isBetaUser;
