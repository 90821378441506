import { gql, useMutation } from '@apollo/client';

const getData = (data) => {
  if (!data) {
    return [];
  }

  return Object.values(data)[0];
};

const useInsertMutation = (
  mutation,
  query,
  queryOptions,
  key = '',
  options,
) => {
  const update =
    (variables) =>
    (cache, { data: response }) => {
      const resultValue = Object.values(
        cache.readQuery({ query, ...queryOptions }),
      )[0];

      const responseKey = Object.keys(response)[0];
      const typename = responseKey.replace('create', '');
      const { id } = getData(response);

      const resource = {
        id,
        ...variables,
      };

      if (!key) {
        throw new Error('Not yet supported');
      }

      cache.modify({
        id: cache.identify(resultValue),
        fields: {
          [key](existingResourcesRef = []) {
            const newResourceRef = cache.writeFragment({
              data: {
                ...resource,
                __typename: typename,
              },
              fragment: gql`
              fragment NewResource on ${typename} {
                ${Object.keys(resource).join(',')}
              }
            `,
            });

            return [...existingResourcesRef, newResourceRef];
          },
        },
      });
    };

  const [mutate, result] = useMutation(mutation, options);

  return [
    (variables) =>
      mutate({ variables, update: update(variables) }).then(({ data }) => {
        return data ? Object.values(data)[0] : null;
      }),
    result,
  ];
};

export default useInsertMutation;
