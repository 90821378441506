import React from 'react';
import IcoMoon, { iconList } from 'react-icomoon';

import iconSet from '../../../assets/activityTypeLogos.json';

const Icon = React.memo(({ className, itemName, onClick, size }) => {
  return (
    <IcoMoon
      iconSet={iconSet}
      className={className}
      icon={itemName}
      onClick={onClick}
      size={size}
    />
  );
});
Icon.displayName = 'Icon';
Icon.defaultProps = {
  onClick: () => {},
  size: 32,
  className: '',
};

const IconKeys = iconList(iconSet);
const IconSet = new Set(IconKeys);

const DefaultIconName = 'book_open';

const DefaultIcon = <Icon itemName={DefaultIconName} />;

const IconOrDefault = (props) => {
  let iconName = IconSet.has(props.itemName) ? props.itemName : DefaultIconName;

  return <Icon {...props} itemName={iconName} />;
};
export { Icon, IconKeys, IconSet, DefaultIcon, IconOrDefault };
