import { Children, isValidElement } from 'react';
import { FaEllipsisV, FaPaperclip } from 'react-icons/fa';

import c from '../../utils/c';

export const ContentCard = ({
  title,
  version,
  image,
  defaultImage: DefaultImage,
  subTitle,
  statusLabel,
  onDocument,
  toggleContextMenu,
  disabled,
  isFeatured,
  children,
}) => {
  // filters out falsy elements
  const validChildren = Children.toArray(children).filter((child) =>
    isValidElement(child),
  );

  const count = validChildren.length;

  return (
    <div
      className={c(
        'w-full h-full flex rounded border border-gray-400 overflow-hidden transition-colors duration-300',
        disabled ? 'opacity-50 cursor-not-allowed' : 'hover:border-gray-500',
        isFeatured && 'flex-col',
      )}
    >
      <div
        className={c(
          'flex-shrink-0',
          isFeatured ? 'aspect-w-1 aspect-h-1' : 'h-32 w-32',
        )}
      >
        {image ? (
          <img src={image} className="h-full w-full object-cover" />
        ) : (
          <DefaultImage className="bg-accent-50 h-full w-full object-cover" />
        )}
      </div>
      <div className="relative h-full w-full flex flex-col justify-end px-4 py-2">
        <div className="flex items-center md:justify-between">
          <div className="flex-grow">
            <div className="w-auto inline-block">{statusLabel}</div>
            {version ?? <span className="w-auto inline"> {version} </span>}
          </div>
          <div className="flex space-x-1 text-gray-500">
            {onDocument && (
              <button onClick={onDocument}>
                <FaPaperclip />
              </button>
            )}
            {toggleContextMenu && (
              <div
                className="inline-block p-1 group hover:bg-gray-300 rounded"
                onClick={toggleContextMenu}
              >
                <FaEllipsisV className="text-xs group-hover:text-gray-1000" />
              </div>
            )}
          </div>
        </div>

        <div className="w-auto flex-grow flex flex-col">
          <span className="leading-tight clamp-2 flex-grow">{title}</span>{' '}
          {subTitle && <p className="text-gray-500 text-xs mt-2">{subTitle}</p>}
        </div>
        {count > 0 && (
          <div
            className={c(
              'flex flex-wrap flex-grow justify-start items-end text-sm leading-none',
            )}
          >
            {validChildren.map((child, index) => {
              if (!isValidElement(child)) {
                return null;
              }

              return (
                <div
                  key={index}
                  className={c(
                    index !== count - 1 &&
                      'mr-2 pr-2 border-r border-gray-400 flex items-center',
                  )}
                >
                  {child}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

const Action = ({ disabled, children, ...props }) => (
  <button
    {...props}
    className={c(
      'leading-none',
      disabled && 'text-accent-200 cursor-not-allowed',
      !disabled && 'text-accent-600 hover:text-accent-900',
    )}
    disabled={disabled}
  >
    {children}
  </button>
);

ContentCard.Action = Action;
