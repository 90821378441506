import { FaTimes } from 'react-icons/fa';
import Tooltip from '@reach/tooltip';

import useFormatMessage from '../../../hooks/useFormatMessage';

import c from '../../../utils/c';

import { Button } from '../../buttons/Button';

export const SlideViewerCloseButton = ({ onClose }) => {
  const t = useFormatMessage();

  return (
    <div>
      <Tooltip label={t('styled-slide-viewer.close')}>
        <div>
          <Button
            className={c(
              'flex items-center justify-center rounded-full shadow ml-2 h-8 w-8',
              'bg-white text-black',
            )}
            onClick={onClose}
            type={null}
          >
            <FaTimes />
          </Button>
        </div>
      </Tooltip>
    </div>
  );
};
