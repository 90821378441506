import c from '../utils/c';

export const Content = ({ children, hasPaddingBottom }) => {
  return (
    <div
      className={c(
        'w-full max-w-5/6 my-4 flex flex-1 mx-auto',
        hasPaddingBottom && 'pb-8',
      )}
    >
      {children}
    </div>
  );
};

Content.defaultProps = {
  hasPaddingBottom: true,
};
