import { graphql, rest } from 'msw';

import dummyFiles from '../data/files';
import dummyLessons from '../data/lessons';
import dummySlides from '../data/slides';

export const post = [
  rest.post('*authenticate', (req, res, ctx) => {
    return res(
      ctx.json({
        eulaAccepted: true,
        role: 'ADMIN',
        classGroups: [],
        classGroupPrograms: [],
        id: 'd49515ed-972c-11e9-b935-0a0027000214',
        username: 'mockTest',
        firstName: 'test',
        lastName: 'test',
        lastLogin: 'Oct 20, 2020 4:56:04 PM',
        idp: 'FTRPRF',
        organization: 1,
        token:
          'eyJhbGciOiJIUzUxMiJ9.eyJvcmdhbml6YXRpb25JZCI6MSwic3ViIjoiaW5ncmlkIiwiZG9tYWluIjoiRlRSUFJGIiwiZXhwIjoxNjAzMjM1NjcxLCJpYXQiOjE2MDMyMDY4NzF9.Fs5vs_BJIcGj47Xv6F9eT_nYFW9U6kpWqPcVEnKfHt3EKLFP2jpIPOoXGGePdVhgftuCFltTk6f9do65pV1BDQ',
      }),
    );
  }),
];

export const queries = [
  graphql.query('FindAllLessonContent', (req, res, ctx) => {
    return res(
      ctx.data({
        findAllLessonContent: {
          total: 220,
          pages: 28,
          currentPage: 0,
          content: dummyLessons,
          __typename: 'LessonContentPage',
        },
      }),
    );
  }),

  graphql.query('UserInfo', (req, res, ctx) => {
    return res(
      ctx.data({
        userInfo: {
          id: '6',
          domain: 'FTRPRF',
          username: 'mock',
          firstName: 'mock',
          lastName: 'mock',
          organization: { id: '1', __typename: 'Organization' },
          organizationRole: 'ADMIN',
          __typename: 'User',
        },
      }),
    );
  }),

  graphql.query('FindLessonContent', (req, res, ctx) => {
    return res(
      ctx.data({
        findLessonContent: {
          id: '1112',
          title: 'Test title of the lesson with ID 1112',
          image: '',
          programId: 40,
          clan: 'SCHOOLS',
          level: 1,
          version: 1,
          published: false,
          archived: false,
          modifiedBy: 'ingrid',
          modifiedOn: '2019-11-18 11:16:25',
          slides: dummySlides,
          tags: [],
          endGoals: [],
          __typename: 'LessonContent',
        },
      }),
    );
  }),

  graphql.query('FindAllContentFilesByPath', (req, res, ctx) => {
    return res(
      ctx.data({
        findAllContentFilesByPath: dummyFiles,
      }),
    );
  }),

  graphql.query('FetchAllOrganizationsByDomain', (req, res, ctx) => {
    return res(
      ctx.data({
        fetchAllOrganizationsByDomain: {
          pages: 0,
          currentPage: 0,
          content: [],
          __typename: 'OrganizationPage',
        },
      }),
    );
  }),

  graphql.query('FindTagsWithName', (req, res, ctx) => {
    return res(
      ctx.data({
        findAllTagsWithName: [
          {
            id: '',
            name: '',
            __typename: 'Tag',
          },
        ],
      }),
    );
  }),

  graphql.query('findEndGoalsByKey', (req, res, ctx) => {
    return res(
      ctx.data({
        findEndGoalsByKey: [
          {
            id: '',
            key: '',
            value: '',
            __typename: 'EndGoal',
          },
        ],
      }),
    );
  }),

  graphql.mutation('CreateQuestion', (req, res, ctx) => {
    const { type } = req.variables;

    return res(
      ctx.data({
        createQuestion: {
          id: 'testId',
          type: type,
          shuffleAnswers: false,
          questionAnswersMultipleChoice: [
            {
              id: '',
              value: '',
              correct: '',
              explanation: '',
              __typename: 'QuestionAnswerMultipleChoice',
            },
          ],
        },
      }),
    );
  }),
  graphql.mutation('UpdateQuestionAnswerMultipleChoice', (req, res, ctx) => {
    const { correct, explanation, id, value } = req.variables;

    return res(
      ctx.data({
        id: id,
        value: value,
        correct: correct,
        explanation: explanation,
      }),
    );
  }),
];

export const mutations = [
  graphql.mutation('UpdateSlide', (req, res, ctx) => {
    const { id } = req.variables;

    return res(
      ctx.data({
        updateSlide: { id: id, __typename: 'Slide' },
      }),
    );
  }),

  graphql.mutation('UpdateSlideSequences', (req, res, ctx) => {
    const { id } = req.variables;

    return res(
      ctx.data({
        updateSlideSequences: { id: id, __typename: 'LessonContent' },
      }),
    );
  }),

  graphql.mutation('UpdateHint', (req, res, ctx) => {
    const { id, title, content } = req.variables;

    return res(
      ctx.data({
        updateHint: {
          id,
          title,
          content,
          __typename: 'Hint',
        },
      }),
    );
  }),

  graphql.mutation('CreateHint', (req, res, ctx) => {
    const { title, content } = req.variables;

    return res(
      ctx.data({
        createHint: {
          id: new Date().toISOString(),
          title,
          content,
          __typename: 'Hint',
        },
      }),
    );
  }),

  graphql.mutation('RemoveHint', (req, res, ctx) => {
    const { id } = req.variables;

    return res(
      ctx.data({
        removeHint: {
          id,
          __typename: 'Response',
        },
      }),
    );
  }),
];
