import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from '@apollo/client';

import { Content } from '@ftrprf/tailwind-components';

import ErrorMessage from '../../components/ErrorMessage';

import useDuplicateExercise from '../../hooks/graphql/useDuplicateExercise';
import useFindAllExercises from '../../hooks/graphql/useFindAllExercises';
import useRemoveExerciseMutation from '../../hooks/graphql/useRemoveExerciseMutation';
import useDebounce from '../../hooks/useDebounce';
import useParams from '../../hooks/useParams';

import { CREATE_SCRATCH_EXERCISE } from '../../api/exercise';

import { EXERCISE, EXERCISES } from '../../utils/constants/urls';

import ExerciseOverview from './ExerciseOverview';

const ExerciseOverviewContainer = () => {
  const params = useParams();
  const history = useHistory();

  const [undebouncedFilters, setFilters] = useState({});

  const filters = useDebounce(undebouncedFilters, 200);

  const currentPage = Number(params.page) || 1;
  const { data, loading, error } = useFindAllExercises({
    currentPage,
    filters,
  });
  const { duplicateExercise: duplicate, loading: duplicateLoading } =
    useDuplicateExercise();

  const setCurrentPage = useCallback(
    (page) => {
      history.push({
        pathname: EXERCISES,
        search: `?page=${page}`,
      });
    },
    [history],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, setCurrentPage]);

  const removeExercise = useRemoveExerciseMutation();
  const [createExercise] = useMutation(CREATE_SCRATCH_EXERCISE);

  const duplicateExercise = useCallback(
    (exerciseId) =>
      duplicate({
        exerciseId,
        onSuccess: (response) =>
          history.push(
            `${EXERCISE}/${response.data.duplicateScratchExercise.id}`,
          ),
      }),
    [duplicate, history],
  );

  if (error) {
    return (
      <Content>
        <ErrorMessage error={error} />
      </Content>
    );
  }

  const paginatedExercises = data?.findAllExercises;

  return (
    <ExerciseOverview
      loading={loading}
      paginatedExercises={paginatedExercises}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      createExercise={createExercise}
      removeExercise={removeExercise}
      setFilters={setFilters}
      duplicateExercise={duplicateExercise}
      duplicateLoading={duplicateLoading}
    />
  );
};

export default ExerciseOverviewContainer;
