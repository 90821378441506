import { useState } from 'react';
import { FaAngleLeft } from 'react-icons/fa';
import { useApolloClient } from '@apollo/client';

import { Content, PageHeader, PageTitle } from '@ftrprf/tailwind-components';

import { ContentCardListSkeleton } from '../../components/ContentCardList';
import OverviewEmpty, { EmptyButton } from '../../components/OverviewEmpty';
import PaginatedContentCardList from '../../components/PaginatedContentCardList';

import useFormatMessage from '../../hooks/useFormatMessage';
import useTitle from '../../hooks/useTitle';

import { ReactComponent as EmptyContent } from '../../assets/vectors/empty-content.svg';

import ContentCard from './partials/ContentCard/ContentCard';
import ExerciseCreateDialog from './partials/ExerciseCreateDialog';
import ExerciseOverviewHeader from './partials/ExerciseOverviewHeader';

const ExerciseOverview = ({
  loading,
  paginatedExercises,
  currentPage,
  setCurrentPage,
  createExercise,
  removeExercise,
  duplicateExercise,
  duplicateLoading,
  setFilters,
}) => {
  const t = useFormatMessage();
  const client = useApolloClient();

  const [showExerciseOverviewDialog, setShowExerciseOverviewDialog] =
    useState(false);

  useTitle(t(`exercise-overview.title`));

  return (
    <>
      <ExerciseCreateDialog
        isOpen={showExerciseOverviewDialog}
        onDismiss={() => setShowExerciseOverviewDialog(false)}
        onSubmit={(data) => {
          createExercise({
            variables: data,
          }).then((res) => {
            window.open(
              `/exercise/${res.data.createScratchExercise.id}`,
              '_blank',
            );
            client.reFetchObservableQueries();
          });
          setShowExerciseOverviewDialog(false);
        }}
      />

      <PageHeader>
        <PageTitle>{t(`exercise-overview.title`)}</PageTitle>

        <ExerciseOverviewHeader
          onNewExerciseClick={() => setShowExerciseOverviewDialog(true)}
          setFilters={setFilters}
        />
      </PageHeader>

      {loading && (
        <Content>
          <ContentCardListSkeleton />
        </Content>
      )}

      {!loading && paginatedExercises.pages === 0 && (
        <Content>
          <OverviewEmpty
            BackgroundImage={EmptyContent}
            emptyText={t('exercise-overview.empty')}
          >
            <EmptyButton onClick={() => setShowExerciseOverviewDialog(true)}>
              {t('exercise-overview.new_exercise')}
            </EmptyButton>
          </OverviewEmpty>
        </Content>
      )}

      {!loading &&
        paginatedExercises.pages !== 0 &&
        currentPage > paginatedExercises.pages && (
          <Content>
            <OverviewEmpty
              BackgroundImage={EmptyContent}
              emptyText={t('exercise-overview.empty_page')}
            >
              <EmptyButton icon={FaAngleLeft} onClick={() => setCurrentPage(1)}>
                {t('exercise-overview.empty_page_action')}
              </EmptyButton>
            </OverviewEmpty>
          </Content>
        )}

      {!loading && paginatedExercises.content.length !== 0 && (
        <Content>
          <div className="w-full flex flex-col">
            <PaginatedContentCardList
              items={paginatedExercises.content}
              itemRender={({ item }) => (
                <ContentCard
                  item={item}
                  onDelete={removeExercise}
                  onDuplicate={duplicateExercise}
                  duplicateLoading={duplicateLoading}
                />
              )}
              totalPages={paginatedExercises.pages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </Content>
      )}
    </>
  );
};

export default ExerciseOverview;
