import { useCallback, useState } from 'react';
import { FaVolumeUp } from 'react-icons/fa';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { ContextMenu } from '@ftrprf/context-menu';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  Label,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

import { FILE_EXTENSIONS } from '../../utils/constants/fileTypes';

import ContentListSelector from '../ContentListSelector/ContentListSelector';
import FormikUrl from '../Form/FormikUrl';

import FileUpload from './FileUpload';

const audioUrlRegex = new RegExp(
  `(${FILE_EXTENSIONS.AUDIO.extensions.join('|')})`,
  'i',
);

const Plugin = ({ editorAPI, editor }) => {
  const t = useFormatMessage();

  const [isExternalShown, setIsExternalShown] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');

  const insertAudioHtml = useCallback(
    (url) => {
      editorAPI.insertHtml(
        `<br/>
        <div style="display: grid; width: 100%; place-items: center; ">
          <audio controls src="${url}" controlsList="nodownload"></audio>
        </div>
        <br/>`,
      );
    },
    [editorAPI],
  );

  return (
    <>
      {isExternalShown && (
        <Dialog>
          <Formik
            initialValues={{
              url: '',
            }}
            validateOnChange
            validateOnBlur={false}
            validationSchema={object({
              url: string()
                .url('validation.url')
                .required('validation.empty')
                .matches(audioUrlRegex, 'validation.external_audio_url'),
            })}
            onSubmit={({ url }) => {
              insertAudioHtml(url);
              setIsExternalShown(false);
            }}
          >
            {({ isValid, setFieldValue, values, errors }) => (
              <Form>
                <DialogHeader>{t('editor.audioplugin.label')}</DialogHeader>
                <DialogContent>
                  <FormikUrl name="url" label={t('global.URL')} />
                  <div>
                    <Label>{t('editor.audioplugin.label-upload')}</Label>
                    {!errors.url && values.url ? (
                      <div className="w-full h-40 grid place-items-center">
                        <audio
                          src={values.url}
                          alt="upload"
                          controls
                          controlsList="nodownload"
                        />
                      </div>
                    ) : (
                      <FileUpload
                        name="file"
                        path={currentPath}
                        setFileUri={(uri) => {
                          setFieldValue('url', uri);
                        }}
                        accept="audio"
                      />
                    )}
                  </div>
                </DialogContent>
                <DialogActions>
                  <OutlineButton
                    onClick={() => {
                      setIsExternalShown(false);
                      setFieldValue('url', null);
                    }}
                  >
                    {t('global.cancel')}
                  </OutlineButton>
                  <FilledButton type="submit" disabled={!isValid}>
                    {t('editor.audioplugin.label')}
                  </FilledButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
      <ContextMenu
        editor={editor}
        trigger={({ _, toggle }) => (
          <FaVolumeUp className="text-base" onClick={toggle} />
        )}
      >
        {({ close }) => (
          <div className="bg-white h-64 w-64 border border-gray">
            <ContentListSelector
              onFileClick={(url) => {
                insertAudioHtml(url);
                close();
              }}
              allowedTypes={FILE_EXTENSIONS.AUDIO.extensions}
              onExternalClick={() => {
                setIsExternalShown(true);
                close();
              }}
              setCurrentPath={setCurrentPath}
            />
          </div>
        )}
      </ContextMenu>
    </>
  );
};

export const AudioPlugin = ({ ...props }) => {
  const t = useFormatMessage();

  return <Plugin label={t('editor.audioplugin.label')} {...props} />;
};
