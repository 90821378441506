import c from '../../utils/c';

const ProgressBase = ({ percentage, className }) => {
  return (
    <div className="w-full relative">
      <div className="w-full bg-gray-200 rounded h-2" />
      <div className="absolute w-full top-0">
        <div
          className={c('rounded h-2', className)}
          style={{ width: `${percentage}%` }}
        />
        <div style={{ width: `${100 - percentage}%` }} />
      </div>
    </div>
  );
};

export default ProgressBase;
