import { useContext } from 'react';

import c from '../../utils/c';

import parseHtml from './parseHtml';
import { SlideViewerContext } from './SlideViewerContextProvider';

const SlideViewerBaseSlide = ({ value, processor, classNames }) => {
  const context = useContext(SlideViewerContext);

  const className = c('SlideViewerTextSlide', classNames);

  // when no context is found, don't try to parse with custom components
  if (!context) {
    return <div className={className}>{parseHtml(value)}</div>;
  }

  return <div className={className}>{processor.processSync(value).result}</div>;
};

export default SlideViewerBaseSlide;
