const ChevronRight = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.933"
      height="38.176"
      fill="currentColor"
      viewBox="0 0 16.933 38.176"
      className={className}
    >
      <path
        d="M159.922 49.252l-15.807-18.687a.393.393 0 01-.094-.258.413.413 0 01.094-.262l15.81-18.686a.4.4 0 01.567-.048l.32.272a.4.4 0 01.049.567L145.5 30.305l15.361 18.155a.4.4 0 01-.049.567l-.32.272a.392.392 0 01-.257.094.41.41 0 01-.313-.141z"
        className="fill-current"
        transform="rotate(180 80.476 24.697)"
      />
    </svg>
  );
};

export default ChevronRight;
