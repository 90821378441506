import { useContext } from 'react';

import { SlideViewerContext } from './SlideViewerContextProvider';

const SlideViewerSlideNumber = () => {
  const { slides, currentSlideIndex } = useContext(SlideViewerContext);

  return (
    <div>
      {currentSlideIndex + 1}/{slides.length}
    </div>
  );
};

export default SlideViewerSlideNumber;
