import { useContext } from 'react';

import { SlideViewerContext } from './SlideViewerContextProvider';

const SlideViewerProgress = ({ children }) => {
  const { slides, currentSlideIndex } = useContext(SlideViewerContext);

  const progress =
    slides.length <= 1
      ? 100
      : Math.round((currentSlideIndex / (slides.length - 1)) * 100);

  return children({ progress });
};

export default SlideViewerProgress;
