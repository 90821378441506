import { useQuery } from '@apollo/client';

import { FIND_ALL_TRANSLATION_GROUPNAMES } from '../../api/translations';

const useTranslationGroupNames = () => {
  const { data, loading, error, refetch } = useQuery(
    FIND_ALL_TRANSLATION_GROUPNAMES,
  );

  return {
    data: data?.findAllTranslationGroupNames,
    isLoading: loading,
    error,
    refetch,
  };
};

export default useTranslationGroupNames;
