import { useState } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';

import c from '../utils/c';

import { Input } from './form/Input';

export const SearchBar = ({
  className,
  items,
  children,
  placeholder,
  onChange,
}) => {
  const [searchString, setSearchString] = useState('');

  const isSearching = searchString && searchString !== '';

  const filteredItems =
    isSearching &&
    items?.filter(({ value }) =>
      value.toLowerCase().includes(searchString.toLowerCase()),
    );

  return (
    <div className={c(className, 'w-full relative')}>
      <Input
        type="text"
        value={searchString}
        onChange={(e) => {
          setSearchString(e.target.value);
          onChange(e);
        }}
        placeholder={placeholder}
      />
      <div
        className={c(
          'absolute h-full top-0 flex items-center right-3 text-gray-500 text-sm',
          isSearching && 'cursor-pointer',
        )}
        onClick={() => isSearching && setSearchString('')}
      >
        {isSearching ? <FaTimes /> : <FaSearch />}
      </div>
      {filteredItems?.length > 0 && (
        <div className="absolute right-0 z-30 min-w-full bg-white border-1 border-gray-500 shadow-base whitespace-nowrap rounded overflow-hidden p-1">
          {filteredItems.map((item) => {
            return children({ item, searchString });
          })}
        </div>
      )}
    </div>
  );
};

SearchBar.defaultProps = {
  onChange: () => {},
};
