const interpolate = (string, data) => {
  const searchValue = /{{.+?}}/g;

  return string.replace(searchValue, (rawProperty) => {
    const propertyWithFilters = rawProperty.substring(2, rawProperty.length - 2).trim();

    const [property, ...filters] = propertyWithFilters.split(' | ');

    const value = data[property];

    return filters.reduce((acc, filter) => {
      if (filter === 'ucase') {
        return acc.charAt(0).toUpperCase() + acc.slice(1);
      }

      if (filter === 'lowercase') {
        return acc.toLowerCase();
      }

      // TODO: throw undefined filter

      return acc;
    }, value);
  });
};

export const generate = (groups, translations) => {
  return Object.fromEntries(
    Object.values(groups)
      .map((data) =>
        Object.entries(translations).map(([translationKey, translationValue]) => {
          return [interpolate(translationKey, data), interpolate(translationValue, data)];
        }),
      )
      .flat(),
  );
};
