import { FaChevronDown } from 'react-icons/fa';

import c from '../utils/c';

const DropdownIcon = ({ className, isOpen }) => {
  return (
    <FaChevronDown
      className={c(
        className,
        isOpen
          ? 'transform -scale-y-1 transition duration-150'
          : 'transition duration-150',
      )}
    />
  );
};

export default DropdownIcon;
