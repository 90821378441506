/*
    This function maps the result of the api call to an object
    the tree expects
 */

export default (content) => {
  return new Promise((resolve) => {
    const mappedItems = content.reduce((obj, item, index) => {
      const { id, ...data } = item;
      return {
        ...obj,
        [item.id]: {
          id,
          children: [],
          hasChildren: false,
          isExpanded: false,
          isChildrenLoading: false,
          data,
          index,
        },
      };
    }, {});
    const returnItems = {
      rootId: '1',
      items: {
        1: {
          id: '1',
          children: Object.values(mappedItems)
            .sort((a, b) => a.index - b.index)
            .map(({ id }) => id),
          hasChildren: false,
          isExpanded: false,
          isChildrenLoading: false,
          data: {
            path: '/',
          },
        },
        ...mappedItems,
      },
    };
    resolve(returnItems);
  });
};
