import { useState } from 'react';
import { Form, Formik } from 'formik';
import { array, object, string } from 'yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  Loader,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import FormikInput from '../../../components/Form/FormikInput';
import MultiSelectList from '../../../components/MultiSelectList';

import useFormatMessage from '../../../hooks/useFormatMessage';

const AddEditGroupDialog = ({
  id,
  loading,
  onSubmit,
  onDismiss,
  isNew,
  name,
  description,
  users,
  currentMembers,
  organizationId,
  ...props
}) => {
  const t = useFormatMessage();

  const [members, setMembers] = useState(currentMembers);

  return (
    <Dialog {...props}>
      <DialogHeader>
        {isNew ? t('admin.groups.add-group') : t('admin.groups.edit-group')}
      </DialogHeader>
      <Formik
        initialValues={{ name: name || '' }}
        onSubmit={({ name }) =>
          onSubmit({ id: id, organizationId, name: name, users: members })
        }
        validationSchema={object({
          name: string().required(),
          members: array(),
        })}
        validateOnBlur={false}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <DialogContent>
              <FormikInput
                name="name"
                type="text"
                placeholder={t('admin.name')}
                label={t('admin.name')}
                disabled={isSubmitting}
              />
              {loading ? (
                <div className="h-5 w-5">
                  <Loader />
                </div>
              ) : (
                <div>
                  <p>{t('admin.users')}</p>
                  <MultiSelectList
                    items={users?.map(({ id, firstName, lastName }) => ({
                      id,
                      label: `${firstName} ${lastName}`,
                    }))}
                    members={members}
                    setMembers={setMembers}
                  />
                </div>
              )}
            </DialogContent>
            <DialogActions>
              <OutlineButton onClick={onDismiss}>
                {t('global.cancel')}
              </OutlineButton>
              <FilledButton type="submit" disabled={isSubmitting || !isValid}>
                {t('global.confirm')}
              </FilledButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

AddEditGroupDialog.defaultProps = {
  currentMembers: [],
};

export default AddEditGroupDialog;
