import { useContext, useState } from 'react';
import { FaPencilAlt } from 'react-icons/fa';
import { useQuery } from '@apollo/client';

import { FilledButton, NotificationContext } from '@ftrprf/tailwind-components';

import Center from '../../../components/Center';
import ErrorMessage from '../../../components/ErrorMessage';
import InlineEdit from '../../../components/InlineEdit';
import OverviewEmpty from '../../../components/OverviewEmpty';
import Pagination from '../../../components/Pagination';
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
  TableSkeleton,
} from '../../../components/Table/index';

import useFieldMutation from '../../../hooks/graphql/useFieldMutation';
import useFormatMessage from '../../../hooks/useFormatMessage';

import { FETCH_ALL_USERS_BY_GROUP_ID, UPDATE_GROUP } from '../../../api/admin';

import AddEditGroupDialog from './AddEditGroupDialog';

const GroupDetail = ({ organizationId, organizationUsers, group }) => {
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);
  const userWidths = [1, 1, 1];

  const [editGroupDialog, toggleEditGroupDialog] = useState(false);

  const [groupUsersVariables, setGroupUsersVariables] = useState({
    currentPage: 0,
  });
  const groupUsersOptions = {
    variables: {
      groupId: group.id,
      page: groupUsersVariables.currentPage,
      size: 10,
    },
  };
  const { loading, error, data } = useQuery(
    FETCH_ALL_USERS_BY_GROUP_ID,
    groupUsersOptions,
  );
  const groupUsers = data?.fetchAllUsersByGroupId.content;
  const totalPages = data?.fetchAllUsersByGroupId.pages;

  const [updateGroupMutation] = useFieldMutation(UPDATE_GROUP, 'Group');
  const updateGroup = (variables) => {
    return updateGroupMutation(variables).then(() => {
      addNotification({
        type: 'success',
        content: t('admin.groups.edit-group.success'),
      });
    });
  };

  return (
    <>
      {editGroupDialog && (
        <AddEditGroupDialog
          loading={loading}
          organizationId={organizationId}
          onSubmit={async (variables) => {
            await updateGroup(variables);
            toggleEditGroupDialog(false);
          }}
          onDismiss={() => {
            if (editGroupDialog) {
              toggleEditGroupDialog(false);
            }
          }}
          id={group.id}
          users={organizationUsers}
          name={group.name}
          currentMembers={groupUsers.map(({ id }) => id)}
        />
      )}
      <div className="w-3/5 ml-4">
        <div className="w-full flex justify-between px-2">
          <div className="w-3/4">
            <InlineEdit
              className="text-xl font-bold w-1/2"
              name="name"
              value={group.name}
              onSubmit={(value) =>
                updateGroup({
                  id: group.id,
                  name: value,
                  organizationId,
                  users: groupUsers.map(({ id }) => id),
                })
              }
            />
          </div>
          <FilledButton
            iconBefore={FaPencilAlt}
            className="max-w-1/2 ml-2 text-sm"
            onClick={() => toggleEditGroupDialog(true)}
          >
            {t('admin.groups.edit-group')}
          </FilledButton>
        </div>
        <Table widths={userWidths} className="px-2">
          <TableRow>
            <TableHeaderCell>{t('admin.firstname')}</TableHeaderCell>
            <TableHeaderCell>{t('admin.lastname')}</TableHeaderCell>
            <TableHeaderCell>{t('global.username')}</TableHeaderCell>
          </TableRow>
          {loading ? (
            <TableSkeleton widths={userWidths} />
          ) : error ? (
            <ErrorMessage className="mt-4 text-center" error={error} />
          ) : groupUsers.map((user) => user).length === 0 ? (
            <OverviewEmpty table />
          ) : (
            groupUsers?.map(({ firstName, lastName, username }) => (
              <TableRow key={username}>
                <TableCell>{firstName}</TableCell>
                <TableCell>{lastName}</TableCell>
                <TableCell>{username}</TableCell>
              </TableRow>
            ))
          )}
        </Table>
        {totalPages > 1 && (
          <Center>
            <Pagination
              currentPage={groupUsersVariables.currentPage}
              totalPages={totalPages}
              onClick={(val) => setGroupUsersVariables({ currentPage: val })}
            />
          </Center>
        )}
      </div>
    </>
  );
};

export default GroupDetail;
