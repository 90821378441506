import { gql } from '@apollo/client';

export const FETCH_ALL_ORGANIZATIONS_BY_DOMAIN = gql`
  query FetchAllOrganizationsByDomain(
    $domain: OrganizationDomain
    $filter: [JSON]
    $page: Int!
    $size: Int!
  ) {
    fetchAllOrganizationsByDomain(
      domain: $domain
      filter: $filter
      page: $page
      size: $size
    ) {
      pages
      currentPage
      content {
        id
        name
        domain
        linkId
      }
    }
  }
`;

export const FETCH_ALL_GROUPS_BY_ORGANIZATION = gql`
  query FetchAllGroupsByOrganization(
    $organizationId: Int!
    $page: Int!
    $size: Int!
  ) {
    fetchAllGroupsByOrganizationId(
      organizationId: $organizationId
      page: $page
      size: $size
    ) {
      total
      pages
      currentPage
      content {
        id
        name
        organizationId
      }
    }
  }
`;

export const FETCH_ALL_USERS_BY_ORGANIZATION_ID = gql`
  query FetchAllUsersByOrganzationId(
    $organizationId: Int!
    $page: Int!
    $size: Int!
  ) {
    fetchAllUsersByOrganizationId(
      organizationId: $organizationId
      page: $page
      size: $size
    ) {
      total
      content {
        id
        firstName
        lastName
        username
      }
    }
  }
`;

export const FETCH_ALL_USERS_BY_GROUP_ID = gql`
  query FetchAllUsersByGroupId($groupId: Int!, $page: Int!, $size: Int!) {
    fetchAllUsersByGroupId(groupId: $groupId, page: $page, size: $size) {
      total
      content {
        id
        firstName
        lastName
        username
      }
    }
  }
`;

export const ADD_USER = gql`
  mutation AddUserToGroup(
    $userId: userId
    $contextId: id
    $contextType: type
    $role: role
  ) {
    addUserToGroup(
      input: {
        userId: $userId
        contextId: $contextId
        contextType: $contextType
        role: $role
      }
    ) {
      id
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUserToGroup($id: id) {
    removeUserFromGroup(id: $id) {
      statuscode
      id
      message
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateGroup($name: String!, $organizationId: Int!, $users: [Int]) {
    createGroup(name: $name, organizationId: $organizationId, users: $users) {
      id
    }
  }
`;

export const REMOVE_GROUP = gql`
  mutation RemoveGroup($id: Int!) {
    removeGroup(id: $id) {
      id
      statuscode
      message
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation UpdateGroup(
    $id: Int!
    $name: String
    $organizationId: Int
    $users: [Int]
  ) {
    updateGroup(
      id: $id
      name: $name
      organizationId: $organizationId
      users: $users
    ) {
      id
    }
  }
`;
