import { Input, InputGroup, Label } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useOpenQuestion from '../../hooks/useOpenQuestion';

import SideBarContent from './SideBarContent';

const OpenQuestionSideBar = ({ id, disabled: contentDisabled }) => {
  const t = useFormatMessage();
  const { question, update } = useOpenQuestion(id);

  const onChange = (e) => {
    if (!isNaN(e.target.value) && e.target.value >= 0) {
      update({ scorePerCorrectAnswer: e.target.value });
    }
  };

  if (!question) {
    return null;
  }

  const disabled = contentDisabled || question.publishedAt;

  return (
    <SideBarContent className="overflow-auto" title={t('slide_type.OPEN')}>
      <div className="p-3">
        <InputGroup>
          <Label>{t('open-question-sidebar.points')}</Label>
          <Input
            value={question.scorePerCorrectAnswer}
            type="number"
            onChange={onChange}
            min="0"
            disabled={disabled}
          />
        </InputGroup>

        <InputGroup>
          <Label>{t('open-question-sidebar.placeholder')}</Label>
          <Input
            value={question.placeholder}
            type="multiline"
            disabled={contentDisabled}
            onChange={(e) => {
              update({ placeholder: e.target.value });
            }}
          />
        </InputGroup>

        <InputGroup>
          <Label>{t('open-question-sidebar.example_solution')}</Label>
          <Input
            value={question.solution}
            type="multiline"
            disabled={contentDisabled}
            onChange={(e) => {
              update({ solution: e.target.value });
            }}
          />
        </InputGroup>
      </div>
    </SideBarContent>
  );
};

export default OpenQuestionSideBar;
