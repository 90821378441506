import { Link, useHistory } from 'react-router-dom';

import { ContentCard } from '@ftrprf/tailwind-components';

import { ReactComponent as EmptyImage } from '../../../../assets/vectors/empty-image.svg';

import ContentCardContextMenu from './ContentCardContextMenu';

const ExerciseContentCard = ({
  item,
  onDelete,
  onDuplicate,
  duplicateLoading,
}) => {
  const history = useHistory();

  const editURL = `/exercise/${item.id}`;

  return (
    <ContentCardContextMenu
      editURL={editURL}
      onDelete={() => onDelete(item.id)}
      onDuplicate={() => onDuplicate(item.id)}
      duplicateLoading={duplicateLoading}
    >
      {({ toggle }) => (
        <Link to={editURL} target="_blank">
          {' '}
          <ContentCard
            key={item.id}
            title={item.title}
            defaultImage={EmptyImage}
            image={item.image}
            onClick={() => history.push(editURL)}
            toggleContextMenu={toggle}
          />
        </Link>
      )}
    </ContentCardContextMenu>
  );
};

export default ExerciseContentCard;
