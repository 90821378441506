import { Suspense } from 'react';
import { Route } from 'react-router-dom';

import { Loader } from '@ftrprf/tailwind-components';

import FullPageLayout from '../layouts/FullPageLayout';

// A restricted route is only accessible to unauthenticated users,
// e.g. a Sign In / Sign Up page
const RestrictedRoute = ({
  component: Component,
  render,
  layout: PageLayout,
  ...rest
}) => {
  return (
    <Route
      render={(matchProps) => (
        <PageLayout>
          <Suspense
            fallback={
              <div className="h-5 w-5">
                <Loader />
              </div>
            }
          >
            {render ? (
              render(matchProps)
            ) : (
              <Component {...matchProps} {...rest} />
            )}
          </Suspense>
        </PageLayout>
      )}
    />
  );
};

RestrictedRoute.defaultProps = {
  layout: FullPageLayout,
};

export default RestrictedRoute;
