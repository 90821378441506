import { FaTimes } from 'react-icons/fa';
import { FormattedDate, FormattedTime } from 'react-intl';

import { FilledButton } from '@ftrprf/tailwind-components';

import ContentIcon from '../../../components/ContentIcon';
import CopyClipboard from '../../../components/CopyClipboard';
import Divider from '../../../components/Divider';
import StyledIcon from '../../../components/StyledIcon';

import useFormatMessage from '../../../hooks/useFormatMessage';

import c from '../../../utils/functions/c';
import formatBytes from '../../../utils/functions/formatBytes';
import { baseName } from '../../../utils/functions/path';

const formatDate = (date, t) => (
  <>
    <FormattedDate value={date} /> {t('global.on')}{' '}
    <FormattedTime value={date} />
  </>
);

const order = [
  { detailName: 'extension' },
  { detailName: 'size', f: ({ data: fileSize }) => formatBytes(fileSize) },
  { detailName: 'modifiedOn', f: ({ data: date, t }) => formatDate(date, t) },
  { detailName: 'modifiedBy' },
  { detailName: 'createdOn', f: ({ data: date, t }) => formatDate(date, t) },
  { detailName: 'createdBy' },
];

const applyFunction = (f, detailName, derivedFrom, selectedItem, t) => {
  if (derivedFrom) {
    return f({ data: selectedItem[derivedFrom], t });
  }
  if (f) {
    return f({ data: selectedItem[detailName], t });
  }
  return selectedItem[detailName];
};

const aggregateFields = (selectedItems) =>
  selectedItems.length === 1
    ? selectedItems[0]
    : selectedItems.reduce(
        (prev, current) => ({ fileSize: prev.fileSize + current.fileSize }),
        {
          fileSize: 0,
        },
      );

const ContentPreview = ({ className, selectedItems, close }) => {
  const t = useFormatMessage();

  if (selectedItems.length === 0) {
    return null;
  }

  const hasOnlyOneItem = selectedItems.length === 1;
  const selectedItem = aggregateFields(selectedItems);

  return (
    <div className={c(className, 'p-2 m-4 relative')}>
      <div className="absolute right-0 top-0 mt-4" />
      <div className="grid grid-cols-2 gap-4 w-full break-all">
        <span className="col-span-2">
          <div className="flex content-between w-full justify-between">
            <span className="font-bold text-xl">
              {hasOnlyOneItem
                ? baseName(selectedItem.path)
                : t('content-manager.preview.mutiple_files')}
            </span>
            <div
              className="text-sm self-center cursor-pointer"
              onClick={() => close()}
            >
              <FaTimes />
            </div>
          </div>
        </span>
        <span className="col-span-2 h-40 text-center">
          <StyledIcon className="h-full w-full text-accent-500 opacity-50 mb-4">
            <ContentIcon {...selectedItem} multiple={!hasOnlyOneItem} />
          </StyledIcon>
        </span>
        {hasOnlyOneItem && (
          <>
            <Divider className="col-span-2" />
            {order.map(({ detailName, f, derivedFrom }) => (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {(selectedItem[detailName] || selectedItem[derivedFrom]) && (
                  <>
                    <span className="break-words text-sm">
                      {t(
                        `content-manager.preview.${derivedFrom || detailName}`,
                      )}
                    </span>
                    <span className="break-words text-sm">
                      {applyFunction(
                        f,
                        detailName,
                        derivedFrom,
                        selectedItem,
                        t,
                      )}
                    </span>
                  </>
                )}
              </>
            ))}
          </>
        )}
      </div>
      {hasOnlyOneItem && selectedItem.isFile && (
        <div className="flex justify-center mt-6">
          <CopyClipboard value={selectedItem.blobUri}>
            <FilledButton secondary>
              {t('global.copy')} {t('global.URL')}
            </FilledButton>
          </CopyClipboard>
        </div>
      )}
    </div>
  );
};

export default ContentPreview;
