import { useMemo } from 'react';

import DropdownIcon from './DropdownIcon';
import { DropdownMenu } from './DropdownMenu';
import { VersionLabel } from './VersionLabel';

export const VersionNumberSelector = ({ values, selected, onChange }) => {
  const sortedValues = useMemo(() => {
    return values.sort((a, z) => z - a);
  }, [values]);

  return (
    <DropdownMenu
      trigger={({ isOpen }) => (
        <button className="flex leading-relaxed">
          <VersionLabel
            className="flex"
            version={
              <div className="flex items-center justify-center">
                <div className="mr-1">{selected || sortedValues[0]}</div>
                <DropdownIcon isOpen={isOpen} className="w-2" />
              </div>
            }
          />
        </button>
      )}
    >
      {({ hide }) => (
        <div className="flex flex-col">
          {sortedValues.map((version) => (
            <button
              className="px-1 hover:bg-accent-100 rounded"
              onClick={() => {
                onChange(version);
                hide();
              }}
              key={version}
            >
              v{version}
            </button>
          ))}
        </div>
      )}
    </DropdownMenu>
  );
};

VersionNumberSelector.defaultProps = {
  values: [],
};
