import { Content, PageTitle } from '@ftrprf/tailwind-components';

import Center from '../../components/Center';

import useTitle from '../../hooks/useTitle';
import useFormatMessage from './../../hooks/useFormatMessage';

import { domains } from '../../utils/constants/domains';
import c from '../../utils/functions/c';

import { ReactComponent as CodefeverLogo } from '../../assets/vectors/logo-codefever.svg';
import { ReactComponent as FTRPRFLogo } from '../../assets/vectors/logo-FTRPRF.svg';

import DomainCard from './partials/DomainCard';

const Admin = () => {
  const t = useFormatMessage();

  useTitle(t('header.admin'));

  return (
    <Content>
      <div className="flex flex-grow flex-col">
        <Center>
          <PageTitle>{t('header.admin')}</PageTitle>
        </Center>
        <div
          className={c(
            'flex flex-col justify-evenly md:flex-row md:justify-around md:-mt-32 flex-grow items-center',
          )}
        >
          <DomainCard
            className=""
            name={domains.CODEFEVER}
            Logo={CodefeverLogo}
          />
          <DomainCard className="" name={domains.FTRPRF} Logo={FTRPRFLogo} />
        </div>
      </div>
    </Content>
  );
};

export default Admin;
