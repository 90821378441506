import { Link } from 'react-router-dom';

const DomainCard = ({ Logo, name }) => {
  return (
    <Link
      to={`/admin/${name}`}
      className="p-4 shadow-base flex flex-col justify-center w-full md:w-1/3 pt-16 transition duration-200 ease-in-out hover:shadow-none transform hover:translate-x-px hover:translate-y-px mt-4 md:mt-0"
    >
      <div className="w-full h-full object-contain mb-16">
        {
          <div className="h-32">
            <Logo />
          </div>
        }
      </div>
      <h2 className="text-center font-semibold text-xl m-4">{name}</h2>
    </Link>
  );
};

export default DomainCard;
