import useFormatMessage from '../../../hooks/useFormatMessage';
import useScrollTopRef from '../../../hooks/useScrollTopRef';

import { SlideViewerTextSlide } from '../../slideviewer/SlideViewer';

const SideBarTeacherInfo = ({ currentSlide }) => {
  const t = useFormatMessage();

  const scrollRef = useScrollTopRef(currentSlide);

  return (
    <div className="overflow-hidden flex flex-col flex-grow px-4">
      <div className="mt-4 mb-1 text-2xl font-bold">
        {t('styled-slide-viewer.teacher_manual')}
      </div>
      <div className="overflow-y-auto flex-grow" ref={scrollRef}>
        {currentSlide.info ? (
          <SlideViewerTextSlide
            value={currentSlide.info}
            classNames="TeacherInfoSidebar"
          />
        ) : (
          <div className="text-gray-600 text-sm">
            {t('styled-slide-viewer.teacher_manual_empty')}
          </div>
        )}
      </div>
    </div>
  );
};

export default SideBarTeacherInfo;
