import c from '../../utils/c';

import { Button } from './Button';

export const DefaultButton = ({ children, className, ...props }) => (
  <Button
    className={c(
      className,
      'hover:bg-gray-200 py-1 rounded px-4 transition-colors duration-100',
    )}
    {...props}
  >
    {children}
  </Button>
);
