import { useContext } from 'react';
import { FaPaperPlane } from 'react-icons/fa';
import confetti from 'canvas-confetti';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import { FilledButton } from '../../../buttons/FilledButton';
import { SlideViewerContext } from '../../../slideviewer/SlideViewerContextProvider';

import SlideViewerMultipleChoiceAnswerCard from './SlideViewerMultipleChoiceAnswerCard';

// This is a little helper to check if 2 arrays contains the same elements.
// This will only work for primitives (not objects) and is probably not efficient for larger arrays.
const containsSameElements = (first = [], second = []) => {
  return first.sort().join(',') === second.sort().join(',');
};

const SlideViewerMultipleChoiceQuestion = ({ question, canSubmit }) => {
  const t = useFormatMessage();

  const {
    showConfetti,
    temporaryQuestionAnswers,
    submittedQuestionAnswers,
    onTemporaryQuestionAnswers,
    onSubmitQuestionAnswers,
    canOverrideAnswers,
  } = useContext(SlideViewerContext);

  const submittedQuestionAnswer = submittedQuestionAnswers.find(
    (submittedQuestion) =>
      submittedQuestion.questionId.toString() === question.id.toString(),
  );

  const submittedQuestionAnswerValues = submittedQuestionAnswer?.answers?.map(
    ({ multipleChoiceAnswerId }) => multipleChoiceAnswerId,
  );

  const temporaryQuestionAnswerValues = temporaryQuestionAnswers[question.id];

  const _values =
    (temporaryQuestionAnswerValues === undefined
      ? submittedQuestionAnswerValues
      : temporaryQuestionAnswerValues) || [];

  // Sometimes an integer is returned as a string (Apollo), so this makes sure everything is a string
  const values = _values.map((x) => x.toString());

  const onTemporaryAnswersSelect = (answerId) => {
    const newSelectedAnswers = values.includes(answerId)
      ? values.filter((id) => id !== answerId)
      : [...values, answerId];

    onTemporaryQuestionAnswers(question, newSelectedAnswers);
  };

  const onSubmit = () => {
    onSubmitQuestionAnswers(question, values).then(
      ({ question, submittedQuestionAnswer }) => {
        const allCorrectItemIds = question.questionAnswersMultipleChoice
          .filter((i) => i.correct)
          .map((i) => i.id.toString());

        const allChosenItemIds = submittedQuestionAnswer.answers.map((a) =>
          a.multipleChoiceAnswerId.toString(),
        );

        const allChosenCorrectItemIds = allChosenItemIds.filter((id) =>
          allCorrectItemIds.includes(id),
        );

        const allChosenWrongItemIds = allChosenItemIds.filter(
          (id) => !allCorrectItemIds.includes(id),
        );

        // user has chosen one or more wrong items
        if (allChosenWrongItemIds.length > 0) {
          return;
        }

        // TODO: maxSelectableAnswers https://codefever.atlassian.net/browse/TC-738
        if (allChosenCorrectItemIds.length === allCorrectItemIds.length) {
          if (!canOverrideAnswers && showConfetti) {
            confetti({
              particleCount: 100,
              spread: 70,
              origin: { y: 0.6 },
              startVelocity: 60,
              ticks: 70,
              gravity: 2,
            });
          }
        }
      },
    );
  };

  // the button is disabled if we can't submit
  // or when there are no selected items
  // or when there is no temporary question answer value
  // or when there is a temporary question answer value but it's the same as the submitted question answer value
  const disabled =
    !canSubmit ||
    values.length === 0 ||
    !temporaryQuestionAnswerValues ||
    (!!temporaryQuestionAnswerValues &&
      containsSameElements(
        submittedQuestionAnswerValues,
        temporaryQuestionAnswerValues,
      ));

  const { maxSelectableAnswers = 15 } = question;
  const canSelect = values && values.length < maxSelectableAnswers;

  return (
    <div>
      <div className="mt-4 grid gap-4 grid-cols-2 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 clear-both">
        {question.questionAnswersMultipleChoice.map((answer, index) => {
          const answerIsSelected = values.includes(answer.id.toString());

          return (
            <SlideViewerMultipleChoiceAnswerCard
              answer={answer}
              key={answer.id}
              index={index}
              selectAnswer={() =>
                onTemporaryAnswersSelect(answer.id.toString())
              }
              isClicked={answerIsSelected}
              hasResults={
                !canSubmit &&
                !canOverrideAnswers &&
                answer.correct !== undefined &&
                answer.correct !== null
              }
              disabled={!canSubmit || (!canSelect && !answerIsSelected)}
            />
          );
        })}
      </div>
      <div className="mt-8 w-full flex justify-center">
        <FilledButton
          iconBefore={FaPaperPlane}
          disabled={disabled}
          onClick={onSubmit}
        >
          {canOverrideAnswers
            ? canSubmit
              ? t('styled-slide-viewer.save')
              : t('styled-slide-viewer.saved')
            : canSubmit
            ? t('styled-slide-viewer.submit')
            : t('styled-slide-viewer.submitted')}
        </FilledButton>
      </div>
    </div>
  );
};

export default SlideViewerMultipleChoiceQuestion;
