import { useEffect } from 'react';

import SlideThumbnail from '../../../../components/SlideThumbnail';

import useSlide from '../../hooks/useSlide';

import c from '../../../../utils/functions/c';

import SlideContextMenu from './SlideContextMenu';
import SlideOverviewItemViewModes from './SlideOverviewItemViewModes';

const primaryButton = 0;

const keyCodes = {
  enter: 13,
};

const isToggleInSelectionGroupKeyUsed = (event) => {
  const isUsingMac = window.navigator.platform.includes('Mac');
  return isUsingMac ? event.metaKey : event.ctrlKey;
};

const SlideOverviewItem = ({
  slideId,
  hasViewModeSelector,
  index,
  draggableSnapshot,
  isSelected,
  isGhosting,
  toggleSelection,
  toggleSelectionGroupKey,
  toggleSelectionShiftKey,
  onInsert,
  onDelete,
  onMoveDown,
  onMoveUp,
  onDuplicate,
  disabled,
  originalIndex,
}) => {
  const { slide, update, refetch } = useSlide(slideId);

  useEffect(() => {
    if (slide && slide.sequence !== index + 1) {
      update({ sequence: index + 1 }, false);
    }
  }, [index, slide, update]);

  useEffect(() => {
    refetch();
  }, [index]);

  if (!slide) {
    return null;
  }

  const performAction = (event) => {
    if (isToggleInSelectionGroupKeyUsed(event)) {
      toggleSelectionGroupKey(slide);
      return;
    }

    if (event.shiftKey === true) {
      toggleSelectionShiftKey(slide);
      return;
    }

    toggleSelection(slide);
  };

  const onKeyDown = (event) => {
    if (event.defaultPrevented) {
      return;
    }

    if (draggableSnapshot.isDragging) {
      return;
    }

    if (event.keyCode !== keyCodes.enter) {
      return;
    }

    event.preventDefault();

    performAction(event);
  };

  const onClick = (event) => {
    if (event.defaultPrevented) {
      return;
    }

    if (event.button !== primaryButton) {
      return;
    }

    event.preventDefault();

    performAction(event);
  };

  return (
    <SlideContextMenu
      onInsert={onInsert}
      onDelete={onDelete}
      onDuplicate={onDuplicate}
      onMoveDown={onMoveDown}
      onMoveUp={onMoveUp}
      disabled={disabled}
    >
      {({ toggle }) => (
        <div
          className={c(
            'px-4 pt-4 pb-2 select-none border bg-white rounded',
            isSelected &&
              !isGhosting &&
              'bg-accent-100 border-accent-200 text-accent-700',
            isGhosting && 'bg-accent-200 border-accent-200 text-accent-700',
            !isSelected && !isGhosting && 'bg-gray-200 border-gray-300',
          )}
          onKeyDown={onKeyDown}
          onClick={onClick}
        >
          <div className="flex justify-between mb-3">
            <div className="truncate">
              {originalIndex + 1} - {slide.title}
            </div>
            <button
              className="font-bold"
              type="button"
              onClick={(e) => toggle(e)}
            >
              &#65049;
            </button>
          </div>
          <SlideThumbnail
            className={c(
              'border rounded overflow-hidden',
              isSelected && !isGhosting && 'border-accent-200',
              isGhosting && 'border-accent-200 text-accent-700',
              !isSelected && !isGhosting && 'border-gray-300',
            )}
            slide={slide}
          />
          <div className="flex justify-end mt-1">
            <SlideOverviewItemViewModes
              hasViewModeSelector={hasViewModeSelector}
              disabled={disabled}
              slideId={slideId}
            />
          </div>
        </div>
      )}
    </SlideContextMenu>
  );
};

export default SlideOverviewItem;
