import {
  FaFileArchive,
  FaFileAudio,
  FaFileCode,
  FaFileExcel,
  FaFileImage,
  FaFilePdf,
  FaFilePowerpoint,
  FaFileVideo,
  FaFileWord,
} from 'react-icons/fa';

const FILE_EXTENSIONS_LOWERCASE = {
  ARCHIVE: {
    type: 'ARCHIVE',
    icon: <FaFileArchive />,
    extensions: ['zip', 'rar', '7z'],
  },
  AUDIO: {
    type: 'AUDIO',
    icon: <FaFileAudio />,
    extensions: [
      'mp3',
      'aif',
      'cda',
      'mid',
      'midi',
      'ogg',
      'wav',
      'wma',
      'wpl',
      'aac',
    ],
  },
  CODE: {
    type: 'CODE',
    icon: <FaFileCode />,
    extensions: ['py', 'sb3', 'html', 'htm', 'css', 'js'],
  },
  EXCEL: {
    type: 'EXCEL',
    icon: <FaFileExcel />,
    extensions: ['xlsx', 'xlsm', 'xls', 'ods'],
  },
  IMAGE: {
    type: 'IMAGE',
    icon: <FaFileImage />,
    extensions: [
      'apng',
      'bmp',
      'gif',
      'ico',
      'cur',
      'jpg',
      'jpeg',
      'jfif',
      'pjpeg',
      'pjp',
      'png',
      'svg',
      'tif',
      'tiff',
    ],
  },
  PDF: { type: 'PDF', icon: <FaFilePdf />, extensions: ['pdf'] },
  PPT: {
    type: 'PPT',
    icon: <FaFilePowerpoint />,
    extensions: ['ppt', 'pptx', 'odp'],
  },
  VIDEO: {
    type: 'VIDEO',
    icon: <FaFileVideo />,
    extensions: ['mp4', 'wmv', 'webm', 'flv', 'avi'],
  },
  WORD: {
    type: 'WORD',
    icon: <FaFileWord />,
    extensions: ['doc', 'docx', 'odt'],
  },
};

// Also allow uppercase versions of jpg, png, ...
export const FILE_EXTENSIONS = Object.keys(FILE_EXTENSIONS_LOWERCASE).reduce(
  (obj, val) => {
    obj[val].extensions = [
      ...obj[val].extensions,
      ...obj[val].extensions.map((extension) => extension.toUpperCase()),
    ];
    return obj;
  },
  { ...FILE_EXTENSIONS_LOWERCASE },
);

export const FILE_TYPES = Object.keys(FILE_EXTENSIONS).reduce((obj, val) => {
  obj[val] = val;
  return obj;
}, {});

export const ALL_FILE_TYPES = Object.keys(FILE_TYPES);
