export const swap = (array, index1, index2) => {
  const swappedArray = [...array];

  swappedArray[index1] = array[index2];
  swappedArray[index2] = array[index1];

  return swappedArray;
};

export const reorderItems = (
  items,
  selectedItemIds,
  source,
  destination,
  groups,
) => {
  const selectedItems = items.filter((item) =>
    selectedItemIds.includes(item.id),
  );
  const filteredItems = items.filter(
    (item) => !selectedItemIds.includes(item.id),
  );

  const sourceGroup = groups.find((group) => group.key === source.droppableId);
  const destinationGroup = groups.find(
    (group) => group.key === destination.droppableId,
  );
  const sourceIndex = sourceGroup.slides[source.index].originalIndex;
  let destinationIndex = destinationGroup.startIndex + destination.index;

  if (sourceIndex < destinationIndex) {
    destinationIndex =
      destinationIndex -
      selectedItemIds.length +
      (sourceGroup === destinationGroup ? 1 : 0);
  }

  //orderedItems.splice(source.index, 1); dees snap ik nie
  filteredItems.splice(destinationIndex, 0, ...selectedItems);

  return filteredItems;
};

export const multiSelectTo = (selectedSlide, slides, selectedSlideIds) => {
  const newSelectedSlideIndex = slides.findIndex(
    (slide) => slide.id === selectedSlide.id,
  );

  const lastSelectedSlideId = selectedSlideIds[selectedSlideIds.length - 1];
  const lastSelectedSlideIndex = slides.findIndex(
    (slide) => slide.id === lastSelectedSlideId,
  );

  const newSelectedSlideIds = [...selectedSlideIds];

  if (newSelectedSlideIndex > lastSelectedSlideIndex) {
    for (let i = lastSelectedSlideIndex + 1; i <= newSelectedSlideIndex; i++) {
      newSelectedSlideIds.push(slides[i].id);
    }
  } else {
    for (let i = newSelectedSlideIndex; i < lastSelectedSlideIndex; i++) {
      newSelectedSlideIds.push(slides[i].id);
    }
  }

  return newSelectedSlideIds;
};

export const getItemStyle = (draggableStyle, virtualizedStyle) => {
  return {
    ...draggableStyle,
    ...virtualizedStyle,
    margin: 0,
    outline: 'none',
  };
};

// Group slides based on same activityType and method (solo, group, class)
export const getGroupedSlides = (slides) => {
  const slideGroups = [];
  let previousSlide;

  slides.forEach((slide, index) => {
    // needs new group?
    if (
      !previousSlide ||
      previousSlide.method !== slide.method ||
      previousSlide.activityType?.key !== slide.activityType?.key
    ) {
      slideGroups.push({
        method: slide.method,
        activityType: slide.activityType,
        key: slide.method + slide.activityType?.key + slide.id,
        startIndex: index,
        slides: [],
        open: true,
      });
    }

    previousSlide = slide;

    // add slide to latest group
    slideGroups[slideGroups.length - 1].slides.push({
      ...slide,
      originalIndex: index,
    });
  });

  return slideGroups;
};
