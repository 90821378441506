import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

import FormikInput from '../Form/FormikInput';

const SingleInputDialog = ({
  title,
  label,
  actionText,
  defaultValue,
  onDismiss,
  onSubmit,
  error,
  errRegex,
  errRegexMessage,
  ...props
}) => {
  const t = useFormatMessage();
  return (
    <Dialog onDismiss={onDismiss} {...props} aria-label={title}>
      <Formik
        initialValues={{
          input: defaultValue,
        }}
        validateOnChange
        validateOnBlur={false}
        validationSchema={object({
          input: string()
            .required('validation.required')
            .test({
              name: 'input',
              test: (value) =>
                !!value && (!errRegex || !value.match(new RegExp(errRegex))),
              message: errRegexMessage,
            }),
        })}
        onSubmit={({ input }) => {
          onSubmit(input);
        }}
      >
        {({ isSubmitting, submitCount, isValid }) => (
          <Form>
            <DialogHeader>{title}</DialogHeader>
            <DialogContent>
              <FormikInput type="text" name="input" label={label} />
            </DialogContent>
            <DialogActions>
              <OutlineButton onClick={onDismiss}>
                {t('global.cancel')}
              </OutlineButton>
              <FilledButton
                type="submit"
                disabled={isSubmitting || (!isValid && submitCount > 0)}
              >
                {actionText}
              </FilledButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

SingleInputDialog.defaultProps = {
  defaultValue: '',
};

export default SingleInputDialog;
