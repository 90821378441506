import DropdownIcon from '../DropdownIcon';

import { DefaultButton } from './DefaultButton';

export const DropdownButton = ({
  children,
  isOpen,
  className,
  iconClassName,
  ...props
}) => (
  <DefaultButton
    {...props}
    className={className}
    role="button"
    aria-expanded={!!isOpen}
  >
    <div className="flex items-center w-full justify-between">
      <div className="mr-2">{children}</div>
      <DropdownIcon className="w-3" isOpen={isOpen} />
    </div>
  </DefaultButton>
);
