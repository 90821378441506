import { useMemo } from 'react';
import Select from 'react-select';

import colors from '@ftrprf/tailwind-components/colorsEsm';

import useFormatMessage from '../hooks/useFormatMessage';

import isArray from '../utils/functions/isArray';
import isObject from '../utils/functions/isObject';

const customStyles = {
  multiValue: (provided) => ({
    ...provided,
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: colors.accent[100],
    color: colors.accent[100],
    flexShrink: '0',
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    ':hover': {
      color: colors.accent[900],
    },
  }),
  control: (provided) => ({
    ...provided,
    borderColor: colors.gray[300],
    backgroundColor: 'white',
    borderWidth: '1px',
    borderRadius: '0.25rem',
    boxShadow: 'inset 0px 0px 3px 0px rgba(0, 0, 0, 0.05)',
    height: '100%',
    minHeight: 'auto',
    padding: '0.25rem 0',
  }),
  container: (provided, state) => ({
    ...provided,
    width: '100%',
    opacity: state.isDisabled ? 0.5 : 1,
    cursor: state.isDisabled ? 'not-allowed' : undefined,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  valueContainer: (provided) => ({
    ...provided,
    flexWrap: 'nowrap',
    flexShrink: '0',
    padding: '0 0.5rem',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '0 0.5rem',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    padding: '0',
    boxShadow: 'none',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? colors.accent[100]
      : state.isFocused
      ? colors.accent[200]
      : 'transparent',
    color: colors.accent[800],
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    textAlign: 'left',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: colors.gray[500],
  }),
  singleValue: (provided) => ({
    ...provided,
    margin: 0,
  }),
};

const theme = (currentTheme) => ({
  ...currentTheme,
  borderRadius: 0,
  colors: {
    ...currentTheme.colors,
    primary: colors.gray[600],
    primary75: colors.accent[400],
    primary50: colors.accent[300],
    primary25: colors.accent[200],
    dangerLight: colors.accent[200],
    neutral10: colors.accent[200],
    neutral20: colors.gray[600],
    neutral30: colors.gray[600],
    neutral40: colors.gray[600],
    neutral50: colors.gray[600],
    neutral60: colors.gray[600],
    neutral70: colors.gray[600],
    neutral80: colors.gray[900],
    neutral90: colors.gray[900],
  },
});

const transformOptions = (options) => {
  return options.map((option) => {
    return isObject(option)
      ? option
      : {
          key: option,
          label: option,
          value: option,
        };
  });
};

const transformSelectedOptions = (selectedOptions) => {
  if (!selectedOptions) {
    return null;
  }

  return isArray(selectedOptions)
    ? selectedOptions.map((x) => x.value)
    : selectedOptions.value;
};

const Dropdown = ({
  options,
  value,
  isMulti,
  onChange,
  disabled,
  ...props
}) => {
  const t = useFormatMessage();

  const transformedOptions = useMemo(() => {
    if (options?.length) {
      return transformOptions(options);
    }

    return options;
  }, [options]);

  const transformedValue = useMemo(() => {
    if (!value) {
      return null;
    }

    if (isArray(value)) {
      return transformOptions(value);
    }

    if (isObject(transformedOptions[0])) {
      return transformedOptions.find((o) => o.value === value);
    }

    return transformOptions([value])[0];
  }, [value, transformedOptions]);

  return (
    <Select
      noOptionsMessage={() => t('dropdown.no_results')}
      options={transformedOptions}
      value={transformedValue}
      isMulti={isMulti}
      styles={customStyles}
      theme={theme}
      isDisabled={disabled}
      onChange={(selectedOptions) =>
        onChange(transformSelectedOptions(selectedOptions))
      }
      {...props}
    />
  );
};

Dropdown.defaultProps = {
  onChange: () => {},
  placeholder: '',
};

export default Dropdown;
