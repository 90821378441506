import { FaChevronRight, FaHome } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';

export const Breadcrumbs = ({ links }) => {
  if (!links || links.length === 0) {
    return null;
  }

  const [homeLink, ...otherLinks] = links;

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4 text-sm">
        <li>
          <div>
            <NavLink
              to={homeLink.href}
              className="text-gray-400 hover:text-gray-500"
            >
              <div className="flex flex-row">
                <FaHome className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span className="ml-2">{homeLink.name || 'Home'}</span>
              </div>
            </NavLink>
          </div>
        </li>
        {otherLinks.map((link) => (
          <li key={link.href}>
            <div className="flex items-center">
              <FaChevronRight
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <NavLink
                to={link.href}
                className="ml-4 font-medium text-gray-500 hover:text-gray-700"
                aria-current={link.current ? 'page' : undefined}
              >
                {link.name || link.href.substring(link.href.lastIndexOf('/'))}
              </NavLink>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
};
