import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { FilledButton, Label } from '@ftrprf/tailwind-components';

import ButtonGroup from '../../../../components/ButtonGroup';
import Editor from '../../../../components/Editor/Editor';
import * as customPlugins from '../../../../components/Editor/sideBarCustomPlugins';
import FormikInput from '../../../../components/Form/FormikInput';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import SideBarContent from './SideBarContent';

const ModalSideBar = ({
  disabled,
  slide,
  onSubmit,
  initialModal,
  isEdit = false,
}) => {
  const t = useFormatMessage();
  return (
    <SideBarContent title={t('editor.editmodalsidebar.title')}>
      <Formik
        initialValues={{
          buttonText: initialModal?.buttonText ?? '',
          modalTitle: initialModal?.modalTitle ?? '',
          modalSize: initialModal?.modalSize ?? '',
          modalContent: initialModal?.modalContent ?? '',
        }}
        validateOnBlur={false}
        validationSchema={object({
          buttonText: string().required('validation.empty'),
          modalTitle: string().required('validation.empty'),
          modalSize: string().required('validation.empty'),
          modalContent: string().required('validation.empty'),
        })}
        onSubmit={onSubmit}
      >
        {({
          isValid,
          setFieldValue,
          values,
          handleChange,
          errors,
          touched,
          setFieldTouched,
        }) => (
          <Form className="p-3 flex flex-col h-full bg-gray-100">
            <FormikInput
              label={t('editor.editmodalsidebar.label-buttontext')}
              placeholder={t('editor.editmodalsidebar.label-text')}
              name="buttonText"
              value={values.buttonText}
              onChange={handleChange}
              disabled={disabled}
            />
            <FormikInput
              label={t('editor.editmodalsidebar.label-modaltitle')}
              placeholder={t('editor.editmodalsidebar.label-title')}
              name="modalTitle"
              value={values.modalTitle}
              onChange={handleChange}
              disabled={disabled}
            />
            <Label>
              {t('editor.editmodalsidebar.label-modalsize')}
              {touched.modalSize && errors.modalSize ? (
                <span className="mt-1 text-sm text-red-700">
                  - {t(errors.modalSize)}
                </span>
              ) : null}
            </Label>
            <ButtonGroup
              name="modalSize"
              selectedValue={values.modalSize}
              options={[
                { title: 'S', value: 'S' },
                { title: 'M', value: 'M' },
                { title: 'L', value: 'L' },
              ]}
              onChange={(value) => {
                if (value || touched.modalSize) {
                  setFieldTouched('modalSize');
                  setFieldValue('modalSize', value);
                }
              }}
              disabled={disabled}
              className="mb-3 w-min"
            />
            <Label>
              {t('editor.editmodalsidebar.label-content')}
              {touched.modalContent && errors.modalContent ? (
                <span className="mt-1 text-sm text-red-700">
                  - {t(errors.modalContent)}
                </span>
              ) : null}
            </Label>
            <Editor
              id={slide?.id}
              name="modalContent"
              value={values.modalContent}
              onChange={(value) => {
                if (value || touched.modalContent) {
                  setFieldTouched('modalContent');
                  setFieldValue('modalContent', value);
                }
              }}
              className="border rounded py-1"
              disabled={disabled}
              plugins={customPlugins}
              isMainEditor={false}
            />
            <div className="mt-3">
              <FilledButton type="submit" disabled={!isValid}>
                {isEdit ? t('global.edit') : t('global.add')}
              </FilledButton>
            </div>
          </Form>
        )}
      </Formik>
    </SideBarContent>
  );
};

export default ModalSideBar;
