import { useCallback, useEffect, useState } from 'react';
import { FaSearch, FaSpinner, FaTimes } from 'react-icons/fa';
import { useParams } from 'react-router';
import { useMutation, useQuery } from '@apollo/client';
import { Form, Formik } from 'formik';
import { number, object, string } from 'yup';

import { ContextMenu } from '@ftrprf/context-menu';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  Input,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import useInfiniteScrollQuery from '../../hooks/graphql/useInfiniteScrollQuery';
import useDebounce from '../../hooks/useDebounce';
import useFormatMessage from '../../hooks/useFormatMessage';

import { GET_LESSON_LANGUAGE } from '../../api/content';
import {
  CREATE_EXERCISE_VERSION_LINK,
  FIND_ALL_EXERCISES,
  FIND_SCRATCH_EXERCISE,
  FIND_SCRATCH_EXERCISE_VERSION,
} from '../../api/exercise';

import { DEFAULT_VERSION_TYPE } from '../../utils/constants/exerciseVersionTypes';

import { ReactComponent as ScratchIcon } from '../../assets/vectors/scratch.svg';

import Dropdown from '../Dropdown';
import FormikInput from '../Form/FormikInput';
import ListSelector, { ListItem } from '../ListSelector';

export const ExerciseDialog = ({
  displayText,
  defaultExerciseId,
  versionId,
  onSubmit: _onSubmit,
  onDismiss,
}) => {
  const { lessonContentId } = useParams();
  const t = useFormatMessage();
  const isNew = !!defaultExerciseId;

  const { data: languageData } = useQuery(GET_LESSON_LANGUAGE, {
    variables: { id: Number(lessonContentId) },
  });

  const language = languageData?.findLessonContent.language;

  const { data: versionData } = useQuery(FIND_SCRATCH_EXERCISE_VERSION, {
    variables: {
      versionId: Number(versionId),
      language: language,
    },

    skip: !language || !versionId,
  });

  const exerciseId = versionData
    ? versionData.findScratchExerciseVersion.exerciseId
    : defaultExerciseId;

  const { data } = useQuery(FIND_SCRATCH_EXERCISE, {
    variables: { id: Number(exerciseId) },
    skip: !exerciseId,
  });

  const [getLinkId] = useMutation(CREATE_EXERCISE_VERSION_LINK);

  const onSubmit = useCallback(
    ({ displayText, version }) => {
      getLinkId({
        variables: { exerciseVersionId: version },
      }).then(({ data }) => {
        _onSubmit({
          displayText,
          linkId: data.createExerciseVersionLink.id,
          exerciseVersionId: version,
          language,
        });
        onDismiss();
      });
    },
    [_onSubmit, getLinkId, language, onDismiss],
  );

  if (!data?.findExercise) {
    return null;
  }

  return (
    <Dialog>
      <DialogHeader>
        {t(`link-plugin.url.${isNew ? 'add' : 'modify'}-exercise`)}
      </DialogHeader>
      <Formik
        initialValues={{
          displayText: displayText || t('exercise-plugin.url.exercise-default'),
          version:
            Number(versionId) ||
            data?.findExercise?.versions.find(
              ({ versionType }) => versionType === DEFAULT_VERSION_TYPE,
            )?.id ||
            data?.findExercise?.versions[0].id,
        }}
        validationSchema={object({
          displayText: string().required('validation.required'),
          version: number().required('validation.required'),
        })}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, isValid }) => {
          return (
            <Form>
              <DialogContent>
                <div className="flex flex-col space-y-2">
                  <FormikInput
                    type="text"
                    name="displayText"
                    label={t('link-plugin.display-text')}
                  />

                  <Dropdown
                    placeholder="title"
                    value={values.version}
                    options={data?.findExercise?.versions.map((version) => ({
                      key: version.id,
                      value: version.id,
                      label: version.name || version.versionType,
                    }))}
                    onChange={(version) => setFieldValue('version', version)}
                  />
                </div>
              </DialogContent>
              <DialogActions>
                <OutlineButton onClick={onDismiss}>
                  {t('global.cancel')}
                </OutlineButton>
                <FilledButton disabled={!isValid} role="submit" type="submit">
                  {t(`link-plugin.url.${isNew ? 'add' : 'modify'}-exercise`)}
                </FilledButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    </Dialog>
  );
};

const Plugin = ({ editorAPI, editor }) => {
  const t = useFormatMessage();
  const [filter, setFilter] = useState('');
  const [exerciseId, setExerciseId] = useState();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const debouncedFilter = useDebounce(filter, 500);
  const insertExercise = useCallback(
    ({ displayText, linkId, exerciseVersionId }) => {
      editorAPI.insertHtml(`<a
      class="SlideViewer__StyledButton SlideViewer__ExerciseLink"
      data-studio-link-id="${linkId}"
      versionId="${exerciseVersionId}"
      'target="_blank"
      >
      ${displayText}
      </a>`);
    },
    [editorAPI],
  );
  const { onInfiniteScroll, fetchMore, data, loading } = useInfiniteScrollQuery(
    FIND_ALL_EXERCISES,
    {
      variables: { filter: [{ key: 'title', value: filter, operation: '~' }] },
    },
  );

  useEffect(() => {
    fetchMore({
      variables: {
        filter: [{ key: 'title', value: debouncedFilter, operation: '~' }],
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter, debouncedFilter]);

  return (
    <>
      {isDialogOpen && (
        <ExerciseDialog
          defaultExerciseId={exerciseId}
          onSubmit={insertExercise}
          onDismiss={() => setIsDialogOpen(false)}
        />
      )}
      <ContextMenu
        editor={editor}
        trigger={({ _, toggle }) => <ScratchIcon onClick={toggle} />}
      >
        {({ close }) => (
          <div className="bg-white h-64 w-64 border-gray-300 border">
            <ListSelector
              isLoading={loading && !data?.findAllExercises?.content}
              isEmpty={data?.findAllExercises?.content.length === 0}
              contentListTop={
                <div className="relative flex-grow min-w-56 max-w-md float-right">
                  <Input
                    value={filter}
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                    placeholder={t('global.search')}
                  />
                  <div className="absolute h-full top-0 flex items-center right-3 text-gray-500 text-sm">
                    {loading || filter !== debouncedFilter ? (
                      <FaSpinner className="animate-spin" />
                    ) : filter ? (
                      <Button
                        onClick={() => {
                          setFilter('');
                        }}
                      >
                        <FaTimes />
                      </Button>
                    ) : (
                      <FaSearch />
                    )}
                  </div>
                </div>
              }
              contentListCenter={
                <div
                  onScroll={onInfiniteScroll}
                  className="flex flex-col w-full overflow-y-scroll"
                >
                  {data?.findAllExercises.content.map((exercise) => (
                    <ListItem
                      key={exercise.id}
                      className="hover:bg-accent-200"
                      onClick={() => {
                        setExerciseId(exercise.id);
                        close();
                        setIsDialogOpen(true);
                      }}
                    >
                      <span key={exercise.id}>{exercise.title}</span>
                    </ListItem>
                  ))}
                </div>
              }
            />
          </div>
        )}
      </ContextMenu>
    </>
  );
};

export const ExercisePlugin = ({ ...props }) => {
  const t = useFormatMessage();

  return <Plugin label={t('editor.exerciseplugin.label')} {...props} />;
};
