import { useContext, useState } from 'react';
import { FaPlus } from 'react-icons/fa';

import { Loader } from '@ftrprf/tailwind-components';

import { ContentActionContext } from '../providers/ContentActionProvider';

import useFormatMessage from '../hooks/useFormatMessage';

import c from '../utils/functions/c';

const DragArea = ({ children, className, setReturnData }) => {
  const t = useFormatMessage();

  const [isVisible, setIsVisible] = useState(false);

  const { uploadFiles, isUploading } = useContext(ContentActionContext);

  const onDragEnter = (e) => {
    setIsVisible(true);
    e.stopPropagation();
    e.preventDefault();
  };

  const onDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDragLeave = (e) => {
    setIsVisible(false);
    e.stopPropagation();
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    uploadFiles(e.dataTransfer.files).then((data) => {
      setReturnData(data);
      setIsVisible(false);
    });
  };

  return (
    <div
      className={c(className, 'flex flex-grow h-full w-full overflow-hidden')}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDrop={onDrop}
      onDragLeave={onDragLeave}
    >
      {(isVisible || isUploading) && (
        <div
          className={c(
            'w-full flex content-center items-center justify-center',
            !isUploading &&
              'bg-accent-100 border-dashed border-2 bordery-gray-600',
          )}
        >
          {isUploading ? (
            <div className="flex flex-col items-center justify-center">
              <div className="h-5 w-5">
                <Loader />
              </div>
              <span>{t('dragarea.uploading')}</span>
            </div>
          ) : (
            <>
              <FaPlus />
              <span className="ml-2 leading-snug">
                {t('content-selector.add')}
              </span>
            </>
          )}
        </div>
      )}
      {!isVisible && !isUploading && children}
    </div>
  );
};

DragArea.defaultProps = {
  uploadFile: () => {},
  setReturnData: () => {},
};

export default DragArea;
