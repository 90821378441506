import { baseName } from './path';

export const fileNameDescendingSort = (files) =>
  files.sort((a, b) => {
    if (a.isFile === b.isFile) {
      return baseName(b.path).localeCompare(baseName(a.path));
    }
    return a.isFile ? -1 : 1;
  });

export const lastModifiedOnAscendingSort = (files) =>
  files.sort((a, b) => {
    return new Date(a.modifiedOn) - new Date(b.modifiedOn);
  });

export const lastModifiedOnDescendingSort = (files) =>
  files.sort((a, b) => {
    return new Date(b.modifiedOn) - new Date(a.modifiedOn);
  });

export const lastModifiedByAscendingSort = (files) =>
  files.sort((a, b) => {
    return a.modifiedBy.localeCompare(b.modifiedBy);
  });

export const lastModifiedByDescendingSort = (files) =>
  files.sort((a, b) => {
    return b.modifiedBy.localeCompare(a.modifiedBy);
  });
