import { useCallback, useRef, useState } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { ContextMenu } from '@ftrprf/context-menu';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  Label,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

import { FILE_EXTENSIONS } from '../../utils/constants/fileTypes';

import { ReactComponent as ImageIcon } from '../../assets/vectors/image.svg';

import ContentListSelector from '../ContentListSelector/ContentListSelector';
import FormikUrl from '../Form/FormikUrl';

import FileUpload from './FileUpload';

const Plugin = ({ editorAPI, editor }) => {
  const t = useFormatMessage();

  const [isExternalShown, setIsExternalShown] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');

  const insertImage = useCallback(
    (url) => {
      editorAPI.insertHtml(
        `<img 
          src="${url}" 
          width="100%" 
          alt="" 
          style="border-style: solid; border-width: 0px;"
         />
        `,
      );
    },
    [editorAPI],
  );

  const imgRef = useRef(null);

  return (
    <>
      {isExternalShown && (
        <Dialog>
          <Formik
            initialValues={{
              url: '',
            }}
            validateOnChange
            validateOnBlur={false}
            validationSchema={object({
              url: string().url('validation.url').required('validation.empty'),
            })}
            onSubmit={({ url }, { setFieldError }) => {
              if (
                !imgRef.current?.naturalWidth ||
                !imgRef.current?.naturalHeight
              ) {
                setFieldError('url', 'validation.external_image_url');
              } else {
                setIsExternalShown(false);
                insertImage(url);
              }
            }}
          >
            {({
              isSubmitting,
              isValid,
              setFieldValue,
              setFieldError,
              values,
              errors,
            }) => (
              <Form>
                <DialogHeader>{t('editor.imageplugin.label')}</DialogHeader>
                <DialogContent>
                  <FormikUrl name="url" label={t('global.URL')} />
                  <Label>{t('editor.imageplugin.label-upload')}</Label>
                  {!errors.url && values.url ? (
                    <div className="w-full h-56">
                      <img
                        ref={imgRef}
                        src={values.url}
                        onError={() =>
                          setFieldError('url', 'validation.external_image_url')
                        }
                        alt="upload"
                        className="h-full m-auto max-w-full"
                      />
                    </div>
                  ) : (
                    <FileUpload
                      name="file"
                      path={currentPath}
                      setFileUri={(uri) => {
                        setFieldValue('url', uri);
                      }}
                      accept="image"
                      maxSize={5242880}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <OutlineButton
                    onClick={() => {
                      setIsExternalShown(false);
                      setFieldValue('url', null);
                    }}
                  >
                    {t('global.cancel')}
                  </OutlineButton>
                  <FilledButton
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    {t('editor.imageplugin.label')}
                  </FilledButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
      <ContextMenu
        editor={editor}
        trigger={({ _, toggle }) => <ImageIcon onClick={toggle} />}
      >
        {({ close }) => (
          <div className="bg-white h-64 w-64 border-gray-300 border">
            <ContentListSelector
              onFileClick={(url) => {
                insertImage(url);
                close();
              }}
              allowedTypes={FILE_EXTENSIONS.IMAGE.extensions}
              placeholder={t('content-selector.url')}
              onExternalClick={() => {
                close();
                setIsExternalShown(true);
              }}
              setCurrentPath={setCurrentPath}
            />
          </div>
        )}
      </ContextMenu>
    </>
  );
};

export const ImagePlugin = ({ ...props }) => {
  const t = useFormatMessage();

  return <Plugin label={t('editor.imageplugin.label')} {...props} />;
};
