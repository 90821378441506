import c from '../../utils/c';

import { Button } from './Button';

export const ClassGroupButton = ({ children, className, ...props }) => (
  <Button
    className={c(className, 'bg-gray-200 py-1 ml-3 my-2 pl-5 rounded w-16')}
    {...props}
  >
    {children}
  </Button>
);
