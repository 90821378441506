import c from '../../utils/c';
import getButtonStyle from '../../utils/getComponentStyle';

import { Button } from './Button';

const buttonStyles = {
  ACCENT: 'text-accent-500 text-center border-accent-500 bg-white',
  SECONDARY: 'text-gray-600 text-center bg-white',
};

export const OutlineButton = ({
  className,
  children,
  accent,
  secondary,
  disabled,
  ...props
}) => {
  const style = getButtonStyle(buttonStyles, { accent, secondary }, 'ACCENT');

  return (
    <Button
      className={c(
        className,
        buttonStyles[style],
        !disabled && 'hover:translate-x-px hover:translate-y-px',
        'border transform px-4 py-1 rounded',
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};
