import { useContext } from 'react';

import c from '../../utils/c';

import SlideViewerQuestion from '../styled-slideviewer/partials/questions/SlideViewerQuestion';
import { SlideViewerContext } from './SlideViewerContextProvider';
import SlideViewerTextSlide from './SlideViewerTextSlide';

const SlideViewerSlide = ({ className }) => {
  const {
    currentSlide,
    fontSizeIndex,
    submittedQuestionAnswers,
    canEdit,
    canOverrideAnswers,
  } = useContext(SlideViewerContext);

  const { question } = currentSlide;

  const submittedQuestionAnswer =
    question &&
    submittedQuestionAnswers?.find &&
    submittedQuestionAnswers?.find(
      (submittedQuestion) => submittedQuestion.questionId === question.id,
    );

  const hasSubmittedQuestionAnswer = !!submittedQuestionAnswer;

  // if they can edit
  // and if it's not yet submitted
  // or when they can override answers
  const canSubmit =
    canEdit && (!hasSubmittedQuestionAnswer || canOverrideAnswers);

  const ContentComponent = currentSlide.component;

  return (
    <div
      className={c('SlideViewerTextSlide', className)}
      style={{ zoom: 1 + fontSizeIndex * 0.3 }}
    >
      {currentSlide.title && (
        <h1 style={{ fontSize: '36px' }}>{currentSlide.title}</h1>
      )}

      {ContentComponent ? (
        <ContentComponent />
      ) : (
        <SlideViewerTextSlide
          value={currentSlide.content || currentSlide.question?.value || ''}
        />
      )}

      {question && (
        <SlideViewerQuestion question={question} canSubmit={canSubmit} />
      )}
    </div>
  );
};

export default SlideViewerSlide;
