import { useState } from 'react';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';

const PasswordInput = ({ type, ...props }) => {
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisible = (e) => {
    e.preventDefault();
    setPasswordVisible(!passwordVisible);
  };

  return (
    <div className="relative">
      <input type={passwordVisible ? 'text' : 'password'} {...props} />
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-end pointer-events-none">
        <button
          type="button"
          className="text-gray-300 hover:text-gray-500 transition-colors duration-500 mr-2 pointer-events-auto"
          onClick={togglePasswordVisible}
          aria-controls="password"
          aria-expanded={passwordVisible ? 'true' : 'false'}
          tabIndex="-1"
        >
          {passwordVisible ? (
            <FaRegEyeSlash className="w-5 h-5" />
          ) : (
            <FaRegEye className="w-5 h-5" />
          )}
        </button>
      </div>
    </div>
  );
};

export default PasswordInput;
