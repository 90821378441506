import { useContext, useEffect, useState } from 'react';

import { VIEW_MODES } from './constants';
import { SlideViewerContext } from './SlideViewerContextProvider';

const toggleFullscreen = () => {
  if (document.fullscreenElement) {
    document.exitFullscreen();
  } else {
    const element = document.getElementById('SlideViewerFullscreen');

    // requestFullscreen is not defined in all browsers
    if (element.requestFullscreen) {
      // On refresh, this will throw an error because `requestFullscreen`
      // can only be initiated by a user gesture.
      element.requestFullscreen().catch(() => {});
    }
  }
};

const SlideViewerViewModes = ({
  projectionChildren,
  selfstudyChildren,
  classicalChildren,
  viewMoreChildren,
  ...props
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const { currentSlide, viewMode, setViewMode } =
    useContext(SlideViewerContext);

  useEffect(() => {
    if (document.fullscreenElement && viewMode !== VIEW_MODES.PROJECTION) {
      document.exitFullscreen();
    }
  }, [viewMode]);

  const projectionDisabled = !currentSlide.viewModes.includes(
    VIEW_MODES.PROJECTION,
  );

  const selfstudyDisabled = !currentSlide.viewModes.includes(
    VIEW_MODES.SELFSTUDY,
  );
  const classicalDisabled = !currentSlide.viewModes.includes(
    VIEW_MODES.CLASSICAL,
  );

  const showViewModes = isHovering || viewMode !== VIEW_MODES.PROJECTION;

  return (
    <div
      {...props}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {!showViewModes && <button type="button">{viewMoreChildren}</button>}
      {showViewModes && (
        <>
          <button
            type="button"
            disabled={!currentSlide.viewModes.includes(VIEW_MODES.CLASSICAL)}
            onClick={() => setViewMode(VIEW_MODES.CLASSICAL)}
          >
            {classicalChildren({
              disabled: classicalDisabled,
              active: viewMode === VIEW_MODES.CLASSICAL,
            })}
          </button>
          <button
            type="button"
            disabled={selfstudyDisabled}
            onClick={() => setViewMode(VIEW_MODES.SELFSTUDY)}
          >
            {selfstudyChildren({
              disabled: selfstudyDisabled,
              active: viewMode === VIEW_MODES.SELFSTUDY,
            })}
          </button>
          <button
            type="button"
            disabled={projectionDisabled}
            onClick={() => {
              toggleFullscreen();
              setViewMode(VIEW_MODES.PROJECTION);
            }}
          >
            {projectionChildren({
              disabled: projectionDisabled,
              active: viewMode === VIEW_MODES.PROJECTION,
            })}
          </button>
        </>
      )}
    </div>
  );
};

export default SlideViewerViewModes;
