import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import useFieldMutation from '../../../hooks/graphql/useFieldMutation';

import {
  CREATE_ACTIVITY_TYPE,
  GET_ALL_ACTIVITY_TYPES,
} from '../../../api/content';

const useActivityTypes = () => {
  const { data: activities, refetch } = useQuery(GET_ALL_ACTIVITY_TYPES);

  const activitiesByKey = useMemo(() => {
    return (
      activities?.findActivityTypes.reduce((prev, activity) => {
        const { id, key, icon } = activity;
        prev[key] = { id: id, icon: icon };
        return prev;
      }, {}) ?? {}
    );
  }, [activities]);

  const activitiesKeyToId = useMemo(() => {
    return (
      activities?.findActivityTypes.reduce((prev, activity) => {
        const { id, key } = activity;
        prev[key] = id;
        return prev;
      }, {}) ?? {}
    );
  }, [activities]);

  const [createActivityTypeMutation] = useFieldMutation(
    CREATE_ACTIVITY_TYPE,
    'ActivityType',
    ['key', 'icon', 'organizationId'],
  );

  const createActivityType = (key, icon, organizationId) => {
    return createActivityTypeMutation({
      key: key,
      icon: icon,
      organizationId: organizationId,
    }).then((newActivity) => {
      refetch();
      return newActivity;
    });
  };

  return {
    activities,
    activitiesByKey,
    activitiesKeyToId,
    refetch,
    createActivityType,
  };
};

export default useActivityTypes;
