import { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { FIND_ALL_EXERCISES } from '../../api/exercise';

const useFindAllExercises = ({ currentPage, filters }) => {
  const queryOptions = useMemo(
    () => ({
      variables: {
        page: currentPage - 1,
        filter: Object.entries(filters)
          .map(([key, value]) => ({
            key,
            value,
            operation: '~',
          }))
          .filter(
            (f) => f.value !== null && f.value !== undefined && f.value !== '',
          ),
      },
      fetchPolicy: 'network-only',
    }),
    [currentPage, filters],
  );

  const { loading, error, data } = useQuery(FIND_ALL_EXERCISES, queryOptions);

  return { loading, error, data };
};

export default useFindAllExercises;
