import { FaCheck } from 'react-icons/fa';

import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/c';

const SuccessNotification = ({ className, title, content }) => {
  const t = useFormatMessage();

  return (
    <div
      className={c(
        className,
        'w-full max-w-sm bg-green-500 py-3 px-3 text-white flex rounded',
      )}
    >
      <div className="mt-1 mr-2">
        <FaCheck />
      </div>
      <div>
        <div className="uppercase font-bold mb-px">
          {title || t('notifications.success.label')}
        </div>
        <div className="">
          {content || t('notifications.success.generic_message')}
        </div>
      </div>
    </div>
  );
};

export default SuccessNotification;
