import { useContext, useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  ApolloClient,
  ApolloProvider as ApolloClientProvider,
  InMemoryCache,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useFormatMessage from '../hooks/useFormatMessage';

import { AUTHORIZATION_TOKEN } from '../utils/constants/localStorage';
import { EXERCISES } from '../utils/constants/urls';

import { UserContext } from './UserProvider';

// TODO: hacky solution
const FILE_TOO_BIG_ERROR = (error) =>
  error.statusCode === 400 || error.statusCode === undefined;

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_APOLLO_CLIENT_URI,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTHORIZATION_TOKEN);

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const ApolloProvider = ({ children }) => {
  const t = useFormatMessage();

  const { pathname } = useLocation();
  const { addNotification } = useContext(NotificationContext);

  const { signOut } = useContext(UserContext);

  const client = useMemo(() => {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          addNotification({
            type: 'error',
            content: `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          });
        });
      }

      if (networkError) {
        // TODO: BE : fix this hacky implementation (RM 14/08/2020)
        if (networkError.statusCode === 401) {
          signOut(client);

          addNotification({
            type: 'error',
            content: t('errors.session_expired'),
          });

          // TODO: fix this implementation.
        } else {
          if (!FILE_TOO_BIG_ERROR(networkError)) {
            addNotification({
              type: 'error',
              content: `[Network error]: ${networkError}`,
            });
          }
        }
      }
    });

    return new ApolloClient({
      link: authLink.concat(errorLink).concat(httpLink),
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              findAllExercises: {
                keyArgs: pathname.startsWith(`${EXERCISES}`),
                merge(existing, incoming, { args }) {
                  if (!!args.filter) {
                    return incoming;
                  }

                  return {
                    ...existing,
                    ...incoming,
                    content: [
                      ...(existing?.content || []),
                      ...incoming.content,
                    ],
                  };
                },
              },

              findAllContentFilesByPath: {
                merge(existing, incoming) {
                  return incoming;
                },
              },
            },
          },
        },
      }),
      defaultOptions: {
        query: {
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    });
  }, [pathname, addNotification, signOut, t]);

  return (
    <ApolloClientProvider client={client}>{children}</ApolloClientProvider>
  );
};

export default ApolloProvider;
