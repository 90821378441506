import { useEffect, useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

const WarningPublishedEditDialog = ({
  onDismiss,
  isOpen,
  onSmallChange,
  onDuplicate,
  ...props
}) => {
  const t = useFormatMessage();

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setDisabled(false);
    }
  }, [isOpen]);

  return (
    <Dialog onDismiss={onDismiss} isOpen={isOpen} {...props}>
      <DialogHeader>
        {t('question-answer-multiple-choice-sidebar.warning.title')}
      </DialogHeader>
      <DialogContent>
        {t('question-answer-multiple-choice-sidebar.warning.content')}
      </DialogContent>
      <DialogActions>
        <OutlineButton disabled={disabled} onClick={onDismiss}>
          {t('global.cancel')}
        </OutlineButton>
        <FilledButton
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            onSmallChange();
          }}
        >
          {t('question-answer-multiple-choice-sidebar.warning.small')}
        </FilledButton>
        <FilledButton
          disabled={disabled}
          onClick={() => {
            setDisabled(true);
            onDuplicate();
          }}
        >
          {t('question-answer-multiple-choice-sidebar.warning.duplicate')}
        </FilledButton>
      </DialogActions>
    </Dialog>
  );
};

export default WarningPublishedEditDialog;
