import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import c from '../../utils/functions/c';
import getComponentStyle from '../../utils/functions/getComponentStyle';

const headerStyles = {
  ACCENT: 'text-white font-bold bg-accent-500 p-2 hover:bg-accent-600',
};

const TableHeaderCell = ({
  children,
  key,
  accent,
  className,
  toggleSortBy,
  isSorted,
  isSortedDesc,
  sortable,
}) => {
  const style = getComponentStyle(headerStyles, { accent }, 'ACCENT');

  return (
    <div
      className={c(
        className,
        headerStyles[style],
        sortable && 'cursor-pointer select-none',
      )}
      onClick={() => {
        if (sortable) {
          toggleSortBy(key);
        }
      }}
    >
      {isSorted && (
        <div className="ml-2">
          <FaCaretUp
            className={c(isSortedDesc && 'opacity-50', '-m-1 text-white')}
          />
          <FaCaretDown
            className={c(!isSortedDesc && 'opacity-50', '-m-1 text-white')}
          />
        </div>
      )}
      <div className="truncate flex-grow">{children}</div>
    </div>
  );
};

export default TableHeaderCell;
