import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { CodeFeverContext } from '../providers/CodeFeverProvider';
import { UserContext } from '../providers/UserProvider';

import { USER_INFO } from '../api/user';

import isCodeFever from '../utils/isCodeFever';

import { ReactComponent as Loader } from '../assets/vectors/logo-FTRPRF-animated-lines.svg';

import programs from './programs';

const dummyClans = [
  'ANONYMOUS',
  'BYTEBUSTERS',
  'CODEKRAKS',
  'DIGIHEROES',
  'DIGITAL SKILLS-KAMP',
  'E-RIDERS',
  'FEVERFREAKS',
  'MINECRAFTERS',
  'MINECRAFTKAMP - GEVORDERDEN',
  'PYTHONS',
  'ROBOTKAMP - EXPERTEN',
  'ROBOTKAMP - GEVORDERDEN',
  'SCHOOLS',
  'START2CODE - 3E & 4E LEERJAAR',
  'START2CODE - 5E & 6E LEERJAAR',
];

const dummyVersions = [
  '0.5',
  '1.0',
  '1.0.5',
  '1.1',
  '1.1.5',
  '1.2',
  '2.0',
  '2.1',
  '3.0',
  '3.1',
  '3.2',
  '4.0',
  '4.1',
];

const dummyLevels = [1, 2, 3, 4];

const Bootstrap = ({ onLoaded }) => {
  const [smallDelay, setSmallDelay] = useState(true);
  const { setUser } = useContext(UserContext);

  const {
    setClans,
    setLevels,
    setLessonNumbers,
    setPrograms,
    setProgramTypes,
    setVersions,
  } = useContext(CodeFeverContext);

  useEffect(() => {
    if (isCodeFever) {
      setClans(dummyClans);
      setLevels(dummyLevels);
      setLessonNumbers([1, 2, 3, 4, 5, 6, 7, 9, 10]);
      setVersions(dummyVersions);
      setPrograms(programs);
      setProgramTypes(['series', 'workshop', 'camp']);
    }
  }, [
    setClans,
    setLevels,
    setLessonNumbers,
    setPrograms,
    setProgramTypes,
    setVersions,
  ]);

  const { data: user } = useQuery(USER_INFO);

  // minimal delay is 1 second
  useEffect(() => {
    const smallDelayTimeOut = setTimeout(() => {
      setSmallDelay(false);
    }, 1000);

    return () => clearTimeout(smallDelayTimeOut);
  }, []);

  useEffect(() => {
    if (!user || !user.userInfo || smallDelay) {
      return;
    }

    setUser(user.userInfo);
    onLoaded(true);
  }, [user, setUser, onLoaded, smallDelay]);

  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <Loader className="w-32" />
    </div>
  );
};

export default Bootstrap;
