import { FaArrowLeft } from 'react-icons/fa';
import { useSetRecoilState } from 'recoil';

import { sideBarAtom } from '../../utils/atom';

const SideBarGoBack = ({ title }) => {
  const setSideBar = useSetRecoilState(sideBarAtom);

  return (
    <button
      className="p-3 border-b border-gray-300 text-right flex justify-between items-center cursor-pointer"
      onClick={() => setSideBar(null)}
    >
      <FaArrowLeft />
      <span className="font-bold text-xl">{title}</span>
    </button>
  );
};

export default SideBarGoBack;
