import { useCallback, useRef } from 'react';
import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useSetRecoilState } from 'recoil';
import { useRecoilState } from 'recoil';

import useFieldMutation from '../../../hooks/graphql/useFieldMutation';

import {
  CREATE_QUESTION_ANSWER_MULTIPLE_CHOICE,
  REMOVE_QUESTION_ANSWER,
  UPDATE_QUESTION_MULTIPLE_CHOICE,
} from '../../../api/question';

import { MULTIPLE_CHOICE } from '../../../utils/constants/slideTypes';
import { questionAtom, sideBarAtom } from '../utils/atom';

import { SIDE_BARS } from '../partials/SideBars/SideBarContextProvider';

const read = (client, id) => {
  return client.readFragment({
    id: `QuestionMultipleChoice:${id}`,
    fragment: gql`
      fragment MyQuestion on QuestionMultipleChoice {
        id
        type
        publishedAt
        value
        scorePerCorrectAnswer
        subtractPerWrongAnswer
        maxSelectableAnswers
        shouldFindAllCorrect
        shuffleAnswers
        questionAnswersMultipleChoice {
          id
          value
          correct
          explanation
        }
      }
    `,
  });
};

const useMultipleChoiceQuestion = (id) => {
  const client = useApolloClient();
  const ref = useRef();

  const [question, setQuestion] = useRecoilState(questionAtom(id));
  const setSideBar = useSetRecoilState(sideBarAtom);

  const refetch = () => {
    setQuestion(read(client, id));
  };

  const [mutate] = useFieldMutation(
    UPDATE_QUESTION_MULTIPLE_CHOICE,
    'Question',
    [
      'id',
      'shuffleAnswers',
      'shouldFindAllCorrect',
      'scorePerCorrectAnswer',
      'subtractPerWrongAnswer',
      'maxSelectableAnswers',
    ],
  );

  if (question === null) {
    refetch();
  }

  const debounceMutation = useCallback(
    (options) => {
      if (ref.current) {
        clearTimeout(ref.current);
      }

      ref.current = setTimeout(() => {
        mutate(options);
      }, 250);
    },
    [ref, mutate],
  );

  const update = useCallback(
    (variables) => {
      setQuestion((l) => {
        const newValue = { ...l, ...variables };

        debounceMutation(newValue);

        return newValue;
      });
    },
    [debounceMutation, setQuestion],
  );

  const [createQuestionAnswerMultipleChoiceMutate] = useMutation(
    CREATE_QUESTION_ANSWER_MULTIPLE_CHOICE,
    {
      update(cache, { data: { createQuestionAnswerMultipleChoice } }) {
        cache.modify({
          id: client.cache.identify(question),
          fields: {
            questionAnswersMultipleChoice(existingRefs = []) {
              return [
                ...existingRefs,
                {
                  __ref: client.cache.identify({
                    __typename: 'QuestionAnswerMultipleChoice',
                    id: createQuestionAnswerMultipleChoice.id,
                  }),
                },
              ];
            },
          },
        });
      },
    },
  );

  const [removeQuestionAnswerMultipleChoiceMutate] = useMutation(
    REMOVE_QUESTION_ANSWER,
    {
      update(cache, { data: { removeQuestionAnswer } }) {
        cache.modify({
          id: client.cache.identify(question),
          fields: {
            questionAnswersMultipleChoice(existingRefs = [], { readField }) {
              return existingRefs.filter(
                (ref) => removeQuestionAnswer.id !== readField('id', ref),
              );
            },
          },
        });
      },
    },
  );

  const createQuestionAnswerMultipleChoice = (variables) => {
    createQuestionAnswerMultipleChoiceMutate({
      variables: {
        questionId: question.id,
        ...variables,
      },
    }).then(({ data }) => {
      setSideBar({
        type: SIDE_BARS.QUESTION_ANSWER_MULTIPLE_CHOICE_SIDEBAR,
        id: data.createQuestionAnswerMultipleChoice.id,
        questionId: question.id,
        index: question.questionAnswersMultipleChoice.length,
      });

      refetch();
    });
  };

  const removeQuestionAnswerMultipleChoice = (id) => {
    setSideBar(null);

    removeQuestionAnswerMultipleChoiceMutate({
      variables: {
        id,
        questionType: MULTIPLE_CHOICE,
      },
    }).then(refetch);
  };

  return {
    question,
    setQuestion,
    update,
    createQuestionAnswerMultipleChoice,
    removeQuestionAnswerMultipleChoice,
  };
};

export default useMultipleChoiceQuestion;
