import { useContext } from 'react';
import { FaDesktop, FaHome, FaUsers } from 'react-icons/fa';

import {
  Button,
  NotificationContext,
  VIEW_MODES,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useSlide from '../../hooks/useSlide';

import c from '../../../../utils/functions/c';

const SlideOverviewItemViewModes = ({
  slideId,
  disabled,
  hasViewModeSelector,
}) => {
  const t = useFormatMessage();

  const { slide, update } = useSlide(slideId);
  const { addNotification } = useContext(NotificationContext);

  if (!slide || !hasViewModeSelector) {
    return null;
  }

  const isClassical = slide.viewModes.includes(VIEW_MODES.CLASSICAL);
  const isSelfstudy = slide.viewModes.includes(VIEW_MODES.SELFSTUDY);
  const isProjection = slide.viewModes.includes(VIEW_MODES.PROJECTION);

  const toggleViewMode = (viewMode) => {
    const containsViewMode = slide.viewModes.includes(viewMode);

    if (containsViewMode) {
      if (slide.viewModes.length === 1) {
        addNotification({
          type: 'warning',
          content: t('content-editor.no_viewmodes.error'),
        });
        return;
      }
      // eslint-disable-next-line consistent-return
      return update({
        viewModes: slide.viewModes.filter((v) => v !== viewMode),
      });
    }

    const newViewModes = [...slide.viewModes, viewMode];

    if (
      viewMode === VIEW_MODES.CLASSICAL &&
      !slide.viewModes.includes(VIEW_MODES.PROJECTION)
    ) {
      newViewModes.push(VIEW_MODES.PROJECTION);
    }

    update({
      viewModes: newViewModes,
    });
  };

  return (
    <>
      <Button
        className={c('mr-1 w-3 h-3', !isClassical && 'opacity-25')}
        onClick={() => toggleViewMode(VIEW_MODES.CLASSICAL)}
        title={t('view-modes.classical')}
        disabled={disabled}
      >
        <FaUsers />
      </Button>
      <Button
        title={t('view-modes.selfstudy')}
        className={c('mr-1 w-3 h-3', !isSelfstudy && 'opacity-25')}
        onClick={() => toggleViewMode(VIEW_MODES.SELFSTUDY)}
        disabled={disabled}
      >
        <FaHome />
      </Button>
      <Button
        title={t(
          isClassical
            ? 'content-editor.overview.projection_warning'
            : 'view-modes.projection',
        )}
        className={c(
          'w-3 h-3',
          !isClassical && !isProjection && 'opacity-25',
          isClassical && 'opacity-75',
          isClassical && 'cursor-not-allowed',
        )}
        onClick={() => !isClassical && toggleViewMode(VIEW_MODES.PROJECTION)}
        disabled={disabled}
      >
        <FaDesktop />
      </Button>
    </>
  );
};

SlideOverviewItemViewModes.defaultProps = {
  hasViewModeSelector: true,
};

export default SlideOverviewItemViewModes;
