export default [
  {
    id: '1111',
    type: 'LESSON',
    title: 'Hoe maak je een tekstdocument aan?',
    image: 'https://i.ytimg.com/vi/FEPZVyXV1To/maxresdefault.jpg',
    programId: 40,
    clan: 'SCHOOLS',
    level: 1,
    version: 6,
    published: true,
    archived: false,
    modifiedBy: 'ingrid',
    modifiedOn: '2020-09-29',
    tags: [],
    endGoals: [],
    __typename: 'LessonContent',
    groupRoles: [],
    language: 'NL',
  },
  {
    id: '1112',
    type: 'LESSON',
    title: 'Test title of the lesson with ID 1112',
    image: '',
    programId: 40,
    clan: 'SCHOOLS',
    level: 1,
    version: 1,
    published: false,
    archived: false,
    modifiedBy: 'ingrid',
    modifiedOn: '2019-11-18 11:16:25',
    tags: [],
    endGoals: [],
    __typename: 'LessonContent',
    groupRoles: [],
    language: 'NL',
  },
  {
    id: '1113',
    type: 'LESSON',
    title: 'Geld verdienen',
    image: 'https://i.ytimg.com/vi/FEPZVyXV1To/maxresdefault.jpg',
    programId: 40,
    clan: 'SCHOOLS',
    level: 1,
    version: 3,
    published: false,
    archived: false,
    modifiedBy: 'ingrid',
    modifiedOn: '2020-09-17',
    tags: [],
    endGoals: [],
    __typename: 'LessonContent',
    groupRoles: [],
    language: 'NL',
  },
  {
    id: '1114',
    type: 'LESSON',
    title: 'MoneyMoneyMoney',
    image: 'https://i.ytimg.com/vi/FEPZVyXV1To/maxresdefault.jpg',
    programId: 40,
    clan: 'SCHOOLS',
    level: 1,
    version: 2,
    published: true,
    archived: false,
    modifiedBy: 'ingrid',
    modifiedOn: '2020-09-29',
    tags: [],
    endGoals: [],
    __typename: 'LessonContent',
    groupRoles: [],
    language: 'NL',
  },
  {
    id: '1115',
    type: 'LESSON',
    title: 'Hoe maak je een presentatie?',
    image: 'https://i.ytimg.com/vi/FEPZVyXV1To/maxresdefault.jpg',
    programId: 40,
    clan: 'SCHOOLS',
    level: 1,
    version: 2,
    published: true,
    archived: false,
    modifiedBy: 'ingrid',
    modifiedOn: '2020-09-29',
    tags: [],
    endGoals: [],
    __typename: 'LessonContent',
    groupRoles: [],
    language: 'NL',
  },
  {
    id: '1116',
    type: 'LESSON',
    title: 'Hoe maak je een PDF van een tekstdocument?',
    image: 'https://i.ytimg.com/vi/FEPZVyXV1To/maxresdefault.jpg',
    programId: 40,
    clan: 'SCHOOLS',
    level: 1,
    version: 2,
    published: true,
    archived: false,
    modifiedBy: 'ingrid',
    modifiedOn: '2020-09-29',
    tags: [],
    endGoals: [],
    __typename: 'LessonContent',
    groupRoles: [],
    language: 'NL',
  },
  {
    id: '1117',
    type: 'LESSON',
    title: 'Hoe maak je een eigen kaart?',
    image: 'https://i.ytimg.com/vi/FEPZVyXV1To/maxresdefault.jpg',
    programId: 40,
    clan: 'SCHOOLS',
    level: 1,
    version: 1,
    published: false,
    archived: false,
    modifiedBy: 'ingrid',
    modifiedOn: '2020-09-02',
    tags: [],
    endGoals: [],
    __typename: 'LessonContent',
    groupRoles: [],
    language: 'NL',
  },
  {
    id: '1118',
    type: 'LESSON',
    title: 'Hoe kan je goed presenteren?',
    image: 'https://i.ytimg.com/vi/FEPZVyXV1To/maxresdefault.jpg',
    programId: 40,
    clan: 'SCHOOLS',
    level: 1,
    version: 1,
    published: false,
    archived: false,
    modifiedBy: 'ingrid',
    modifiedOn: '2020-09-02',
    tags: [],
    endGoals: [],
    __typename: 'LessonContent',
    groupRoles: [],
    language: 'NL',
  },
];
