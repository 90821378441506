import React, { useState } from 'react';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  Input,
} from '@ftrprf/tailwind-components';

import { Icon, IconKeys } from '../pages/ContentEditor/utils/activityTypeIcons';

import useFormatMessage from '../hooks/useFormatMessage';
const IconPickerDialog = ({ onConfirm, onDismiss, isOpen }) => {
  const t = useFormatMessage();
  const [searchString, setSearchString] = useState('');

  const isSearching = searchString && searchString !== '';

  const filteredIcons = isSearching
    ? IconKeys?.filter((value) => value.toLowerCase().includes(searchString))
    : IconKeys;

  return (
    <Dialog isOpen={isOpen} onDismiss={onDismiss}>
      <DialogHeader>{t('content-editor.activity.image.select')}</DialogHeader>
      <DialogContent>
        <div className="">
          <Input
            type="text"
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value.toLowerCase());
            }}
          />
          <div className="flex flex-start h-64 flex-wrap gap-2 mt-2 overflow-y-scroll">
            {filteredIcons.map((key) => (
              <Icon
                itemName={key}
                key={key}
                size={64}
                onClick={() => onConfirm(key)}
              />
            ))}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default React.memo(IconPickerDialog);
