import { useEffect, useState } from 'react';
import { createElement } from 'react';
import parsePlugin from 'rehype-parse';
import rehypeReact from 'rehype-react';
import unified from 'unified';

import { OutlineButton } from '../../buttons/OutlineButton';
import { Dialog, DialogContent, DialogHeader } from '../../dialogs';
import SlideViewerBaseSlide from '../SlideViewerBaseSlide';

import Link from './Link';

const processor = unified()
  .use(parsePlugin, { fragment: true })
  .use(rehypeReact, {
    createElement,
    components: { ['a']: Link },
  });
const ModalButton = ({
  modalcontent,
  modaltitle,
  modalsize,
  label,
  children,
  ...props
}) => {
  const [isModalOpen, setIsModalOpen] = useState();
  const escFunction = (event) => {
    if (event.keyCode === 27) {
      setIsModalOpen(false);
    }
  };

  const clickAutoClose = (event) => {
    if (
      event.toElement == document.querySelector('[data-reach-dialog-overlay]')
    ) {
      setIsModalOpen(false);
    }
  };
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('keyup', escFunction);
      document.addEventListener('mousedown', clickAutoClose);
    } else {
      document.removeEventListener('keyup', escFunction);
      document.removeEventListener('mousedown', clickAutoClose);
    }
  }, [isModalOpen]);

  if (!modaltitle) {
    return <button {...props}>{children}</button>;
  }

  return (
    <>
      {isModalOpen && (
        <Dialog
          className={
            modalsize === 'S'
              ? 'w-3/12'
              : modalsize === 'M'
              ? 'w-6/12'
              : 'w-9/12'
          }
          modal={true}
        >
          <DialogHeader
            hasCloseButton={true}
            onDismiss={() => setIsModalOpen(false)}
          >
            {modaltitle}
          </DialogHeader>
          <DialogContent>
            <SlideViewerBaseSlide value={modalcontent} processor={processor} />
          </DialogContent>
        </Dialog>
      )}
      <OutlineButton onClick={() => setIsModalOpen(true)} {...props}>
        {label}
      </OutlineButton>
    </>
  );
};

export default ModalButton;
