import Tooltip from '@reach/tooltip';

import c from '../../utils/c';

import { Loader } from '../Loader';

export const Button = ({
  className,
  iconBefore,
  iconAfter,
  children,
  loading,
  disabled,
  disabledClassName,
  hidden,
  hiddenClassName,
  type,
  title,
  badge,
  loaderClassName,
  iconSizeClassName,
  ...props
}) => {
  const IconBefore = iconBefore;
  const IconAfter = iconAfter;

  const button = (
    <button
      type={type}
      disabled={disabled}
      className={c(
        className,
        hidden
          ? hiddenClassName
          : disabled
          ? disabledClassName
          : 'cursor-pointer',
        'flex items-center box-border',
        badge && 'relative',
      )}
      {...props}
    >
      {iconBefore && (
        <span className="mr-2">
          {loading ? (
            <div className={loaderClassName}>
              <Loader />
            </div>
          ) : (
            <IconBefore className={iconSizeClassName} />
          )}
        </span>
      )}
      {loading && !iconBefore && !iconAfter ? (
        <div className={loaderClassName}>
          <Loader />
        </div>
      ) : (
        children
      )}
      {iconAfter && (
        <span className="ml-2">
          {loading ? (
            <div className={loaderClassName}>
              <Loader />
            </div>
          ) : (
            <IconAfter className={iconSizeClassName} />
          )}{' '}
        </span>
      )}
      {badge && (
        <div className="absolute top-0 right-0 text-xxs py-1 px-2 -mr-1 bg-accent-100 text-accent-600 rounded-full leading-none -mt-3 font-semibold shadow-sm">
          {badge}
        </div>
      )}
    </button>
  );

  if (title) {
    return <Tooltip label={title}>{button}</Tooltip>;
  }
  return button;
};

Button.defaultProps = {
  disabled: false,
  hidden: false,
  disabledClassName: 'opacity-40 cursor-not-allowed',
  hiddenClassName: 'invisible',
  loading: false,
  type: 'button',
  iconSizeClassName: 'w-3 h-3',
  loaderClassName: 'w-5 h-5',
};
