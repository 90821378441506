import { atom } from 'recoil';

const cache = {};

const memoize = (resource, fn) => (key) => {
  if (!cache[resource]) {
    cache[resource] = {};
  }

  if (cache[resource][key]) {
    return cache[resource][key];
  }

  cache[resource][key] = fn(key);

  return cache[resource][key];
};

export const lessonContentAtom = atom({
  key: `LessonContent`,
  default: null,
});

export const slideAtom = memoize('Slide', (id) => {
  return atom({
    key: `Slide:${id}`,
    default: null,
  });
});

export const questionAtom = memoize('Question', (id) => {
  return atom({
    key: `Question:${id}`,
    default: null,
  });
});

export const questionAnswerMultipleChoiceAtom = memoize(
  'QuestionAnswerMultipleChoiceAtom',
  (id) => {
    return atom({
      key: `QuestionAnswerMultipleChoiceAtom:${id}`,
      default: null,
    });
  },
);

export const hintAtom = memoize('Hint', (id) => {
  return atom({
    key: `Hint:${id}`,
    default: null,
  });
});

export const sideBarAtom = atom({
  key: `SideBar`,
  default: null,
});

export const sideBarQueryPath = atom({
  key: `SideBarQueryPath`,
  default: null,
});

export const isDraggingAtom = atom({
  key: `IsDragging`,
  default: false,
});

export const isHoveredAtom = atom({
  key: `IsEditorHovered`,
  default: null,
});

export const lastSavedAtAtom = atom({
  key: `LastSavedAt`,
  default: null,
});
