import c from '../../utils/c';

import { Button } from './Button';

export const MenuButton = ({ className, children, ...props }) => (
  <Button
    className={c(
      'w-full py-1 px-4 text-left rounded-lg hover:bg-gray-200',
      className,
    )}
    {...props}
  >
    {children}
  </Button>
);
