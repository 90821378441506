import { FaFilter, FaPlus } from 'react-icons/fa';

import { useLocalStorage } from '@ftrprf/hooks';
import { FilledButton, OutlineButton } from '@ftrprf/tailwind-components';

import PageActions from '../../../components/PageActions';

import useFormatMessage from '../../../hooks/useFormatMessage';

import { CONTENT_OVERVIEW_FILTERBAR_OPEN } from '../../../utils/constants/localStorage';

import ContentOverviewFilterbar from './ContentOverviewFilterbar';

const ContentOverviewHeader = ({
  contentType,
  setFilters,
  onNewContentClick,
}) => {
  const t = useFormatMessage();

  const [isShowingFilter, setIsShowingFilter] = useLocalStorage(
    CONTENT_OVERVIEW_FILTERBAR_OPEN,
    false,
  );

  return (
    <>
      <PageActions>
        <FilledButton iconBefore={FaPlus} onClick={onNewContentClick}>
          {t(`content-overview.${contentType}.new_content`)}
        </FilledButton>
        {!isShowingFilter ? (
          <FilledButton
            iconBefore={FaFilter}
            onClick={() => setIsShowingFilter((s) => !s)}
          >
            {t('content-overview.toggle_filter')}
          </FilledButton>
        ) : (
          <OutlineButton
            iconBefore={FaFilter}
            onClick={() => setIsShowingFilter((s) => !s)}
          >
            {t('content-overview.toggle_filter')}
          </OutlineButton>
        )}
      </PageActions>

      {isShowingFilter && (
        <div className="mt-6">
          <ContentOverviewFilterbar onChange={setFilters} />
        </div>
      )}
    </>
  );
};

export default ContentOverviewHeader;
