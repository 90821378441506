import { Fragment, useMemo } from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

import useWindowSize from '../../hooks/useWindowSize';

import c from '../../utils/functions/c';
const MIN_ITEMS = 5;
export const getPaginationItems = (currentPage, totalPages, maxItems) => {
  if (maxItems <= MIN_ITEMS - 1) {
    throw new Error('maxItems should be longer than 5');
  }
  const numberOfSurroundingItems = maxItems - (MIN_ITEMS - 1);
  const numberOfSurroundingItemsLeftOfCurrentPage = Math.ceil(
    numberOfSurroundingItems / 2,
  );
  const numberOfSurroundingItemsRightOfCurrentPage = Math.floor(
    numberOfSurroundingItems / 2,
  );
  if (maxItems === totalPages) {
    return Array.from({ length: maxItems }, (_, index) => index + 1);
  }
  if (currentPage <= numberOfSurroundingItemsLeftOfCurrentPage + 2) {
    return [
      ...Array.from(
        { length: Math.min(totalPages - 1, maxItems - 2) },
        (_, index) => index + 1,
      ),
      totalPages,
    ];
  }
  if (
    totalPages - currentPage + 1 <=
    numberOfSurroundingItemsRightOfCurrentPage + 3
  ) {
    return [
      1,
      ...Array.from(
        { length: maxItems - 2 },
        (_, index) => totalPages - maxItems + 3 + index,
      ),
    ];
  }
  return [
    1,
    ...Array.from(
      { length: numberOfSurroundingItems },
      (_, index) =>
        currentPage - numberOfSurroundingItemsLeftOfCurrentPage + 1 + index,
    ),
    totalPages,
  ];
};
const getResponsiveMaxItems = ({ width }, maxItems) => {
  if (width <= 640) {
    return Math.min(MIN_ITEMS, maxItems);
  }
  if (width <= 768) {
    return Math.min(9, maxItems);
  }
  return Math.min(width, maxItems);
};
const PaginationItem = ({ disabled, active, children, ...props }) => (
  <button
    type="submit"
    className={c(
      'text-center font-bold cursor-pointer px-4 py-1',
      disabled && 'text-gray-600 cursor-not-allowed',
      active && 'text-accent-600 bg-accent-100 ',
      !disabled && !active && 'hover:text-accent-500',
    )}
    disabled={disabled}
    {...props}
  >
    {children}
  </button>
);
const Pagination = ({ currentPage, totalPages, maxItems, onClick }) => {
  const windowSize = useWindowSize();
  const leftActionDisabled = currentPage <= 1;
  const rightActionDisabled = currentPage >= totalPages;

  const items = useMemo(() => {
    let maxCount = maxItems;
    if (maxCount > totalPages) {
      maxCount = Math.max(totalPages, MIN_ITEMS);
    }
    return getPaginationItems(
      currentPage,
      totalPages,
      getResponsiveMaxItems(windowSize, maxCount),
    );
  }, [maxItems, totalPages, currentPage, windowSize]);

  if (totalPages <= 1) {
    return null;
  }

  return (
    <div className="flex items-center text-sm shadow-base bg-white rounded">
      <PaginationItem
        disabled={leftActionDisabled}
        onClick={(e) => !leftActionDisabled && onClick(currentPage - 1, e)}
      >
        <FaAngleLeft className="leading-none" />
      </PaginationItem>
      {items.map((item, index) => (
        <Fragment key={index}>
          {index - 1 >= 0 && items[index - 1] !== item - 1 && (
            <PaginationItem
              onClick={() =>
                onClick(
                  items[index - 1] === 1 ? item - 1 : items[index - 1] + 1,
                )
              }
            >
              ...
            </PaginationItem>
          )}
          <PaginationItem
            active={currentPage === item}
            onClick={(e) => onClick(item, e)}
          >
            {item}
          </PaginationItem>
        </Fragment>
      ))}
      <PaginationItem
        disabled={rightActionDisabled}
        onClick={(e) => !rightActionDisabled && onClick(currentPage + 1, e)}
      >
        <FaAngleRight className="leading-none" />
      </PaginationItem>
    </div>
  );
};
Pagination.defaultProps = {
  currentPage: 1,
  maxItems: 7,
  onClick: () => {},
};
export default Pagination;
