import c from '../../utils/c';
import getButtonStyle from '../../utils/getComponentStyle';

import { Button } from './Button';

const buttonStyles = {
  ACCENT: 'text-center bg-accent-100 text-accent-600',
  SECONDARY: 'text-center bg-gray-200',
  TERTIARY: 'text-center bg-gray-300',
  SUCCESS: 'text-center bg-green-100 text-green-700',
  ERROR: 'text-center bg-red-100 text-red-700',
};

export const FilledButton = ({
  children,
  className,
  accent,
  success,
  secondary,
  tertiary,
  disabled,
  error,
  ...props
}) => {
  const style = getButtonStyle(
    buttonStyles,
    { accent, success, secondary, tertiary, error },
    'ACCENT',
  );

  return (
    <Button
      className={c(
        className,
        buttonStyles[style],
        !disabled &&
          'hover:shadow-none hover:translate-x-px hover:translate-y-px',
        'transform px-4 py-1 rounded',
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};
