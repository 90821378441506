import '@ftrprf/tailwind-components/slideviewer-styles.css';

import { useLayoutEffect, useState } from 'react';
import { Field, Form, Formik } from 'formik';
import { boolean, object, string } from 'yup';

import { hideDialog } from '@ftrprf/editor';
import {
  allLinkStyles,
  CheckBox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  Label,
  OutlineButton,
  SLIDEVIEWER_PREFIX,
  STYLED_BUTTON_CLASS,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/functions/c';

import FormikInput from '../Form/FormikInput';
import FormikUrl from '../Form/FormikUrl';

import { ExerciseDialog } from './ExercisePlugin';

const availableLinkStyles = {
  MOVIE: 'VideoLink',
  SLIDE: 'SlideLink',
  EXTERNAL: 'ExternalLink',
  DOWNLOAD: 'DownloadLink',
  STEAMSQR: 'SteamsQR', // STEAMS
  OTHER: 'OtherLink',
};

// NB: It's not a regular CKEDITOR plugin
// How it works:
// 1. ftrprf-ckeditor (forked repo of CKEDITOR) throws a custom showLinkDialogEvent
//    during the original onShow event of link dialog
// 2. This file intercepts showLinkDialogEvent and completely replaces the modal and its logic
export const LinkPlugin = ({ editorAPI }) => {
  const t = useFormatMessage();
  const [isDialogShown, setIsDialogShown] = useState(false);
  const [selectedStyle, setSelectedStyle] = useState();
  const [initialLinkElement, setInitialLinkElement] = useState();
  const [isWarning, setIsWarning] = useState(false);

  const addNewLinkElement = ({
    url,
    displayText,
    isBlank,
    isDownload,
    fileName,
    slideId,
  }) => {
    editorAPI.insertHtml(
      `<a
            ${!isDownload && !slideId && `href="${url}"`}
            class="${c(
              selectedStyle && STYLED_BUTTON_CLASS,
              selectedStyle && `${SLIDEVIEWER_PREFIX}${selectedStyle}`,
            )}"
            ${slideId && `data-target-slide-id=${slideId}`}
            ${isBlank && 'target="_blank" rel="noreferrer"'}
            ${isDownload && `download-file-name="${fileName}"`}
            ${isDownload && `download-url="${url}"`}
          >
            ${displayText || url}
          </a>`,
    );
  };

  const editLinkElement = ({
    url,
    displayText,
    isBlank,
    isDownload,
    fileName,
    slideId,
  }) => {
    if (!!url && !slideId) {
      initialLinkElement.setAttribute('href', url);
    }
    if (!!slideId) {
      initialLinkElement.setAttribute('data-target-slide-id', slideId);
      initialLinkElement.removeAttribute('href');
    }
    initialLinkElement.setAttribute('target', isBlank && '_blank');
    initialLinkElement.setHtml(displayText || url);
    initialLinkElement.removeAttribute('data-cke-saved-href');

    if (isBlank) {
      initialLinkElement.setAttribute('target', '_blank');
      initialLinkElement.setAttribute('rel', 'noreferrer');
    } else {
      initialLinkElement.removeAttribute('target');
    }

    if (isDownload) {
      initialLinkElement.removeAttribute('href');
      initialLinkElement.removeAttribute('target');
      initialLinkElement.setAttribute('download-file-name', fileName);
      initialLinkElement.setAttribute('download-url', url);
    } else {
      initialLinkElement.removeAttribute('download-file-name');
      initialLinkElement.removeAttribute('download-url');
    }

    Object.values(availableLinkStyles).map((style) =>
      initialLinkElement.removeClass(`${SLIDEVIEWER_PREFIX}${style}`),
    );

    if (selectedStyle) {
      initialLinkElement.addClass(`${SLIDEVIEWER_PREFIX}${selectedStyle}`);
      if (!initialLinkElement.hasClass(STYLED_BUTTON_CLASS)) {
        initialLinkElement.addClass(`${STYLED_BUTTON_CLASS}`);
      }
    } else {
      initialLinkElement.removeClass(STYLED_BUTTON_CLASS);
    }
  };

  const onSubmit = (values) => {
    const slideId = values.url.startsWith('slide://')
      ? values.url.replace('slide://', '')
      : null;
    if (!initialLinkElement) {
      addNewLinkElement({ ...values, slideId });
    } else {
      editLinkElement({ ...values, slideId });
    }
    editorAPI.fire('change'); // send a backend call to update the content
    setIsDialogShown(false);
  };

  const setSelectedStyleEnhanced = (style) => {
    setSelectedStyle((prevStyle) => (prevStyle === style ? null : style));
  };

  useLayoutEffect(() => {
    function openLinkDialog(event) {
      // process the event only if it was fired from the necessary editor
      // (currently showLinkDialogEvent is dispatched from each instance of ckeditor)
      if (editorAPI.name === event.detail.editorName) {
        setInitialLinkElement(event.detail.linkElement);
        setSelectedStyle(
          Object.values(allLinkStyles).find((className) =>
            event.detail.linkElement?.hasClass(
              `${SLIDEVIEWER_PREFIX}${className}`,
            ),
          ),
        );
        hideDialog();
        setIsDialogShown(true);
      }
    }
    window.addEventListener('showLinkDialogEvent', openLinkDialog);
    return () => {
      window.removeEventListener('showLinkDialogEvent', openLinkDialog);
    };
  }, [editorAPI]);

  const generateTitle = () => {
    if (selectedStyle === allLinkStyles.EXERCISE) {
      return t('link-plugin.url.modify-exercise');
    }

    return !!initialLinkElement
      ? t('link-plugin.url.modify-url')
      : t('link-plugin.url.add-url');
  };

  const isExercise = initialLinkElement?.hasClass('SlideViewer__ExerciseLink');

  const validateUrl = async (value) => {
    if (value.startsWith('slide://')) {
      return null;
    }
    const urlSchema = object({
      url: string().url('validation.url').required('validation.required'),
    });
    return urlSchema
      .validate({ url: value })
      .then(() => {
        return null;
      })
      .catch((error) => {
        if (error.errors && error.errors.length > 0) {
          return error.errors[0];
        }
        return null;
      });
  };

  // initial value for the url input
  const initialUrlValue = () => {
    const slideId = initialLinkElement?.getAttribute('data-target-slide-id');
    if (slideId) {
      return `slide://${slideId}`;
    } else {
      return (
        initialLinkElement?.getAttribute('href') ||
        initialLinkElement?.getAttribute('download-url')
      );
    }
  };

  if (isExercise) {
    // initialLinkElement is never null in this case
    return (
      isDialogShown && (
        <ExerciseDialog
          displayText={initialLinkElement?.getHtml()}
          versionId={initialLinkElement?.getAttribute('versionid')}
          onSubmit={({ displayText, linkId, exerciseVersionId }) => {
            initialLinkElement.setAttribute('versionid', exerciseVersionId);
            initialLinkElement.setAttribute('data-studio-link-id', linkId);
            initialLinkElement.setHtml(displayText);
          }}
          onDismiss={() => setIsDialogShown(false)}
        />
      )
    );
  }

  return (
    <Dialog
      onDismiss={() => {
        setIsDialogShown(false);
      }}
      aria-label={t('link-plugin.url.dialog')}
      isOpen={isDialogShown}
    >
      <Formik
        initialValues={{
          url: initialUrlValue(),
          displayText:
            initialLinkElement?.getHtml() ||
            editorAPI.getSelection()?.getSelectedText(),
          isBlank:
            !initialLinkElement ||
            initialLinkElement.getAttribute('target') === '_blank',
          isDownload: !!initialLinkElement?.getAttribute('download-url'),
          fileName:
            initialLinkElement?.getAttribute('download-file-name') || '',
        }}
        validateOnBlur={false}
        validationSchema={object({
          displayText: string().optional(),
          ...(selectedStyle === allLinkStyles.EXERCISE || {
            isBlank: boolean().required(),
            isDownload: boolean().required(),
            fileName: string().nullable(true).optional(),
          }),
        })}
        onSubmit={onSubmit}
      >
        {({ isSubmitting, isValid, values }) => (
          <Form>
            <DialogHeader>{generateTitle()}</DialogHeader>
            <DialogContent>
              {selectedStyle === allLinkStyles.EXERCISE ? (
                <FormikInput
                  type="text"
                  name="displayText"
                  label={t('link-plugin.display-text')}
                />
              ) : (
                <>
                  <Field name="url" type="url">
                    {({ form: { setFieldValue } }) => (
                      <FormikUrl
                        name="url"
                        label={t('global.URL')}
                        validate={validateUrl}
                        onChange={(e) => {
                          setFieldValue('url', e.target.value);
                          if (
                            e.target.value &&
                            e.target.value.startsWith('slide://')
                          ) {
                            setSelectedStyle(allLinkStyles.SLIDE);
                            setIsWarning(true);
                          } else {
                            setIsWarning(false);
                          }
                        }}
                      />
                    )}
                  </Field>
                  {isWarning && (
                    <p className="text-sm text-yellow-600">
                      {t('link-plugin.url.warning')}
                    </p>
                  )}
                  <FormikInput
                    type="text"
                    name="displayText"
                    label={t('link-plugin.display-text')}
                  />
                  {values.isDownload && (
                    <FormikInput
                      type="text"
                      name="fileName"
                      label={t('link-plugin.file_name')}
                    />
                  )}
                  <Field name="isDownload" type="checkbox">
                    {({ field: { value }, form: { setFieldValue } }) => (
                      <CheckBox
                        checked={value}
                        label={t('link-plugin.is_download')}
                        onChange={(e) => {
                          setFieldValue('isDownload', e);
                          if (e) {
                            setSelectedStyle(allLinkStyles.DOWNLOAD);
                            setFieldValue('isBlank', false);
                          }
                        }}
                      />
                    )}
                  </Field>
                  {!values.isDownload && (
                    <>
                      <div className="h-2" />
                      <Field name="isBlank" type="checkbox">
                        {({ field: { value }, form: { setFieldValue } }) => (
                          <CheckBox
                            checked={value}
                            label={t('link-plugin.open_new_tab')}
                            onChange={(e) => setFieldValue('isBlank', e)}
                          />
                        )}
                      </Field>
                    </>
                  )}
                  <Label className="mt-4">
                    {t('link-plugin.url.link-style.label')}{' '}
                    <span className="text-xs text-gray-600">
                      {t('global.not_required')}
                    </span>
                  </Label>
                  <div className="flex flex-wrap justify-around items-center SlideViewerTextSlide">
                    {Object.values(availableLinkStyles).map((style) => (
                      // eslint-disable-next-line
                      <a
                        key={style}
                        className={c(
                          STYLED_BUTTON_CLASS,
                          'cursor-pointer select-none mb-2',
                          `${SLIDEVIEWER_PREFIX}${style}`,
                          style !== selectedStyle && 'opacity-25',
                        )}
                        onClick={() => setSelectedStyleEnhanced(style)}
                      >
                        {t(`link-plugin.link-style.${style}`)}
                      </a>
                    ))}
                  </div>
                </>
              )}
            </DialogContent>
            <DialogActions>
              <OutlineButton
                onClick={() => {
                  setIsDialogShown(false);
                  setIsWarning(false);
                }}
              >
                {t('global.cancel')}
              </OutlineButton>
              <FilledButton
                role="submit"
                type="submit"
                disabled={isSubmitting || !isValid}
              >
                {generateTitle()}
              </FilledButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};
