const errorToMessageDescriptor = (error) => {
  if (
    error.response?.data?.code === 'USER_DOES_NOT_EXIST' ||
    error.response?.status === 403
  ) {
    return `errors.user_does_not_exist`;
  }

  if (error.message === 'Network Error') {
    return 'errors.network_error';
  }

  return 'errors.something_went_wrong';
};

export default errorToMessageDescriptor;
