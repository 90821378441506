const pick = (data, fields) => {
  const result = {};

  fields.forEach((field) => {
    result[field] = data[field];
  });

  return result;
};

export default pick;
