import { gql } from '@apollo/client';

export const REMOVE_QUESTION = gql`
  mutation RemoveQuestion($id: Int!) {
    removeQuestion(id: $id) {
      id
      statuscode
      message
    }
  }
`;

export const CREATE_QUESTION_MULTIPLE_CHOICE = gql`
  mutation CreateQuestionMultipleChoice($slideId: Int!) {
    createQuestionMultipleChoice(slideId: $slideId, input: { value: "" }) {
      id
      value
      type
      publishedAt
      scorePerCorrectAnswer
      subtractPerWrongAnswer
      maxSelectableAnswers
      shouldFindAllCorrect
      shuffleAnswers
      questionAnswersMultipleChoice {
        id
        value
        correct
        explanation
        numbering
      }
    }
  }
`;

export const CREATE_QUESTION_OPEN = gql`
  mutation CreateQuestionOpen($slideId: Int!) {
    createQuestionOpen(slideId: $slideId, input: { value: "" }) {
      id
      value
      type
      publishedAt
      scorePerCorrectAnswer
      placeholder
      solution
      questionAnswerOpen {
        id
        explanation
        optional
      }
    }
  }
`;

export const UPDATE_QUESTION_MULTIPLE_CHOICE = gql`
  mutation UpdateQuestionMultipleChoice(
    $id: Int!
    $shuffleAnswers: Boolean
    $shouldFindAllCorrect: Boolean
    $scorePerCorrectAnswer: String
    $subtractPerWrongAnswer: String
    $maxSelectableAnswers: Int
  ) {
    updateQuestionMultipleChoice(
      id: $id
      input: {
        shuffleAnswers: $shuffleAnswers
        shouldFindAllCorrect: $shouldFindAllCorrect
        scorePerCorrectAnswer: $scorePerCorrectAnswer
        subtractPerWrongAnswer: $subtractPerWrongAnswer
        maxSelectableAnswers: $maxSelectableAnswers
      }
    ) {
      id
      shuffleAnswers
      shouldFindAllCorrect
      scorePerCorrectAnswer
      subtractPerWrongAnswer
      maxSelectableAnswers
    }
  }
`;

export const UPDATE_QUESTION_OPEN = gql`
  mutation UpdateQuestionOpen(
    $id: Int!
    $solution: String
    $placeholder: String
    $scorePerCorrectAnswer: String
  ) {
    updateQuestionOpen(
      id: $id
      input: {
        solution: $solution
        placeholder: $placeholder
        scorePerCorrectAnswer: $scorePerCorrectAnswer
      }
    ) {
      id
      solution
      placeholder
      scorePerCorrectAnswer
    }
  }
`;

export const CREATE_QUESTION_ANSWER_MULTIPLE_CHOICE = gql`
  mutation CreateQuestionAnswerMultipleChoice(
    $questionId: Int!
    $value: String!
    $correct: Boolean
    $explanation: String
  ) {
    createQuestionAnswerMultipleChoice(
      questionId: $questionId
      input: { value: $value, correct: $correct, explanation: $explanation }
    ) {
      id
      value
      correct
      explanation
    }
  }
`;

export const UPDATE_QUESTION_ANSWER_MULTIPLE_CHOICE = gql`
  mutation UpdateQuestionAnswerMultipleChoice(
    $id: Int!
    $value: String!
    $correct: Boolean
    $explanation: String
  ) {
    updateQuestionAnswerMultipleChoice(
      id: $id
      input: { value: $value, correct: $correct, explanation: $explanation }
    ) {
      id
      value
      correct
      explanation
    }
  }
`;

export const REMOVE_QUESTION_ANSWER = gql`
  mutation RemoveQuestionAnswer($id: Int!, $questionType: QuestionType!) {
    removeQuestionAnswer(id: $id, questionType: $questionType) {
      id
    }
  }
`;
