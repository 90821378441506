import { FaPlus } from 'react-icons/fa';

import useFormatMessage from '../../../../../hooks/useFormatMessage';
import useMultipleChoiceQuestion from '../../../hooks/useMultipleChoiceQuestion';

import c from '../../../../../utils/functions/c';

import { SIDE_BARS } from '../../SideBars/SideBarContextProvider';
import QuestionBox from '../QuestionBox';

import QuestionAnswerMultipleChoice from './QuestionAnswerMultipleChoice';

const UPPER_THRESHOLD_ANSWERS = 15;

const MultipleChoice = ({
  questionId,
  disabled,
  setWarningDialogOpen,
  questionDisabled,
}) => {
  const t = useFormatMessage();

  const { question, createQuestionAnswerMultipleChoice } =
    useMultipleChoiceQuestion(questionId);

  if (!question) {
    return null;
  }

  const answers = question.questionAnswersMultipleChoice;

  return (
    <div className="flex flex-col flex-grow border-t border-gray-300 p-2 overflow-y-auto">
      <QuestionBox
        title={t('global.answer')}
        question={question}
        disabled={disabled}
        sideBarType={SIDE_BARS.MULTIPLE_CHOICE_QUESTION_SIDEBAR}
        questionDisabled={questionDisabled}
        setWarningDialogOpen={setWarningDialogOpen}
      >
        <div className="grid grid-cols-2 xl:grid-cols-3 gap-4">
          {answers.map((answer, index) => (
            <QuestionAnswerMultipleChoice
              questionAnswerMultipleChoiceId={answer.id}
              key={answer.id}
              index={index}
              questionId={questionId}
            />
          ))}
          {answers.length !== UPPER_THRESHOLD_ANSWERS && !question.publishedAt && (
            <button
              className={c(
                'rounded border-dashed border-gray-400 border-2 text-2xl text-gray-400 cursor-pointer flex items-center justify-center h-full min-h-16',
                !disabled && 'hover:bg-gray-200',
                disabled && 'opacity-50',
              )}
              disabled={disabled}
              onClick={() =>
                createQuestionAnswerMultipleChoice({
                  value: '',
                  correct: false,
                  explanation: '',
                })
              }
            >
              <FaPlus />
            </button>
          )}
        </div>
      </QuestionBox>
    </div>
  );
};

export default MultipleChoice;
