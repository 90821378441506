import { useEffect, useState } from 'react';

import WarningPublishedEditDialog from '../../../../components/Dialog/WarningPublishedEditDialog';

import MultipleChoice from './MultipleChoice';
import OpenQuestion from './OpenQuestion';

const questionElements = {
  MULTIPLE_CHOICE: MultipleChoice,
  OPEN: OpenQuestion,
};

const Question = ({ question, disabled, onDuplicateSlide }) => {
  const [warningDialogOpen, setWarningDialogOpen] = useState(false);
  const [questionDisabled, setQuestionDisabled] = useState(false);

  useEffect(() => {
    setQuestionDisabled(!!question?.publishedAt);
  }, [question]);

  if (!question) {
    return null;
  }

  const QuestionElement = questionElements[question.type];

  return (
    <>
      <WarningPublishedEditDialog
        onDismiss={() => setWarningDialogOpen(false)}
        isOpen={warningDialogOpen}
        onDuplicate={() => {
          onDuplicateSlide().then(() => {
            setWarningDialogOpen(false);
          });
        }}
        onSmallChange={() => {
          setQuestionDisabled(false);
          setWarningDialogOpen(false);
        }}
      />
      <QuestionElement
        questionId={question.id}
        disabled={disabled}
        questionDisabled={questionDisabled}
        setWarningDialogOpen={setWarningDialogOpen}
      />
    </>
  );
};

export default Question;
