import c from '../utils/functions/c';

const ButtonGroup = ({ selectedValue, onChange, className, options }) => {
  const buttons = options.map(({ title, value }) => (
    <button
      autoFocus={selectedValue === value}
      type="button"
      key={value}
      onClick={() => onChange(value)}
      className={c(
        'px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700',
        selectedValue === value
          ? 'z-10 outline-none bg-blue-300'
          : 'hover:bg-gray-50',
      )}
    >
      {title}
    </button>
  ));

  return (
    <div
      className={c(
        'relative z-0 inline-flex shadow-sm rounded-md overflow-hidden',
        className,
      )}
    >
      {buttons}
    </div>
  );
};

export default ButtonGroup;
