import { useCallback, useState } from 'react';
import { FaWindowRestore } from 'react-icons/fa';
import { FormikProvider, useFormik } from 'formik';
import { number, object, string } from 'yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  Label,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

import FormikInput from '../Form/FormikInput';
import FormikUrl from '../Form/FormikUrl';

const Plugin = ({ editorAPI }) => {
  const t = useFormatMessage();

  const [isExternalShown, setIsExternalShown] = useState(false);

  const openModal = useCallback(() => setIsExternalShown(true), []);
  const closeModal = useCallback(() => setIsExternalShown(false), []);

  const insertIframeHtml = useCallback(
    (url) => {
      editorAPI.insertHtml(
        `
        <div style="display: grid; width: 100%; place-items: center;">
          <iframe src="${url}"></iframe>
        </div>
       `,
      );
    },
    [editorAPI],
  );

  const formik = useFormik({
    initialValues: {
      url: '',
      width: 300,
      height: 150,
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: false,
    validationSchema: object({
      url: string().url('validation.url').required('validation.empty'),
      width: number().max(1500, 'editor.iframeplugin.error.iframe.size'),
      height: number().max(1500, 'editor.iframeplugin.error.iframe.size'),
    }),
    onSubmit: ({ url }) => {
      insertIframeHtml(url);
      closeModal();
    },
  });

  const { isValid, resetForm, submitForm, values, errors } = formik;

  return (
    <>
      {isExternalShown && (
        <Dialog>
          <DialogHeader>{t('editor.iframeplugin.label')}</DialogHeader>
          <DialogContent>
            <FormikProvider value={formik}>
              <FormikUrl name="url" label={t('global.URL')} />
              <FormikInput
                type="number"
                name="height"
                className="w-1/3"
                label={t('global.height')}
                max="1500"
                min="0"
              />
              <FormikInput
                type="number"
                name="width"
                className="w-1/3"
                label={t('global.width')}
                max="1500"
                min="0"
              />
            </FormikProvider>
            <div>
              <Label>{t('content-editor.header.preview')}</Label>

              <div className="w-full grid place-items-center h-40">
                {!errors.url && values.url && (
                  <iframe
                    src={values.url}
                    title="iframe source"
                    width={values.width > 300 ? 300 : values.width}
                    height={values.height > 150 ? 150 : values.height}
                  />
                )}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <OutlineButton
              onClick={() => {
                closeModal();
                resetForm();
              }}
            >
              {t('global.cancel')}
            </OutlineButton>
            <FilledButton onClick={submitForm} disabled={!isValid}>
              {t('editor.iframeplugin.label')}
            </FilledButton>
          </DialogActions>
        </Dialog>
      )}
      <FaWindowRestore
        className="text-base"
        onClick={openModal}
        title={t('editor.iframeplugin.label')}
      />
    </>
  );
};

export const IframePlugin = ({ ...props }) => {
  const t = useFormatMessage();

  return <Plugin label={t('editor.iframeplugin.label')} {...props} />;
};
