import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';

import c from '../utils/c';

export const CheckBox = ({
  label,
  className,
  disabled,
  inverted,
  checked: givenChecked,
  defaultChecked,
  onChange,
}) => {
  const [checked, setChecked] = useState(defaultChecked || givenChecked);

  useEffect(() => {
    setChecked(givenChecked);
  }, [givenChecked]);

  return (
    <button
      className="flex items-center"
      type="button"
      disabled={disabled}
      onClick={() => {
        setChecked((c) => {
          onChange(!c);
          return !c;
        });
      }}
    >
      <div
        type="checkbox"
        className={c(
          className,
          'w-4 h-4 rounded bg-white cursor-pointer transition duration-200',
          !inverted && 'border border-gray-400 hover:border-gray-500',
          'focus:outline-none focus:shadow-focus',
          checked &&
            !inverted &&
            'bg-accent-500 border-accent-500 hover:bg-accent-600 hover:border-accent-600',
          disabled ? 'bg-gray-300 cursor-not-allowed' : 'cursor-pointer',
        )}
      >
        <FaCheck
          className={c(
            'text-white stroke-current w-full h-full m-auto transform transition duration-200',
            !checked && 'scale-0',
            checked && 'scale-75',
            inverted && 'text-accent-300',
          )}
        />
      </div>
      {label && (
        <span
          className={c(
            'ml-2',
            disabled ? 'text-gray-500 cursor-not-allowed' : 'cursor-pointer',
          )}
          htmlFor={label}
        >
          {label}
        </span>
      )}
    </button>
  );
};

CheckBox.defaultProps = {
  defaultChecked: false,
  onChange: () => {},
};
