import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from 'react-icons/ti';

const Arrows = ({ status }) => {
  switch (status) {
    case 0:
      return <TiArrowUnsorted />;
    case 1:
      return <TiArrowSortedUp />;
    case 2:
      return <TiArrowSortedDown />;
    default:
      return <TiArrowUnsorted />;
  }
};

export default Arrows;
