import c from '../utils/functions/c';

const ComponentTitle = ({ className, title }) => {
  return (
    <div className={c('ComponentTitle relative after:bg-accent', className)}>
      <p className="px-2 py-1 bg-accent-400 text-white font-semibold inline-block max-w-auto">
        {title}
      </p>
    </div>
  );
};

export default ComponentTitle;
