import { memo, useContext, useState } from 'react';
import { Resizable } from 're-resizable';

import { FilledButton, Loader } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useSlide from '../../hooks/useSlide';

import { SIDE_BARS, SideBarContext, sideBars } from './SideBarContextProvider';
import SlideSideBar from './SlideSideBar';

const SideBar = ({ slideId, disabled, enable }) => {
  const { slide, createHint } = useSlide(slideId);
  const [isCreatingHint, setIsCreatingHint] = useState(false);
  const { currentSideBar, setSideBar } = useContext(SideBarContext);
  const SideBarComponent = currentSideBar?.type
    ? sideBars[currentSideBar.type]
    : SlideSideBar;

  const t = useFormatMessage();

  if (!slide) {
    return null;
  }

  const hasHints = slide.hints.length > 0;

  return (
    <div className="flex flex-col flex-shrink-0 overflow-hidden">
      <div className="h-16 flex items-center justify-end p-4 border-b border-gray-300 bg-gray-50">
        <div className="h-6 w-px bg-gray-300" />
        <div className="px-4">
          <FilledButton
            tertiary
            disabled={disabled}
            onClick={() =>
              setSideBar({ type: SIDE_BARS.TEACHER_INFO_SIDEBAR, id: slideId })
            }
          >
            {t('content-editor.buttons.teacher_info')}
          </FilledButton>
        </div>
        <div className="h-6 w-px bg-gray-300" />
        <div className="pl-4">
          {hasHints ? (
            <FilledButton
              tertiary
              onClick={() =>
                setSideBar({ type: SIDE_BARS.HINTS_SIDEBAR, id: slideId })
              }
              badge={slide.hints.length}
            >
              {t('content-editor.buttons.hints')}
            </FilledButton>
          ) : (
            <FilledButton
              tertiary
              onClick={() => {
                setIsCreatingHint(true);
                createHint().then(() => {
                  setSideBar({ type: SIDE_BARS.HINTS_SIDEBAR, id: slideId });
                  setIsCreatingHint(false);
                });
              }}
              disabled={disabled || isCreatingHint}
            >
              {t('content-editor.buttons.add_hint')}{' '}
              {isCreatingHint && (
                <span className="inline-block w-3 h-3">
                  <Loader />
                </span>
              )}
            </FilledButton>
          )}
        </div>
      </div>
      <Resizable
        enable={enable}
        minWidth="350"
        maxWidth="500"
        defaultSize={{ width: '350' }}
        className="overflow-y-auto overflow-x-hidden flex-shrink-0 flex-grow flex-basis-0"
      >
        <div className="flex border-l border-gray-300 h-full">
          <SideBarComponent
            slideId={slideId}
            disabled={disabled}
            {...currentSideBar}
          />
        </div>
      </Resizable>
    </div>
  );
};

export default memo(SideBar);
