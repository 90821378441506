import { useCallback, useContext } from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { useHistory } from 'react-router-dom';

import ContentIcon from '../../../components/ContentIcon';
import Selection from '../../../components/Selection';

import { ContentActionContext } from '../../../providers/ContentActionProvider';

import c from '../../../utils/functions/c';
import formatBytes from '../../../utils/functions/formatBytes';
import { baseName } from '../../../utils/functions/path';

import ContentItemContextMenu from './ContentItemContextMenu';

const ContentItem = ({ queryPath, setQueryPath, root, item, provided }) => {
  const history = useHistory();

  const isSideBarManager = !!queryPath;

  const {
    data: { path, modifiedOn, size, modifiedBy, isFile },
    id,
    index,
  } = item;

  const {
    selectFile,
    selectedItems,
    addToSelection,
    deselectFile,
    clipBoardCut,
  } = useContext(ContentActionContext);

  const isSelected = selectedItems.find((fileId) => fileId === id);
  const isCut = clipBoardCut.find((fileId) => fileId === id);

  const onFolderPathChange = useCallback(() => {
    if (isSideBarManager) {
      setQueryPath(`${path}/`);
    } else {
      history.push(`/${root}${path}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);

  return (
    <ContentItemContextMenu file={{ ...item.data, id }}>
      <div
        onDoubleClick={() => {
          if (!isFile) {
            onFolderPathChange();
          }
        }}
      >
        <div
          className={c(
            'select-none hover:bg-gray-100',
            isSelected && 'bg-gray-100',
            isCut && 'opacity-50',
            index !== 0 && 'border-gray-200 border-t',
          )}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          onClick={(event) => {
            // Prevent from firing when checkbox or its parent is clicked
            if (
              event.target.getAttribute('type') !== 'checkbox' &&
              event.target.getAttribute('type') !== 'selection'
            ) {
              if (selectedItems.includes(id)) {
                deselectFile(id);
              } else {
                selectFile(id);
              }
            }
          }}
          onDoubleClick={(e) => e.preventDefault}
        >
          <span className="flex">
            <Selection
              isChecked={isSelected}
              onCheck={() => {
                addToSelection(id);
              }}
              onUncheck={() => {
                deselectFile(id);
              }}
            />
            <div className="p-4 flex justify-between w-full h-full items-center">
              <span
                className={c(
                  'flex  truncate mr-2',
                  !isSideBarManager && 'w-2/6',
                )}
              >
                <div className="w-4 h-4">
                  <ContentIcon {...item.data} thumbnail />
                </div>
                <span className="ml-4">{baseName(path)}</span>
              </span>
              {!isSideBarManager && (
                <>
                  <span className="w-1/6 truncate mr-2">
                    <FormattedDate
                      value={modifiedOn}
                      year="numeric"
                      month="2-digit"
                      day="2-digit"
                    />{' '}
                    <FormattedTime value={modifiedOn} />
                  </span>
                  <span className="w-2/6 truncate mr-2">{modifiedBy}</span>
                  <span className="w-1/6">
                    {size ? formatBytes(size) : '-'}
                  </span>
                </>
              )}
            </div>
          </span>
        </div>
      </div>
    </ContentItemContextMenu>
  );
};
export default ContentItem;
