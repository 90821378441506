import useScrollTopRef from '../../../hooks/useScrollTopRef';

import { SlideViewerSlide } from '../../slideviewer/SlideViewer';

const SlideViewerCenter = ({ currentSlide }) => {
  const scrollRef = useScrollTopRef(currentSlide);

  return (
    <div
      className="w-full flex flex-col items-center justify-center overflow-auto h-full z-10 py-10 pb-20"
      ref={scrollRef}
    >
      <SlideViewerSlide className="h-full block relative SlideViewerSlide flex-grow w-9/12" />
    </div>
  );
};

export default SlideViewerCenter;
