import { createContext, useMemo } from 'react';

export const TailwindContext = createContext({});

export const TailwindProvider = ({ children, t }) => {
  const context = useMemo(() => {
    return {
      t,
    };
  }, [t]);

  return (
    <TailwindContext.Provider value={context}>
      {children}
    </TailwindContext.Provider>
  );
};
