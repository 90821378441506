import c from '../../utils/functions/c';
import { baseName } from '../../utils/functions/path';

import ContentIcon from '../ContentIcon';
import { ListItem } from '../ListSelector';

const ContentListSelectorCenter = ({
  content,
  pathStack,
  currentPath,
  setCurrentPath,
  onFolderClick,
  onFileClick,
  onFileDoubleClick,
  onFolderDoubleClick,
}) => {
  return (
    <div className="flex-grow-0 overflow-y-auto">
      {content.map((item) => {
        const { isFile, path, extension, blobUri: url } = item;

        return (
          <ListItem
            key={path}
            className={c(isFile ? 'hover:bg-accent-200' : 'hover:bg-gray-100')}
            onClick={() => {
              if (!isFile) {
                pathStack.push(currentPath);
                setCurrentPath(path);
                onFolderClick(url);
              } else {
                onFileClick(url);
              }
            }}
            onDoubleClick={() => {
              if (isFile) {
                onFileDoubleClick(url);
              } else {
                onFolderDoubleClick();
              }
            }}
          >
            <ContentIcon thumbnail className="flex-none mr-3" {...item} />
            <span className="break-all leading-tight flex-1">
              {baseName(path, extension || '')}
            </span>
          </ListItem>
        );
      })}
    </div>
  );
};

export default ContentListSelectorCenter;
