import c from '../utils/functions/c';

const Center = ({ className, children, ...props }) => {
  return (
    <div className={c('flex justify-center text-center', className)} {...props}>
      {children}
    </div>
  );
};

export default Center;
