import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  FaChalkboardTeacher,
  FaChevronDown,
  FaChevronUp,
  FaUser,
  FaUsers,
} from 'react-icons/fa';
import { useMutation } from '@apollo/client';
import xor from 'lodash/xor';

import { NotificationContext } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import { UPDATE_SLIDE } from '../../../../api/slide';

import SLIDEMETHODS from '../../../../utils/constants/slideMethods';
import updateSlideSequences from '../../utils/updateSlideSequences';
import { multiSelectTo, reorderItems, swap } from './helpers/utils';
import { getGroupedSlides } from './helpers/utils';

import { DRAGTYPES } from './helpers/constants';

import SlideOverviewItem from './SlideOverviewItem';

const SlideOverview = ({
  currentSlide,
  slides,
  hasViewModeSelector,
  selectedSlideIds,
  setCurrentSlide,
  setSelectedSlideIds,
  setSlideSequences,
  onInsertSlide,
  onRemoveSlides,
  onDuplicateSlide,
  disabled,
  canSwitchSlides,
  setShowCreateNewVersionDialog,
}) => {
  const t = useFormatMessage();
  const listRef = useRef();
  const [currentDraggingSlideId, setCurrentDraggingSlideId] = useState();
  const { addNotification } = useContext(NotificationContext);
  const [closedGroups, setClosedGroups] = useState([]);

  const groupedSlides = getGroupedSlides(slides);

  const [updateSlide] = useMutation(UPDATE_SLIDE);

  const toggleGroup = (groupKey) => {
    setClosedGroups(xor(closedGroups, [groupKey]));
  };

  const updateSlideMethodAndActivityType = (slide, method, activityType) => {
    const updatedSlide = {
      ...slide,
      method,
      activityTypeId: activityType?.id,
      activityType,
    };

    // added optimistic response to preven glitching in the UI
    const response = updateSlide({
      variables: {
        ...updatedSlide,
      },
      optimisticResponse: {
        updateSlide: {
          __typename: 'Slide',
          id: updatedSlide.id,
          title: updatedSlide.title,
          content: updatedSlide.content,
          sequence: updatedSlide.sequence,
          viewModes: updatedSlide.viewModes,
          info: updatedSlide.info,
          part: updatedSlide.part,
          method: updatedSlide.method,
          activityType: updatedSlide.activityType
            ? {
                __typename: 'ActivityType',
                id: updatedSlide.activityType.id,
                key: updatedSlide.activityType.key,
              }
            : null,
        },
      },
    });

    return response;
  };

  const onMoveUp = useCallback(
    (slide) => {
      const index = slides.findIndex((s) => s.id === slide.id);

      if (index === 0) {
        return;
      }

      const previousSlide = slides[index - 1];

      // Check if previous slide has same method and activityType
      // If not the case this means its in a different group and it should just
      // update to switch to the group above, not actualy update the sequence
      if (
        slide.method === previousSlide.method &&
        slide.activityType.id === previousSlide.activityType.id
      ) {
        const [slideSequences] = updateSlideSequences(
          swap(slides, index, index - 1),
        );

        setSlideSequences(slideSequences);
      } else {
        updateSlideMethodAndActivityType(
          slide,
          previousSlide.method,
          previousSlide.activityType,
        );
      }
    },
    [slides, setSlideSequences],
  );

  const onMoveDown = useCallback(
    (slide) => {
      const index = slides.findIndex((s) => s.id === slide.id);

      if (index === slides.length - 1) {
        return;
      }

      const nextSlide = slides[index + 1];

      // Check if next slide has same method and activityType
      // If not the case this means its in a different group and it should just
      // update to switch to the group above, not actualy update the sequence
      if (
        slide.method === nextSlide.method &&
        slide.activityType.id === nextSlide.activityType.id
      ) {
        const [slideSequences] = updateSlideSequences(
          swap(slides, index, index - 1),
        );

        setSlideSequences(slideSequences);
      } else {
        updateSlideMethodAndActivityType(
          slide,
          nextSlide.method,
          nextSlide.activityType,
        );
      }

      const [slideSequences] = updateSlideSequences(
        swap(slides, index, index + 1),
      );
      setSlideSequences(slideSequences);
    },
    [slides, setSlideSequences],
  );

  const onInsert = useCallback(
    (slide) => {
      onInsertSlide(slide);
    },
    [onInsertSlide],
  );

  const onDelete = useCallback(
    (slide) => {
      if (selectedSlideIds.length === slides.length) {
        addNotification({
          type: 'warning',
          content: t('content-editor.errors.attempting_to_delete_all'),
        });

        return;
      }

      // If delete is clicked on a non-selected slide, only remove that slide and stop rest of function
      if (!selectedSlideIds.includes(slide.id)) {
        const result = slides.filter((s) => s.id !== slide.id);
        const [slideSequences] = updateSlideSequences(result);

        onRemoveSlides([slide], slideSequences);

        // hard stop
        return;
      }

      // Delete all slides that were selected.
      const slidesToRemove = slides.filter((s) =>
        selectedSlideIds.includes(s.id),
      );

      const slidesToKeep = slides.filter(
        (s) => !selectedSlideIds.includes(s.id),
      );

      const [slideSequences] = updateSlideSequences(slidesToKeep);

      // Calculate closest slide to set as the new current slide
      const nextSlide =
        slidesToKeep.find(
          (s) => s.sequence === slidesToRemove[0].sequence - 1,
        ) || slidesToKeep[0];

      setCurrentSlide(nextSlide);
      onRemoveSlides(slidesToRemove, slideSequences);
      setSelectedSlideIds(nextSlide ? [nextSlide.id] : []);
      setCurrentDraggingSlideId(undefined);
    },
    [
      selectedSlideIds,
      slides,
      setCurrentSlide,
      onRemoveSlides,
      setSelectedSlideIds,
      addNotification,
      t,
    ],
  );

  const onDuplicate = useCallback(
    (slide) => {
      onDuplicateSlide(slide);
    },
    [onDuplicateSlide],
  );

  const onDragStart = ({ draggableId, type }) => {
    if (type === DRAGTYPES.SLIDE) {
      const slideId = draggableId;

      if (!selectedSlideIds.includes(slideId)) {
        setSelectedSlideIds([draggableId]);
      }

      setCurrentDraggingSlideId(slideId);
      setCurrentSlide(slides.find((o) => o.id === slideId));
    } else {
      const selectedGroup = groupedSlides.find(
        (group) => group.key === draggableId,
      );
      const selectedSlides = selectedGroup.slides.map((slide) => slide.id);
      setSelectedSlideIds(selectedSlides);
    }
  };

  const onDragEnd = ({ destination, source, type }) => {
    // Published
    if (disabled) {
      setShowCreateNewVersionDialog(true);
      return;
    }

    // Dropped outside the Droppable
    if (!destination) {
      return;
    }

    if (type === DRAGTYPES.SLIDE) {
      // Draging a slide
      // If a slide changes groups it shoud receive the method and activity of the group it is dragged ontoo.
      if (source.droppableId !== destination.droppableId) {
        const destinationGroup = groupedSlides.find(
          (group) => group.key === destination.droppableId,
        );
        // we need to store all promisses from the updates so we can wait untill they all finished before updating the sequencing
        const promisses = [];

        // update each slide
        selectedSlideIds.forEach((selectedSlideId) => {
          const slide = slides.find((slide) => slide.id === selectedSlideId);
          const response = updateSlideMethodAndActivityType(
            slide,
            destinationGroup.method,
            destinationGroup.activityType,
          );
          promisses.push(response);
        });

        // When slides are update reorder
        Promise.all(promisses).then(() => {
          const newlyOrderedSlides = reorderItems(
            slides,
            selectedSlideIds,
            source,
            destination,
            groupedSlides,
          );
          const [slideSequences] = updateSlideSequences(newlyOrderedSlides);
          setSlideSequences(slideSequences);
          setCurrentDraggingSlideId();
        });
        // if dragged in same group, life is easy, just reorder.
      } else {
        const newlyOrderedSlides = reorderItems(
          slides,
          selectedSlideIds,
          source,
          destination,
          groupedSlides,
        );
        const [slideSequences] = updateSlideSequences(newlyOrderedSlides);
        setSlideSequences(slideSequences);
        setCurrentDraggingSlideId();
      }
    } else {
      // Draging a group, this is easy, just reorder the slides
      const destinationGroup = groupedSlides[destination.index];
      const newlyOrderedSlides = reorderItems(
        slides,
        selectedSlideIds,
        {
          index: 0,
          droppableId: groupedSlides[source.index].key,
        },
        {
          // depending on the drag direction you want to place it in front or behind the destination
          index:
            source.index < destination.index
              ? destinationGroup.slides.length
              : 0,
          droppableId: groupedSlides[destination.index].key,
        },
        groupedSlides,
      );

      const [slideSequences] = updateSlideSequences(newlyOrderedSlides);
      setSlideSequences(slideSequences);

      setCurrentDraggingSlideId();
    }
  };

  const toggleSelection = useCallback(
    (slide) => {
      setSelectedSlideIds([slide.id]);
      setCurrentDraggingSlideId(undefined);
      setCurrentSlide(slide);

      //const index = slides.findIndex((s) => s.id === slide.id);
      //listRef.current.scrollToItem(index);
    },
    [slides, setSelectedSlideIds, setCurrentDraggingSlideId, setCurrentSlide],
  );

  const toggleSelectionGroupKey = useCallback(
    (slide) => {
      if (!selectedSlideIds.includes(slide.id)) {
        setSelectedSlideIds((s) => [...s, slide.id]);
      } else {
        setSelectedSlideIds((s) => s.filter((id) => id !== slide.id));
      }
    },
    [selectedSlideIds, setSelectedSlideIds],
  );

  const toggleSelectionShiftKey = useCallback(
    (slide) => {
      setSelectedSlideIds(multiSelectTo(slide, slides, selectedSlideIds));
    },
    [slides, setSelectedSlideIds, selectedSlideIds],
  );

  const downHandler = useCallback(
    function handler({ key }) {
      if (canSwitchSlides) {
        const index = slides.findIndex((s) => s.id === currentSlide.id);

        if (key === 'ArrowDown' && index < slides.length - 1) {
          toggleSelection(slides[index + 1]);
        }

        if (key === 'ArrowUp' && index > 0) {
          toggleSelection(slides[index - 1]);
        }
      }
    },
    [currentSlide.id, canSwitchSlides, slides, toggleSelection],
  );

  useEffect(() => {
    window.addEventListener('keydown', downHandler);

    listRef?.current?.scrollToItem(
      slides.findIndex((slide) => selectedSlideIds.includes(slide.id)),
      'center',
    );

    return () => {
      window.removeEventListener('keydown', downHandler);
    };
  }, [currentSlide, downHandler, listRef, slides, selectedSlideIds]);

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="full-list" type={DRAGTYPES.GROUP}>
        {(provided1) => (
          <div
            {...provided1.droppableProps}
            ref={provided1.innerRef}
            className="p-2 pl-3"
          >
            {groupedSlides.map((group, index) => (
              <Draggable draggableId={group.key} index={index} key={group.key}>
                {(provided2) => (
                  <div
                    ref={provided2.innerRef}
                    {...provided2.draggableProps}
                    {...provided2.dragHandleProps}
                    className="mb-2 relative"
                  >
                    <div className="bg-gray-200 border border-gray-300 p-1 rounded-r flex items-center">
                      {group.method === SLIDEMETHODS.INDIVIDUALLY && (
                        <div className="text-sm">
                          <FaUser className="mr-2" />
                        </div>
                      )}
                      {group.method === SLIDEMETHODS.GROUP && (
                        <div className="text-lg">
                          <FaUsers className="mr-2" />
                        </div>
                      )}
                      {group.method === SLIDEMETHODS.CLASS && (
                        <div className="text-lg">
                          <FaChalkboardTeacher className="mr-2" />
                        </div>
                      )}
                      <h5 className="text-sm flex-grow p-1">
                        {group.activityType?.key || 'No activity'}
                      </h5>
                      {closedGroups.indexOf(group.key) > -1 ? (
                        <button
                          className="p-1"
                          onClick={() => toggleGroup(group.key)}
                        >
                          <FaChevronUp />
                        </button>
                      ) : (
                        <button
                          className="p-1"
                          onClick={() => toggleGroup(group.key)}
                        >
                          <FaChevronDown />
                        </button>
                      )}
                    </div>

                    <div className="absolute top-0 bottom-0 right-full w-1 bg-gray-300" />
                    <div
                      className={
                        'p-2 pr-0 pb-0 bg-white ' +
                        (closedGroups.indexOf(group.key) > -1 ? 'hidden' : '')
                      }
                    >
                      <Droppable droppableId={group.key} type={DRAGTYPES.SLIDE}>
                        {(provided3) => (
                          <div
                            {...provided3.droppableProps}
                            ref={provided3.innerRef}
                          >
                            {group.slides.map((slide, index) => (
                              <Draggable
                                draggableId={slide.id}
                                index={index}
                                key={slide.id}
                              >
                                {(provided4, draggableSnapshot4) => (
                                  <div
                                    ref={provided4.innerRef}
                                    {...provided4.draggableProps}
                                    {...provided4.dragHandleProps}
                                    className="mb-2"
                                  >
                                    <SlideOverviewItem
                                      hasViewModeSelector={hasViewModeSelector}
                                      disabled={disabled}
                                      slideId={slide.id}
                                      index={slide.originalIndex}
                                      originalIndex={slide.originalIndex}
                                      draggableSnapshot={draggableSnapshot4}
                                      isSelected={selectedSlideIds.includes(
                                        slide.id,
                                      )}
                                      isGhosting={
                                        selectedSlideIds.includes(slide.id) &&
                                        !!currentDraggingSlideId &&
                                        currentDraggingSlideId !== slide.id
                                      }
                                      toggleSelection={toggleSelection}
                                      toggleSelectionGroupKey={
                                        toggleSelectionGroupKey
                                      }
                                      toggleSelectionShiftKey={
                                        toggleSelectionShiftKey
                                      }
                                      onInsert={() => onInsert(slide)}
                                      onDelete={() => onDelete(slide)}
                                      onMoveDown={() => onMoveDown(slide)}
                                      onMoveUp={() => onMoveUp(slide)}
                                      onDuplicate={() => onDuplicate(slide)}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided3.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided1.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

SlideOverview.defaultProps = {
  slides: [],
};

export default SlideOverview;
