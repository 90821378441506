import { Suspense, useContext } from 'react';
import { Route } from 'react-router-dom';

import { Loader } from '@ftrprf/tailwind-components';

import { UserContext } from '../../providers/UserProvider';

import { CURRENT_DOMAIN_SETTINGS } from '../../utils/constants/domains';

import GeneralLayout from '../layouts/GeneralLayout';

const Redirect = () => {
  window.location = CURRENT_DOMAIN_SETTINGS.appUrl;

  return null;
};

const PrivateRoute = ({
  component: Component,
  render,
  layout: PageLayout,
  ...rest
}) => {
  const { signedIn } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(matchProps) =>
        signedIn ? (
          <PageLayout>
            <Suspense
              fallback={
                <div className="h-5 w-5">
                  <Loader />
                </div>
              }
            >
              {render ? render(matchProps) : <Component {...matchProps} />}
            </Suspense>
          </PageLayout>
        ) : (
          <Redirect />
        )
      }
    />
  );
};

PrivateRoute.defaultProps = {
  layout: GeneralLayout,
};

export default PrivateRoute;
