import '@ftrprf/editor/styles.css';

import { useContext, useEffect, useMemo, useState } from 'react';

import { Editor as EditorComponent } from '@ftrprf/editor';
import { usePrevious } from '@ftrprf/tailwind-components';

import { LanguageContext } from '../../providers/LanguageProvider';

import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/functions/c';

import getContentStyles from './functions/getContentStyles';
import Overlay from '../Overlay';

import {
  AudioPlugin,
  ExercisePlugin,
  IframePlugin,
  ImagePlugin,
  LinkPlugin,
  ModalPlugin,
  VideoPlugin,
} from './customPlugins';

const Editor = ({
  id,
  className,
  value,
  onChange,
  disabled,
  withBorder,
  plugins,
  containerClass,
  isMainEditor,
}) => {
  const { language } = useContext(LanguageContext);
  const t = useFormatMessage();

  const labels = {
    AudioPlugin: t('editor.audioplugin.label'),
    ExercisePlugin: t('editor.exerciseplugin.label'),
    ImagePlugin: t('editor.imageplugin.label'),
    LinkPlugin: t('editor.linkplugin.label'),
    VideoPlugin: t('editor.videoplugin.label'),
    ModalPlugin: t('editor.modalplugin.label'),
    IframePlugin: t('editor.iframeplugin.label'),
  };

  const contentStyles = useMemo(getContentStyles, []);

  const previousId = usePrevious(id);
  const [shortlyDisabled, setShortlyDisabled] = useState(false);

  /**
   * Sometimes we use the same editor for different resource objects.
   * When switching between instances, editor.setDate() and editor.getData() is
   * called interchangeably resulting in some weird behavior.
   *
   * The 'id' prop of this component indicates a unique identifier for the resource object.
   * When this id is changed, we remove the onChange callback and don't update the content in the
   * editor for a few milliseconds. When switching is done, the onChange callback and most recent
   * data is set.
   */
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      setShortlyDisabled(false);
    }, 100);

    if (previousId !== undefined && previousId !== id) {
      setShortlyDisabled(true);
    }

    return () => {
      clearTimeout(timeOutId);
    };
  }, [previousId, id, value]);

  const isShortlyDisabled =
    shortlyDisabled || (previousId !== undefined && previousId !== id);

  /* 
      When changing the value of the editor an onchange is triggered, causing the the app to send an update
      call everytime you just open an exercise. This prevents updates the first second the slide is opened.
  */
  const [preventEarlyChange, setPreventEarlyChange] = useState(true);
  useEffect(() => {
    const timeOut = setTimeout(() => {
      setPreventEarlyChange(false);
    }, 1000);

    return () => {
      clearTimeout(timeOut);
    };
  }, [previousId, id, value]);

  return (
    <Overlay showOverlay={disabled} className="cursor-not-allowed">
      <EditorComponent
        id={id}
        className={c(
          className,
          disabled && 'pointer-events-none',
          withBorder &&
            'border border-gray-300 bg-white rounded overflow-hidden',
        )}
        value={isShortlyDisabled ? '' : value}
        onChange={
          isShortlyDisabled || disabled || preventEarlyChange
            ? undefined
            : onChange
        }
        contentStyles={contentStyles}
        disabled={disabled}
        language={language}
        containerClass={containerClass}
        isMainEditor={isMainEditor}
      >
        {Object.values(plugins).map((CustomPlugin, index) => (
          <CustomPlugin
            key={index}
            pluginName={CustomPlugin.name}
            label={labels[CustomPlugin.name]}
          />
        ))}
      </EditorComponent>
    </Overlay>
  );
};

Editor.defaultProps = {
  value: '',
  withBorder: true,
  plugins: [
    AudioPlugin,
    ExercisePlugin,
    ImagePlugin,
    LinkPlugin,
    VideoPlugin,
    ModalPlugin,
    IframePlugin,
  ],
};

export * from './customPlugins';

export default Editor;
