import { Badge } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';

const ContentCardStatus = ({ content }) => {
  const t = useFormatMessage();

  let label = 'content-state.CONCEPT';

  if (content.published) {
    label = 'content-state.PUBLISHED';
  }

  if (content.archived) {
    label = 'content-state.ARCHIVED';
  }

  return (
    <div className="flex">
      <Badge
        warning={!content.published && !content.archived}
        danger={content.archived}
        className="py-px text-xs px-2"
      >
        {t(label)}
      </Badge>

      {content.isDefault && (
        <Badge info className="py-px text-xs px-2 ml-2">
          Default
        </Badge>
      )}
    </div>
  );
};

export default ContentCardStatus;
