import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import ErrorMessage from '../../components/ErrorMessage';

import ContentActionProvider from '../../providers/ContentActionProvider';

import usePathStack from '../../hooks/usePathStack';

import { FIND_ALL_CONTENT_FILES_BY_PATH } from '../../api/files';

import { FILES } from '../../utils/constants/urls';

import ContentManager from './ContentManager';

const ContentManagerContainer = () => {
  // replace the first slash from the url
  const root = FILES.substring(1);
  const [loading, setLoading] = useState(false);

  const { relativePath: path } = usePathStack(root);

  const queryOptions = { variables: { path } };
  const { error, data, refetch } = useQuery(
    FIND_ALL_CONTENT_FILES_BY_PATH,
    queryOptions,
  );

  const files = data?.findAllContentFilesByPath;

  useEffect(() => {
    if (!data) setLoading(true);
  }, [data]);

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <ContentActionProvider
      root={root}
      files={files}
      fetchAll={refetch}
      maxSize={5242880}
    >
      <ContentManager
        root={root}
        files={(files && Object.values(files)) || []}
        loading={loading}
        setLoading={setLoading}
      />
    </ContentActionProvider>
  );
};

export default ContentManagerContainer;
