import { useCallback, useState } from 'react';
import { RiVideoFill } from 'react-icons/ri';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import { ContextMenu } from '@ftrprf/context-menu';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  Label,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

import { embedVideoFeaturePolicy } from '../../utils/constants';
import { FILE_EXTENSIONS } from '../../utils/constants/fileTypes';
import c from '../../utils/functions/c';

import ContentListSelector from '../ContentListSelector/ContentListSelector';
import FormikUrl from '../Form/FormikUrl';
import StyledIcon from '../StyledIcon';

import FileUpload from './FileUpload';

const Plugin = ({ editorAPI, editor }) => {
  const t = useFormatMessage();

  const [isExternalShown, setIsExternalShown] = useState(false);
  const [currentPath, setCurrentPath] = useState('/');

  const insertVideoHtml = useCallback(
    (url) => {
      editorAPI.insertHtml(
        c(
          `<table style="border: solid 3px transparent; margin-left: auto; margin-right:auto; width:100%">`,
          `<tr>`,
          `<td style="border: solid 10px transparent;`,
          isExternalShown
            ? `position:relative; padding-top:56.25%; overflow:hidden;">`
            : `">`,
          isExternalShown
            ? `<iframe style="left: 0; top: 0; right:0; bottom:0; position: absolute" height="100%" width="100%" src="${url}" frameborder="0">`
            : `<video src="${url}" alt="" width="100%" controls controlsList="nodownload"/>`,
          `</td></tr></table><br/>`,
        ),
      );
    },
    [editorAPI, isExternalShown],
  );

  const getVideoURL = (url) => {
    if (url.includes('youtube')) {
      return `https://www.youtube.com/embed/${url.substring(
        url.lastIndexOf('=') + 1,
        url.length,
      )}?autohide=1&controls=1&showinfo=0`;
    }

    if (url.includes('youtu')) {
      return `https://www.youtube.com/embed/${url.substring(
        url.lastIndexOf('/') + 1,
        url.length,
      )}?autohide=1&controls=1&showinfo=0`;
    }

    if (url.includes('dailymotion')) {
      return `https://www.dailymotion.com/embed/video/${url.substring(
        url.lastIndexOf('/') + 1,
        url.lastIndexOf('?playlist') || url.length,
      )}`;
    }

    if (url.includes('vimeo')) {
      return `https://player.vimeo.com/video/${url.substring(
        url.lastIndexOf('/') + 1,
        url.length,
      )}`;
    }

    if (url.includes(process.env.REACT_APP_CODEFEVER_BLOB_DOMAIN)) {
      return url;
    }

    return null;
  };

  return (
    <>
      {isExternalShown && (
        <Dialog>
          <Formik
            initialValues={{
              url: '',
            }}
            validateOnChange
            validateOnBlur={false}
            validationSchema={object({
              url: string().url('validation.url').required('validation.empty'),
            })}
            onSubmit={({ url }, { setErrors }) => {
              url = getVideoURL(url);

              if (!url) {
                setErrors({ url: 'validation.external_video_url' });
                return;
              }

              insertVideoHtml(url);
              setIsExternalShown(false);
            }}
          >
            {({ isSubmitting, isValid, setFieldValue, values, errors }) => (
              <Form>
                <DialogHeader>{t('editor.videoplugin.label')}</DialogHeader>
                <DialogContent>
                  <FormikUrl name="url" label={t('global.URL')} />
                  <Label>{t('editor.videoplugin.label-upload')}</Label>
                  {!errors.url && values.url ? (
                    <div className="w-full h-56">
                      <iframe
                        width="300"
                        height="200"
                        src={getVideoURL(values.url)}
                        allow={embedVideoFeaturePolicy}
                      />
                    </div>
                  ) : (
                    <FileUpload
                      name="file"
                      path={currentPath}
                      setFileUri={(uri) => {
                        setFieldValue('url', uri);
                      }}
                      accept="video"
                      maxSize={5242880}
                    />
                  )}
                </DialogContent>
                <DialogActions>
                  <OutlineButton
                    onClick={() => {
                      setIsExternalShown(false);
                      setFieldValue('url', null);
                    }}
                  >
                    {t('global.cancel')}
                  </OutlineButton>
                  <FilledButton
                    type="submit"
                    disabled={isSubmitting || !isValid}
                  >
                    {t('editor.videoplugin.label')}
                  </FilledButton>
                </DialogActions>
              </Form>
            )}
          </Formik>
        </Dialog>
      )}
      <ContextMenu
        editor={editor}
        trigger={({ _, toggle }) => (
          <StyledIcon
            icon={RiVideoFill}
            className="text-base"
            onClick={toggle}
          />
        )}
      >
        {({ close }) => (
          <div className="bg-white h-64 w-64 border border-gray">
            <ContentListSelector
              onFileClick={(url) => {
                insertVideoHtml(url);
                close();
              }}
              allowedTypes={FILE_EXTENSIONS.VIDEO.extensions}
              onExternalClick={() => {
                setIsExternalShown(true);
                close();
              }}
              setCurrentPath={setCurrentPath}
            />
          </div>
        )}
      </ContextMenu>
    </>
  );
};

export const VideoPlugin = ({ ...props }) => {
  const t = useFormatMessage();

  return <Plugin label={t('editor.videoplugin.label')} {...props} />;
};
