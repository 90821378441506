import { memo, useEffect, useState } from 'react';
import { FaLink } from 'react-icons/fa';

import {
  ConfirmationDialog,
  Input,
  InputGroup,
  Label,
  Loader,
  QUESTION_TYPES,
} from '@ftrprf/tailwind-components';
import { FilledButton } from '@ftrprf/tailwind-components';

import Dropdown from '../../../../components/Dropdown';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useSlide from '../../hooks/useSlide';

import * as SLIDE_TYPES from '../../../../utils/constants/slideTypes';

const GeneralSideBar = ({ disabled, slideId }) => {
  const t = useFormatMessage();

  const [changeSlideTypeConfirmationOpen, setChangeSlideTypeConfirmationOpen] =
    useState(false);
  const [selectedType, setSelectedType] = useState();
  const [copySlideClicked, setCopySlideClicked] = useState(false);
  const [creatingQuestion, setCreatingQuestion] = useState(false);

  const {
    slide,
    update,
    createOpenQuestion,
    createMultipleChoiceQuestion,
    removeQuestion,
  } = useSlide(slideId);

  // make sure "copy slide link" button is available when slide changes
  useEffect(() => {
    setCopySlideClicked(false);
  }, [slideId]);

  if (!slide) {
    return null;
  }

  const hasQuestions = slide?.questions?.length > 0;

  const question = hasQuestions && slide.questions[0];

  const createQuestion = (type) => {
    let promise;
    if (type === QUESTION_TYPES.OPEN) {
      promise = createOpenQuestion();
    } else if (type === QUESTION_TYPES.MULTIPLE_CHOICE) {
      promise = createMultipleChoiceQuestion();
    }

    return promise;
  };

  const changeSlideType = (type) => {
    setCreatingQuestion(true);
    if (type === SLIDE_TYPES.TEXT && hasQuestions) {
      removeQuestion(question.id).then(() => {
        setCreatingQuestion(false);
      });
    }

    if (type !== SLIDE_TYPES.TEXT && !hasQuestions) {
      createQuestion(type).then(() => {
        setCreatingQuestion(false);
      });
    }

    if (type !== SLIDE_TYPES.TEXT && hasQuestions && question.type !== type) {
      removeQuestion(question.id).then(() => {
        createQuestion(type).then(() => {
          setCreatingQuestion(false);
        });
      });
    }
  };

  const copyLinkSlide = () => {
    navigator.clipboard.writeText(`slide://${slideId}`);
    setCopySlideClicked(true);
    setTimeout(() => {
      setCopySlideClicked(false);
    }, 1500);
  };
  return (
    <>
      <ConfirmationDialog
        isOpen={changeSlideTypeConfirmationOpen}
        content={t('content-editor.change_question.confirm')}
        onDismiss={() => {
          setChangeSlideTypeConfirmationOpen(false);
        }}
        onConfirm={() => {
          setChangeSlideTypeConfirmationOpen(false);
          changeSlideType(selectedType);
        }}
      />
      <div className="">
        <InputGroup>
          <Label>{t('slide-sidebar.slide_title')}</Label>
          <Input
            placeholder={t('content-editor.title.placeholder')}
            value={slide.title || ''}
            disabled={disabled}
            onChange={(e) =>
              update({
                title: e.target.value,
              })
            }
          />
        </InputGroup>

        <InputGroup>
          <Label>
            {t('slide-sidebar.slide_type')}
            {creatingQuestion && (
              <span className="w-3 h-3 inline-block">
                <Loader />
              </span>
            )}
          </Label>
          <Dropdown
            disabled={disabled || question.publishedAt || creatingQuestion}
            placeholder={t('content-editor.title.placeholder')}
            options={Object.values(SLIDE_TYPES).map((type) => ({
              key: type,
              value: type,
              label: t(`slide_type.${type}`),
            }))}
            value={hasQuestions ? SLIDE_TYPES[question.type] : SLIDE_TYPES.TEXT}
            onChange={(newType) => {
              if (
                question?.type === SLIDE_TYPES.MULTIPLE_CHOICE &&
                newType !== SLIDE_TYPES.MULTIPLE_CHOICE
              ) {
                setSelectedType(newType);
                setChangeSlideTypeConfirmationOpen(true);
                return;
              }
              changeSlideType(newType);
            }}
          />
        </InputGroup>

        <div className="flex justify-center mt-4">
          <FilledButton
            iconBefore={FaLink}
            onClick={copyLinkSlide}
            className="w-max"
            disabled={copySlideClicked}
          >
            {copySlideClicked
              ? t('slide-sidebar.link_copied')
              : t('slide-sidebar.copy_slide_link')}
          </FilledButton>
        </div>
      </div>
    </>
  );
};

export default memo(GeneralSideBar);
