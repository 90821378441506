import { Fragment, memo, useCallback } from 'react';
import { FaHome } from 'react-icons/fa';

const DIVIDER = '/';

const SideBarBreadCrumbs = ({ queryPath, setQueryPath, rootDisplayName }) => {
  const path = queryPath.split('/');
  const last = path[path.length - 2];
  const breadCrumbItems = path.slice(1, -2);

  const handleBreadCrumbItemClick = useCallback(
    (index) => {
      const newQueryPath = breadCrumbItems.slice(0, index + 1).join('/');
      setQueryPath(`/${newQueryPath}/`);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [breadCrumbItems],
  );

  return (
    <div className="flex flex-column flex-wrap break-all items-center space-x-1 text-gray-500">
      <div className="cursor-pointer select-none">
        <FaHome onClick={() => setQueryPath('/')} />
      </div>
      <div className="text-lg align-middle select-none">{DIVIDER}</div>
      {breadCrumbItems.map((item, i) => (
        <Fragment key={i}>
          <div
            onClick={() => {
              handleBreadCrumbItemClick(i);
            }}
            className="cursor-pointer select-none hover:underline"
          >
            {item}
          </div>

          <div className="text-lg align-middle select-none">{DIVIDER}</div>
        </Fragment>
      ))}
      <div className="LastBreadCrumb select-none pr-2 relative text-gray-600">
        {!!last ? last : rootDisplayName}
      </div>
    </div>
  );
};

export default memo(SideBarBreadCrumbs);
