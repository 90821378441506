import { useContext } from 'react';
import { useMutation } from '@apollo/client';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { DUPLICATE_EXERCISE } from '../../api/exercise';

import useFormatMessage from '../useFormatMessage';

const useDuplicateExercise = () => {
  const t = useFormatMessage();
  const { addNotification } = useContext(NotificationContext);
  const [createMutation, { loading, error }] = useMutation(DUPLICATE_EXERCISE);

  return {
    loading,
    error,
    duplicateExercise: ({ exerciseId, onSuccess }) =>
      createMutation({
        variables: { id: exerciseId },
      })
        .then((data) => {
          onSuccess(data);
          addNotification({
            type: 'success',
            content: t('exercise-overview.duplicate-success'),
          });
        })
        .catch(() =>
          addNotification({
            type: 'error',
            content: t('exercise-overview.duplicate-error'),
          }),
        ),
  };
};

export default useDuplicateExercise;
