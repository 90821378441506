import c from '../utils/c';

export const PageHeader = ({
  children,
  marginBottom,
  marginTop,
  borderBottom,
}) => {
  return (
    <div
      className={c(
        'bg-gray-50 w-full',
        borderBottom && 'border-b border-gray-300',
      )}
    >
      <div className={c('w-full flex flex-col', marginTop && 'mt-8')}>
        <div
          className={c(
            marginBottom && 'mb-4',
            'rounded w-full max-w-5/6 self-center',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

PageHeader.defaultProps = {
  marginBottom: true,
  marginTop: true,
  borderBottom: true,
};
