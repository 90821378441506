import c from '../utils/c';

const Icon = ({ className, hasMessages, text }) => {
  return (
    <svg
      className={c(
        className,
        hasMessages ? 'text-accent-500' : 'text-gray-300 fill-current',
        'stroke-current h-full w-full',
      )}
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
    >
      <path
        d="M2.71263 13.7257C2.75281 13.5591 2.70485 13.3835 2.58555 13.2604C1.27324 11.9069 0.5 10.2036 0.5 8.35714C0.5 4.09707 4.66489 0.5 10 0.5C15.3351 0.5 19.5 4.09707 19.5 8.35714C19.5 12.6172 15.3351 16.2143 10 16.2143C8.56364 16.2143 7.20673 15.9411 5.9851 15.4685C5.81709 15.4035 5.6271 15.4339 5.48776 15.5481C4.61957 16.2596 2.93944 17.367 0.714773 17.489C0.891103 17.2716 1.13582 16.9528 1.39633 16.5625C1.88297 15.8334 2.44881 14.8198 2.71263 13.7257ZM0.452033 17.798C0.453364 17.7966 0.454831 17.795 0.45643 17.7932C0.455018 17.7949 0.453568 17.7965 0.452083 17.7981L0.452033 17.798Z"
        strokeLinejoin="round"
      />
      <text
        className={c(
          'text-xxs stroke-0 fill-current font-medium',
          hasMessages ? 'text-accent' : 'text-gray-600 font-mono',
        )}
        x="50%"
        y="46%"
        dominantBaseline="middle"
        textAnchor="middle"
      >
        {text}
      </text>
    </svg>
  );
};

export const ChatBubble = ({ className, amount: initialAmount, onClick }) => {
  const hasMessages = initialAmount && initialAmount > 0;

  const amount = initialAmount > 99 ? 99 : initialAmount;

  return (
    <button role="button" className={className} onClick={onClick}>
      <Icon
        className={c(className)}
        hasMessages={hasMessages}
        text={hasMessages ? amount : '+'}
      />
    </button>
  );
};

ChatBubble.defaultProps = {
  amount: 0,
  onClick: () => {},
};
