import { isValidElement } from 'react';

import { Loader } from '@ftrprf/tailwind-components';

import useFormatMessage from '../hooks/useFormatMessage';

import c from '../utils/functions/c';

export const ListItem = ({ className, onClick, onDoubleClick, children }) => {
  return (
    <div
      className={c(
        'w-full flex flex-row p-2 cursor-pointer select-none',
        className,
      )}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      {children}
    </div>
  );
};

const ContentListCenter = ({ contentListCenter, isLoading, isEmpty }) => {
  const t = useFormatMessage();

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center content-center items-center">
        <div className="h-5 w-5">
          <Loader />
        </div>
      </div>
    );
  }

  if (isEmpty) {
    return (
      <div className="w-full h-full flex flex-col justify-center content-center text-center text-sm">
        <span>{t('content-selector.empty_folder')}</span>
      </div>
    );
  }

  return contentListCenter;
};

const ContentList = ({
  contentListTop,
  contentListCenter,
  isLoading,
  isEmpty,
}) => {
  const hasTop = isValidElement(contentListTop);

  return (
    <div className="flex flex-col w-full h-full overflow-hidden">
      {hasTop && (
        <div className="bg-gray-200 w-full shadow-sm p-2 flex items-center justify-between flex-grow-0 flex-shrink-0 flex-basis-0">
          {contentListTop}
        </div>
      )}

      <ContentListCenter
        isLoading={isLoading}
        isEmpty={isEmpty}
        contentListCenter={contentListCenter}
      />
    </div>
  );
};

ContentList.defaultProps = {
  contentListTop: () => null,
  contentListcenter: () => null,
};

export default ContentList;
