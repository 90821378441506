import { useEffect } from 'react';
import zxcvbn from 'zxcvbn';

import useFormatMessage from '../../hooks/useFormatMessage';

import c from '../../utils/c';

import en from './translations/en';
import nl from './translations/nl';

import ProgressBase from './ProgressBase';

const backgroundColors = [
  'bg-red-700',
  'bg-red-700',
  'bg-red-500',
  'bg-yellow-500',
  'bg-green-400',
];

const textColors = [
  'text-red-700',
  'text-red-700',
  'text-red-600',
  'text-yellow-600',
  'text-green-600',
];

const feedbackText = ['very_weak', 'weak', 'almost_there', 'good', 'excellent'];

export const PasswordStrengthBar = ({ password, onScoreChange }) => {
  const t = useFormatMessage();

  const score = zxcvbn(password).score;

  useEffect(() => onScoreChange(score), [score, onScoreChange]);

  const percentage = (score / 4) * 100;

  return (
    <div className="w-full flex flex-col items-end">
      <div className={c('text-xs', textColors[score])}>
        {t(`password-strength.${feedbackText[score]}`)}
      </div>
      <ProgressBase
        percentage={percentage}
        className={backgroundColors[score]}
      />
    </div>
  );
};

export const PasswordStrengthBarTranslations = {
  en,
  nl,
};
