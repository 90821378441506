import '@ftrprf/tailwind-components/slideviewer-styles.css';

import { useCallback, useMemo, useState } from 'react';
import { generatePath } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import {
  generateSubmittedQuestionAnswersState,
  generateTemporaryQuestionAnswersState,
  SlideViewerContextProvider,
  StyledSlideViewer,
  VIEW_MODES,
} from '@ftrprf/tailwind-components';

import useTitle from '../../hooks/useTitle';

import { FIND_LESSON_CONTENT } from '../../api/content';

import { CONTENT, EXERCISE } from '../../utils/constants/urls';
import * as urls from '../../utils/constants/urls';

const generateScratchExercisePath = ({ versionId }) => {
  if (!versionId) {
    return '';
  }
  return generatePath(`${EXERCISE}/version/:versionId`, { versionId });
};

const SlideViewerContainer = ({ history, match: { params } }) => {
  const lessonContentId = params.lessonContentId;

  const [temporaryQuestionAnswers, setTemporaryQuestionAnswers] = useState({});
  const [submittedQuestionAnswers, setSubmittedQuestionAnswers] = useState([]);

  const [viewMode, setViewMode] = useState(
    VIEW_MODES[params.viewMode?.toUpperCase() || VIEW_MODES.CLASSICAL],
  );

  const queryOptions = {
    variables: {
      id: lessonContentId,
    },
  };

  const { loading, error, data } = useQuery(FIND_LESSON_CONTENT, queryOptions);
  const lesson = data?.findLessonContent;

  useTitle(lesson?.title);

  const onSlideChange = useCallback(
    (slide) => {
      if (!lesson) {
        return;
      }

      history.replace(`${CONTENT}/${lesson.id}/${viewMode}/${slide.id}`);
    },
    [history, viewMode, lesson],
  );

  const onClose = () => {
    history.push(`${urls[lesson.type]}/${lesson.id}/edit`);
  };

  const filteredSlides = useMemo(() => {
    return (
      lesson?.slides.filter((slide) => slide.viewModes.includes(viewMode)) || []
    );
  }, [lesson, viewMode]);

  const onTemporaryQuestionAnswers = useCallback((question, value) => {
    return setTemporaryQuestionAnswers(
      generateTemporaryQuestionAnswersState(question, value),
    );
  }, []);

  // This generates the output that normally the API will generate
  const onSubmitQuestionAnswers = useCallback((question, value) => {
    return new Promise((resolve) => {
      const submittedQuestionAnswer = generateSubmittedQuestionAnswersState(
        question,
        value,
      );

      setSubmittedQuestionAnswers((prev) => {
        const filteredSubmittedAnswers = prev.filter(
          ({ questionId }) => questionId !== question.id,
        );
        return [...filteredSubmittedAnswers, submittedQuestionAnswer];
      });

      // clear the temporary value for the current question
      setTemporaryQuestionAnswers(
        ({ [question.id]: _, ...otherQuestions }) => ({
          ...otherQuestions,
        }),
      );

      resolve({
        question,
        submittedQuestionAnswer,
      });
    });
  }, []);

  if (loading || error) {
    return null;
  }

  const defaultSlide = filteredSlides.find(
    (slide) => slide.id === params.slideId,
  );

  const defaultSlideId = defaultSlide ? defaultSlide.id : filteredSlides[0]?.id;

  return (
    <SlideViewerContextProvider
      showConfetti={true}
      canEdit={true}
      canOverrideAnswers={false}
      lesson={lesson}
      slides={filteredSlides}
      defaultViewMode={viewMode}
      defaultSlideId={defaultSlideId}
      onSlideChange={onSlideChange}
      onViewModeChange={(viewMode, { currentSlide }) => {
        history.push(`${CONTENT}/${lesson.id}/${viewMode}/${currentSlide.id}`);
        setViewMode(viewMode);
      }}
      temporaryQuestionAnswers={temporaryQuestionAnswers}
      submittedQuestionAnswers={submittedQuestionAnswers}
      onTemporaryQuestionAnswers={onTemporaryQuestionAnswers}
      onSubmitQuestionAnswers={onSubmitQuestionAnswers}
      generateScratchExercisePath={generateScratchExercisePath}
    >
      <StyledSlideViewer
        onClose={onClose}
        showViewModes={true}
        showTeacherInfoTab={true}
        showSettingsTab={true}
        showSlidesOverviewTab={true}
        areAnswersSaved={false}
      />
    </SlideViewerContextProvider>
  );
};

export default SlideViewerContainer;
