import { createContext, useMemo } from 'react';
import { useRecoilState } from 'recoil';

import { sideBarAtom } from '../../utils/atom';

import HintsSideBar from './HintsSideBar/HintsSideBar';

import ModalSideBar from './ModalSideBar';
import MultipleChoiceQuestionSideBar from './MultipleChoiceQuestionSideBar';
import OpenQuestionSideBar from './OpenQuestionSideBar';
import QuestionAnswerMultipleChoiceSideBar from './QuestionAnswerMultipleChoiceSideBar';
import SlideSideBar from './SlideSideBar';
import TeacherInfoSideBar from './TeacherInfoSideBar';

export const SideBarContext = createContext({});

export const sideBars = {
  DEFAULT: SlideSideBar,
  HINTS_SIDEBAR: HintsSideBar,
  OPEN_QUESTION_SIDEBAR: OpenQuestionSideBar,
  MULTIPLE_CHOICE_QUESTION_SIDEBAR: MultipleChoiceQuestionSideBar,
  QUESTION_ANSWER_MULTIPLE_CHOICE_SIDEBAR: QuestionAnswerMultipleChoiceSideBar,
  TEACHER_INFO_SIDEBAR: TeacherInfoSideBar,
  MODAL_SIDEBAR: ModalSideBar,
  EMPTY: () => <div />,
};

export const SIDE_BARS = Object.fromEntries(
  Object.keys(sideBars).map((s) => [s, s]),
);

const SideBarContextProvider = ({ children }) => {
  const [currentSideBar, setSideBar] = useRecoilState(sideBarAtom);

  const context = useMemo(
    () => ({ currentSideBar, setSideBar }),
    [currentSideBar, setSideBar],
  );

  return (
    <SideBarContext.Provider value={context}>
      {children}
    </SideBarContext.Provider>
  );
};

export default SideBarContextProvider;
