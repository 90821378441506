import { useState } from 'react';
import {
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClockIcon,
  DocumentIcon,
  ExclamationCircleIcon,
  FolderIcon,
  FolderOpenIcon,
  XCircleIcon,
} from '@heroicons/react/outline';

import c from '../utils/c';

import { Actions } from './Actions/Actions';

export const MultiCollapsableState = {
  success: 'SUCCESS',
  partial: 'PARTIAL',
  failed: 'FAILED',
  loading: 'LOADING',
};

export const MultiCollapsable = ({
  children,
  state,
  image,
  mainText,
  boldText,
  subText,
  isOpen,
  hasDropDown,
  amount,
  child = 0,
  actions,
}) => {
  const [openState, setOpenState] = useState(isOpen);
  const indentation = `pl-${child * 4}`;
  const frontIcon = () => {
    if (image) {
      return <img className="rounded w-6 h-6" src={image} />;
    }

    if (state === MultiCollapsableState.success) {
      return <CheckCircleIcon className="text-green-500 w-6 h-6" />;
    }

    if (state === MultiCollapsableState.partial) {
      return <ExclamationCircleIcon className="text-yellow-500 w-6 h-6" />;
    }

    if (state === MultiCollapsableState.failed) {
      return <XCircleIcon className="text-red-500 w-6 h-6" />;
    }

    if (state === MultiCollapsableState.loading) {
      return <ClockIcon className="text-gray-600 w-6 h-6" />;
    }

    if (hasDropDown && openState) {
      return <FolderOpenIcon className="text-accent-500 w-6 h-6" />;
    }

    if (hasDropDown && !openState) {
      return <FolderIcon className="text-accent-500 w-6 h-6" />;
    }

    return <DocumentIcon className="text-accent-500 w-6 h-6" />;
  };

  return (
    <div className={c('flex flex-col w-full', indentation)}>
      <div
        className="bg-gray-50 flex align-center w-full mx-auto mb-2 self-center p-2 rounded border border-gray-300"
        onClick={() => {
          setOpenState(!openState);
        }}
      >
        <div className="flex align-center w-full">
          <div className="w-6 m-2 flex-shrink-0">{frontIcon()}</div>

          <div className="my-auto pl-2">
            <p className="text-md align-center">
              {mainText} <span className="font-bold">{boldText}</span>
            </p>
            {!!subText && <p className="text-xs text-gray-500">{subText}</p>}
          </div>
        </div>
        {(hasDropDown || actions?.length > 0 || amount) && (
          <div className="my-auto align-center justify-end mr-2 flex w-2/3">
            <p className="text-sm mr-2 whitespace-nowrap">{amount}</p>
            {actions?.length > 0 && <Actions items={actions} />}
            {hasDropDown &&
              (openState ? (
                <ChevronUpIcon className="w-3 h-3 ml-4 self-center" />
              ) : (
                <ChevronDownIcon className="w-3 h-3 ml-4 self-center" />
              ))}
          </div>
        )}
      </div>
      {openState && children}
    </div>
  );
};
