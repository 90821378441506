import c from '../../utils/functions/c';
import gridTemplateString from '../../utils/functions/gridTemplateString';

// widths: array of column width proportions, e.g. [1, 3, 1, 2]
const TableRow = ({ widths, children, className, onClick, ...props }) => {
  return (
    <div
      {...props}
      className={c(
        className,
        'grid min-w-full py-2',
        !widths && 'grid-flow-col TableRow-same-width',
        onClick && 'cursor-pointer hover:bg-gray-100',
      )}
      style={widths && { gridTemplateColumns: gridTemplateString(widths) }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default TableRow;
