import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import { Content, Input, PageTitle } from '@ftrprf/tailwind-components';

import BreadCrumbs from '../../../components/BreadCrumbs';
import Center from '../../../components/Center';
import ErrorMessage from '../../../components/ErrorMessage';
import OverviewEmpty from '../../../components/OverviewEmpty';
import Pagination from '../../../components/Pagination';
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
  TableSkeleton,
} from '../../../components/Table/index';

import useDebounce from '../../../hooks/useDebounce';
import useFormatMessage from '../../../hooks/useFormatMessage';
import useTitle from '../../../hooks/useTitle';

import { FETCH_ALL_ORGANIZATIONS_BY_DOMAIN } from '../../../api/admin';

const Organizations = ({ domain }) => {
  const t = useFormatMessage();
  useTitle(t('header.admin'));

  const widths = [1]; // [2, 1];
  const history = useHistory();

  const [undebouncedDomainVariables, setDomainVariables] = useState({
    filter: { name: '' },
    currentPage: 1,
  });
  const domainVariables = useDebounce(undebouncedDomainVariables, 200);
  const domainQueryOptions = {
    variables: {
      domain,
      filter: [
        {
          key: 'name',
          value: domainVariables?.filter?.name?.trim(),
          operation: '~',
        },
      ].filter(({ value }) => !!value),
      page: undebouncedDomainVariables?.currentPage - 1,
      size: 8,
    },
  };
  const { loading, error, data } = useQuery(
    FETCH_ALL_ORGANIZATIONS_BY_DOMAIN,
    domainQueryOptions,
  );
  const organizations = data?.fetchAllOrganizationsByDomain.content;
  const totalPages = data?.fetchAllOrganizationsByDomain.pages;

  const onChangeDomainFilter = (variables) => {
    setDomainVariables({
      ...domainVariables,
      filter: { ...domainVariables.filter, ...variables },
      currentPage: 1,
    });
  };

  const onChangeDomainOptions = (variables) => {
    setDomainVariables({
      ...domainVariables,
      currentPage: variables.currentPage || domainVariables.currentPage,
      filter: variables.filter,
    });
  };

  return (
    <Content>
      <div className="flex flex-grow flex-col">
        <Center>
          <PageTitle>{t('admin.organizations')}</PageTitle>
        </Center>
        <div className="flex items-end justify-between w-full px-2">
          <div className="-mb-3">
            <BreadCrumbs root="admin" />
          </div>
          <div className="w-1/4">
            <Input
              className="text-sm"
              name="filter"
              placeholder={t('admin.organizations.filter')}
              type="text"
              onChange={(e) => onChangeDomainFilter({ name: e.target.value })}
            />
          </div>
        </div>
        <Table widths={widths}>
          <TableRow>
            <TableHeaderCell>{t('admin.name')}</TableHeaderCell>
          </TableRow>
          {loading ? (
            <TableSkeleton widths={widths} />
          ) : error ? (
            <ErrorMessage className="mt-4 text-center" error={error} />
          ) : organizations.length === 0 ? (
            <OverviewEmpty table />
          ) : (
            organizations.map(({ id, name }) => (
              <Link to={`${history.location.pathname}/${id}`} key={id}>
                <TableRow widths={widths} onClick>
                  <TableCell>{name}</TableCell>
                </TableRow>
              </Link>
            ))
          )}
        </Table>
        {totalPages > 1 && (
          <Center>
            <Pagination
              currentPage={undebouncedDomainVariables?.currentPage}
              totalPages={totalPages}
              onClick={(val) =>
                onChangeDomainOptions({
                  ...undebouncedDomainVariables,
                  currentPage: val,
                })
              }
            />
          </Center>
        )}
      </div>
    </Content>
  );
};

export default Organizations;
