import { FaChevronLeft, FaDesktop, FaHome, FaUsers } from 'react-icons/fa';
import Tooltip from '@reach/tooltip';

import useFormatMessage from '../../../hooks/useFormatMessage';

import c from '../../../utils/c';

import {
  SlideViewerRightAction,
  SlideViewerViewModes,
} from '../../slideviewer/SlideViewer';

import ChevronRight from './ChevronRight';
import { SlideViewerCloseButton } from './CloseButon';
import HintsButton from './HintsButton';

const SlideViewerRight = ({ showViewModes, hints, onClose }) => {
  const t = useFormatMessage();

  return (
    <div className="flex-shrink-0 flex flex-col items-start justify-between w-1/12 h-full absolute right-1 z-10">
      <div className="h-1/2 flex self-end mr-6 mt-2 z-10">
        {showViewModes && (
          <SlideViewerViewModes
            className="flex flex-row items-start"
            viewMoreChildren={
              <div
                className={c(
                  'flex items-center justify-center rounded-full text-gray shadow ml-2 h-8 w-8',
                )}
              >
                <FaChevronLeft />
              </div>
            }
            projectionChildren={({ disabled }) => (
              <Tooltip label={t('styled-slide-viewer.view-modes.projection')}>
                <div
                  className={c(
                    'flex items-center justify-center rounded-full text-white ml-2 h-8 w-8',
                    disabled
                      ? 'bg-accent-200 cursor-not-allowed'
                      : 'bg-accent-400',
                  )}
                >
                  <FaDesktop />
                </div>
              </Tooltip>
            )}
            selfstudyChildren={({ disabled }) => (
              <Tooltip label={t('styled-slide-viewer.view-modes.selfstudy')}>
                <div
                  className={c(
                    'flex items-center justify-center rounded-full text-white ml-2 h-8 w-8',
                    disabled
                      ? 'bg-accent-200 cursor-not-allowed'
                      : 'bg-accent-400',
                  )}
                >
                  <FaHome />
                </div>
              </Tooltip>
            )}
            classicalChildren={({ disabled }) => (
              <Tooltip label={t('styled-slide-viewer.view-modes.classical')}>
                <div
                  className={c(
                    'flex items-center justify-center rounded-full text-white ml-2 h-8 w-8',
                    disabled
                      ? 'bg-accent-200 cursor-not-allowed'
                      : 'bg-accent-400',
                  )}
                >
                  <FaUsers />
                </div>
              </Tooltip>
            )}
          />
        )}
        <SlideViewerCloseButton onClose={onClose} />
      </div>
      <SlideViewerRightAction>
        {({ disabled }) => (
          <button
            type="button"
            aria-label={t('styled-slide-viewer.next')}
            className={c(
              'transform transition duration-400 z-20',
              !disabled && 'text-gray-400 hover:text-black hover:scale-150',
              disabled && 'text-gray-100 cursor-not-allowed ',
            )}
            disabled={disabled}
          >
            <ChevronRight />
          </button>
        )}
      </SlideViewerRightAction>

      <HintsButton hints={hints} />

      <div className="h-1/2" />
    </div>
  );
};

SlideViewerRight.defaultProps = {
  showViewModes: false,
  isSidebarOpen: false,
  onClose: () => {},
};

export default SlideViewerRight;
