import './theme/tailwind.css';

import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import ApolloProvider from './providers/ApolloProvider';
import CodeFeverProvider from './providers/CodeFeverProvider';
import LanguageProvider from './providers/LanguageProvider';
import TailwindProvider from './providers/TailwindProvider';
import UserProvider from './providers/UserProvider';

import initSentry from './utils/initSentry';

import App from './app/App';

initSentry();

if (process.env.REACT_APP_MOCK && process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/setup-server-browser');
  worker.start();
}

if (process.env.NODE_ENV === 'production') {
  console.log(`
  =============================================================================================
  __________________________ ________ ________ __________
  ___  ____/___  __/___  __ \\___  __ \\___  __ \\___  ____/
  __  /_    __  /   __  /_/ /__  /_/ /__  /_/ /__  /_    
  _  __/    _  /    _  _, _/ _  ____/ _  _, _/ _  __/    
  /_/       /_/     /_/ |_|  /_/      /_/ |_|  /_/    v${process.env.REACT_APP_VERSION}
  =============================================================================================  
  `);

  console.log(
    'Wanted: Passionate developers. React / Java Spring Boot. Send a mail to katelijne@ftrprf.be with your CV and motivation!',
  );

  window.console = {
    log: () => {},
    info: () => {},
    warn: () => {},
    error: () => {},
  };
}

ReactDOM.render(
  <LanguageProvider>
    <TailwindProvider>
      <Router>
        <UserProvider>
          <ApolloProvider>
            <CodeFeverProvider>
              <App />
            </CodeFeverProvider>
          </ApolloProvider>
        </UserProvider>
      </Router>
    </TailwindProvider>
  </LanguageProvider>,
  document.getElementById('root'),
);
