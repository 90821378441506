import { useContext } from 'react';
import { FaPaperPlane } from 'react-icons/fa';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import { FilledButton } from '../../../buttons/FilledButton';
import { Input } from '../../../form/Input';
import { SlideViewerContext } from '../../../slideviewer/SlideViewerContextProvider';

const SlideViewerOpenQuestion = ({ question, canSubmit }) => {
  const t = useFormatMessage();

  const {
    temporaryQuestionAnswers,
    submittedQuestionAnswers,
    onTemporaryQuestionAnswers,
    onSubmitQuestionAnswers,
    canOverrideAnswers,
  } = useContext(SlideViewerContext);

  // We make use of template literals to make sure the types are the same
  const submittedQuestionAnswer = submittedQuestionAnswers.find(
    (submittedQuestion) =>
      `${submittedQuestion.questionId}` === `${question.id}`,
  );

  const submittedQuestionAnswerValue =
    submittedQuestionAnswer?.answers[0].value;

  const temporaryQuestionAnswerValue = temporaryQuestionAnswers[question.id];

  // the undefined check is needed because the new answer can be an empty string.
  const value =
    temporaryQuestionAnswerValue === undefined
      ? submittedQuestionAnswerValue
      : temporaryQuestionAnswerValue;

  // the button is disabled if we can't submit
  // or when there is no value
  // or when there is no temporary question answer value
  // or when there is a temporary question answer value but it's the same as the submitted question answer value
  const disabled =
    !canSubmit ||
    !value ||
    !temporaryQuestionAnswerValue ||
    (!!temporaryQuestionAnswerValue &&
      submittedQuestionAnswerValue === temporaryQuestionAnswerValue);

  return (
    <div>
      <div className="mt-4">
        <Input
          type="multiline"
          rows={4}
          placeholder={
            question.placeholder ?? t('styled-slide-viewer.answer_placeholder')
          }
          disabled={!canSubmit}
          onChange={(e) => onTemporaryQuestionAnswers(question, e.target.value)}
          onKeyUp={(e) => e.stopPropagation()}
          value={value || ''}
        />
      </div>
      <div className="mt-8 w-full flex justify-center">
        <FilledButton
          iconBefore={FaPaperPlane}
          disabled={disabled}
          onClick={() => onSubmitQuestionAnswers(question, value)}
        >
          {canOverrideAnswers
            ? canSubmit
              ? t('styled-slide-viewer.save')
              : t('styled-slide-viewer.saved')
            : canSubmit
            ? t('styled-slide-viewer.submit')
            : t('styled-slide-viewer.submitted')}
        </FilledButton>
      </div>
    </div>
  );
};

export default SlideViewerOpenQuestion;
