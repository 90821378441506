import { OutlineButton } from '@ftrprf/tailwind-components';

import useFormatMessage from '../hooks/useFormatMessage';

import { ReactComponent as ClearIcon } from '../assets/vectors/clear-filter.svg';

const Filterbar = ({ className, onClear, children }) => {
  const t = useFormatMessage();

  return (
    <div className={className}>
      {children}
      <OutlineButton secondary title={t('filterbar.clear')} onClick={onClear}>
        <ClearIcon className="h-4" />
      </OutlineButton>
    </div>
  );
};

export default Filterbar;
