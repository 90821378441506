import React from 'react';
import ReactDOM from 'react-dom';

import c from './utils/c';

const CustomButtons = ({ buttonLocation, children, editorAPI }) => {
  if (buttonLocation) {
    return ReactDOM.createPortal(
      React.Children.map(children, (child) => {
        if (!React.isValidElement(child)) {
          return null;
        }

        const { label, pluginName } = child.props;

        return (
          <a
            className={c(
              pluginName !== 'LinkPlugin' &&
                `cke_button cke_button__bgcolor cke_button_expandable cke_button_off ${
                  'cke_button__' + pluginName.toLowerCase()
                }`,
            )}
            title={label}
            tabIndex="-1"
            hidefocus="true"
            role="button"
          >
            {React.cloneElement(child, {
              className: 'cke_button_icon',
              editorAPI: editorAPI,
            })}
          </a>
        );
      }),
      buttonLocation,
    );
  }
  return null;
};

export default CustomButtons;
