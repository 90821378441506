import { useContext, useState } from 'react';
import { FaCut, FaEdit, FaFolder, FaLink, FaTrash } from 'react-icons/fa';
import copy from 'copy-to-clipboard';

import { ListContextMenu } from '@ftrprf/context-menu';
import { ConfirmationDialog, MenuButton } from '@ftrprf/tailwind-components';

import SingleInputDialog from '../../../components/Dialog/SingleInputDialog';
import Divider from '../../../components/Divider';

import { ContentActionContext } from '../../../providers/ContentActionProvider';

import useFormatMessage from '../../../hooks/useFormatMessage';

import { baseName } from '../../../utils/functions/path';

import ContentActionDialog from './ContentActionDialog';

const ContentItemContextMenu = ({
  children,
  file: { id, path, isFile, blobUri },
}) => {
  const t = useFormatMessage();

  const {
    selectedItems,
    renameFile,
    cutFiles,
    deleteFiles,
    moveFiles,
    clipBoardCopy,
    clipBoardCut,
    notInPathRegex,
  } = useContext(ContentActionContext);

  const [showRemoveFilesDialog, setShowRemoveFilesDialog] = useState(false);

  // Single input dialog
  const [showRenameFileDialog, setShowRenameFileDialog] = useState(false);
  const [contentActionHandler, setContentActionHandler] = useState(null);
  const [confirmMessage, setConfirmMessage] = useState();
  const [titleMessage, setTitleMessage] = useState();

  const clipBoardEmpty =
    clipBoardCopy.length === 0 && clipBoardCut.length === 0;

  return (
    <>
      <ContentActionDialog
        isOpen={!!contentActionHandler}
        onDismiss={() => {
          setContentActionHandler(null);
        }}
        onConfirm={(destination) => {
          contentActionHandler(destination);
          setContentActionHandler(null);
        }}
        source={selectedItems}
        confirmMessage={confirmMessage}
        titleMessage={titleMessage}
      />

      <ConfirmationDialog
        isOpen={showRemoveFilesDialog}
        onDismiss={() => {
          setShowRemoveFilesDialog(false);
        }}
        onConfirm={() => {
          setShowRemoveFilesDialog(false);
          deleteFiles([...selectedItems, id]);
        }}
      />

      <SingleInputDialog
        isOpen={showRenameFileDialog}
        onDismiss={() => {
          setShowRenameFileDialog(false);
        }}
        onSubmit={(input) => {
          setShowRenameFileDialog(false);
          renameFile(
            id,
            `${path.substring(0, path.lastIndexOf('/'))}/${input}`,
          );
        }}
        title={t('content-manager.actions.rename-title')}
        actionText={t('content-manager.actions.rename')}
        label={t('global.name')}
        defaultValue={baseName(path)}
        errRegex={notInPathRegex}
        errRegexMessage="content-manager.actions.rename.path-exists"
      />

      <ListContextMenu trigger={children}>
        <MenuButton
          iconBefore={FaEdit}
          onClick={() => {
            setShowRenameFileDialog(true);
          }}
          disabled={selectedItems.length > 1}
        >
          {t('content-manager.actions.rename')}
        </MenuButton>
        {isFile && (
          <MenuButton
            iconBefore={FaLink}
            onClick={() => {
              copy(blobUri);
            }}
          >
            {t('content-manager.actions.copy-link')}
          </MenuButton>
        )}

        <MenuButton
          iconBefore={FaTrash}
          onClick={() => {
            setShowRemoveFilesDialog(true);
          }}
        >
          {t('content-manager.actions.delete')}
        </MenuButton>

        <MenuButton
          disabled={!clipBoardEmpty || !isFile}
          iconBefore={FaCut}
          onClick={() => cutFiles(selectedItems)}
        >
          {t('content-manager.actions.cut')}
        </MenuButton>
        <Divider />

        <MenuButton
          iconBefore={FaFolder}
          onClick={() => {
            setTitleMessage(t('files.actions-context_menu.move_file_to'));
            setConfirmMessage(t('files.actions-context_menu.move_here'));
            setContentActionHandler(() => (destination) => {
              moveFiles(id, destination, path);
            });
          }}
        >
          {t('content-manager.actions.move_to')}
        </MenuButton>
      </ListContextMenu>
    </>
  );
};

export default ContentItemContextMenu;
