import { useCallback, useMemo } from 'react';
import { useTable } from 'react-table';

import c from '../utils/c';

const UnstyledTable = ({
  columns,
  data,
  className,
  columnClassName,
  rowClassName,
  cellClassName,
  headerClassName,
  onRowClick: _onRowClick,
}) => {
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);

  const onRowClick = useCallback(
    (row) => {
      if (_onRowClick) {
        _onRowClick(row);
      }
    },
    [_onRowClick],
  );

  const { rows, headerGroups, prepareRow, getTableProps, getTableBodyProps } =
    useTable({
      columns: memoizedColumns || [],
      data: memoizedData || [],
    });

  return (
    <table className={className} {...getTableProps()}>
      <thead className="rounded-t-md">
        {headerGroups.map((headerGroup) => (
          <tr
            key={headerGroup}
            {...headerGroup.getHeaderGroupProps([
              { className: headerClassName },
            ])}
          >
            {headerGroup.headers.map((column) => (
              <th
                key={column}
                {...column.getHeaderProps([
                  {
                    className: columnClassName,
                  },
                ])}
              >
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              key={row}
              onClick={() => onRowClick(row.original)}
              className={c(!!_onRowClick && 'hover:bg-gray-200', rowClassName)}
              {...row.getRowProps()}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    key={cell}
                    {...cell.getCellProps([{ className: cellClassName }])}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export const Table = ({ data, columns, rowClassName, onRowClick }) => (
  <UnstyledTable
    className="overflow-x-auto h-8 w-full border border-gray-200 rounded-md"
    rowClassName={c(rowClassName, 'border-b border-gray-200')}
    cellClassName="p-2 whitespace-nowrap"
    columnClassName="p-2 font-normal"
    headerClassName="uppercase text-xs leading-4 tracking-wide text-left rounded-t text-gray-600 bg-gray-200 h-8"
    columns={columns}
    data={data}
    onRowClick={onRowClick}
  />
);
