import {
  CheckBox,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useMultipleChoiceQuestion from '../../hooks/useMultipleChoiceQuestion';

import SideBarContent from './SideBarContent';

const MultipleChoiceQuestionSideBar = ({ disabled: contentDisabled, id }) => {
  const t = useFormatMessage();

  const { question, update } = useMultipleChoiceQuestion(id);

  const onChange = (e, key) => {
    if (!isNaN(e.target.value) && e.target.value >= 0) {
      update({ [key]: e.target.value });
    }
  };

  if (!question) {
    return null;
  }

  const disabled = contentDisabled || question.publishedAt;

  return (
    <SideBarContent
      className="overflow-auto"
      title={t('slide_type.MULTIPLE_CHOICE')}
    >
      <div className="p-3">
        <InputGroup>
          <Label>
            {t('multiple-choice-question-sidebar.score_per_correct_answer')}
          </Label>
          <Input
            value={question.scorePerCorrectAnswer}
            type="number"
            onChange={(e) => onChange(e, 'scorePerCorrectAnswer')}
            min="0"
            disabled={disabled}
          />
        </InputGroup>

        <InputGroup>
          <Label>
            {t('multiple-choice-question-sidebar.subtract_per_wrong_answer')}
          </Label>
          <Input
            value={question.subtractPerWrongAnswer}
            type="number"
            onChange={(e) => onChange(e, 'subtractPerWrongAnswer')}
            min="0"
            disabled={disabled}
          />
        </InputGroup>

        <InputGroup>
          <Label>
            {t('multiple-choice-question-sidebar.max_selectable_answers')}
          </Label>
          <Input
            value={question.maxSelectableAnswers}
            type="number"
            onChange={(e) => onChange(e, 'maxSelectableAnswers')}
            min="0"
            disabled={disabled}
          />
        </InputGroup>

        <CheckBox
          label={t('multiple-choice-question-sidebar.should_find_all_correct')}
          checked={question.shouldFindAllCorrect}
          onChange={() =>
            update({ shouldFindAllCorrect: !question.shouldFindAllCorrect })
          }
          disabled={disabled}
        />
      </div>
    </SideBarContent>
  );
};

export default MultipleChoiceQuestionSideBar;
