import { generateLocalId } from '../../utils/generateLocalId';

import { QUESTION_TYPES } from './constants';

export const generateTemporaryQuestionAnswersState =
  (question, value) => (prev) => {
    const { [question.id]: _, ...otherQuestions } = prev;

    // only when undefined is passed, we'll remove it from the temporary answers.
    // This is needed when a user can override his answers,
    // and want to set an empty array or empty string (temporary) as value
    if (value === undefined) {
      return otherQuestions;
    }

    return {
      ...otherQuestions,
      [question.id]: value,
    };
  };

// This can be used for optimistic updates or when you don't want to use an API
export const generateSubmittedQuestionAnswersState = (question, value) => {
  const generateAnswers = {
    [QUESTION_TYPES.MULTIPLE_CHOICE]: () =>
      value.map((x) => ({
        id: generateLocalId('local'),
        correct: null,
        multipleChoiceAnswerId: x,
      })),
    [QUESTION_TYPES.OPEN]: () => [
      {
        id: generateLocalId('local'),
        correct: null,
        value,
      },
    ],
  };

  return {
    id: generateLocalId('local'),
    questionId: question.id,
    submittedAt: null,
    type: question.type,
    score: null,
    answers: generateAnswers[question.type](),
    feedback: null,
  };
};
