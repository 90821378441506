import c from '../utils/c';

export const VersionLabel = ({ version, className }) => {
  return (
    <span
      className={c(
        'relative -top-1 text-xxs font-semibold bg-accent-100 text-accent-600 px-2 rounded-lg',
        className,
      )}
    >
      v{version}
    </span>
  );
};
