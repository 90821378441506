import { useContext, useEffect, useMemo, useState } from 'react';
import { FaEdit, FaTimes } from 'react-icons/fa';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  Dropdown,
  FilledButton,
  InputGroup,
  Label,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import IconPickerDialog from '../../../components/IconPickerDialog';

import { UserContext } from '../../../providers/UserProvider';

import useFormatMessage from '../../../hooks/useFormatMessage';
import useActivityTypes from '../hooks/useActivityTypes';

import SLIDEMETHODS from '../../../utils/constants/slideMethods';
import { DefaultIcon, Icon, IconSet } from '../utils/activityTypeIcons';

const filterInput = (e) => {
  if (/[^A-Za-z0-9\s]+/g.test(e.key)) {
    e.preventDefault();
  }
  e.key = e.key.toLowerCase();
};

const no_icon_name = 'None';

const AddActivityDialog = ({
  onConfirm,
  onDismiss,
  isOpen,
  insertSlideBelow,
}) => {
  const t = useFormatMessage();

  const [selectedActivity, setActivity] = useState(null);
  const [selectedPublic, setPublic] = useState(SLIDEMETHODS.CLASS);
  const [activityFilter, setActivityfilter] = useState(null);
  const [iconName, setIconName] = useState(no_icon_name);
  const [showIconPicker, setShowIconPicker] = useState(false);

  useEffect(() => {
    setActivity(null);
    setPublic(SLIDEMETHODS.CLASS);
    setActivityfilter(null);
    setIconName(no_icon_name);
  }, [isOpen]);

  const { activitiesByKey, createActivityType } = useActivityTypes();

  const filteredActivites = useMemo(() => {
    const keys = Object.keys(activitiesByKey);
    if (!activityFilter) {
      return keys;
    } else {
      return keys.filter((e) => e.toLowerCase().startsWith(activityFilter));
    }
  }, [activitiesByKey, activityFilter]);

  const isNewActivity = selectedActivity && !activitiesByKey[selectedActivity];

  const { user } = useContext(UserContext);
  const organizationId = user.organization.id;

  const createActivity = async () => {
    let params = {
      method: selectedPublic,
      activityTypeId: activitiesByKey[selectedActivity]?.id,
      activityType: activitiesByKey[selectedActivity],
    };
    if (isNewActivity) {
      const newAct = await createActivityType(
        selectedActivity,
        iconName,
        organizationId,
      );
      params.activityTypeId = newAct.id;
      params.activityType = newAct;
    }

    insertSlideBelow(params);
  };

  const confirm = () => {
    createActivity();
    onConfirm();
  };

  const changeActivity = (act) => {
    setActivity(act);
    /*
    change:
    existing  -> existing
    new       -> existing
    existing  -> new
    !change:
    new       -> new
    */
    // Keep selected icon if we come from new activity and just change the name
    if (!(isNewActivity && act && !activitiesByKey[act])) {
      setIconName(activitiesByKey[act]?.icon ?? no_icon_name);
    }
  };

  const isDefaultIcon = !iconName || iconName === no_icon_name;

  const iconBg = isDefaultIcon ? 'bg-gray-500' : 'bg-blue-300';

  return (
    <div>
      <IconPickerDialog
        isOpen={showIconPicker}
        onConfirm={(i) => {
          setShowIconPicker(false);
          setIconName(i);
        }}
        onDismiss={() => setShowIconPicker(false)}
      />
      <Dialog isOpen={isOpen} onDismiss={onDismiss}>
        <DialogHeader>{t('content-editor.activity.title')}</DialogHeader>
        <DialogContent>
          <div className="">
            <div className="flex mb-2 gap-2">
              <div
                className={`flex items-center w-16 h-16 rounded-full ${iconBg} relative`}
              >
                <div className="block m-auto items-center">
                  {isDefaultIcon || !IconSet.has(iconName) ? (
                    DefaultIcon
                  ) : (
                    <>
                      <Icon itemName={iconName} />
                      {isNewActivity && (
                        <button
                          error
                          type="button"
                          onClick={() => setIconName('')}
                          className="text-center bg-red-100 text-red-700 hover:shadow-none hover:bg-red-300 hover:text-red-900 transform p-1 rounded-full flex items-center box-border absolute -right-1 -top-1"
                        >
                          <FaTimes />
                        </button>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col flex-grow">
                <InputGroup>
                  <Label>{t('content-editor.activity.type')}</Label>
                  <Dropdown
                    onKeyDown={filterInput}
                    isMulti={false}
                    onInputChange={setActivityfilter}
                    creatable={true}
                    value={selectedActivity}
                    options={filteredActivites}
                    placeholder={t('content-editor.activity.type_placeholder')}
                    onChange={changeActivity}
                    defaultValue={[]}
                  />
                </InputGroup>
                {isNewActivity && (
                  <InputGroup>
                    <div className="relative w-full">
                      <div className="flex gap-2">
                        <FilledButton
                          onClick={() => setShowIconPicker(true)}
                          className="flex-1 flex-grow gap-2"
                        >
                          <FaEdit />
                          {isDefaultIcon
                            ? t('content-editor.activity.image_add')
                            : t('content-editor.activity.image_change')}
                        </FilledButton>
                      </div>
                    </div>
                  </InputGroup>
                )}
              </div>
            </div>
            <InputGroup>
              <Label>{t('content-editor.activity.method')}</Label>
              <Dropdown
                value={selectedPublic}
                options={Object.values(SLIDEMETHODS).map((method) => ({
                  key: method,
                  value: method,
                  label: t(`slide_method.${method}`),
                }))}
                onChange={setPublic}
              />
            </InputGroup>
          </div>
        </DialogContent>
        <DialogActions>
          <OutlineButton onClick={onDismiss}>
            {t('global.cancel')}
          </OutlineButton>
          <FilledButton
            disabled={
              !selectedActivity ||
              !iconName ||
              !selectedPublic ||
              (isNewActivity && iconName == no_icon_name)
            }
            onClick={confirm}
          >
            {t('global.save')}
          </FilledButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AddActivityDialog;
