import { useContext } from 'react';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { UNARCHIVE_LESSON_CONTENT } from '../../api/content';

import useFormatMessage from '../useFormatMessage';

import useFieldMutation from './useFieldMutation';

const useUnarchiveContentMutation = () => {
  const t = useFormatMessage();
  const [unarchiveLessonMutate] = useFieldMutation(
    UNARCHIVE_LESSON_CONTENT,
    'LessonContent',
  );
  const { addNotification } = useContext(NotificationContext);

  const unarchive = (id) => {
    return unarchiveLessonMutate({ id }).then(({ type }) => {
      addNotification({
        type: 'success',
        content: t(`content-overview.${type}.unarchive.success`),
      });
    });
  };

  return unarchive;
};

export default useUnarchiveContentMutation;
