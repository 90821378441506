import tailwindColors from 'tailwindcss/colors';

const accent = tailwindColors.lightBlue;

const gray = {
  100: '#FAFAFA',
  200: '#F4F4F5',
  300: '#E4E4E7',
  400: '#D4D4D8',
  500: '#A2A2A8',
  600: '#6E6E76',
  700: '#52525A',
  800: '#3F3F45',
  900: '#2E2E33',
  1000: '#1D1D20',
};

const colors = {
  ...tailwindColors,
  accent,
  gray,
};

export default colors;
