import SideBar from '../SideBars/SideBar';
import SideBarContextProvider from '../SideBars/SideBarContextProvider';

import ContentEditorDetail from './ContentEditorDetail';

const SlideEditor = ({
  slideId,
  disabled,
  content,
  setShowCreateNewVersionDialog,
  duplicateSlideAndGoTo,
}) => {
  return (
    <SideBarContextProvider>
      <ContentEditorDetail
        slideId={slideId}
        disabled={disabled}
        setShowCreateNewVersionDialog={setShowCreateNewVersionDialog}
        content={content}
        onDuplicateSlide={duplicateSlideAndGoTo}
      />

      <SideBar slideId={slideId} disabled={disabled} />
    </SideBarContextProvider>
  );
};
export default SlideEditor;
