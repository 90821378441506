import c from '../utils/functions/c';

const Overlay = ({ className, children, onClick, showOverlay }) => {
  if (!showOverlay) {
    return children;
  }

  return (
    <div className={c('flex opacity-50 h-full', className)} onClick={onClick}>
      {children}
    </div>
  );
};

export default Overlay;
