import { Children, cloneElement, useContext } from 'react';

import { SlideViewerContext } from './SlideViewerContextProvider';

const SlideViewerRightAction = ({ children }) => {
  const { slides, currentSlideIndex, setSlide } =
    useContext(SlideViewerContext);

  const disabled = currentSlideIndex === slides.length - 1;
  const child = Children.only(children({ disabled }));

  return cloneElement(child, {
    onClick: () => !disabled && setSlide(currentSlideIndex + 1),
  });
};

export default SlideViewerRightAction;
