import c from '../utils/c';
import getLoaderStyle from '../utils/getComponentStyle';

const loaderStyles = {
  ACCENT: 'text-accent-500',
  GRAY: 'text-gray-500',
  LIGHTGRAY: 'text-gray-100',
  WHITE: 'text-white',
};

export const Loader = ({ accent, gray, white, lightGray, color }) => {
  const style =
    color ||
    getLoaderStyle(loaderStyles, { accent, gray, white, lightGray }, 'ACCENT');

  return (
    <svg
      className={c('animate-spin w-full', loaderStyles[style])}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="white"
        strokeWidth="4"
      />
      <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      />
    </svg>
  );
};
