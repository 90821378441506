import { VIEW_MODES } from '@ftrprf/tailwind-components';

import SLIDEMETHODS from '../../utils/constants/slideMethods';
import * as SLIDEPARTTYPES from '../../utils/constants/slidePartTypes';

import ContentEditorContainer from './ContentEditorContainer';

const ExamContainer = (props) => {
  const insertSlideBelow = ({
    sequence,
    content,
    insertSlideMutate,
    prevSlide,
    parameters = {},
  }) => {
    const part = prevSlide ? prevSlide.part : SLIDEPARTTYPES.THEORY;
    const activityTypeId = prevSlide?.activityType
      ? prevSlide.activityType.id
      : null;
    const method = prevSlide ? prevSlide.method : SLIDEMETHODS.CLASS;

    const newSlide = {
      lessonContentId: content.id,
      sequence,
      content: '',
      title: '',
      viewModes: [VIEW_MODES.CLASSICAL],
      part: part,
      method: method,
      activityTypeId,
      time: null,
      groupRoles: [],
    };

    if (activityTypeId) {
      newSlide.activityType = {
        id: activityTypeId,
        key: prevSlide.activityType.key,
      };
    }

    return insertSlideMutate({ ...newSlide, ...parameters });
  };
  return (
    <ContentEditorContainer
      hasViewModeSelector={false}
      insertSlideBelow={insertSlideBelow}
      {...props}
    />
  );
};

export default ExamContainer;
