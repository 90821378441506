import {
  SlideViewerParentVisit,
  SlideViewerQuiz,
} from '../../slideviewer/SlideViewer';
import ParentVisitButton from '../images/parent-visit-button.png';
import QuizButton from '../images/quiz-button.png';

const SlideViewerFooter = () => {
  return (
    <div className="w-full flex items-end justify-between p-4 absolute bottom-0">
      <div className="flex">
        <SlideViewerQuiz>
          <img
            className="h-16 w-16 bg-green-500"
            src={QuizButton}
            alt="quiz-button"
          />
        </SlideViewerQuiz>

        <SlideViewerParentVisit>
          <img
            className="h-16 w-16 bg-green-500"
            src={ParentVisitButton}
            alt="parent-visit-button"
          />
        </SlideViewerParentVisit>
      </div>
    </div>
  );
};

export default SlideViewerFooter;
