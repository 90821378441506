import { Children, cloneElement, useContext } from 'react';

import { SlideViewerContext } from './SlideViewerContextProvider';

const SlideViewerLeftAction = ({ children }) => {
  const { currentSlideIndex, setSlide } = useContext(SlideViewerContext);

  const disabled = currentSlideIndex <= 0;
  const child = Children.only(children({ disabled }));

  return cloneElement(child, {
    onClick: () => !disabled && setSlide(currentSlideIndex - 1),
  });
};

export default SlideViewerLeftAction;
