import { useQuery } from '@apollo/client';

import { FIND_CONTENT_NAMES_BY_PATH } from '../../api/files';

const useFileNamesInPath = (path) => {
  const { data } = useQuery(FIND_CONTENT_NAMES_BY_PATH, {
    variables: { path },
  });

  const fileNames = data?.findAllContentFilesByPath.reduce((res, { path }) => {
    return { ...res, [path.substring(path.lastIndexOf('/'))]: true };
  }, {});

  return fileNames;
};

export default useFileNamesInPath;
