const programs = [
  {
    id: 1,
    name: 'B1',
    type: 'series',
    clan: 'BYTEBUSTERS',
    level: 1,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 2,
    name: 'B2',
    type: 'series',
    clan: 'BYTEBUSTERS',
    level: 2,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 3,
    name: 'B3',
    type: 'series',
    clan: 'BYTEBUSTERS',
    level: 3,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 4,
    name: 'B4',
    type: 'series',
    clan: 'BYTEBUSTERS',
    level: 4,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 5,
    name: 'M1',
    type: 'series',
    clan: 'MINECRAFTERS',
    level: 1,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 6,
    name: 'C1',
    type: 'series',
    clan: 'CODEKRAKS',
    level: 1,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 7,
    name: 'C2',
    type: 'series',
    clan: 'CODEKRAKS',
    level: 2,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 8,
    name: 'C3',
    type: 'series',
    clan: 'CODEKRAKS',
    level: 3,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 9,
    name: 'C4',
    type: 'series',
    clan: 'CODEKRAKS',
    level: 4,
    programTypeId: 1,
    obsolete: true,
  },
  {
    id: 10,
    name: 'D1',
    type: 'series',
    clan: 'DIGIHEROES',
    level: 1,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 11,
    name: 'D2',
    type: 'series',
    clan: 'DIGIHEROES',
    level: 2,
    programTypeId: 1,
    obsolete: true,
  },
  {
    id: 12,
    name: 'E1',
    type: 'series',
    clan: 'E-RIDERS',
    level: 1,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 13,
    name: 'E2',
    type: 'series',
    clan: 'E-RIDERS',
    level: 2,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 14,
    name: 'WSB1',
    type: 'workshop',
    clan: 'BYTEBUSTERS',
    level: 1,
    programTypeId: 2,
    obsolete: false,
  },
  {
    id: 15,
    name: 'WSC1',
    type: 'workshop',
    clan: 'CODEKRAKS',
    level: 1,
    programTypeId: 2,
    obsolete: false,
  },
  {
    id: 16,
    name: 'WSD1',
    type: 'workshop',
    clan: 'DIGIHEROES',
    level: 1,
    programTypeId: 2,
    obsolete: false,
  },
  {
    id: 17,
    name: 'T9B1',
    type: 'series',
    clan: 'BYTEBUSTERS',
    level: 1,
    programTypeId: 3,
    obsolete: true,
  },
  {
    id: 18,
    name: 'K4dC1',
    type: 'camp',
    clan: 'CODEKRAKS',
    level: 1,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 19,
    name: 'zzzK4d D1',
    type: 'camp',
    clan: 'DIGIHEROES',
    level: 1,
    programTypeId: 4,
    obsolete: true,
  },
  {
    id: 20,
    name: 'M2',
    type: 'series',
    clan: 'MINECRAFTERS',
    level: 2,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 21,
    name: 'zzzT9B2',
    type: 'series',
    clan: 'BYTEBUSTERS',
    level: 2,
    programTypeId: 3,
    obsolete: true,
  },
  {
    id: 22,
    name: 'WSMM',
    type: 'workshop',
    clan: 'CODEKRAKS',
    level: 2,
    programTypeId: 2,
    obsolete: false,
  },
  {
    id: 23,
    name: 'KMPAL',
    type: 'camp',
    clan: 'ALICEKAMP - EXPERTEN',
    level: 1,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 24,
    name: 'K5dB1',
    type: 'camp',
    clan: 'START2CODE - 3E & 4E LEERJAAR',
    level: 1,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 25,
    name: 'K5dC1',
    type: 'camp',
    clan: 'START2CODE - 5E & 6E LEERJAAR',
    level: 1,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 26,
    name: 'E3',
    type: 'series',
    clan: 'E-RIDERS',
    level: 3,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 27,
    name: 'M3',
    type: 'series',
    clan: 'MINECRAFTERS',
    level: 3,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 28,
    name: 'WRbt',
    type: 'workshop',
    clan: 'BYTEBUSTERS',
    level: 1,
    programTypeId: 2,
    obsolete: false,
  },
  {
    id: 29,
    name: 'TTT',
    type: 'workshop',
    clan: 'FEVERFREAKS',
    level: 1,
    programTypeId: 2,
    obsolete: false,
  },
  {
    id: 30,
    name: 'WSDBSFC1',
    type: 'workshop',
    clan: 'CODEKRAKS',
    level: 1,
    programTypeId: 2,
    obsolete: false,
  },
  {
    id: 31,
    name: 'K4dB1',
    type: 'camp',
    clan: 'BYTEBUSTERS',
    level: 1,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 32,
    name: 'K4dB2',
    type: 'camp',
    clan: 'BYTEBUSTERS',
    level: 2,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 33,
    name: 'K4dC2',
    type: 'camp',
    clan: 'CODEKRAKS',
    level: 2,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 34,
    name: 'WSDBSFD1',
    type: 'workshop',
    clan: 'DIGIHEROES',
    level: 1,
    programTypeId: 2,
    obsolete: false,
  },
  {
    id: 35,
    name: 'WSC1Lite',
    type: 'workshop',
    clan: 'CODEKRAKS',
    level: 1,
    programTypeId: 2,
    obsolete: false,
  },
  {
    id: 36,
    name: 'E4',
    type: 'series',
    clan: 'E-RIDERS',
    level: 4,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 37,
    name: 'KMPMcrft',
    type: 'camp',
    clan: 'MINECRAFTKAMP - GEVORDERDEN',
    level: 1,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 38,
    name: 'KMPRbt1',
    type: 'camp',
    clan: 'ROBOTKAMP - GEVORDERDEN',
    level: 1,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 39,
    name: 'KMPRbt2',
    type: 'camp',
    clan: 'ROBOTKAMP - EXPERTEN',
    level: 2,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 40,
    name: 'Scholen Demo',
    type: 'workshop',
    clan: 'SCHOOLS',
    level: 1,
    programTypeId: 2,
    obsolete: false,
  },
  {
    id: 41,
    name: 'P1',
    type: 'series',
    clan: 'PYTHONS',
    level: 1,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 42,
    name: 'P2',
    type: 'series',
    clan: 'PYTHONS',
    level: 2,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 43,
    name: 'DSKmp',
    type: 'camp',
    clan: 'DIGITAL SKILLS-KAMP',
    level: 1,
    programTypeId: 4,
    obsolete: false,
  },
  {
    id: 44,
    name: 'Security',
    type: 'series',
    clan: 'ANONYMOUS',
    level: 1,
    programTypeId: 1,
    obsolete: false,
  },
  {
    id: 45,
    name: 'AA_to use for next',
    type: 'series',
    clan: 'ANONYMOUS',
    level: 1,
    programTypeId: 1,
    obsolete: true,
  },
];

export default programs;
