export const toolbarGroups = [
  {
    name: 'all',
    groups: [
      'styles',
      'break',
      'others',
      'undo',
      'seperator2',
      'basicstyles',
      'seperator3',
      'colors',
      'seperator4',
      'cleanup',
      'seperator5',
      'align',
      'seperator6',
      'list',
      'seperator7',
      'indent',
      'seperator8',
      'tools',
      'find',
      'seperator9',
      'iframe',
      'iframedialog',
      'insert',
      'links',
      'blocks',
      'mode',
    ],
  },
];
