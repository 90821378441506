import { createElement } from 'react';
import parsePlugin from 'rehype-parse';
import rehypeReact from 'rehype-react';
import unified from 'unified';

import Link from './SlideComponents/Link';
import ModalButton from './SlideComponents/ModalButton';

import SlideViewerBaseSlide from './SlideViewerBaseSlide';

const processor = unified()
  .use(parsePlugin, { fragment: true })
  .use(rehypeReact, {
    createElement,
    components: { ['a']: Link, ['button']: ModalButton },
  });

const SlideViewerTextSlide = ({ value, classNames }) => {
  return (
    <SlideViewerBaseSlide
      value={value}
      processor={processor}
      classNames={classNames}
    />
  );
};

export default SlideViewerTextSlide;
