import Footer from '../../components/Footer';
import Header from '../../components/Header';

const GeneralLayout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};

export default GeneralLayout;
