import { useState } from 'react';

import {
  CheckBox,
  FilledButton,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';

import Collapsable from '../../../../components/Collapsable';
import Editor, {
  ImagePlugin,
  LinkPlugin,
} from '../../../../components/Editor/Editor';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useMultipleChoiceQuestion from '../../hooks/useMultipleChoiceQuestion';
import useQuestionAnswerMultipleChoice from '../../hooks/useQuestionAnswerMultipleChoice';

import alphabet from '../../../../utils/functions/alphabet';

import SideBarContent from './SideBarContent';
import SideBarFooter from './SideBarFooter';

const QuestionAnswerMultipleChoiceSideBar = ({
  id,
  questionId,
  index,
  disabled,
}) => {
  const t = useFormatMessage();

  const [, setLocalQuestionState] = useState();
  const [, setLocalExplanationState] = useState();

  const { questionAnswerMultipleChoice, update } =
    useQuestionAnswerMultipleChoice(id);

  const updateLocalQuestionState = (value) => {
    update({ value: value });
    setLocalQuestionState(value);
  };

  const updateExplanationState = (value) => {
    update({ explanation: value });
    setLocalExplanationState(value);
  };

  const { removeQuestionAnswerMultipleChoice, question } =
    useMultipleChoiceQuestion(questionId);

  if (!questionAnswerMultipleChoice) {
    return null;
  }

  return (
    <div>
      <SideBarContent
        className="overflow-auto"
        title={`${t('global.answer')} ${alphabet(index)}`}
      >
        <InputGroup className="border-b border-gray-300 p-3">
          <CheckBox
            label={t(
              'question-answer-multiple-choice-sidebar.is_correct_answer',
            )}
            disabled={disabled || question.publishedAt}
            checked={questionAnswerMultipleChoice.correct}
            onChange={(checked) => update({ correct: checked })}
          />
        </InputGroup>

        <div className="flex-grow flex flex-col">
          <Label className="px-3">
            {t('question-answer-multiple-choice-sidebar.answer')}
          </Label>
          <Editor
            className="border-t border-b border-gray-300 py-1"
            value={questionAnswerMultipleChoice.value || ''}
            onChange={updateLocalQuestionState}
            disabled={disabled}
            withBorder={false}
            plugins={[LinkPlugin, ImagePlugin]}
          />
        </div>

        <div className="flex flex-col">
          <Collapsable
            className="pr-3 pb-2 pt-3"
            trigger={
              <Label className="px-3 flex-grow">
                {t('question-answer-multiple-choice-sidebar.explanation')}{' '}
                <span className="text-xs text-gray-600">
                  {t('global.not_required')}
                </span>
              </Label>
            }
          >
            <Editor
              className="border-t border-gray-300 py-1"
              value={questionAnswerMultipleChoice.explanation || ''}
              onChange={updateExplanationState}
              disabled={disabled}
              withBorder={false}
              plugins={[LinkPlugin, ImagePlugin]}
            />
          </Collapsable>
        </div>
      </SideBarContent>

      <SideBarFooter>
        <FilledButton
          error
          disabled={disabled || question.publishedAt}
          onClick={() => removeQuestionAnswerMultipleChoice(id)}
        >
          {t('global.delete')}
        </FilledButton>
      </SideBarFooter>
    </div>
  );
};

export default QuestionAnswerMultipleChoiceSideBar;
