import { useEffect, useState } from 'react';

const Form = ({ children, onChange: onChangeProp }) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    onChangeProp(values);
  }, [values, onChangeProp]);

  const onChange = (name, value) => {
    setValues((v) => ({
      ...v,
      [name]: value,
    }));
  };

  const clear = () => {
    setValues({});
  };

  return (
    <div className="flex flex-wrap flex-col items-start md:flex-row md:items-center">
      {children({ values, onChange, clear })}
    </div>
  );
};

export default Form;
