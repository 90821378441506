import { FiGlobe } from 'react-icons/fi';

import { DropdownButton } from './buttons/DropdownButton';
import { MenuButton } from './buttons/MenuButton';

import { DropdownMenu } from './DropdownMenu';

export const LanguagePicker = ({ languages, onLanguageChange }) => {
  return (
    <DropdownMenu
      trigger={({ isOpen }) => (
        <DropdownButton isOpen={isOpen}>
          <FiGlobe className="w-5 h-5" />
        </DropdownButton>
      )}
    >
      {({ hide }) => (
        <div>
          {languages.map((language) => (
            <MenuButton
              key={language.key}
              onClick={() => {
                hide();
                onLanguageChange(language.key);
              }}
            >
              {language.label}
            </MenuButton>
          ))}
        </div>
      )}
    </DropdownMenu>
  );
};
