import { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import { Input } from '@ftrprf/tailwind-components';

import Dropdown from '../../../components/Dropdown';
import Filterbar from '../../../components/Filterbar';
import Form from '../../../components/Form';

import { CodeFeverContext } from '../../../providers/CodeFeverProvider';

import useFormatMessage from '../../../hooks/useFormatMessage';

import { FIND_TAGS_WITH_NAME } from '../../../api/content';

import contentStates from '../../../utils/constants/contentStates';
import c from '../../../utils/functions/c';
import isCodeFever from '../../../utils/isCodeFever';

const commonClassnames = 'flex-grow';

const ContentOverviewFilterbar = ({ onChange: onChangeHandler }) => {
  const t = useFormatMessage();
  const { clans, levels, lessonNumbers, programTypes } =
    useContext(CodeFeverContext);
  const [filter, setFilter] = useState('');

  const { fetchMore, data } = useQuery(FIND_TAGS_WITH_NAME, {
    variables: {
      name: filter,
    },
  });
  useEffect(() => {
    fetchMore({
      variables: {
        name: filter,
      },
    });
  }, [data, fetchMore, filter]);

  const handleInputChange = (value) => {
    setFilter(value);
  };

  const stateOptions = Object.values(contentStates).map((state) => ({
    key: state,
    value: state,
    label: t(`content-state.${state}`),
  }));
  return (
    // TODO: Make this a formik form
    <Form onChange={onChangeHandler}>
      {({ values, onChange, clear }) => (
        <Filterbar className="u-emulated-flex-gap justify-end" onClear={clear}>
          <div className={c(commonClassnames, 'min-w-56 max-w-md')}>
            <Input
              className="h-11"
              name="title"
              placeholder={t('content-overview.filters.title')}
              type="text"
              onChange={(event) =>
                onChange('title', event.target.value || null)
              }
              value={values.title || ''}
            />
          </div>
          {isCodeFever && (
            <>
              <div className={c(commonClassnames, 'min-w-56')}>
                <Dropdown
                  className="h-11"
                  name="programType"
                  options={programTypes}
                  onChange={(selectedOption) =>
                    onChange('programType', selectedOption)
                  }
                  placeholder={t('content-overview.filters.programme')}
                  value={values.programType}
                />
              </div>
              <div className={c(commonClassnames, 'min-w-56')}>
                <Dropdown
                  className="h-11"
                  name="clan"
                  options={clans}
                  onChange={(selectedOption) =>
                    onChange('clan', selectedOption)
                  }
                  placeholder={t('content-overview.filters.clan')}
                  value={values.clan}
                />
              </div>
              <div className={c(commonClassnames, 'min-w-32')}>
                <Dropdown
                  className="h-11"
                  name="level"
                  options={levels}
                  onChange={(selectedOption) =>
                    onChange('level', selectedOption)
                  }
                  placeholder={t('content-overview.filters.level')}
                  value={values.level}
                />
              </div>
              <div className={c(commonClassnames, 'min-w-32')}>
                <Dropdown
                  className="h-11"
                  name="programLessonId"
                  options={lessonNumbers}
                  onChange={(selectedOption) =>
                    onChange('programLessonId', selectedOption)
                  }
                  placeholder={t('content-overview.filters.lesson')}
                  value={values.programLessonId}
                />
              </div>
            </>
          )}
          <div className={c(commonClassnames, 'w-32')}>
            <Dropdown
              className="h-11"
              name="state"
              options={stateOptions}
              onChange={(selectedOption) => onChange('state', selectedOption)}
              placeholder={t('content-overview.filters.state')}
              value={values.state}
            />
          </div>
          <div className={c(commonClassnames, 'w-32')}>
            <Dropdown
              className="h-11"
              onChange={(selectedOption) => onChange('tags', selectedOption)}
              isMulti={true}
              options={data?.findTagsWithName?.map((tag) => ({
                key: tag.id,
                value: tag.name,
                label: tag.name,
              }))}
              onInputChange={handleInputChange}
              placeholder={t('content-overview.filters.tags')}
              value={values.tags}
            />
          </div>
        </Filterbar>
      )}
    </Form>
  );
};

export default ContentOverviewFilterbar;
