import { FaTimes } from 'react-icons/fa';
import { useQuery } from '@apollo/client';

import ContentFlowDiagram from '../../pages/ContentEditor/partials/ContentEditorDetail/ContentFlowDiagram';

import { FIND_LESSON_CONTENT } from '../../api/content';

const LessonFlowContainer = ({ id, hide }) => {
  const queryOptions = {
    variables: {
      id,
    },
  };

  const { data } = useQuery(FIND_LESSON_CONTENT, queryOptions);
  const content = data?.findLessonContent;

  if (!content) {
    return 'Loading';
  }

  return (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex justify-center ">
      <div
        className="bg-white shadow-base max-w-4xl flex flex-col w-full min-w-96 rounded-lg h-3/4  relative"
        style={{ zIndex: 99999, marginTop: '10vh' }}
      >
        <button
          className="absolute -top-6 -right-6 bg-accent-600 hover:bg-accent-500 text-accent-200 rounded-full p-4 shadow-base border border-accent-700 cursor-pointer"
          onClick={hide}
        >
          <FaTimes />
        </button>
        <ContentFlowDiagram content={content} />
      </div>
    </div>
  );
};

export default LessonFlowContainer;
