import {
  FaArchive,
  FaCopy,
  FaDesktop,
  FaEye,
  FaHome,
  FaPencilAlt,
  FaTrash,
  FaUsers,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { ListContextMenu } from '@ftrprf/context-menu';
import { MenuButton } from '@ftrprf/tailwind-components';

import Divider from '../../../../components/Divider';

import useFormatMessage from '../../../../hooks/useFormatMessage';

import CONTENT_TYPES from '../../../../utils/constants/contentTypes';
import * as urls from '../../../../utils/constants/urls';

const ContentCardContextMenu = ({
  content,
  contentType,
  children,
  onCopy,
  onArchive,
  onUnarchive,
  onRemove,
}) => {
  const t = useFormatMessage();

  const disabled = content.archived;

  return (
    <ListContextMenu trigger={children}>
      {contentType === CONTENT_TYPES.EXAM ? (
        <Link to={`${urls.CONTENT}/${content.id}/classical`} target="_blank">
          <MenuButton iconBefore={FaEye} disabled={disabled}>
            {t('content-editor.header.preview')}
          </MenuButton>
        </Link>
      ) : (
        <>
          <Link to={`${urls.CONTENT}/${content.id}/classical`} target="_blank">
            <MenuButton iconBefore={FaUsers} disabled={disabled}>
              {t('view-modes.classical')}
            </MenuButton>
          </Link>
          <Link to={`${urls.CONTENT}/${content.id}/selfstudy`} target="_blank">
            <MenuButton iconBefore={FaHome} disabled={disabled}>
              {t('view-modes.selfstudy')}
            </MenuButton>
          </Link>
          <Link to={`${urls.CONTENT}/${content.id}/projection`} target="_blank">
            <MenuButton iconBefore={FaDesktop} disabled={disabled}>
              {t('view-modes.projection')}
            </MenuButton>
          </Link>
          <Divider />
        </>
      )}
      <Link to={`${urls[contentType]}/${content.id}/edit`}>
        <MenuButton iconBefore={FaPencilAlt} disabled={disabled}>
          {t('global.edit')}
        </MenuButton>
      </Link>
      <MenuButton iconBefore={FaCopy} onClick={onCopy} disabled={disabled}>
        {t('global.copy')}
      </MenuButton>
      {content.archived ? (
        <MenuButton iconBefore={FaArchive} onClick={onUnarchive}>
          {t('global.unarchive')}
        </MenuButton>
      ) : (
        <MenuButton iconBefore={FaArchive} onClick={onArchive}>
          {t('global.archive')}
        </MenuButton>
      )}
      <MenuButton iconBefore={FaTrash} onClick={onRemove}>
        {t('global.delete')}
      </MenuButton>
    </ListContextMenu>
  );
};

export default ContentCardContextMenu;
