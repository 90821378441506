import { useRef } from 'react';

export const FileInput = ({ children, onUpload, multiple, ...props }) => {
  const ref = useRef();

  return (
    <>
      {children(() => ref.current?.click())}
      <input
        className="hidden"
        type="file"
        onChange={(e) => onUpload(e.target.files)}
        ref={ref}
        multiple={multiple}
        {...props}
      />
    </>
  );
};

FileInput.defaultProps = {
  onUpload: () => {},
  multiple: false,
};
