import PaginatedContentCardList from '../../../components/PaginatedContentCardList';

import useArchiveContentMutation from '../../../hooks/graphql/useArchiveContentMutation';
import useCopyContentMutation from '../../../hooks/graphql/useCopyContentMutation';
import useRemoveContentMutation from '../../../hooks/graphql/useRemoveContentMutation';
import useUnarchiveContentMutation from '../../../hooks/graphql/useUnarchiveContentMutation';

import StudioContentCard from './ContentCard/ContentCard';

const ContentOverviewCards = ({
  contentList,
  contentType,
  totalPages,
  currentPage,
  setCurrentPage,
  refetchContentList,
}) => {
  const copy = useCopyContentMutation();
  const archive = useArchiveContentMutation();
  const unarchive = useUnarchiveContentMutation();
  const remove = useRemoveContentMutation();

  return (
    <div className="w-full flex flex-col">
      <PaginatedContentCardList
        items={contentList}
        itemRender={({ item }) => (
          <StudioContentCard
            key={item.id}
            content={item}
            contentType={contentType}
            remove={() =>
              remove(item).then(() => {
                if (contentList.length === 1 && currentPage === totalPages) {
                  setCurrentPage(totalPages - 1);
                }

                refetchContentList();
              })
            }
            copy={() => copy(item.id).then(refetchContentList)}
            archive={() => archive(item.id)}
            unarchive={() => unarchive(item.id)}
          />
        )}
        totalPages={totalPages}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ContentOverviewCards;
