import useFormatMessage from '../../hooks/useFormatMessage';

import en from './translations/en';
import nl from './translations/nl';

import { InfoBar } from './InfoBar';

// source: https://stackoverflow.com/a/13348618
// please note, that IE11 now returns undefined again for window.chrome
// and new Opera 30 outputs true for window.chrome  but needs to check if
// window.opr is not undefined  and new IE Edge outputs to true now for window.chrome
// and if not iOS Chrome check so use the below updated condition
const isChrome = () => {
  const isChromium = window.chrome;
  const winNav = window.navigator;
  const vendorName = winNav.vendor;
  const isOpera = typeof window.opr !== 'undefined';
  const isIEedge = winNav.userAgent.indexOf('Edge') > -1;

  return (
    isChromium !== null &&
    typeof isChromium !== 'undefined' &&
    vendorName === 'Google Inc.' &&
    isOpera === false &&
    isIEedge === false
  );
};

const getChromeVersion = () => {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);

  return raw ? parseInt(raw[2], 10) : false;
};

export const ChromeInfoBar = ({ container }) => {
  const t = useFormatMessage();

  const isUsingChrome = isChrome();
  const isNewVersion = isUsingChrome && getChromeVersion() >= 87;

  if (isUsingChrome && isNewVersion) {
    return null;
  }

  return (
    <InfoBar
      container={container}
      text={
        isUsingChrome
          ? t('chrome-info-bar.use_newer_version')
          : t('chrome-info-bar.use_chrome')
      }
    />
  );
};

export const ChromeInfoBarTranslations = {
  en,
  nl,
};
