import { useContext, useState } from 'react';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { useLazyQuery, useQuery } from '@apollo/client';

import {
  Content,
  FilledButton,
  NotificationContext,
  PageTitle,
} from '@ftrprf/tailwind-components';

import BreadCrumbs from '../../../components/BreadCrumbs';
import Center from '../../../components/Center';
import ErrorMessage from '../../../components/ErrorMessage';
import OverviewEmpty from '../../../components/OverviewEmpty';
import Pagination from '../../../components/Pagination';
import {
  Table,
  TableCell,
  TableHeaderCell,
  TableRow,
  TableSkeleton,
} from '../../../components/Table/index';

import { UserContext } from '../../../providers/UserProvider';

import useFieldMutation from '../../../hooks/graphql/useFieldMutation';
import useRemoveMutation from '../../../hooks/graphql/useRemoveMutation';
import useDebounce from '../../../hooks/useDebounce';
import useFormatMessage from '../../../hooks/useFormatMessage';
import useTitle from '../../../hooks/useTitle';

import {
  CREATE_GROUP,
  FETCH_ALL_GROUPS_BY_ORGANIZATION,
  FETCH_ALL_USERS_BY_ORGANIZATION_ID,
  REMOVE_GROUP,
} from '../../../api/admin';

import { ADMIN } from '../../../utils/constants/organizationRoles';
import c from '../../../utils/functions/c';

import AddEditGroupDialog from './AddEditGroupDialog';
import GroupDetail from './GroupDetail';

const Groups = ({ organizationId }) => {
  const t = useFormatMessage();

  useTitle(t('header.admin'));

  const { user } = useContext(UserContext);
  const { addNotification } = useContext(NotificationContext);

  const widths = [2, 1];
  const [activeGroup, setActiveGroup] = useState(null);
  const [addGroupDialog, toggleAddGroupDialog] = useState(false);

  const [undebouncedOrganizationVariables, setOrganizationVariables] = useState(
    { currentPage: 1 },
  );
  const organizationVariables = useDebounce(
    undebouncedOrganizationVariables,
    200,
  );
  const organizationQueryOptions = {
    variables: {
      organizationId,
      page: undebouncedOrganizationVariables?.currentPage - 1,
      size: 8,
    },
  };
  const { loading, error, data, refetch } = useQuery(
    FETCH_ALL_GROUPS_BY_ORGANIZATION,
    organizationQueryOptions,
  );
  const groups = data?.fetchAllGroupsByOrganizationId.content;
  const totalPages = data?.fetchAllGroupsByOrganizationId.pages;

  const onChangeOrganizationOptions = (variables) => {
    setOrganizationVariables({
      ...organizationVariables,
      currentPage: variables.currentPage || organizationVariables.currentPage,
    });
  };

  /* ---------- ORGANIZATION.USERS ---------- */

  const [allUsersVariables] = useState({ currentPage: 0 });
  const allUsersOptions = {
    variables: {
      organizationId,
      page: allUsersVariables.currentPage,
      size: 10,
    },
  };
  const [
    fetchOrganizationUsers,
    { loading: allUsersLoading, data: allUsersData },
  ] = useLazyQuery(FETCH_ALL_USERS_BY_ORGANIZATION_ID, allUsersOptions);
  const organizationUsers = allUsersData?.fetchAllUsersByOrganizationId.content;

  const [addGroupMutation] = useFieldMutation(CREATE_GROUP, 'Group');
  const addGroup = (variables) => {
    return new Promise((resolve) => {
      addGroupMutation(variables).then(async () => {
        addNotification({
          type: 'success',
          content: t('admin.groups.add-group.success'),
        });
        await refetch();
        resolve();
      });
    });
  };
  const [removeGroupMutation] = useRemoveMutation(
    REMOVE_GROUP,
    FETCH_ALL_GROUPS_BY_ORGANIZATION,
    organizationQueryOptions,
  );
  const removeGroup = (variables) => {
    return removeGroupMutation(variables).then(() => {
      addNotification({
        type: 'success',
        content: t('admin.groups.remove-group.success'),
      });
    });
  };

  return (
    <Content>
      {addGroupDialog && (
        <AddEditGroupDialog
          loading={loading || allUsersLoading}
          organizationId={organizationId}
          onSubmit={async (variables) => {
            await addGroup(variables);
            toggleAddGroupDialog(false);
          }}
          onDismiss={() => {
            toggleAddGroupDialog(false);
          }}
          users={organizationUsers}
          isNew={addGroupDialog}
        />
      )}
      <div className="relative flex flex-grow flex-col max-w-full">
        <Center>
          <PageTitle>{t('admin.groups')}</PageTitle>
        </Center>
        <div className="flex max-w-full">
          <div
            className={c(
              'transition-all ease-in-out duration-200',
              activeGroup ? 'w-2/5' : 'w-full',
            )}
          >
            <div
              className={c(
                'w-full flex items-end px-2',
                user.organizationRole === ADMIN
                  ? 'justify-between'
                  : 'justify-end',
              )}
            >
              {user.organizationRole === ADMIN && (
                <div className="-mb-3">
                  <BreadCrumbs root="admin" />
                </div>
              )}
              <div
                className={c(
                  'flex justify-end items-end transition-all ease-in-out duration-200',
                  activeGroup ? 'w-1/2' : 'w-3/4',
                )}
              >
                <FilledButton
                  iconBefore={FaPlus}
                  className="max-w-1/2 ml-2 text-sm truncate"
                  onClick={() => {
                    setActiveGroup(null);
                    fetchOrganizationUsers(organizationId);
                    toggleAddGroupDialog(true);
                  }}
                >
                  {t('admin.groups.add-group')}
                </FilledButton>
              </div>
            </div>
            <Table widths={widths} className="px-2">
              <TableRow>
                <TableHeaderCell>{t('admin.name')}</TableHeaderCell>
                <TableHeaderCell sortable={false} />
              </TableRow>
              {loading ? (
                <TableSkeleton widths={widths} />
              ) : error ? (
                <ErrorMessage error={error} />
              ) : groups && groups.length === 0 ? (
                <OverviewEmpty table />
              ) : (
                groups.map((group) => {
                  const { id, name } = group;

                  return (
                    <TableRow
                      key={id}
                      onClick={() => {
                        if (activeGroup?.id === id) {
                          setActiveGroup(null);
                        } else {
                          setActiveGroup(group);
                          fetchOrganizationUsers(organizationId);
                        }
                      }}
                      className={activeGroup?.id === id && 'bg-accent-200'}
                    >
                      <TableCell>{name}</TableCell>
                      <TableCell className="flex items-center justify-end">
                        <FaTrash
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveGroup(null);
                            removeGroup({ id });
                          }}
                          className="mr-2 hover:opacity-75"
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
            </Table>
          </div>
          {activeGroup && (
            <GroupDetail
              organizationId={organizationId}
              organizationUsers={organizationUsers}
              group={activeGroup}
            />
          )}
        </div>
        {totalPages > 1 && (
          <Center>
            <Pagination
              currentPage={undebouncedOrganizationVariables.currentPage}
              totalPages={totalPages}
              onClick={(val) =>
                onChangeOrganizationOptions({ currentPage: val })
              }
            />
          </Center>
        )}
      </div>
    </Content>
  );
};

export default Groups;
