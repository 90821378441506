import { useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { Editable } from '@ftrprf/tailwind-components';

import Editor from '../../../../components/Editor/Editor';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useSlide from '../../hooks/useSlide';

import c from '../../../../utils/functions/c';
import { isDraggingAtom, isHoveredAtom } from '../../utils/atom';

import Question from '../Question/Question';

const ContentEditorDetail = ({
  slideId,
  disabled,
  setShowCreateNewVersionDialog,
  content,
  onDuplicateSlide,
}) => {
  const t = useFormatMessage();
  const isDragging = useRecoilValue(isDraggingAtom);
  const [isHovered, setIsHovered] = useRecoilState(isHoveredAtom);

  const { slide, update } = useSlide(slideId);
  const [, setLocal] = useState('');

  const setValue = (value) => {
    update({ content: value });
    setLocal(value);
  };

  if (!slide) {
    return null;
  }

  const slideContainsQuestions = slide.questions?.length > 0;

  return (
    <div className="flex relative flex-col flex-grow bg-gray-50 overflow-y-none">
      <div className="h-16 px-4 flex items-center border-b border-gray-300 flex-shrink-0">
        <Editable
          contentEditable={!disabled}
          className={c(
            'text-bold text-xl p-1 rounded',
            !slide.title && 'text-gray-500 cursor-default',
          )}
          singleLine
          maxChars={99}
          onBlur={(title) => {
            update({ title });
          }}
          defaultValue={t('content-editor.title.placeholder')}
        >
          {slide.title || t('content-editor.title.placeholder')}
        </Editable>
      </div>
      <div className="bg-white flex-grow flex flex-col overflow-y-hidden">
        <div
          className={c(
            'flex flex-col flex-grow',
            slideContainsQuestions && 'h-112 flex-shrink-0',
          )}
          onClick={
            content.published && !content.archived
              ? () => setShowCreateNewVersionDialog(true)
              : undefined
          }
        >
          <Editor
            id={slideId}
            className="Editor__Main pb-3"
            withBorder={false}
            disabled={disabled}
            value={slide.content}
            onChange={setValue}
            isMainEditor={true}
          />
        </div>
        {slideContainsQuestions &&
          slide.questions.map((question) => (
            <Question
              question={question}
              disabled={disabled}
              onDuplicateSlide={() => onDuplicateSlide(slide)}
              key={question.id}
            />
          ))}
      </div>

      <div
        onMouseEnter={() => {
          if (isDragging) {
            setIsHovered(true);
          }
        }}
        onMouseLeave={() => {
          if (isDragging) {
            setIsHovered(false);
          }
        }}
        className={c(
          'h-5/6 w-full bottom-0 absolute bg-transparent z-100',
          isHovered && 'border-blue-400',
          isDragging ? 'visible' : 'invisible',
        )}
      />
    </div>
  );
};

export default ContentEditorDetail;
