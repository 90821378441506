import c from '../../utils/c';

import PasswordInput from './PasswordInput';

const commonClassnames = (disabled) => {
  return c(
    'outline-none appearance-none w-full px-2 py-1 border border-gray-300 placeholder-gray-500 rounded shadow-form',
    disabled && 'opacity-50 cursor-not-allowed',
  );
};

export const Input = ({ type, className, disabled, ...props }) => {
  if (type === 'multiline') {
    return (
      <textarea
        className={c(commonClassnames(disabled), className)}
        {...props}
        disabled={disabled}
      />
    );
  }

  if (type === 'password') {
    return (
      <PasswordInput
        className={c(commonClassnames(disabled), className)}
        {...props}
        disabled={disabled}
      />
    );
  }

  return (
    <input
      type={type}
      className={c(commonClassnames(disabled), className)}
      {...props}
      disabled={disabled}
    />
  );
};
