import c from '../../utils/c';

import SlideViewerTextSlide from './SlideViewerTextSlide';

const SlideViewerSlideThumbnail = ({
  className,
  currentSlide,
  fontSizeIndex,
}) => {
  return (
    <div
      className={c('SlideViewerTextSlide', className)}
      style={{ zoom: fontSizeIndex, color: 'black' }}
    >
      <h1>{currentSlide.title}</h1>
      <SlideViewerTextSlide value={currentSlide.content} />
    </div>
  );
};

export default SlideViewerSlideThumbnail;
