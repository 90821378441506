import useFormatMessage from '../../../hooks/useFormatMessage';

import { SlideViewerCloseButton } from './CloseButon';

const EmptySlides = ({ onClose }) => {
  const t = useFormatMessage();

  return (
    <div className="w-full h-full flex items-center justify-center relative">
      <div className="absolute top-5 right-5">
        <SlideViewerCloseButton onClose={onClose} />
      </div>

      {t('styled-slide-viewer.no_slides')}
    </div>
  );
};

export default EmptySlides;
