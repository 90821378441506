import { gql } from '@apollo/client';

export const REMOVE_HINT = gql`
  mutation RemoveHint($id: Int!) {
    removeHint(id: $id) {
      id
      statuscode
      message
    }
  }
`;

export const CREATE_HINT = gql`
  mutation CreateHint($slideId: Int!, $title: String, $content: String) {
    createHint(slideId: $slideId, input: { title: $title, content: $content }) {
      id
      title
      content
    }
  }
`;

export const UPDATE_HINT = gql`
  mutation UpdateHint($id: Int!, $title: String, $content: String) {
    updateHint(id: $id, input: { title: $title, content: $content }) {
      id
      title
      content
    }
  }
`;
