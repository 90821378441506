import useFormatMessage from '../../hooks/useFormatMessage';

import en from './translations/en';
import nl from './translations/nl';
import { InfoBar } from '../InfoBar/InfoBar';

const messages = [
  {
    regex: /^\/schoolteacherprofile\/exams$/,
    tranlationKey: 'demo-info-bar.exams',
  },
  {
    regex: /^\/schoolteacherprofile\/exercises$/,
    tranlationKey: 'demo-info-bar.exercises',
  },
  {
    regex: /\//,
    tranlationKey: 'demo-info-bar.general',
  },
];

export const DemoInfoBar = ({ path, container }) => {
  const t = useFormatMessage();
  const message = messages.find(({ regex }) => regex.test(path));

  if (!message) {
    return null;
  }

  return (
    <InfoBar
      container={container}
      text={t(message.tranlationKey)}
      className="max-w-screen-sm"
    />
  );
};

export const DemoInfoBarTranslations = {
  en,
  nl,
};
