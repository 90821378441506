import isCodeFever from '../utils/isCodeFever';

import { ReactComponent as LogoCodeFever } from '../assets/vectors/logo-codefever.svg';
import { ReactComponent as LogoFTRPRF } from '../assets/vectors/logo-FTRPRF.svg';

const Logo = ({ className }) => {
  if (isCodeFever) {
    return <LogoCodeFever className={className} />;
  }

  return <LogoFTRPRF className={className} />;
};

export default Logo;
