const updateSlideSequences = (slides) => {
  let i = 1;

  const slideSequences = [];

  const updatedSlides = slides.map((slide) => {
    slideSequences.push({ id: slide.id, sequence: i });

    return {
      ...slide,
      sequence: i++,
    };
  });

  return [slideSequences, updatedSlides];
};

export default updateSlideSequences;
