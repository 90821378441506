import { useLayoutEffect, useState } from 'react';
import { useQuery } from '@apollo/client';

import useStack from '../../hooks/useStack';

import { FIND_ALL_CONTENT_FILES_BY_PATH } from '../../api/files';

import { ALL_FILE_TYPES } from '../../utils/constants/fileTypes';
import defaultSort from '../../utils/functions/sortByFolderAlphabet';

import ListSelector from '../ListSelector';

import ContentListSelectorCenter from './ContentListSelectorCenter';
import ContentListSelectorTop from './ContentListSelectorTop';

const ContentListSelector = ({
  onFileDoubleClick,
  onFileClick,
  onFolderClick,
  onFolderDoubleClick,
  onNavigate,
  onExternalClick,
  allowedTypes,
  setCurrentPath: setCurrentPathOut,
}) => {
  const [currentPath, _setCurrentPath] = useState('/');
  const [content, setContent] = useState([]);
  const pathStack = useStack();

  const setCurrentPath = (path) => {
    _setCurrentPath(path);
    setCurrentPathOut(path);
  };

  const { data, loading, refetch } = useQuery(FIND_ALL_CONTENT_FILES_BY_PATH, {
    variables: { path: currentPath },
  });
  const contentData = data?.findAllContentFilesByPath;

  useLayoutEffect(() => {
    if (!loading) {
      onNavigate(currentPath);
      refetch();
      const allowedContent =
        allowedTypes.length > 0
          ? Object.values(contentData).filter(
              ({ extension, isFile }) =>
                allowedTypes.includes(extension) || !isFile,
            )
          : Object.values(contentData).filter(({ isFile }) => !isFile);
      setContent(defaultSort(allowedContent));
    }
  }, [currentPath, allowedTypes, onNavigate, loading, contentData, refetch]);

  return (
    <ListSelector
      isLoading={loading}
      isEmpty={content.length === 0}
      contentListTop={
        <ContentListSelectorTop
          pathStack={pathStack}
          currentPath={currentPath}
          onExternalClick={onExternalClick}
          setCurrentPath={setCurrentPath}
        />
      }
      contentListCenter={
        <ContentListSelectorCenter
          loading={loading}
          content={content}
          pathStack={pathStack}
          currentPath={currentPath}
          setCurrentPath={setCurrentPath}
          onFolderClick={onFolderClick}
          onFileClick={onFileClick}
          onFileDoubleClick={onFileDoubleClick}
          onFolderDoubleClick={onFolderDoubleClick}
        />
      }
    />
  );
};

ContentListSelector.defaultProps = {
  onFileClick: () => {},
  onFileDoubleClick: () => {},
  onFolderClick: () => {},
  onFolderDoubleClick: () => {},
  onNavigate: () => {},
  onExternalClick: () => {},
  allowedTypes: ALL_FILE_TYPES,
  setCurrentPath: () => {},
};

export default ContentListSelector;
