import times from '../../utils/functions/times';

import TableCell from './TableCell';
import TableRow from './TableRow';

// rows      the amount of skeleton rows
// columns   the amount of columns (equal width)
// widths    array of custom columns widths, overrides `column` prop
// ...props  all valid TableRow Props
const TableSkeleton = ({ rows, columns, widths, ...props }) => {
  const columnAmount = columns || widths.length;
  return times(rows, (i) => (
    <TableRow {...props} widths={widths} key={i}>
      {times(columnAmount, (j) => (
        <TableCell key={j}>
          <div className="bg-gray-100 animate-pulse h-6 w-full" />
        </TableCell>
      ))}
    </TableRow>
  ));
};

TableSkeleton.defaultProps = {
  rows: 20,
};

export default TableSkeleton;
