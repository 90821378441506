import { useContext, useEffect, useState } from 'react';
import { FaCloudUploadAlt } from 'react-icons/fa';

import { FileInput, FilledButton } from '@ftrprf/tailwind-components';

import ContentActionProvider, {
  ContentActionContext,
} from '../../providers/ContentActionProvider';

import useFormatMessage from '../../hooks/useFormatMessage';

import DragArea from '../DragArea';

const FileUpload = ({ setFileUri, accept }) => {
  const t = useFormatMessage();

  const { uploadFiles } = useContext(ContentActionContext);

  const [returnData, setReturnData] = useState();

  const onUploadFiles = (files) => {
    uploadFiles(files).then((data) => {
      setReturnData(data);
    });
  };

  useEffect(() => {
    if (returnData?.data?.createContentFiles?.length > 0) {
      setFileUri(returnData.data.createContentFiles[0].blobUri);
    }
  }, [returnData, setFileUri]);

  return (
    <div className="h-56">
      <DragArea setReturnData={setReturnData}>
        <div className="w-full border-2 border-dashed border-accent-200 flex flex-col content-center items-center justify-center">
          <div className="mb-5">{t('files.drop_file')}</div>
          <FileInput accept={accept && `${accept}/*`} onUpload={onUploadFiles}>
            {(onOpenFileDialog) => (
              <FilledButton onClick={onOpenFileDialog}>
                <FaCloudUploadAlt className="mr-2" />
                {t('global.upload')}
              </FilledButton>
            )}
          </FileInput>
        </div>
      </DragArea>
    </div>
  );
};

const FileUploadContainer = ({ path, setFileUri, accept, maxSize }) => (
  <ContentActionProvider path={path} accept={accept} maxSize={maxSize}>
    <FileUpload setFileUri={setFileUri} accept={accept} />
  </ContentActionProvider>
);

export default FileUploadContainer;
