/* eslint-disable complexity */
import { useEffect, useState } from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

import c from '../../../../utils/c';

import SlideViewerTextSlide from '../../../slideviewer/SlideViewerTextSlide';

const SlideViewerMultipleChoiceAnswerCard = ({
  answer,
  index,
  isClicked,
  hasResults,
  selectAnswer,
  disabled,
}) => {
  const [clicked, setClicked] = useState(isClicked);

  const clickAnswer = () => {
    if (!hasResults && !disabled) {
      setClicked(!clicked);
      selectAnswer();
    }
  };

  useEffect(() => {
    setClicked(isClicked);
  }, [isClicked]);

  return (
    <div className="SlideViewerMultipleChoiceAnswerCard flex flex-col flex-1">
      <div
        className={c(
          'rounded text-gray-100 flex items-center justify-center self-end -mb-3 w-8 h-8 font-bold text-lg z-10 mr-2',
          hasResults
            ? answer.correct
              ? 'bg-green-500'
              : 'bg-red-500'
            : 'bg-accent-300',
        )}
      >
        <p style={{ margin: '0' }}>
          {hasResults ? (
            answer.correct ? (
              <FaCheck />
            ) : (
              <FaTimes />
            )
          ) : (
            String.fromCharCode(index + 65)
          )}
        </p>
      </div>
      <button
        className={c(
          'appearence-none focus:outline-none flex flex-grow rounded p-4 border-solid border-2 border-transparent',
          disabled && 'cursor-not-allowed',
          hasResults && 'cursor-default divide-x',
          !hasResults && 'cursor-pointer',
          hasResults &&
            (clicked
              ? answer.correct
                ? 'border-green-500'
                : 'border-red-500'
              : 'shadow-base'),
          !hasResults && (clicked ? 'border-accent-300' : 'shadow-base'),
        )}
        onClick={clickAnswer}
      >
        <div
          className={c(
            'break-words flex-grow w-full overflow-hidden',
            hasResults && 'pr-4',
          )}
        >
          <SlideViewerTextSlide value={answer.value} />
        </div>
        {answer.explanation && hasResults && (
          <div
            className={c(
              'flex-grow overflow-hidden pl-4 border-gray-500 w-full ml-2 break-words ',
            )}
          >
            <SlideViewerTextSlide value={answer.explanation} />
          </div>
        )}
      </button>
    </div>
  );
};

export default SlideViewerMultipleChoiceAnswerCard;
