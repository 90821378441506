export const FILE_NAME_SORT_ASCENDING = 'FILE_NAME_SORT_ASCENDING';
export const FILE_NAME_SORT_DESCENDING = 'FILE_NAME_SORT_DESCENDING';

export const LAST_MODIFIED_ON_SORT_ASCENDING =
  'LAST_MODIFIED_ON_SORT_ASCENDING';
export const LAST_MODIFIED_ON_SORT_DESCENDING =
  'LAST_MODIFIED_ON_SORT_DESCENDING';

export const LAST_MODIFIED_BY_SORT_ASCENDING =
  'LAST_MODIFIED_BY_SORT_ASCENDING';
export const LAST_MODIFIED_BY_SORT_DESCENDING =
  'LAST_MODIFIED_BY_SORT_DESCENDING';

export const FILE_NAME = 'FILE_NAME';
export const LAST_MODIFIED_ON = 'LAST_MODIFIED_ON';
export const LAST_MODIFIED_BY = 'LAST_MODIFIED_BY';
