import { gql } from '@apollo/client';

export const USER_INFO = gql`
  query UserInfo {
    userInfo {
      id
      domain
      username
      firstName
      lastName
      organizationRole
      organization {
        id
      }
    }
  }
`;
