import { useState } from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogHeader,
  FilledButton,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import ContentListSelector from '../../../components/ContentListSelector/ContentListSelector';

import useFormatMessage from '../../../hooks/useFormatMessage';

const ContentActionDialog = ({
  onDismiss,
  onConfirm,
  confirmMessage,
  titleMessage,
  ...props
}) => {
  const t = useFormatMessage();
  const [destinationPath, setDestinationPath] = useState('/');

  return (
    <Dialog onDismiss={onDismiss} {...props}>
      <DialogHeader>{titleMessage}</DialogHeader>
      <DialogContent>
        <div className="bg-white h-64 w-full border-gray">
          <ContentListSelector
            onNavigate={(path) => setDestinationPath(path)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <OutlineButton disabled={!destinationPath} onClick={onDismiss}>
          {t('global.cancel')}
        </OutlineButton>
        <FilledButton
          disabled={!destinationPath}
          onClick={() => {
            onConfirm(destinationPath);
            setDestinationPath(null);
          }}
        >
          {confirmMessage}
        </FilledButton>
      </DialogActions>
    </Dialog>
  );
};

export default ContentActionDialog;
