import { useCallback, useEffect } from 'react';

import { FilledButton } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';
import useParams from '../../hooks/useParams';

import { CURRENT_DOMAIN_SETTINGS } from '../../utils/constants/domains';
import { AUTHORIZATION_TOKEN } from '../../utils/constants/localStorage';
import {
  REDIRECT_DOMAINS,
  REDIRECT_PROJECTS,
} from '../../utils/constants/redirectProjects';

const Logout = () => {
  const t = useFormatMessage();

  const { projects } = useParams();

  const logout = useCallback(() => {
    localStorage.removeItem(AUTHORIZATION_TOKEN);

    const filteredProjects = projects
      .split(',')
      .filter((p) => p !== REDIRECT_PROJECTS.STUDIO);

    window.location.href =
      filteredProjects.length === 0
        ? CURRENT_DOMAIN_SETTINGS.appUrl
        : `${
            REDIRECT_DOMAINS[filteredProjects[0]]
          }/logout?projects=${filteredProjects.join(',')}`;
  }, [projects]);

  useEffect(() => {
    logout();
  }, [logout]);

  return (
    <div className="flex justify-center items-center pt-10">
      <FilledButton onClick={logout}>{t('header.sign_out')}</FilledButton>
    </div>
  );
};

export default Logout;
