import isCodeFever from '../isCodeFever';

export const domains = {
  CODEFEVER: 'CODEFEVER',
  FTRPRF: 'FTRPRF',
};

export const domainSettings = {
  [domains.CODEFEVER]: {
    name: domains.CODEFEVER,
    apiUrl: process.env.REACT_APP_CODEFEVER_API_URI,
    appUrl: process.env.REACT_APP_CODEFEVER_APP_URI,
  },
  [domains.FTRPRF]: {
    name: domains.FTRPRF,
    apiUrl: process.env.REACT_APP_FTRPRF_API_URI,
    appUrl: process.env.REACT_APP_FTRPRF_APP_URI,
  },
};

export const CURRENT_DOMAIN_SETTINGS = isCodeFever
  ? domainSettings.CODEFEVER
  : domainSettings.FTRPRF;
