import en from './translations/en';
import nl from './translations/nl';

export * from './ErrorMessage';
export * from './SuccessMessage';

export const MessageTranslations = {
  en,
  nl,
};
