import ContentCardList from './ContentCardList';
import Pagination from './Pagination';

const MAX_ITEMS = 11;

const PaginatedContentCardList = ({
  items,
  itemRender,
  currentPage,
  totalPages,
  setCurrentPage,
}) => {
  return (
    <>
      <ContentCardList items={items}>
        {(props) => itemRender(props)}
      </ContentCardList>

      <div className="flex justify-center mt-12">
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          maxItems={MAX_ITEMS}
          onClick={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default PaginatedContentCardList;
