import {
  FaBook,
  FaChevronLeft,
  FaChevronRight,
  FaCog,
  FaInfoCircle,
  FaRobot,
  FaStream,
} from 'react-icons/fa';
import { Resizable } from 're-resizable';

import { useLocalStorage } from '@ftrprf/hooks';

import useFormatMessage from '../../../hooks/useFormatMessage';

import c from '../../../utils/c';

import { FilledButton } from '../../buttons/FilledButton';
import {
  SlideViewerLeftAction,
  SlideViewerProgress,
  SlideViewerRightAction,
  SlideViewerSlideNumber,
} from '../../slideviewer/SlideViewer';

import SideBarSettings from './SideBarSettings';
import SideBarSlidesOverview from './SideBarSlidesOverview';
import SideBarTeacherInfo from './SideBarTeacherInfo';
import Tabs from './Tabs';

const enable = {
  top: false,
  right: true,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

const standardWidth = 385;

const SideBar = ({
  currentSlide,
  lesson,
  feedbackUrl,
  showTeacherInfoTab,
  showSettingsTab,
  showSlidesOverviewTab,
  showSteamsTab,
  areAnswersSaved,
}) => {
  const t = useFormatMessage();
  const SteamsTab = showSteamsTab;

  const [sidebarSize, setSidebarSize] = useLocalStorage(
    'SLIDE_VIEWER_SIDEBAR_SIZE',
    {
      width: standardWidth,
    },
  );

  return (
    <Resizable
      onResizeStop={(e, direction, ref, d) => {
        setSidebarSize((s) => ({
          width: s.width + d.width,
        }));
      }}
      enable={enable}
      defaultSize={sidebarSize}
      minWidth={standardWidth}
      maxWidth="50%"
      className="shadow-base border-r border-gray-300 overflow-hidden flex flex-col self-stretch bg-white z-10"
    >
      <div className="flex justify-between pt-6 px-4">
        <div className="text-2xl font-bold leading-none">{lesson?.title}</div>
        <div className="flex flex-shrink-0 items-center text-2xl">
          <SlideViewerLeftAction className="text-2xl">
            {({ disabled }) => (
              <button>
                <FaChevronLeft
                  className={c(disabled && 'text-gray-300 cursor-not-allowed')}
                />
              </button>
            )}
          </SlideViewerLeftAction>
          <SlideViewerRightAction className="text-2xl">
            {({ disabled }) => (
              <button>
                <FaChevronRight
                  className={c(disabled && 'text-gray-300 cursor-not-allowed')}
                />
              </button>
            )}
          </SlideViewerRightAction>
        </div>
      </div>
      <SlideViewerProgress>
        {({ progress }) => (
          <div className="flex-shrink-0 mt-5 relative h-2 rounded-lg shadow-base mx-4">
            <div
              className="text-xs absolute bottom-0 mb-1 pb-px"
              style={{ left: `max(0px, calc(${progress}% - 17px))` }}
            >{`${progress}%`}</div>
            <div
              className="bg-black absolute h-2 rounded-lg "
              style={{ width: `${progress}%` }}
            />
          </div>
        )}
      </SlideViewerProgress>
      {currentSlide.question && !areAnswersSaved && (
        <div className="mt-4 mx-4 p-2 bg-accent-100 text-sm flex items-center rounded">
          <div>
            <FaInfoCircle className="text-accent-600 flex-shrink-0 mr-2" />
          </div>
          <div>{t('styled-slide-viewer.sidebar.questions_not_saved')}</div>
        </div>
      )}
      <Tabs
        containerClassName="flex flex-col overflow-hidden flex-grow"
        footerContainerClassName="flex border-t border-gray-300 px-4 pt-2 pb-1 justify-between items-center"
        labelsContainerClassName="flex"
        labelClassName="px-2 mr-4 pb-px border-b-2 border-transparent opacity-50"
        labelClassNameActive="px-2 pb-px mr-4 border-b-2 border-accent-500"
        footerExtra={
          <div className="flex items-center">
            <div className="mr-4">
              {feedbackUrl && (
                <div className="mb-2">
                  <a
                    href={feedbackUrl}
                    type="submit"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <FilledButton>
                      {t('styled-slide-viewer.settings.feedback')}
                    </FilledButton>
                  </a>
                </div>
              )}
            </div>
            <div className="text-xs">
              <SlideViewerSlideNumber />
            </div>
          </div>
        }
      >
        {showTeacherInfoTab && (
          <div
            label={
              <button aria-label={t('styled-slide-viewer.teacher_manual')}>
                <FaBook className="w-6 h-6" />
              </button>
            }
          >
            <SideBarTeacherInfo
              currentSlide={currentSlide}
              areAnswersSaved={areAnswersSaved}
            />
          </div>
        )}
        {showSlidesOverviewTab && (
          <div
            label={
              <button aria-label={t('styled-slide-viewer.slides_overview')}>
                <FaStream className="w-6 h-6" />
              </button>
            }
          >
            <SideBarSlidesOverview />
          </div>
        )}
        {showSettingsTab && (
          <div
            label={
              <button aria-label={t('styled-slide-viewer.settings')}>
                <FaCog className="w-6 h-6" />
              </button>
            }
          >
            <SideBarSettings />
          </div>
        )}
        {!!showSteamsTab && (
          <div
            label={
              <button aria-label={t('styled-slide-viewer.settings')}>
                <FaRobot className="w-6 h-6" />
              </button>
            }
          >
            <SteamsTab />
          </div>
        )}
      </Tabs>
    </Resizable>
  );
};

export default SideBar;
