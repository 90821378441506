import { VIEW_MODES } from '@ftrprf/tailwind-components';

import SLIDEMETHODS from '../../utils/constants/slideMethods';
import * as SLIDEPARTTYPES from '../../utils/constants/slidePartTypes';

import ContentEditorContainer from './ContentEditorContainer';

const LessonContainer = (props) => {
  const insertSlideBelow = ({
    sequence,
    content,
    insertSlideMutate,
    prevSlide,
    parameters = {},
  }) => {
    const [part, method, activityId] = !prevSlide
      ? [SLIDEPARTTYPES.THEORY, SLIDEMETHODS.CLASS, null]
      : [prevSlide.part, prevSlide.method, prevSlide.activityType?.id ?? null];

    const variables = {
      lessonContentId: content.id,
      sequence,
      content: '',
      title: '',
      part: part,
      method: method,
      activityTypeId: activityId,
      viewModes: [
        VIEW_MODES.CLASSICAL,
        VIEW_MODES.PROJECTION,
        VIEW_MODES.SELFSTUDY,
      ],
    };
    return insertSlideMutate({ ...variables, ...parameters });
  };

  return (
    <ContentEditorContainer insertSlideBelow={insertSlideBelow} {...props} />
  );
};

export default LessonContainer;
