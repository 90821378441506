import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router';
import { useQuery } from '@apollo/client';

import useFormatMessage from '../../hooks/useFormatMessage';
import useParams from '../../hooks/useParams';

import { FIND_SCRATCH_EXERCISE_VERSION } from '../../api/exercise';

import { EXERCISE } from '../../utils/constants/urls';

const ExerciseVersionContainer = () => {
  const { versionId } = useParams();

  const t = useFormatMessage();

  const history = useHistory();

  const { loading, error, data } = useQuery(FIND_SCRATCH_EXERCISE_VERSION, {
    variables: { versionId: Number(versionId) },
  });

  let hasError = useRef();

  useEffect(() => {
    if (!loading) {
      if (error && error.message?.includes('Exception: ForbiddenException')) {
        // Ref is necessary to keep the variable outside of the useEffect hook
        hasError.current = true;
      } else {
        history.push(
          `${EXERCISE}/${data?.findScratchExerciseVersion?.exerciseId}`,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading, error, history]);

  if (hasError.current) {
    return (
      <div className="flex flex-col items-center justify-center w-full h-full">
        {t('exercise.forbidden_error')}
      </div>
    );
  }
  return null;
};

export default ExerciseVersionContainer;
