import { useContext } from 'react';

import { NotificationContext } from '@ftrprf/tailwind-components';

import { COPY_LESSON_CONTENT } from '../../api/content';

import useFormatMessage from '../useFormatMessage';

import useFieldMutation from './useFieldMutation';

const useCopyContentMutation = () => {
  const t = useFormatMessage();
  const [copyMutate] = useFieldMutation(COPY_LESSON_CONTENT, 'LessonContent');
  const { addNotification } = useContext(NotificationContext);

  const copy = (id) => {
    return copyMutate({ id: id }).then(({ type }) => {
      addNotification({
        type: 'success',
        content: t(`content-overview.${type}.copy.success`),
      });
    });
  };

  return copy;
};

export default useCopyContentMutation;
