import { useState } from 'react';

const useConfirmationDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogOptions, setDialogOptions] = useState();
  const [actionCallback, setActionCallback] = useState({});

  const resetDialog = () => {
    setIsDialogOpen(false);
    setDialogOptions();
    setActionCallback({});
  };

  const onConfirm = () => {
    actionCallback.resolve(true);
    resetDialog();
  };

  const onDismiss = () => {
    actionCallback.resolve(false);
    resetDialog();
  };

  const getConfirmation = (options) =>
    new Promise((resolve) => {
      setDialogOptions(options);
      setActionCallback({ resolve });
      setIsDialogOpen(true);
    });

  return { getConfirmation, isDialogOpen, dialogOptions, onConfirm, onDismiss };
};

export default useConfirmationDialog;
