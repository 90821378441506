export const useFileDownload = (mode) => {
  const forceDownload = (blob, filename) => {
    var a = document.createElement('a');
    a.download = filename;
    a.href = blob;
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  const downloadResource = (url, filename) => {
    if (!filename) filename = url.split('\\').pop().split('/').pop();
    fetch(url, {
      headers: new Headers({
        Origin: location.origin,
      }),
      mode,
    })
      .then((response) => response.blob())
      .then((blob) => {
        let blobUrl = window.URL.createObjectURL(blob);
        forceDownload(blobUrl, filename);
      });
  };

  return downloadResource;
};

useFileDownload.defaultProps = {
  mode: 'cors',
};
