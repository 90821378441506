import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';
import Tree, { moveItemOnTree } from '@atlaskit/tree';

import DragArea from '../../../components/DragArea';
import OverviewEmpty, {
  EmptyExtraInfo,
} from '../../../components/OverviewEmpty';

import { ContentActionContext } from '../../../providers/ContentActionProvider';

import useFormatMessage from '../../../hooks/useFormatMessage';

import { FILE_EXTENSIONS } from '../../../utils/constants/fileTypes';
import {
  FILE_NAME,
  FILE_NAME_SORT_ASCENDING,
  FILE_NAME_SORT_DESCENDING,
  LAST_MODIFIED_BY,
  LAST_MODIFIED_BY_SORT_ASCENDING,
  LAST_MODIFIED_BY_SORT_DESCENDING,
  LAST_MODIFIED_ON,
  LAST_MODIFIED_ON_SORT_ASCENDING,
  LAST_MODIFIED_ON_SORT_DESCENDING,
} from '../../../utils/constants/tableSort';
import c from '../../../utils/functions/c';

import { ReactComponent as EmptyContent } from '../../../assets/vectors/empty-content.svg';

import Arrows from './Arrows';
import ContentItem from './ContentItem';

const initialState = {
  fileNameSort: 1,
  lastModifiedOnSort: 0,
  lastModifiedBySort: 0,
};

const reducer = (state, action) => {
  switch (action.type) {
    case FILE_NAME_SORT_ASCENDING:
      return { fileNameSort: 1, lastModifiedOnSort: 0, lastModifiedBySort: 0 };
    case FILE_NAME_SORT_DESCENDING:
      return { fileNameSort: 2, lastModifiedOnSort: 0, lastModifiedBySort: 0 };
    case LAST_MODIFIED_ON_SORT_ASCENDING:
      return { fileNameSort: 0, lastModifiedOnSort: 1, lastModifiedBySort: 0 };
    case LAST_MODIFIED_ON_SORT_DESCENDING:
      return { fileNameSort: 0, lastModifiedOnSort: 2, lastModifiedBySort: 0 };
    case LAST_MODIFIED_BY_SORT_ASCENDING:
      return { fileNameSort: 0, lastModifiedOnSort: 0, lastModifiedBySort: 1 };
    case LAST_MODIFIED_BY_SORT_DESCENDING:
      return { fileNameSort: 0, lastModifiedOnSort: 0, lastModifiedBySort: 2 };
    default:
      return { fileNameSort: 0, lastModifiedOnSort: 0, lastModifiedBySort: 0 };
  }
};

const ContentTree = ({
  isHovered,
  setIsDragging,
  setQueryPath,
  queryPath,
  root,
  className,
  fileTree,
  setFileTree,
  sortTable,
}) => {
  const t = useFormatMessage();
  const { deselectAll, selectedItems, uploadFile, moveFiles } =
    useContext(ContentActionContext);

  const [state, dispatch] = useReducer(reducer, initialState);

  const [editorAPI, setEditorAPI] = useState(null);
  const [dragItem, setDragItem] = useState({});

  useEffect(() => {
    try {
      if (window.CKEDITOR.instances?.editor1) {
        setTimeout(() => {
          setEditorAPI(window.CKEDITOR.instances?.editor1);
        }, 210);
      }
    } catch (error) {
      return false;
    }
    return false;
  }, []);

  const isEmpty = Object.values(fileTree.items)?.length === 1;

  const isSideBarManager = !!queryPath;

  const allowedImageTypes = FILE_EXTENSIONS.IMAGE.extensions;
  const allowedVideoTypes = FILE_EXTENSIONS.VIDEO.extensions;

  const renderItem = ({ item, provided }) => {
    return (
      <ContentItem
        setQueryPath={setQueryPath}
        queryPath={queryPath}
        root={root}
        item={item}
        provided={provided}
      />
    );
  };

  const insertImageHtml = useCallback(
    (url) => {
      editorAPI.insertHtml(`<img src="${url}" width="100%" alt=""/>`);
    },
    [editorAPI],
  );

  const insertVideoHtml = useCallback(
    (url) => {
      editorAPI.insertHtml(
        `<video src="${url}" alt="" width="100%" controls controlsList="nodownload"/>`,
      );
    },
    [editorAPI],
  );

  const insertFileToEditor = useCallback(
    (url, extension) => {
      if (isSideBarManager && editorAPI) {
        if (allowedImageTypes.includes(extension)) {
          insertImageHtml(url);
        } else if (allowedVideoTypes.includes(extension)) {
          insertVideoHtml(url);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [editorAPI],
  );

  const onDragStart = (result) => {
    if (isSideBarManager) {
      setIsDragging(true);
      setDragItem(fileTree.items[result]);
    }

    // multidrag is not supported as of yet
    if (selectedItems.length > 1) {
      deselectAll();
    }
  };

  const onDragEnd = (source, destination) => {
    if (isSideBarManager) {
      setIsDragging(false);
      const {
        data: { isFile, extension, blobUri: url },
      } = dragItem;

      if (!destination && isFile && isHovered) {
        insertFileToEditor(url, extension);
      }
    }

    const destinationIsFile =
      fileTree.items[destination?.parentId]?.data?.isFile;

    // Do nothing if parents are the same: order of the folder should not change
    if (
      !destination ||
      source.parentId === destination.parentId ||
      destinationIsFile
    ) {
      return;
    }

    // move files in the tree
    const newTree = moveItemOnTree(fileTree, source, destination);
    setFileTree(newTree);

    // get path of parent and child and combine to new location
    const parentPath = fileTree.items[destination.parentId]?.data.path;
    const {
      data: { path: childPath },
      id,
    } =
      fileTree.items[
        Object.values(fileTree.items).find(
          ({ index }) => source.index === index,
        ).id
      ];
    moveFiles(id, parentPath, childPath);
  };

  const sortItems = (type) => {
    switch (type) {
      case FILE_NAME:
        if (state.fileNameSort === 1) {
          dispatch({ type: FILE_NAME_SORT_DESCENDING });
          sortTable(FILE_NAME_SORT_DESCENDING);
        } else {
          dispatch({ type: FILE_NAME_SORT_ASCENDING });
          sortTable(FILE_NAME_SORT_ASCENDING);
        }
        break;
      case LAST_MODIFIED_ON:
        if (state.lastModifiedOnSort === 1) {
          dispatch({ type: LAST_MODIFIED_ON_SORT_DESCENDING });
          sortTable(LAST_MODIFIED_ON_SORT_DESCENDING);
        } else {
          dispatch({ type: LAST_MODIFIED_ON_SORT_ASCENDING });
          sortTable(LAST_MODIFIED_ON_SORT_ASCENDING);
        }
        break;
      case LAST_MODIFIED_BY:
        if (state.lastModifiedBySort === 1) {
          dispatch({ type: LAST_MODIFIED_BY_SORT_DESCENDING });
          sortTable(LAST_MODIFIED_BY_SORT_DESCENDING);
        } else {
          dispatch({ type: LAST_MODIFIED_BY_SORT_ASCENDING });
          sortTable(LAST_MODIFIED_BY_SORT_ASCENDING);
        }
        break;
      default:
        dispatch({ type: 'default' });
        sortTable(FILE_NAME_SORT_ASCENDING);
    }
  };

  return (
    <div className={c('flex flex-col flex-grow', className)}>
      <div className="p-2 flex items-center uppercase text-xs leading-4 tracking-wide rounded-t text-gray-600 bg-gray-200">
        <div
          className="w-2/6 mr-2 ml-6 pt-px truncate flex items-center cursor-pointer"
          onClick={() => sortItems(FILE_NAME)}
        >
          {t('content-manager.preview.path')}
          <Arrows status={state.fileNameSort} />
        </div>
        {!isSideBarManager && (
          <>
            <div
              className="w-1/6 mr-2 pt-px truncate flex items-center cursor-pointer"
              onClick={() => sortItems(LAST_MODIFIED_ON)}
            >
              {t('content-manager.preview.modifiedOn')}
              <Arrows status={state.lastModifiedOnSort} />
            </div>
            <div
              className="w-2/6 mr-2 pt-px truncate flex items-center cursor-pointer"
              onClick={() => sortItems(LAST_MODIFIED_BY)}
            >
              {t('content-manager.preview.modifiedBy')}
              <Arrows status={state.lastModifiedBySort} />
            </div>
            <div className="w-1/6 mr-2 pt-px truncate flex items-center">
              {t('content-manager.preview.size')}
            </div>
          </>
        )}
      </div>
      {isEmpty ? (
        <DragArea uploadFile={uploadFile}>
          <OverviewEmpty table BackgroundImage={EmptyContent}>
            <EmptyExtraInfo>{t('files.drop_file')}</EmptyExtraInfo>
          </OverviewEmpty>
        </DragArea>
      ) : (
        <DragArea uploadFile={uploadFile}>
          <div className="overflow-auto justify-between flex flex-col flex-grow ContentTree">
            <Tree
              tree={fileTree}
              renderItem={renderItem}
              onDragStart={onDragStart}
              onDragEnd={onDragEnd}
              isDragEnabled
              isNestingEnabled
            />
          </div>
        </DragArea>
      )}
    </div>
  );
};

export default memo(ContentTree);
