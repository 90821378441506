import c from '../../../../utils/functions/c';

import SideBarGoBack from './SideBarGoBack';

const SideBarContent = ({ className, children, title, withGoBack }) => {
  return (
    <div className={c(className, 'flex flex-col flex-start flex-grow')}>
      {withGoBack && <SideBarGoBack title={title} />}
      <div className="flex flex-col flex-start flex-grow">{children}</div>
    </div>
  );
};

SideBarContent.defaultProps = {
  withGoBack: true,
};

export default SideBarContent;
