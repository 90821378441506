import FullpageContent from '../../../components/Content/FullpageContent';

const ContentEditorContainer = () => {
  return (
    <FullpageContent className="flex flex-col h-full">
      <div className="border-b border-gray-300 flex items-center">
        <div className="my-2 mx-4 h-12 flex items-center justify-between w-full">
          <div className="h-8 w-32 bg-gray-200 animate-pulse" />
          <div className="h-8 w-88 bg-gray-200 animate-pulse" />
          <div className="flex">
            <div className="h-8 w-32 mr-6 bg-gray-200 animate-pulse" />
            <div className="h-8 w-32 mr-6 bg-gray-200 animate-pulse" />
            <div className="h-8 w-32 mr-6 bg-gray-200 animate-pulse" />
          </div>
        </div>
      </div>
      <div className="h-16 border-b border-gray-300 bg-gray-100 flex items-center flex-shrink-0">
        <div className="w-64 flex justify-center px-4">
          <div className="h-8 w-full bg-gray-200 animate-pulse" />
        </div>
      </div>
      <div className="w-full flex-grow flex justify-between">
        <div className="w-64 flex-grow border-r border-gray-300 overflow-hidden flex-shrink-0">
          <div className="m-4 bg-gray-200 animate-pulse">
            <div className="h-12" />
            <div className="h-28" />
            <div className="h-10" />
          </div>
          <div className="m-4 bg-gray-200 animate-pulse">
            <div className="h-12" />
            <div className="h-28" />
            <div className="h-10" />
          </div>
          <div className="m-4 bg-gray-200 animate-pulse">
            <div className="h-12" />
            <div className="h-28" />
            <div className="h-10" />
          </div>
          <div className="m-4 bg-gray-200 animate-pulse">
            <div className="h-12" />
            <div className="h-28" />
            <div className="h-10" />
          </div>
          <div className="m-4 bg-gray-200 animate-pulse">
            <div className="h-12" />
            <div className="h-28" />
            <div className="h-10" />
          </div>
          <div className="m-4 bg-gray-200 animate-pulse">
            <div className="h-12" />
            <div className="h-28" />
            <div className="h-10" />
          </div>
        </div>
        <div className="w-full flex flex-col">
          <div className="border-b border-gray-300">
            <div className="h-28 bg-gray-200 animate-pulse m-4" />
          </div>
          <div className="flex-grow flex flex-col">
            <div className="bg-gray-200 animate-pulse m-4 flex-grow" />
          </div>
        </div>
        <div className="w-88 flex-grow border-l border-gray-300 flex-shrink-0 flex flex-col">
          <div className="m-4 flex-grow animate-pulse bg-gray-200" />
        </div>
      </div>
    </FullpageContent>
  );
};

export default ContentEditorContainer;
