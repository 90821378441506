import { useState } from 'react';

import { CheckBox } from '@ftrprf/tailwind-components';

import c from '../utils/functions/c';

const Selection = ({ className, isChecked, onCheck, onUncheck }) => {
  const [isHovered, setIsHovered] = useState(false);
  const toggle = () => {
    if (isChecked) {
      onUncheck();
    } else {
      onCheck();
    }
  };

  return (
    <div
      type="selection"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        toggle();
      }}
      className={c(
        className,
        'self-stretch flex items-center text-center transition-all duration-300 ease-in-out hover:w-8 cursor-pointer',
        isChecked ? 'bg-accent-200 w-8' : 'bg-gray-300',
        isHovered && !isChecked ? 'w-8' : 'w-4',
      )}
    >
      {(isChecked || isHovered) && (
        <div
          className="flex-grow cursor-pointer flex justify-center"
          type="selection"
        >
          <CheckBox className="cursor-pointer" checked={isChecked} inverted />
        </div>
      )}
    </div>
  );
};

export default Selection;
