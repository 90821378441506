import { useMutation } from '@apollo/client';

import { UPDATE_SLIDE_SEQUENCES } from '../../../api/slide';

const useUpdateSlideSequencesMutation = (content) => {
  const update = (slideSequences) => (cache) => {
    slideSequences.forEach(({ id, sequence }) => {
      cache.modify({
        id: cache.identify({
          id,
          __typename: 'Slide',
        }),
        fields: {
          sequence() {
            return sequence;
          },
        },
      });
    });
  };

  const [mutate, result] = useMutation(UPDATE_SLIDE_SEQUENCES);

  return [
    (slideSequences) => {
      return mutate({
        update: update(slideSequences),
        variables: {
          lessonContentId: content.id,
          slideSequences,
        },
        optimisticResponse: {
          updateSlideSequences: {
            id: content.id,
            __typename: 'LessonContent',
          },
        },
      });
    },
    result,
  ];
};

export default useUpdateSlideSequencesMutation;
