import { useContext } from 'react';
import { Form, Formik } from 'formik';
import { object, string } from 'yup';

import {
  FilledButton,
  LanguagePicker,
  OutlineButton,
} from '@ftrprf/tailwind-components';

import FormikInput from '../../components/Form/FormikInput';
import Logo from '../../components/Logo';

import {
  LanguageContext,
  supportedLanguages,
} from '../../providers/LanguageProvider';

import useFormatMessage from '../../hooks/useFormatMessage';

import {
  CURRENT_DOMAIN_SETTINGS,
  domains,
} from '../../utils/constants/domains';
import isCodeFever from '../../utils/isCodeFever';

import { ReactComponent as Illustration } from '../../assets/vectors/login-illustration.svg';

const OrDivider = ({ t }) => (
  <div className="my-12 h-6 flex items-center justify-center">
    <div className="w-full ">
      <div className="h-px bg-gray-500" />
    </div>
    <div className="px-3 text-gray-700">{t('login.or')}</div>
    <div className="w-full ">
      <div className="h-px bg-gray-500" />
    </div>
  </div>
);

const Login = ({ onSubmit, error, loading }) => {
  const t = useFormatMessage();
  const { setLanguage } = useContext(LanguageContext);

  const languages = supportedLanguages.map((key) => ({
    key,
    label: t(`languages.${key}`),
  }));

  const redirect = () => {
    window.location = isCodeFever
      ? window.location.href.replace('codefever', 'ftrprf')
      : window.location.href.replace('ftrprf', 'codefever');
  };

  return (
    <div className="max-w-screen-xl mx-auto p-10 h-full flex flex-col justify-between">
      <div className="w-full flex justify-between mb-5">
        <Logo className="h-12" />

        <LanguagePicker
          className="h-12"
          languages={languages}
          onLanguageChange={setLanguage}
        />
      </div>
      <div className="flex flex-col-reverse md:flex-row justify-end md:justify-center items-stretch md:items-center flex-1">
        <div className="flex-1 -mt-10 px-10 md:py-10">
          <Illustration className="w-full" />
        </div>
        <div className="flex-1 max-w-md mb-4 self-center">
          <h1 className="text-4xl font-bold">{t('login.title')}</h1>
          <p className="mb-12">
            {t(`login.welcome_message.${CURRENT_DOMAIN_SETTINGS.name}`)}
          </p>
          {error && <p className="text-red-500">{t(error)}</p>}
          <Formik
            initialValues={{
              username: '',
              password: '',
            }}
            validationSchema={object({
              username: string().required('validation.required'),
              password: string().required('validation.required'),
            })}
            onSubmit={(values) =>
              onSubmit(CURRENT_DOMAIN_SETTINGS.name, values)
            }
          >
            {({ isValid }) => (
              <Form className="">
                <FormikInput
                  type="text"
                  name="username"
                  autoComplete="username"
                  label={t('global.username')}
                />
                <FormikInput
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  label={t('login.password')}
                />
                <FilledButton
                  type="submit"
                  loading={loading}
                  disabled={!isValid}
                  className="w-full my-5 justify-center"
                >
                  {t('login.login')}
                </FilledButton>
              </Form>
            )}
          </Formik>
          <OrDivider t={t} />
          <OutlineButton onClick={redirect} className="w-full justify-center">
            {t(
              `login.switch_to.${
                isCodeFever ? domains.FTRPRF : domains.CODEFEVER
              }`,
            )}
          </OutlineButton>
        </div>
      </div>
    </div>
  );
};

export default Login;
