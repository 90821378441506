import { useState } from 'react';

export const useLocalStorage = (storageKey, initialState) => {
  const [state, setState] = useState(() => {
    const item = window.localStorage.getItem(storageKey);

    return item ? JSON.parse(item) : initialState;
  });

  const setLocalStorageState = (valueOrFunction) => {
    const newValue =
      valueOrFunction instanceof Function
        ? valueOrFunction(state)
        : valueOrFunction;

    setState(newValue);

    window.localStorage.setItem(storageKey, JSON.stringify(newValue));
  };

  return [state, setLocalStorageState];
};
