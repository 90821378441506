import { FilledButton } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../../hooks/useFormatMessage';
import useSlide from '../../../hooks/useSlide';

import SideBarContent from '../SideBarContent';

import HintsSideBarItem from './HintsSideBarItem';

const HintsSideBar = ({ disabled, id }) => {
  const t = useFormatMessage();
  const { slide, createHint, removeHint } = useSlide(id);

  if (!slide) {
    return null;
  }

  return (
    <SideBarContent className="overflow-auto" title={t('hints-sidebar.title')}>
      {slide.hints.map((hint, index) => (
        <HintsSideBarItem
          key={hint.id}
          id={hint.id}
          index={index}
          removeHint={removeHint}
          disabled={disabled}
        />
      ))}
      <div className="flex justify-center my-3">
        <FilledButton secondary onClick={createHint} disabled={disabled}>
          {t('hints-sidebar.add_hint')}
        </FilledButton>
      </div>
    </SideBarContent>
  );
};

export default HintsSideBar;
