import { gql } from '@apollo/client';

export const FIND_ALL_CONTENT_FILES_BY_PATH = gql`
  query FindAllContentFilesByPath($path: String) {
    findAllContentFilesByPath(path: $path) {
      id
      path
      isFile
      extension
      fileSize
      organizationId
      blobUri
      isDeleted
      createdOn
      createdBy
      modifiedOn
      modifiedBy
    }
  }
`;

export const FIND_CONTENT_NAMES_BY_PATH = gql`
  query FindContentNamesByPath($path: String) {
    findAllContentFilesByPath(path: $path) {
      path
    }
  }
`;

export const FIND_CONTENT_FILE = gql`
  query FindContentFile($id: Int) {
    findContentFile(id: $id) {
      id
      path
      isFile
      extension
      fileSize
      organizationId
      blobUri
      isDeleted
      createdOn
      createdBy
      modifiedOn
      modifiedBy
    }
  }
`;

export const CREATE_CONTENT_FILES = gql`
  mutation CreateContentFiles($files: [Upload], $paths: [ContentFileInput]) {
    createContentFiles(files: $files, input: $paths) {
      id
      blobUri
    }
  }
`;

export const UPDATE_CONTENT_FILE = gql`
  mutation UpdateContentFile($id: ID!, $path: String!) {
    updateContentFile(id: $id, path: $path) {
      id
    }
  }
`;

export const REMOVE_CONTENT_FILE = gql`
  mutation RemoveContentfile($id: ID!) {
    removeContentFile(id: $id) {
      statuscode
      id
      message
    }
  }
`;
