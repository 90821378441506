import { useEffect, useState } from 'react';
import { Redirect } from 'react-router';

import useFormatMessage from '../../hooks/useFormatMessage';

import { AUTHORIZATION_TOKEN } from '../../utils/constants/localStorage';

const states = {
  INITIAL: 'initial',
  LOADING: 'loading',
  DONE: 'done',
};

const CallbackContainer = () => {
  const t = useFormatMessage();

  const [error, setError] = useState();
  const [state, setState] = useState(states.INITIAL);

  const { token, domain, redirect } = Object.fromEntries([
    ...new URLSearchParams(window.location.search.substring(1)).entries(),
  ]);

  useEffect(() => {
    if (state === states.INITIAL) {
      setState(states.LOADING);

      fetch(`${process.env.REACT_APP_STUDIO_API_URI}/authenticate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify({
          domain,
          token: token,
        }),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(res);
          }

          return res.json();
        })
        .then(({ token }) => {
          localStorage.setItem(AUTHORIZATION_TOKEN, token);

          setState(states.DONE);
        })
        .catch((err) => {
          // eslint-disable-next-line
          console.error(err);

          setError(true);
        });
    }
  }, [state, domain, token]);

  if (error) {
    return <div>{t('errors.something_went_wrong')}</div>;
  }

  if (state === states.DONE) {
    return <Redirect to={redirect} />;
  }

  return null;
};

export default CallbackContainer;
