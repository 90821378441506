import { Children, cloneElement } from 'react';
import { IconContext } from 'react-icons';

import throwIfDevelopment from '../utils/functions/throwIfDevelopment';

const StyledIcon = ({ className, icon: Icon, children, style, ...props }) => {
  throwIfDevelopment(
    children && Icon,
    `StyledIcon cannot render both an icon passed in props and children. 
  Choose one of the two options to render an icon`,
  );

  return (
    <IconContext.Provider value={{ className: className }}>
      {!!children ? (
        Children.map(children, (child) =>
          cloneElement(child, {
            ...props,
          }),
        )
      ) : (
        <Icon style={style} {...props} />
      )}
    </IconContext.Provider>
  );
};

export default StyledIcon;
