import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

import c from '../../utils/c';

export const Actions = ({ items }) => {
  return (
    <span
      className="relative inline-flex shadow-sm rounded-md"
      onClick={(e) => e.stopPropagation()}
    >
      <button
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          items[0].onClick(e);
        }}
        className={c(
          'relative inline-flex items-center px-4 py-2 border border-gray-300 border-solid bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-accent-500 focus:border-accent-500',
          items.length > 1 ? 'rounded-l-md' : 'rounded',
        )}
      >
        {items[0].icon} {items[0].label}
      </button>
      {items.length > 1 && (
        <Menu as="span" className="-ml-px relative block">
          <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 border-solid bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-accent-500 focus:border-accent-500 border-l">
            <span className="sr-only">Open options</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-right absolute right-0 mt-2 -mr-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-20">
              <div className="py-1">
                {items.map((item) => (
                  <Menu.Item key={item.label}>
                    {({ active }) => (
                      <button
                        type="button"
                        onClick={(e) => {
                          e.stopPropagation();
                          item.onClick(e);
                        }}
                        className={c(
                          active
                            ? 'bg-gray-100 text-gray-900'
                            : 'text-gray-700',
                          'block px-4 py-2 text-sm text-left w-full box-border',
                        )}
                      >
                        {item.icon} {item.label}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </span>
  );
};
