export default [
  {
    id: '665',
    path: '/Lesson documents',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-07-30 07:50:40.0',
    createdBy: 'maarten@codefever.be',
    modifiedOn: '2019-07-30 07:50:40.0',
    modifiedBy: 'maarten@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '759',
    path: '/FTRPRF',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-10-07 15:28:40.0',
    createdBy: 'fien@codefever.be',
    modifiedOn: '2019-10-07 15:28:40.0',
    modifiedBy: 'fien@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '1802',
    path: '/IMG_Lessons',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-07-30 07:37:16.0',
    createdBy: 'maarten@codefever.be',
    modifiedOn: '2019-07-30 07:37:16.0',
    modifiedBy: 'maarten@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '1860',
    path: '/BlocksMicrobit',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-07-30 07:37:17.0',
    createdBy: 'maarten@codefever.be',
    modifiedOn: '2019-07-30 07:37:17.0',
    modifiedBy: 'maarten@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '3353',
    path: '/CodeFeverWebsite',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-07-26 11:49:47.0',
    createdBy: 'jonathan@codefever.be',
    modifiedOn: '2019-07-26 11:49:47.0',
    modifiedBy: 'jonathan@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '3779',
    path: '/Te sorteren',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-10-07 15:52:49.0',
    createdBy: 'fien@codefever.be',
    modifiedOn: '2019-10-07 15:52:49.0',
    modifiedBy: 'fien@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '4605',
    path: '/Geen idee',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-10-07 15:01:13.0',
    createdBy: 'fien@codefever.be',
    modifiedOn: '2019-10-07 15:01:13.0',
    modifiedBy: 'fien@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '5148',
    path: '/External editors',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-10-29 15:27:30.0',
    createdBy: 'fien@codefever.be',
    modifiedOn: '2019-10-29 15:27:30.0',
    modifiedBy: 'fien@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '5251',
    path: '/Movies',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-07-30 07:20:08.0',
    createdBy: 'maarten@codefever.be',
    modifiedOn: '2019-07-30 07:20:08.0',
    modifiedBy: 'maarten@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '6637',
    path: '/Bought images - no avatars',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-07-30 07:50:44.0',
    createdBy: 'maarten@codefever.be',
    modifiedOn: '2020-02-05 13:31:06.0',
    modifiedBy: 'fien@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '7046',
    path: '/snelle fix filmpjes',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2020-03-28 13:27:28.0',
    createdBy: 'elisa@codefever.be',
    modifiedOn: '2020-03-28 13:27:28.0',
    modifiedBy: 'elisa@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '7219',
    path: '/PresentatieTijl',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2020-06-14 15:55:25.0',
    createdBy: 'tijl@codefever.be',
    modifiedOn: '2020-06-14 15:55:25.0',
    modifiedBy: 'tijl@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '7482',
    path: '/General',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-07-30 07:50:44.0',
    createdBy: 'maarten@codefever.be',
    modifiedOn: '2019-07-30 07:50:44.0',
    modifiedBy: 'maarten@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '7728',
    path: '/ScriptsScratch',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-07-30 07:50:44.0',
    createdBy: 'maarten@codefever.be',
    modifiedOn: '2019-07-30 07:50:44.0',
    modifiedBy: 'maarten@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '8376',
    path: '/Prullenbak',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2019-07-23 12:55:37.0',
    createdBy: 'maarten@codefever.be',
    modifiedOn: '2019-07-23 12:55:37.0',
    modifiedBy: 'maarten@codefever.be',
    __typename: 'ContentFile',
  },
  {
    id: '8917',
    path: '/test2',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2020-09-17 11:29:48.0',
    createdBy: 'ingrid',
    modifiedOn: '2020-09-17 11:29:48.0',
    modifiedBy: 'ingrid',
    __typename: 'ContentFile',
  },
  {
    id: '8929',
    path: '/dfdf',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2020-10-01 10:12:33.0',
    createdBy: 'ingrid',
    modifiedOn: '2020-10-01 10:12:33.0',
    modifiedBy: 'ingrid',
    __typename: 'ContentFile',
  },
  {
    id: '8932',
    path: '/test',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2020-10-13 11:48:22.0',
    createdBy: 'ingrid',
    modifiedOn: '2020-10-13 11:48:22.0',
    modifiedBy: 'ingrid',
    __typename: 'ContentFile',
  },
  {
    id: '8933',
    path: '/dfdfdf',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2020-10-13 12:05:27.0',
    createdBy: 'ingrid',
    modifiedOn: '2020-10-13 12:05:27.0',
    modifiedBy: 'ingrid',
    __typename: 'ContentFile',
  },
  {
    id: '8934',
    path: '/testdf',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2020-10-13 12:25:42.0',
    createdBy: 'ingrid',
    modifiedOn: '2020-10-13 12:25:42.0',
    modifiedBy: 'ingrid',
    __typename: 'ContentFile',
  },
  {
    id: '8935',
    path: '/t',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2020-10-13 12:25:55.0',
    createdBy: 'ingrid',
    modifiedOn: '2020-10-13 12:25:55.0',
    modifiedBy: 'ingrid',
    __typename: 'ContentFile',
  },
  {
    id: '8937',
    path: '/demian',
    isFile: false,
    extension: null,
    fileSize: 0,
    organizationId: 1,
    blobUri: null,
    isDeleted: false,
    createdOn: '2020-10-13 13:00:12.0',
    createdBy: 'ingrid',
    modifiedOn: '2020-10-13 13:00:12.0',
    modifiedBy: 'ingrid',
    __typename: 'ContentFile',
  },
];
