import c from '../utils/c';
import getBadgeStyle from '../utils/getComponentStyle';

export const styles = {
  WARNING: 'bg-yellow-100 text-yellow-600',
  SUCCESS: 'bg-green-100 text-green-600',
  DANGER: 'bg-red-100 text-red-600',
  INFO: 'bg-accent-100 text-accent-600',
  SUCCESACCENT: 'bg-green-200 text-green-700',
};

export const Badge = ({
  children,
  className,
  success,
  warning,
  danger,
  info,
  succesAccent,
}) => {
  const badgeClassNames =
    styles[
      getBadgeStyle(
        styles,
        { success, warning, danger, info, succesAccent },
        'SUCCESS',
      )
    ];

  return (
    <div className={c('rounded', badgeClassNames, className)}>{children}</div>
  );
};
