import { createContext, useCallback, useMemo, useState } from 'react';

import en from './translations/en';
import nl from './translations/nl';

export const NotificationContext = createContext({});

let index = 1;

export const NotificationContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback((newNotification) => {
    const newEnhancedNotification = {
      id: index++,
      ...newNotification,
    };

    setNotifications((prevNotifications) => [
      ...prevNotifications,
      newEnhancedNotification,
    ]);

    setTimeout(() => {
      setNotifications((prevNotifications) =>
        prevNotifications.filter((n) => n !== newEnhancedNotification),
      );
    }, 5000);
  }, []);

  const removeNotification = (notification) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n !== notification),
    );
  };

  const context = useMemo(() => {
    return {
      notifications,
      addNotification,
      removeNotification,
    };
  }, [notifications, addNotification]);

  return (
    <NotificationContext.Provider value={context}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;

export const NotificationTranslations = {
  en,
  nl,
};
