import { useEffect, useState } from 'react';
import { FaLightbulb } from 'react-icons/fa';

import { ListContextMenu } from '@ftrprf/context-menu';

import useFormatMessage from '../../../hooks/useFormatMessage';

import { FilledButton } from '../../buttons/FilledButton';
import { MenuButton } from '../../buttons/MenuButton';

import { HintDialog } from './HintDialog';

const HintsButton = ({ hints }) => {
  const t = useFormatMessage();

  const [openHint, setOpenHint] = useState(null);
  const [viewedHints, setViewedHints] = useState([]);

  useEffect(() => {
    setOpenHint(null);
  }, [hints]);

  const onOpen = (hint, index) => {
    setViewedHints((prev) => [...prev, hint.id]);
    setOpenHint({ ...hint, index });
  };

  const onClose = () => {
    setOpenHint(null);
  };

  if (!hints || hints.length === 0) {
    return null;
  }

  return (
    <div className="absolute right-0 bottom-0 overflow-hidden">
      {openHint && (
        <HintDialog
          isOpen={!!openHint}
          title={
            openHint.title ||
            `${t('styled-slide-viewer.hint')} ${openHint.index}`
          }
          content={openHint.content || ''}
          onDismiss={onClose}
        />
      )}
      <ListContextMenu
        trigger={({ toggle }) => (
          <FilledButton
            className="text-lg mr-6 mb-6"
            iconBefore={FaLightbulb}
            onClick={toggle}
            accent
          >
            {hints.length > 1
              ? t('styled-slide-viewer.hints')
              : t('styled-slide-viewer.hint')}
          </FilledButton>
        )}
      >
        {hints.map((hint, index) => {
          const disabled = openHint?.id === hint.id;

          return (
            <MenuButton
              key={hint.id}
              disabled={disabled}
              onClick={() => onOpen(hint, index + 1)}
            >
              {!disabled && !viewedHints.includes(hint.id) && (
                <span className="h-2 w-2 mr-2 bg-accent-500 rounded-full" />
              )}
              {hint.title || `${t('styled-slide-viewer.hint')} ${index + 1}`}
            </MenuButton>
          );
        })}
      </ListContextMenu>
    </div>
  );
};

export default HintsButton;
