import { FaDesktop, FaHome, FaUsers } from 'react-icons/fa';

import c from '../../utils/c';

import { VIEW_MODES } from '../slideviewer/SlideViewer';

import { Button } from './Button';

const icons = {
  [VIEW_MODES.CLASSICAL]: FaUsers,
  [VIEW_MODES.PROJECTION]: FaDesktop,
  [VIEW_MODES.SELFSTUDY]: FaHome,
};

export const ViewModeButton = ({
  isActive,
  disabled,
  onClick,
  title,
  viewMode,
}) => {
  if (!viewMode) {
    throw new Error('No viewmode given');
  }
  const Icon = icons[viewMode];

  return (
    <Button
      className={c(
        'justify-center rounded-full h-full w-full',
        isActive ? 'bg-accent-400 text-white' : 'bg-gray-300 text-gray-600',
      )}
      onClick={onClick}
      title={title}
      disabled={disabled}
    >
      <Icon />
    </Button>
  );
};

ViewModeButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};
