import { Fragment } from 'react';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import usePathStack from '../hooks/usePathStack';

const DIVIDER = '/';

const BreadCrumbs = ({ root, rootDisplayName }) => {
  const { path, last } = usePathStack(root, rootDisplayName);

  return (
    <div className="flex flex-column flex-wrap break-all items-center space-x-1 text-gray-500">
      <div className="cursor-pointer select-none" onClick={() => {}}>
        <Link to={`/${root}`}>
          <FaHome />
        </Link>
      </div>
      <div className="text-lg align-middle select-none">{DIVIDER}</div>
      {path.map((item, i) => (
        <Fragment key={i}>
          <Link
            to={`/${root}${item === rootDisplayName ? '' : '/'}${[
              ...(!!rootDisplayName
                ? path.slice(1, i + 1)
                : path.slice(0, i + 1)),
            ].join('/')}`}
          >
            <div className="cursor-pointer select-none hover:underline">
              {item}
            </div>
          </Link>
          <div className="text-lg align-middle select-none">{DIVIDER}</div>
        </Fragment>
      ))}
      <div className="LastBreadCrumb select-none pr-2 relative text-gray-600">
        {last}
      </div>
    </div>
  );
};

export default BreadCrumbs;
