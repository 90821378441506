import { Children, cloneElement, useState } from 'react';
import { FaRegUserCircle } from 'react-icons/fa';

import c from '../utils/c';

import { DropdownButton } from './buttons/DropdownButton';

import { DropdownMenu } from './DropdownMenu';

const Item = ({ children }) => {
  return children;
};

export const Header = ({
  label,
  userName,
  beforeNavigationChildren: BeforeNavigationChildren,
  profileChildren,
  externalItem: ExternalItem,
  children,
}) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <nav className="w-full text-sm relative">
        <div className="w-full max-w-5/6 mx-auto">
          <div className="flex items-center justify-between h-16 w-full lg:w-auto">
            <div className="w-full flex items-center lg:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md transition duration-150 ease-in-out"
                aria-label="Main menu"
                aria-expanded={open}
                onClick={() => setOpen((o) => !o)}
              >
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>

                <svg
                  className="hidden h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            <div className="w-full flex items-center justify-center h-full lg:items-stretch lg:justify-start">
              <div className="hidden lg:flex items-center">
                <BeforeNavigationChildren />
                {Children.map(children, (child) => {
                  if (!child) {
                    return null;
                  }

                  return (
                    <div className="mr-2">
                      {cloneElement(child, {
                        children: (
                          <div
                            className={c(
                              'py-1 px-4 rounded transition-colors cursor-pointer duration-100',
                              child.props.active && 'bg-gray-200 font-bold',
                              !child.props.active && 'hover:bg-gray-200',
                            )}
                          >
                            {child.props.children}
                          </div>
                        ),
                        active: undefined,
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="w-full flex flex-row-reverse lg:w-auto lg:flex-shrink-0">
              <div className="flex leading-none items-center">
                <DropdownMenu
                  trigger={({ isOpen }) => (
                    <DropdownButton className="flex-shrink-0" isOpen={isOpen}>
                      <div className="leading-none">
                        <div className="hidden lg:block">
                          {label} <b>{userName}</b>
                        </div>
                        <div className="h-5 w-5 block lg:hidden">
                          <FaRegUserCircle className="w-full h-full" />
                        </div>
                      </div>
                    </DropdownButton>
                  )}
                >
                  {({ hide }) => (
                    <div className="z-50">{profileChildren({ hide })}</div>
                  )}
                </DropdownMenu>
                <div className="hidden lg:block">
                  <ExternalItem />
                </div>
              </div>
            </div>
          </div>
        </div>
        {open && (
          <div className="lg:hidden z-20 absolute mt-px w-full bg-white border-b border-gray-300 flex flex-col">
            {Children.map(children, (child) => {
              if (!child) {
                return null;
              }

              return cloneElement(child, {
                children: (
                  <div className="w-full text-left px-4 py-2 hover:bg-gray-200">
                    {child.props.children}
                  </div>
                ),
                active: undefined,
                onClick: (e) => {
                  setOpen(false);

                  if (child.props.onClick) child.props.onClick(e);
                },
              });
            })}
            <BeforeNavigationChildren />
          </div>
        )}
      </nav>
      <hr className="w-full border-gray-300" />
    </>
  );
};

Header.defaultProps = {
  beforeNavigationChildren: () => null,
  externalItem: () => null,
  classOptions: () => null,
};

Header.Item = Item;
