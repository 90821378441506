import { FaCopy, FaPencilAlt, FaTrash } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { ListContextMenu } from '@ftrprf/context-menu';
import { MenuButton } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';

const ContentCardContextMenu = ({
  editURL,
  onDelete,
  onDuplicate,
  children,
  duplicateLoading,
}) => {
  const t = useFormatMessage();

  return (
    <ListContextMenu trigger={children}>
      {editURL && (
        <Link to={editURL}>
          <MenuButton iconBefore={FaPencilAlt}>{t('global.edit')}</MenuButton>
        </Link>
      )}
      <MenuButton
        onClick={onDuplicate}
        iconBefore={FaCopy}
        preventClose={true}
        loading={duplicateLoading}
      >
        {t('global.duplicate')}
      </MenuButton>
      <MenuButton onClick={onDelete} iconBefore={FaTrash}>
        {t('global.delete')}
      </MenuButton>
    </ListContextMenu>
  );
};

ContentCardContextMenu.defaultProps = {
  onDelete: () => {},
};

export default ContentCardContextMenu;
