import { useState } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { useApolloClient } from '@apollo/client';
import { useSetRecoilState } from 'recoil';

import {
  ConfirmationDialog,
  FilledButton,
  usePrevious,
} from '@ftrprf/tailwind-components';

import FullpageContent from '../../components/Content/FullpageContent';
import ContentMetaDataDialog from '../../components/Dialog/ContentMetaDataDialog';

import useArchiveContentMutation from '../../hooks/graphql/useArchiveContentMutation';
import useCopyContentMutation from '../../hooks/graphql/useCopyContentMutation';
import useRemoveContentMutation from '../../hooks/graphql/useRemoveContentMutation';
import useFormatMessage from '../../hooks/useFormatMessage';
import useTitle from '../../hooks/useTitle';

import { isDraggingAtom, sideBarAtom } from './utils/atom';

import AddActivityDialog from './partials/AddActivityDialog';
import ContentFlowDiagram from './partials/ContentEditorDetail/ContentFlowDiagram';
import SlideEditor from './partials/ContentEditorDetail/SlideEditor';
import ContentEditorHeader from './partials/ContentEditorHeader';
import { SIDE_BARS } from './partials/SideBars/SideBarContextProvider';
import SlideOverview from './partials/SlideOverview/SlideOverview';
import SlideOverviewOld from './partials/SlideOverviewOld/SlideOverview';

const ContentEditor = ({
  content,
  slides,
  overviewRoute,
  hasViewModeSelector,
  updateContent,
  publishContent,
  duplicateSlide,
  insertSlideBelow,
  removeSlides,
  increaseContentVersion,
  setSlideSequences,
  defaultSlide,
}) => {
  const t = useFormatMessage();
  const setSideBar = useSetRecoilState(sideBarAtom);
  const setIsDragging = useSetRecoilState(isDraggingAtom);

  const history = useHistory();
  const client = useApolloClient();

  useTitle(content.title);

  const [currentSlideId, setCurrentSlideId_] = useState(
    defaultSlide || slides[0]?.id,
  );

  const copy = useCopyContentMutation();
  const archive = useArchiveContentMutation();
  const remove = useRemoveContentMutation();

  const [showMetaDataDialog, setShowMetaDataDialog] = useState(false);

  const [showPublishConfirmDialog, setShowPublishConfirmDialog] =
    useState(false);

  const [showCreateNewVersionDialog, setShowCreateNewVersionDialog] =
    useState(false);
  const [showCopyConfirmDialog, setShowCopyConfirmDialog] = useState(false);
  const [showArchiveConfirmDialog, setShowArchiveConfirmDialog] =
    useState(false);
  const [showRemoveConfirmDialog, setShowRemoveConfirmDialog] = useState(false);
  const [showAddActivityDialog, setShowAddActivityDialog] = useState(false);
  const [showFlow, setShowFlow] = useState(false);

  const disabled = content.published || content.archived;

  const currentSlideIndex = slides.findIndex(
    (slide) => slide.id === currentSlideId,
  );

  const previousCurrentSlideIndex = usePrevious(currentSlideIndex, -1);

  const currentSlide =
    currentSlideIndex !== -1
      ? slides[currentSlideIndex]
      : slides[previousCurrentSlideIndex];

  const [selectedSlideIds, setSelectedSlideIds] = useState(
    currentSlide ? [currentSlide.id] : [],
  );

  const hasActivities = slides.find((slide) => slide.activityType !== null);
  const Overview = hasActivities ? SlideOverview : SlideOverviewOld;

  const setCurrentSlideId = (id) => {
    setCurrentSlideId_(id);

    setSideBar((sb) => {
      if (sb?.type === SIDE_BARS.TEACHER_INFO_SIDEBAR) {
        // Keep the teacher info sidebar open when swithching between slides
        return { type: SIDE_BARS.TEACHER_INFO_SIDEBAR, id };
      }
      return null;
    });
  };

  const onInsertSlide = (slide, parameters = {}) => {
    insertSlideBelow(slide, parameters).then(({ id }) => {
      setCurrentSlideId(id);
      setSelectedSlideIds([id]);

      return id;
    });
  };

  const duplicateSlideAndGoTo = (slide) => {
    return duplicateSlide(slide).then((response) => {
      setCurrentSlideId(response.id);
      setSelectedSlideIds([response.id]);

      return response;
    });
  };

  const onSaveTitle = (title) => {
    if (title !== '') {
      updateContent({ title });
    }
  };

  if (!currentSlide) {
    return null;
  }

  return (
    <div
      className="flex flex-col h-full"
      onMouseUp={() => setIsDragging(false)}
    >
      <ContentEditorHeader
        content={content}
        currentSlideId={currentSlideId}
        overviewRoute={overviewRoute}
        disabled={disabled}
        onSaveTitle={onSaveTitle}
        setShowMetaDataDialog={setShowMetaDataDialog}
        hasViewModeSelector={hasViewModeSelector}
        setShowCreateNewVersionDialog={setShowCreateNewVersionDialog}
        setShowPublishConfirmDialog={setShowPublishConfirmDialog}
        setShowCopyConfirmDialog={setShowCopyConfirmDialog}
        setShowArchiveConfirmDialog={setShowArchiveConfirmDialog}
        setShowRemoveConfirmDialog={setShowRemoveConfirmDialog}
        openFlowContentDetail={() => setShowFlow(!showFlow)}
      />
      <ContentMetaDataDialog
        content={content}
        initialType={content.type}
        isOpen={showMetaDataDialog}
        onDismiss={() => setShowMetaDataDialog(false)}
        onSubmit={(changedFields) => {
          updateContent(changedFields).then(() => setShowMetaDataDialog(false));
        }}
      />
      <ConfirmationDialog
        isOpen={showPublishConfirmDialog}
        onDismiss={() => setShowPublishConfirmDialog(false)}
        onConfirm={() => {
          publishContent({ id: content.id }).then(() => {
            setShowPublishConfirmDialog(false);
          });
        }}
        content={
          <div>
            {t(`content-editor.${content.type}.publish-confirm.text1`)} <br />
            {t(`content-editor.${content.type}.publish-confirm.text2`)}
          </div>
        }
        title={t(`content-editor.${content.type}.publish-confirm.title`)}
      />

      <ConfirmationDialog
        isOpen={showCreateNewVersionDialog}
        onDismiss={() => setShowCreateNewVersionDialog(false)}
        onConfirm={() =>
          increaseContentVersion({ id: content.id }).then(() => {
            setShowCreateNewVersionDialog(false);
          })
        }
        content={<div>{t('content-editor.new_version_confirm')}</div>}
        title={t('content-editor.header.new_version')}
      />

      <ConfirmationDialog
        isOpen={showCopyConfirmDialog}
        onDismiss={() => setShowCopyConfirmDialog(false)}
        onConfirm={() => {
          copy(content.id).then(() => {
            setShowCopyConfirmDialog(false);
            client.resetStore().then(() => history.replace(overviewRoute));
          });
        }}
      />
      <ConfirmationDialog
        isOpen={showArchiveConfirmDialog}
        onDismiss={() => setShowArchiveConfirmDialog(false)}
        onConfirm={() => {
          archive(content.id).then(() => {
            setShowArchiveConfirmDialog(false);
            history.replace(overviewRoute);
          });
        }}
      />
      <ConfirmationDialog
        isOpen={showRemoveConfirmDialog}
        onDismiss={() => setShowRemoveConfirmDialog(false)}
        onConfirm={() => {
          remove(content).then(() => {
            history.replace(overviewRoute);

            client.resetStore();
            setShowRemoveConfirmDialog(false);
          });
        }}
      />
      <AddActivityDialog
        isOpen={showAddActivityDialog}
        content={content}
        onConfirm={() => setShowAddActivityDialog(false)}
        onDismiss={() => setShowAddActivityDialog(false)}
        insertSlideBelow={(parameters) =>
          onInsertSlide(currentSlide, parameters)
        }
      />

      <FullpageContent className="flex flex-grow">
        <div className="flex border-gray-300 flex-col w-60 item ">
          <div className="h-16 flex items-center gap-5 px-4 border-b border-gray-300 bg-gray-50">
            <FilledButton
              className="w-1/2"
              iconBefore={FaPlus}
              onClick={() => setShowAddActivityDialog(true)}
              disabled={disabled}
            >
              {t('content-editor.add_activity')}
            </FilledButton>
            <FilledButton
              className="w-1/2"
              iconBefore={FaPlus}
              onClick={() => onInsertSlide(currentSlide)}
              disabled={disabled}
            >
              {t('content-editor.add_slide')}
            </FilledButton>
          </div>
          <div className="overflow-scroll flex-shrink-0 flex-grow flex-basis-0 pt-3 px-2 border-r border-gray-300">
            <Overview
              hasViewModeSelector={hasViewModeSelector}
              currentSlide={currentSlide}
              slides={slides}
              selectedSlideIds={selectedSlideIds}
              setSelectedSlideIds={setSelectedSlideIds}
              setSlideSequences={setSlideSequences}
              setCurrentSlide={(slide) => {
                if (currentSlideId !== slide.id) {
                  setCurrentSlideId(slide?.id);
                }
              }}
              onInsertSlide={onInsertSlide}
              onRemoveSlides={removeSlides}
              onDuplicateSlide={duplicateSlide}
              disabled={disabled}
              canSwitchSlides={!showMetaDataDialog}
              setShowCreateNewVersionDialog={setShowCreateNewVersionDialog}
            />
          </div>
        </div>
        {!showFlow ? (
          <SlideEditor
            slideId={currentSlide.id}
            disabled={disabled}
            content={content}
            setShowCreateNewVersionDialog={setShowCreateNewVersionDialog}
            duplicateSlideAndGoTo={duplicateSlideAndGoTo}
          />
        ) : (
          <ContentFlowDiagram
            slideId={currentSlide.id}
            content={content}
            onItemClick={(id) => {
              setCurrentSlideId(id);
              setSelectedSlideIds([id]);
              setShowFlow(false);
            }}
          />
        )}
      </FullpageContent>
    </div>
  );
};

export default ContentEditor;
