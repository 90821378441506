import { gql } from '@apollo/client';

export const FIND_ALL_LESSON_CONTENT = gql`
  query FindAllLessonContent(
    $page: Int!
    $filter: [JSON]
    $tags: [String]
    $sort: JSON
    $endGoals: [String]
  ) {
    findAllLessonContent(
      page: $page
      size: 12
      filter: $filter
      endGoals: $endGoals
      tags: $tags
      sort: $sort
    ) {
      total
      pages
      currentPage
      content {
        id
        title
        type
        language
        image
        programId
        clan
        level
        version
        published
        project
        description
        summary
        isDefault
        archived
        modifiedBy
        modifiedOn
        tags {
          id
          name
        }
        endGoals {
          id
          key
          value
        }
        groupLesson
        groupRoles {
          id
          role
        }
      }
    }
  }
`;

export const GET_LESSON_LANGUAGE = gql`
  query getLessonLanguage($id: Int!) {
    findLessonContent(id: $id) {
      language
    }
  }
`;

export const FIND_LESSON_CONTENT = gql`
  query FindLessonContent($id: Int!) {
    findLessonContent(id: $id) {
      id
      title
      type
      language
      image
      programId
      clan
      level
      version
      published
      summary
      description
      archived
      modifiedOn
      groupLesson
      groupRoles {
        id
        role
      }
      tags {
        id
        name
      }
      groupRoles {
        id
        role
      }
      endGoals {
        id
        key
        value
      }
      slides {
        id
        title
        content
        sequence
        viewModes
        motivation
        info
        part
        method
        activityType {
          id
          key
          icon
        }
        time
        groupRoles {
          id
          role
        }
        questions {
          id
          type
          publishedAt
          ... on QuestionOpen {
            value
            scorePerCorrectAnswer
            placeholder
            solution
            questionAnswerOpen {
              id
              explanation
              optional
            }
          }
          ... on QuestionMultipleChoice {
            value
            scorePerCorrectAnswer
            subtractPerWrongAnswer
            maxSelectableAnswers
            shouldFindAllCorrect
            shuffleAnswers
            questionAnswersMultipleChoice {
              id
              value
              correct
              explanation
            }
          }
        }
        hints {
          id
          title
          content
        }
      }
    }
  }
`;
export const CREATE_LESSON_CONTENT_FROM_TEMPLATE = gql`
  mutation createLessonContentFromTemplate(
    $templateIdToCopy: Int!
    $title: String!
    $type: LessonContentType!
    $language: Language
    $image: String
    $programId: Int
    $clan: String
    $level: Int
    $tags: [String]
    $endGoals: [Int]
    $groupLesson: Boolean
  ) {
    createLessonContentFromTemplate(
      input: {
        title: $title
        type: $type
        language: $language
        image: $image
        programId: $programId
        clan: $clan
        level: $level
        tags: $tags
        endGoals: $endGoals
        groupLesson: $groupLesson
      }
      templateIdToCopy: $templateIdToCopy
    ) {
      id
      type
      language
    }
  }
`;

export const CREATE_LESSON_CONTENT = gql`
  mutation CreateLessonContent(
    $title: String!
    $type: LessonContentType!
    $language: Language
    $image: String
    $summary: String
    $description: String
    $programId: Int
    $clan: String
    $level: Int
    $tags: [String]
    $endGoals: [Int]
    $groupLesson: Boolean
    $groupRoles: [LessonGroupRoleInput]
  ) {
    createLessonContent(
      input: {
        title: $title
        type: $type
        language: $language
        image: $image
        programId: $programId
        clan: $clan
        level: $level
        tags: $tags
        endGoals: $endGoals
        summary: $summary
        description: $description
        groupLesson: $groupLesson
        groupRoles: $groupRoles
      }
    ) {
      id
      type
      language
    }
  }
`;

export const UPDATE_LESSON_CONTENT = gql`
  mutation UpdateLessonContent(
    $id: Int!
    $title: String!
    $type: LessonContentType!
    $language: Language
    $image: String
    $programId: Int
    $clan: String
    $level: Int
    $tags: [String]
    $endGoals: [Int]
    $summary: String
    $description: String
    $groupLesson: Boolean
    $groupRoles: [LessonGroupRoleInput]
  ) {
    updateLessonContent(
      id: $id
      input: {
        title: $title
        type: $type
        language: $language
        image: $image
        programId: $programId
        clan: $clan
        level: $level
        tags: $tags
        endGoals: $endGoals
        summary: $summary
        description: $description
        groupLesson: $groupLesson
        groupRoles: $groupRoles
      }
    ) {
      id
      title
      type
      language
      image
      programId
      clan
      level
    }
  }
`;

export const REMOVE_LESSON_CONTENT = gql`
  mutation RemoveLessonContent($id: Int!) {
    removeLessonContent(id: $id) {
      id
      statuscode
      message
    }
  }
`;

export const PUBLISH_LESSON_CONTENT = gql`
  mutation PublishLessonContent($id: Int!) {
    publishLessonContent(id: $id) {
      id
      archived
      published
    }
  }
`;

export const ARCHIVE_LESSON_CONTENT = gql`
  mutation ArchiveLessonContent($id: Int!) {
    archiveLessonContent(id: $id) {
      id
      type
      archived
      published
    }
  }
`;

export const UNARCHIVE_LESSON_CONTENT = gql`
  mutation UnarchiveLessonContent($id: Int!) {
    unarchiveLessonContent(id: $id) {
      id
      type
      archived
      published
    }
  }
`;

export const INCREASE_LESSON_CONTENT_VERSION = gql`
  mutation IncreaseLessonContentVersion($id: Int!) {
    increaseLessonContentVersion(id: $id) {
      id
      version
      archived
      published
    }
  }
`;

export const COPY_LESSON_CONTENT = gql`
  mutation CopyLessonContent($id: Int!) {
    copyLessonContent(id: $id) {
      id
      type
      archived
      published
    }
  }
`;

export const FIND_TAGS_WITH_NAME = gql`
  query FindTagsWithName($name: String) {
    findTagsWithName(name: $name) {
      id
      name
    }
  }
`;

export const FIND_ENDGOALS_BY_KEY = gql`
  query findEndGoalsByKey($key: String) {
    findEndGoalsByKey(key: $key) {
      id
      key
      value
    }
  }
`;

export const GET_ALL_ACTIVITY_TYPES = gql`
  query findActivityTypes {
    findActivityTypes {
      id
      key
      icon
      organizationId
    }
  }
`;

export const CREATE_ACTIVITY_TYPE = gql`
  mutation createActivityType(
    $key: String!
    $icon: String!
    $organizationId: Int
  ) {
    createActivityType(
      input: { key: $key, icon: $icon, organizationId: $organizationId }
    ) {
      id
      key
      icon
      organizationId
    }
  }
`;
