import { useState } from 'react';

import { Label } from '@ftrprf/tailwind-components';

import Editor from '../../../../components/Editor/Editor';
import * as customPlugins from '../../../../components/Editor/sideBarCustomPlugins';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useSlide from '../../hooks/useSlide';

import SideBarContent from './SideBarContent';

const TeacherInfoSideBar = ({ disabled, id }) => {
  const t = useFormatMessage();
  const [, setLocalInfo] = useState();

  const { slide, update } = useSlide(id);

  if (!slide) {
    return null;
  }

  const updateLocalInfo = (info) => {
    update({ info: info || '' });
    setLocalInfo(info);
  };

  return (
    <SideBarContent
      className="overflow-auto"
      title={t('teacher-info-sidebar.title')}
    >
      <div className="p-3 flex flex-col flex-grow bg-gray-100">
        <Label className="text-sm text-gray-700">
          {t('teacher-info-sidebar.description')}
        </Label>

        <Editor
          id={slide.id}
          className="border py-1"
          value={slide.info}
          onChange={updateLocalInfo}
          containerClass="TeacherInfoSidebar"
          disabled={disabled}
          plugins={customPlugins}
          isMainEditor={false}
        />
      </div>
    </SideBarContent>
  );
};

export default TeacherInfoSideBar;
