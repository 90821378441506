export default [
  {
    id: '2221',
    title: 'Title slide 1 with multiple choice question',
    content: 'Content of slide 1',
    sequence: 1,
    viewModes: ['CLASSICAL', 'SELFSTUDY'],
    info: 'Info of slide 1',
    hints: [],
    question: {
      id: 'test',
      type: 'MULTIPLE_CHOICE',
      shuffleAnswers: false,
      questionAnswersMultipleChoice: [
        {
          id: '1',
          value: 'sdsd',
          correct: true,
          explanation: 'sdsd',
          __typename: 'QuestionAnswerMultipleChoice',
        },
        {
          id: '2',
          value: 'sdsd',
          correct: false,
          explanation: 'sdsd',
          __typename: 'QuestionAnswerMultipleChoice',
        },
      ],
      __typename: 'Question',
    },
    __typename: 'Slide',
  },
  {
    id: '2222',
    title: 'Title slide 2 with hints',
    content: 'Content of slide 2',
    sequence: 2,
    viewModes: ['CLASSICAL', 'SELFSTUDY'],
    info: 'Info of slide 2',
    question: null,
    __typename: 'Slide',
    hints: [
      {
        id: '3331',
        title: 'Custom Hint 1',
        content: 'Content of Hint 1',
        __typename: 'Hint',
      },
      {
        id: '3332',
        title: null,
        content: null,
        __typename: 'Hint',
      },
    ],
  },
  {
    id: '2223',
    title: 'Title slide 3',
    content: 'Content of slide 3',
    sequence: 3,
    viewModes: ['CLASSICAL', 'SELFSTUDY'],
    hints: [],
    info: 'Info of slide32',
    question: null,
    __typename: 'Slide',
  },
];
