import { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { UserContext } from './../../providers/UserProvider';

import * as URLS from '../../utils/constants/urls';
import {
  ADMIN,
  ORGANIZATION_ADMIN,
} from './../../utils/constants/organizationRoles';

import ErrorPage from './../ErrorPage/index';

import Groups from './partials/Groups';
import Organizations from './partials/Organizations';
import Admin from './Admin';

const AdminContainer = () => {
  const { user } = useContext(UserContext);

  return (
    <Switch>
      {user?.organizationRole === ADMIN && (
        <Route exact path={URLS.ADMIN} component={Admin} />
      )}
      {user?.organizationRole === ADMIN && (
        <Route
          exact
          path={`${URLS.ADMIN}/:domain`}
          render={({
            match: {
              params: { domain },
            },
          }) => <Organizations domain={domain} />}
        />
      )}
      {(user?.organizationRole === ADMIN ||
        user?.organizationRole === ORGANIZATION_ADMIN) && (
        <Route
          exact
          path={`${URLS.ADMIN}/:domain/:organizationId`}
          render={({
            match: {
              params: { organizationId },
            },
          }) => {
            if (
              user.organizationRole === ORGANIZATION_ADMIN &&
              organizationId !== user.organization.id
            ) {
              return (
                <Redirect
                  to={`${URLS.ADMIN}/${user.domain}/${user.organization.id}`}
                />
              );
            }
            return <Groups organizationId={organizationId} />;
          }}
        />
      )}
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default AdminContainer;
