import axios from 'axios';

import { domains } from '../utils/constants/domains';
import { AUTHORIZATION_TOKEN } from '../utils/constants/localStorage';

const authenticateFtrprf = async (body) => {
  const {
    headers: { authorization },
    data: { firstName: name, idp: domain },
  } = await axios(
    `${process.env.REACT_APP_FTRPRF_API_URI}/api/users/authenticate`,
    {
      method: 'POST',
      data: body,
    },
  );
  return {
    token: authorization,
    user: {
      name,
      domain,
    },
  };
};

const authenticateCodeFever = async (body) => {
  const {
    data: { token, firstName, username: fallBackUserName },
  } = await axios(
    `${process.env.REACT_APP_CODEFEVER_API_URI}/v1/userservice/authenticate`,
    {
      method: 'POST',
      headers: {
        Authorization:
          'nS4riFujBdhsQPQGEU4BCwrNUd8KQKPX7F17ZhSLTsoZnA3i4ZQiMwzn87nbmNXvCP19',
      },
      data: body,
    },
  );
  return {
    token,
    user: {
      name: firstName || fallBackUserName,
      domain: domains.CODEFEVER,
    },
  };
};

export const authenticate = async (domain, body) => {
  let intermediateResponse;
  if (domain === domains.CODEFEVER) {
    intermediateResponse = await authenticateCodeFever(body);
  } else if (domain === domains.FTRPRF) {
    intermediateResponse = await authenticateFtrprf(body);
  } else {
    throw new Error(`Invalid domain: ${domain}`);
  }
  const { token } = intermediateResponse;

  const { data } = await axios(
    `${process.env.REACT_APP_STUDIO_API_URI}/authenticate`,
    {
      method: 'POST',
      data: {
        domain,
        token,
      },
    },
  );

  window.localStorage.setItem(AUTHORIZATION_TOKEN, data.token);

  return { token: data.token };
};

export const validate = (token) => {
  // TODO: validate token by BE
  return !!token;
};

export const invalidate = () => {
  // TODO: invalidate token in BE
};
