import useFormatMessage from './useFormatMessage';

export const useCustomColors = () => {
  const t = useFormatMessage();

  return `${t('content-editor.colors.scratch.beweging')}/4c97ff,${t(
    'content-editor.colors.scratch.uiterlijken',
  )}/9966ff,${t('content-editor.colors.scratch.geluid')}/cf63cf,${t(
    'content-editor.colors.scratch.gebeurtenis',
  )}/ffbf00,${t('content-editor.colors.scratch.besturen')}/ffab19,${t(
    'content-editor.colors.scratch.waarnemen',
  )}/5cb1d6,${t('content-editor.colors.scratch.functies')}/59c059,${t(
    'content-editor.colors.scratch.variabelen',
  )}/ff8c1a,${t('content-editor.colors.scratch.mijn_blokken')}/ff6680,${t(
    'content-editor.colors.scratch.pen_makey',
  )}/0fbd8c,${t('content-editor.colors.white')}/,${t(
    'content-editor.colors.minecraft.basic',
  )}/e89005,${t('content-editor.colors.minecraft.player')}/0078d7,${t(
    'content-editor.colors.minecraft.blocks',
  )}/7abb55,${t('content-editor.colors.minecraft.mobs')}/764bcc,${t(
    'content-editor.colors.minecraft.agent',
  )}/d83b01,${t('content-editor.colors.minecraft.gameplay')}/8f6d40,${t(
    'content-editor.colors.minecraft.positions',
  )}/69b090,${t('content-editor.colors.minecraft.loops')}/569138,${t(
    'content-editor.colors.minecraft.logic',
  )}/459197,${t('content-editor.colors.minecraft.variables')}/ea2b1f,${t(
    'content-editor.colors.minecraft.math',
  )}/6c6ea0,${t('content-editor.colors.minecraft.functions')}/235789,${t(
    'content-editor.colors.minecraft.arrays',
  )}/a94400,${t('content-editor.colors.minecraft.text')}/e89005,${t(
    'content-editor.colors.minecraft.builder',
  )}/cb48b7,${t('content-editor.colors.minecraft.shapes')}/ec7505,${t(
    'content-editor.styles.intertitle',
  )}/2980b9,${t('content-editor.colors.subtitle-defintion.color')}/3498db,${t(
    'content-editor.styles.tip',
  )}/c0c0c0,${t('content-editor.styles.text')}/000000`;
};

export default useCustomColors;
