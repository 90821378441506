// From https://lodash.com/docs/#times
const times = (n, iteratee) => {
  let index = -1;
  let result = Array(n);

  while (++index < n) {
    result[index] = iteratee(index);
  }

  return result;
};

export default times;
