import { useContext } from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { NavLink, useHistory } from 'react-router-dom';

import {
  ChromeInfoBar,
  Content,
  FilledButton,
  Header as TWHeader,
  MenuButton,
} from '@ftrprf/tailwind-components';

import {
  LanguageContext,
  supportedLanguages,
} from '../providers/LanguageProvider';
import { UserContext } from '../providers/UserProvider';

import useFormatMessage from '../hooks/useFormatMessage';

import {
  EDITOR,
  ORGANIZATION_ADMIN,
} from '../utils/constants/organizationRoles';
import * as URLS from '../utils/constants/urls';
import isBetaUser from '../utils/isBetaUser';

import Logo from './Logo';

export const FTRPRFLink = ({ className, to, children }) => {
  const href = `${process.env.REACT_APP_NEXT_URL}${to}`;

  return (
    <a className={className} href={href} onClick={(e) => e.stopPropagation()}>
      {children}
    </a>
  );
};

const HeaderItem = ({ to, children, ...props }) => {
  return (
    <NavLink to={to} key={to} {...props}>
      {children}
    </NavLink>
  );
};

const Header = () => {
  const t = useFormatMessage();
  const { user, signOut } = useContext(UserContext);
  const { setLanguage } = useContext(LanguageContext);

  const {
    location: { pathname },
  } = useHistory();

  const languages = supportedLanguages.map((key) => ({
    key,
    label: t(`languages.${key}`),
  }));

  return (
    <>
      <TWHeader
        label={t('header.hello')}
        logo={
          <HeaderItem pathname={pathname} to={URLS.LESSONS}>
            <Logo className="h-full" />
          </HeaderItem>
        }
        userName={user.firstName}
        languages={languages}
        onLanguageChange={setLanguage}
        profileChildren={({ hide }) => (
          <MenuButton
            onClick={() => {
              signOut();
              hide();
            }}
          >
            {t('header.sign_out')}
          </MenuButton>
        )}
        externalItem={() => (
          <FTRPRFLink to="/">
            <FilledButton iconAfter={FaExternalLinkAlt}>
              {t('global.go_back')}
            </FilledButton>
          </FTRPRFLink>
        )}
      >
        <HeaderItem
          pathname={pathname}
          to={URLS.LESSONS}
          active={pathname.startsWith(URLS.LESSONS)}
        >
          {t('header.lessons')}
        </HeaderItem>

        {isBetaUser(user) && (
          <HeaderItem
            pathname={pathname}
            to={URLS.LESSON_TEMPLATES}
            active={pathname.startsWith(URLS.LESSON_TEMPLATES)}
          >
            {t('header.templates')}
          </HeaderItem>
        )}

        <HeaderItem
          pathname={pathname}
          to={URLS.EXAMS}
          active={pathname.startsWith(URLS.EXAMS)}
        >
          {t('header.exams')}
        </HeaderItem>

        {
          <HeaderItem
            pathname={pathname}
            to={URLS.EXERCISES}
            active={pathname.startsWith(URLS.EXERCISES)}
          >
            {t('header.exercises')}
          </HeaderItem>
        }

        <HeaderItem
          pathname={pathname}
          to={URLS.FILES}
          active={pathname.startsWith(URLS.FILES)}
        >
          {t('header.files')}
        </HeaderItem>

        {user?.organizationRole !== EDITOR && (
          <HeaderItem
            pathname={pathname}
            to={`${URLS.ADMIN}${
              user?.organizationRole === ORGANIZATION_ADMIN
                ? `/${user?.domain}/${user?.organization}`
                : ''
            }`}
            active={pathname.startsWith(URLS.ADMIN)}
          >
            {t('header.admin')}
          </HeaderItem>
        )}

        <FTRPRFLink className="lg:hidden" to="/">
          {t('header.back_to_FTRPRF')}
        </FTRPRFLink>
      </TWHeader>
      <ChromeInfoBar container={(children) => <Content>{children}</Content>} />
    </>
  );
};

export default Header;
