import React from 'react';

import c from './utils/c';
import { ContextMenu } from './ContextMenu';

export const ListContextMenu = ({ children, isWide, ...props }) => {
  return (
    <ContextMenu
      {...props}
      className={c('ListContextMenu', isWide ? 'w-56' : 'w-48')}
    >
      {({ close }) =>
        React.Children.map(
          children,
          (child) =>
            child &&
            React.cloneElement(child, {
              onClick: () => {
                if (!child.props.preventClose) {
                  close();
                }
                if (child.props.onClick) {
                  child.props.onClick();
                }
              },
            }),
        )
      }
    </ContextMenu>
  );
};
