import c from '../utils/functions/c';

const ErrorMessage = ({ className, error }) => {
  if (!error) {
    return null;
  }
  return <div className={c(className, 'text-red-700')}>{error.message}</div>;
};

export default ErrorMessage;
