import React, { useState } from 'react';

const Tabs = ({
  containerClassName,
  footerContainerClassName,
  labelsContainerClassName,
  labelClassName,
  labelClassNameActive,
  footerExtra: FooterExtra,
  children,
}) => {
  const [activeTab, setActiveTab] = useState(0);

  const labels = React.Children.map(
    children,
    (child) => child && child.props.label,
  );
  const items = React.Children.map(
    children,
    (child) => child && child.props.children,
  );

  return (
    <div className={containerClassName}>
      {items && items[activeTab]}
      <div className={footerContainerClassName}>
        <div className={labelsContainerClassName}>
          {labels &&
            labels.map((label, index) => (
              <div
                key={index}
                className={
                  index === activeTab ? labelClassNameActive : labelClassName
                }
                onClick={() => setActiveTab(index)}
              >
                {label}
              </div>
            ))}
        </div>
        {FooterExtra}
      </div>
    </div>
  );
};

export default Tabs;
