import { useState } from 'react';

import {
  FilledButton,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';

import Collapsable from '../../../../../components/Collapsable';
import Editor from '../../../../../components/Editor/Editor';

import useFormatMessage from '../../../../../hooks/useFormatMessage';
import useHint from '../../../hooks/useHint';

const HintsSideBarItem = ({ id, index, disabled, removeHint }) => {
  const t = useFormatMessage();
  const [, setLocalContent] = useState();

  const { hint, update } = useHint(id);

  const updateLocalContent = (content) => {
    update({ content: content || '' });
    setLocalContent(content);
  };

  if (!hint) {
    return null;
  }

  return (
    <div className="flex flex-col border-b border-gray-300">
      <Collapsable
        initialShown={index === 0}
        className="pr-3"
        trigger={
          <div className="px-3 py-3 flex-grow">
            {hint.title
              ? hint.title
              : `${t('hints-sidebar.item.hint_x')} ${index + 1}`}
          </div>
        }
      >
        <div className="border-t border-gray-300 bg-gray-100 p-4">
          <InputGroup>
            <Label>{t('hints-sidebar.item.title')}</Label>
            <Input
              type="text"
              value={hint.title || ''}
              onChange={(e) => update({ title: e.target.value })}
              disabled={disabled}
            />
          </InputGroup>

          <InputGroup>
            <Label>{t('hints-sidebar.item.content')}</Label>
            <Editor
              value={hint.content || ''}
              onChange={updateLocalContent}
              disabled={disabled}
            />
          </InputGroup>
          <div className="flex justify-end">
            <FilledButton
              error
              onClick={() => removeHint(hint.id)}
              disabled={disabled}
            >
              {t('global.delete')}
            </FilledButton>
          </div>
        </div>
      </Collapsable>
    </div>
  );
};

export default HintsSideBarItem;
