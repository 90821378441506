import { FaQuestionCircle } from 'react-icons/fa';

import { SlideViewerSlideThumbnail } from '@ftrprf/tailwind-components';

import useFormatMessage from '../hooks/useFormatMessage';

const SlideThumbnail = ({ className, slide, fontSizeIndex }) => {
  const t = useFormatMessage();

  // Will be changed in backend, a slide should only have one question
  const containsQuestion = slide.questions?.length > 0;

  return (
    <div className={className}>
      {/* set height to 9/16 of the width (fix aspect ratio) */}
      <div className="pb-9/16 w-full bg-white relative">
        <div className="absolute h-full w-full overflow-hidden p-1 pointer-events-none">
          {containsQuestion && (
            <FaQuestionCircle
              className="absolute text-accent-300 text-lg top-0 right-0 m-1 cursor-default"
              title={t('slide-thumbnail.contains-question')}
            />
          )}

          <SlideViewerSlideThumbnail
            currentSlide={slide}
            fontSizeIndex={fontSizeIndex}
          />
        </div>
      </div>
    </div>
  );
};

SlideThumbnail.defaultProps = {
  fontSizeIndex: 0.2,
};

export default SlideThumbnail;
