import { useHistory } from 'react-router-dom';

const usePathStack = (root = null, rootDisplayName = null) => {
  const history = useHistory();
  const [, ...path] = history.location.pathname
    .substring(history.location.pathname.indexOf(root))
    .split('/');

  let relativePath = `${history.location.pathname.substring(
    history.location.pathname.indexOf(root) + root?.length,
  )}/`;

  if (rootDisplayName) {
    path.unshift(rootDisplayName);
  }

  const last = path.pop();

  return { path, last, relativePath };
};

export default usePathStack;
