import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import useFormatMessage from '../../..//hooks/useFormatMessage';

import c from '../../../utils/c';

import { SlideViewerLeftAction } from '../../slideviewer/SlideViewer';

import ChevronRight from './ChevronRight';

const SlideViewerLeft = ({ isSidebarOpen, setIsSidebarOpen }) => {
  const t = useFormatMessage();

  return (
    <div className="flex items-center justify-between -m-px z-20 w-1/12 flex-shrink-0 h-full left-0 absolute">
      <div>
        <button
          aria-label={t('styled-slide-viewer.open_the_sidebar')}
          aria-expanded={isSidebarOpen}
          type="button"
          className="text-2xl bg-white z-10 border-t border-b border-r border-gray-300 px-1 py-4 rounded-tr-2xl rounded-br-2xl"
          onClick={() => setIsSidebarOpen((x) => !x)}
        >
          {isSidebarOpen ? <FaChevronLeft /> : <FaChevronRight />}
        </button>
      </div>

      <SlideViewerLeftAction>
        {({ disabled }) => (
          <button
            type="button"
            aria-label={t('styled-slide-viewer.previous')}
            className={c(
              'transform transition duration-500',
              !disabled && 'text-gray-400 hover:text-black hover:scale-150',
              disabled && 'text-gray-100 cursor-not-allowed ',
            )}
            disabled={disabled}
          >
            <ChevronRight className="transform -scale-x-1" />
          </button>
        )}
      </SlideViewerLeftAction>
    </div>
  );
};

export default SlideViewerLeft;
