import { Children } from 'react';

import c from '../utils/c';

export const BorderedList = ({ className, children }) => {
  if (Children.count(children) === 0) {
    return null;
  }

  return (
    <div
      className={c(
        'w-full border border-gray-300 rounded divide-y divide-gray-300',
        className,
      )}
    >
      {children}
    </div>
  );
};
