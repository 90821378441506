import { useState } from 'react';
import { Redirect } from 'react-router';

import useParams from '../../hooks/useParams';

import { authenticate } from '../../api/authenticate';

import { ROOT } from '../../utils/constants/urls';
import errorToMessageDescriptor from '../../utils/functions/errorToMessageDescriptor';

import Login from './Login';

const LoginContainer = () => {
  const { redirect } = useParams();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [signedIn, setSignedIn] = useState(false);

  const onSubmit = (domain, { username, password }) => {
    setLoading(true);

    authenticate(domain, { username, password })
      .then(() => {
        setLoading(false);
        setSignedIn(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(errorToMessageDescriptor(err));
      });
  };

  if (signedIn) {
    return <Redirect to={redirect || ROOT} />;
  }

  return <Login error={error} loading={loading} onSubmit={onSubmit} />;
};

export default LoginContainer;
