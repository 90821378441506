import { useEffect, useState } from 'react';

const useInitialize = (editorRef, contentStyles) => {
  const [customButtonsLocation, setCustomButtonsLocation] = useState();
  const [editorAPI, setEditorAPI] = useState();
  const [times, setTimes] = useState(0);

  useEffect(() => {
    let checkDomTimer;
    if (!customButtonsLocation) {
      checkDomTimer = setTimeout(() => {
        if (window.CKEDITOR && editorRef.current) {
          const { name } = editorRef.current.editor;
          setEditorAPI(window.CKEDITOR.instances[`${name}`]);
          setCustomButtonsLocation(
            document.querySelector(`#custom-buttons-${name}`),
          );
        }
        setTimes((prevTimes) => prevTimes + 1);
      }, 60);
    }
    return () => clearTimeout(checkDomTimer);
  }, [times, customButtonsLocation, editorRef, contentStyles]);

  return [customButtonsLocation, editorAPI];
};

export default useInitialize;
