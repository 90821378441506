import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import * as Sentry from '@sentry/react';

import { LOGOUT } from '../utils/constants/urls';
import isCodeFever from '../utils/isCodeFever';

export const UserContext = createContext({});

const UserProvider = ({ initialUser, children }) => {
  const history = useHistory();
  const [user, setUser] = useState(initialUser);

  const signOut = useCallback(() => {
    history.push({
      pathname: LOGOUT,
      search: `?projects=${isCodeFever ? 'STUDIO,EDU' : 'STUDIO,NEXT,EDU'}`,
    });
  }, [history]);

  useEffect(() => {
    if (user) {
      Sentry.setUser(user);
    }
  }, [user]);

  const context = useMemo(() => {
    return {
      user,
      signedIn: !!user,
      setUser,
      signOut,
    };
  }, [user, setUser, signOut]);

  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
