import { useField } from 'formik';

import { Input, InputGroup, Label } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../hooks/useFormatMessage';

const FormikInput = ({ className, label, grouped, ...props }) => {
  const t = useFormatMessage();
  const [field, meta] = useField(props);

  const Container = grouped ? InputGroup : 'div';

  return (
    <Container className={className}>
      {label && (
        <Label
          aria-labelledby={props.id || props.name}
          htmlFor={props.id || props.name}
        >
          {label}{' '}
          {meta.touched && meta.error ? (
            <span className="mt-1 text-sm text-red-700">- {t(meta.error)}</span>
          ) : null}
        </Label>
      )}
      <Input id={props.id || props.name} {...field} {...props} />
    </Container>
  );
};

FormikInput.defaultProps = {
  grouped: true,
};

export default FormikInput;
