import c from '../../utils/c';

export const FieldError = ({ className, children, ...props }) => (
  <span className={c(className, 'text-red-500 text-xs')} {...props}>
    {children}
  </span>
);

FieldError.defaultProps = {
  children: null,
};
