import { FaCheck, FaTimes } from 'react-icons/fa';

import c from '../utils/c';

import { SlideViewerTextSlide } from './slideviewer/SlideViewer';

// eslint-disable-next-line complexity
export const MultipleChoiceCard = ({
  value,
  isClicked,
  isCorrect,
  explanation,
  showIsCorrect,
  showExplanation,
  onClick,
  ...props
}) => {
  const As = showIsCorrect ? 'div' : 'button';

  return (
    <As
      onClick={showIsCorrect ? undefined : onClick}
      className={c(
        'SlideViewerMultipleChoiceAnswerCard w-full border-2 rounded overflow-hidden flex justify-between text-left',
        showIsCorrect && isClicked && isCorrect && 'border-green-500',
        showIsCorrect && isClicked && !isCorrect && 'border-red-500',
        showIsCorrect && !isClicked && 'border-gray-300',
        !showIsCorrect && !isClicked && 'border-gray-300',
        !showIsCorrect && isClicked && 'border-accent-500',
        !showIsCorrect && !isClicked && 'hover:bg-gray-100',
        !showIsCorrect && isClicked && 'bg-accent-100',
      )}
      {...props}
    >
      <div className="p-2">
        <SlideViewerTextSlide value={value} />
        {showExplanation && (
          <div className="mt-1 text-sm text-gray-700">
            {' '}
            <SlideViewerTextSlide value={explanation} />
          </div>
        )}
      </div>
      {showIsCorrect && (
        <div
          className={c(
            'pl-3 pr-2 flex items-center justify-center',
            isClicked
              ? isCorrect
                ? 'bg-green-500 text-white'
                : 'bg-red-500 text-white'
              : 'text-gray-500',
          )}
        >
          {showIsCorrect && isCorrect && <FaCheck className="w-4 h-4" />}
          {showIsCorrect && !isCorrect && isClicked && (
            <FaTimes className="w-4 h-4" />
          )}
        </div>
      )}
    </As>
  );
};

MultipleChoiceCard.defaultProps = {
  showExplanation: false,
  showIsCorrect: false,
};
