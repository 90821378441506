import { useState } from 'react';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';

import {
  Dropdown,
  Input,
  InputGroup,
  Label,
} from '@ftrprf/tailwind-components';
import { CheckBox } from '@ftrprf/tailwind-components';

import useFormatMessage from '../../../../hooks/useFormatMessage';
import useActivityTypes from '../../hooks/useActivityTypes';
import useSlide from '../../hooks/useSlide';

import SLIDEMETHODS from '../../../../utils/constants/slideMethods';
import * as SLIDEPARTTYPES from '../../../../utils/constants/slidePartTypes';
import { lessonContentAtom } from '../../utils/atom';

const subTitleStyle = 'opacity-70 text-sm py-2';
const subsubTitleStyle = 'opacity-50 text-xs py-1';

const Divider = () => <hr className="w-11/12 opacity-30  mt-3 ml-4" />;

const SteamsSideBar = ({ disabled, slideId }) => {
  const t = useFormatMessage();

  const [currentLessonContent] = useRecoilState(lessonContentAtom);
  const groupRoleKeyToId = currentLessonContent?.groupRoles?.reduce(
    (prev, groupRole) => {
      const { id, role } = groupRole;
      return { ...prev, [role]: id };
    },
    {},
  );

  const { slide, update } = useSlide(slideId);

  const { activitiesKeyToId } = useActivityTypes();
  const [timerValue, setTimer] = useState(slide.time ?? 10);
  const [timeCheckboxChecked, setTimeCheckbox] = useState(slide.time !== null);
  const [selectedRoles, setSelectedRoles] = useState(
    slide.groupRoles?.map((gr) => gr.role) ?? [],
  );
  const [motivation, setMotivation] = useState(slide.motivation ?? null);
  const [rolesCheckboxChecked, setRolesCheckbox] = useState(
    slide.method === SLIDEMETHODS.INDIVIDUALLY &&
      slide.groupRoles !== null &&
      slide.groupRoles.length > 0,
  );

  useEffect(() => {
    setTimer(slide.time ?? 10);
    setTimeCheckbox(slide.time !== null);
    setSelectedRoles(slide.groupRoles?.map((gr) => gr.role) ?? []);
    setRolesCheckbox(
      slide.method === SLIDEMETHODS.INDIVIDUALLY &&
        slide.groupRoles !== null &&
        slide.groupRoles.length > 0,
    );
    setMotivation(slide.motivation ?? null);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slide.id]);

  const updateTime = (value, checked) => {
    update({
      time: checked ? value : null,
    });
  };
  const updateRoles = (value, checked) => {
    update({
      groupRoles: !checked
        ? []
        : value.map((key) => ({
            id: groupRoleKeyToId[key],
            role: key,
            lessonId: currentLessonContent.id,
          })),
    });
  };
  const updateMotivation = (value) => {
    update({ motivation: value ? value : null });
  };

  return (
    <div className="flex flex-col">
      <Label className={subTitleStyle}>
        {t('slide-sidebar-steams.general')}
      </Label>
      <InputGroup>
        <Label className={subsubTitleStyle}>
          {t('slide-sidebar-steams.chapter')}
        </Label>
        <Dropdown
          disabled={disabled}
          value={slide.part}
          options={Object.values(SLIDEPARTTYPES).map((part) => ({
            key: part,
            value: part,
            label: t(`slide_part.${part}`),
          }))}
          onChange={(newval) => {
            update({
              part: newval,
            });
          }}
        />
      </InputGroup>
      <InputGroup>
        <Label className={subsubTitleStyle}>
          {t('slide-sidebar-steams.public')}
        </Label>
        <Dropdown
          disabled={disabled}
          value={slide.method}
          options={Object.values(SLIDEMETHODS).map((method) => ({
            key: method,
            value: method,
            label: t(`slide_method.${method}`),
          }))}
          onChange={(newval) => {
            let newvars = {
              method: newval,
            };

            if (newval !== SLIDEMETHODS.INDIVIDUALLY) {
              newvars.groupRoles = [];
              setRolesCheckbox(false);
            }
            update(newvars);
          }}
        />
      </InputGroup>
      <InputGroup>
        <Label className={subsubTitleStyle}>
          {t('slide-sidebar-steams.activity')}
        </Label>
        <Dropdown
          disabled={disabled}
          value={slide.activityType?.key}
          options={Object.keys(activitiesKeyToId)}
          onChange={(newkey) => {
            const id = activitiesKeyToId[newkey];
            update({
              activityTypeId: id,
              activityType: { key: newkey, id: id },
            });
          }}
        />
      </InputGroup>
      <div className="flex flex-col">
        <Label className={subsubTitleStyle}>
          {t('slide-sidebar-steams.time')}
        </Label>
        <div className="flex flex-row">
          <CheckBox
            className="mr-2 "
            checked={!!slide?.time}
            disabled={disabled}
            onChange={(c) => {
              updateTime(timerValue, c);
              setTimeCheckbox(c);
            }}
          />
          <div
            className={`flex-grow flex flex-row ${
              timeCheckboxChecked ? '' : 'opacity-50'
            }`}
          >
            <Label className="text-sm">
              {t('slide-sidebar-steams.time_text')}
            </Label>
            <input
              className="border-gray-300 ml-auto"
              value={timerValue}
              size="1"
              disabled={disabled || !timeCheckboxChecked}
              onChange={(event) => {
                //match and delete non digits
                const valueChars = event.target.value.replace(/\D/, '');
                const v = valueChars !== '' ? parseInt(valueChars, 10) : 0;
                updateTime(v, timeCheckboxChecked);
                setTimer(v);
              }}
            />
          </div>
        </div>
      </div>

      <Divider className="flex-grow" />
      <div className="">
        <Label className={subTitleStyle}>
          {t('slide-sidebar-steams.visibility')}
        </Label>
        <div className="flex flex-row flex-grow">
          <CheckBox
            className="mr-2 "
            checked={rolesCheckboxChecked}
            disabled={disabled || slide.method !== SLIDEMETHODS.INDIVIDUALLY}
            onChange={(c) => {
              updateRoles(selectedRoles, c);
              setRolesCheckbox(c);
            }}
          />
          <Label
            className={`text-sm ${rolesCheckboxChecked ? '' : 'opacity-50'}`}
          >
            {t('slide-sidebar-steams.visibility_text')}
          </Label>
        </div>
        <div className={rolesCheckboxChecked ? '' : 'opacity-50'}>
          <Label className={subsubTitleStyle}>
            {t('slide-sidebar-steams.visibility_choose')}
          </Label>
          <InputGroup>
            <Dropdown
              disabled={
                disabled ||
                !rolesCheckboxChecked ||
                slide.method !== SLIDEMETHODS.INDIVIDUALLY
              }
              value={selectedRoles}
              options={groupRoleKeyToId ? Object.keys(groupRoleKeyToId) : []}
              isMulti={true}
              onChange={(newval) => {
                updateRoles(newval, rolesCheckboxChecked);
                setSelectedRoles(newval);
              }}
            />
          </InputGroup>
        </div>
        <Label className={subsubTitleStyle}>
          {t('slide-sidebar-steams.motivation')}
        </Label>
        <InputGroup>
          <Input
            placeholder={t('slide-sidebar-steams.motivation.placeholder')}
            value={motivation || ''}
            disabled={disabled}
            onChange={(e) => {
              updateMotivation(e.target.value);
              setMotivation(e.target.value);
            }}
          />
        </InputGroup>
      </div>
    </div>
  );
};
export default SteamsSideBar;
