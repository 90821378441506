import { memo, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useRecoilState, useRecoilValue } from 'recoil';

import ErrorMessage from '../../../../components/ErrorMessage';

import ContentActionProvider from '../../../../providers/ContentActionProvider';

import { FIND_ALL_CONTENT_FILES_BY_PATH } from '../../../../api/files';

import { FILES } from '../../../../utils/constants/urls';
import {
  isDraggingAtom,
  isHoveredAtom,
  sideBarQueryPath,
} from '../../utils/atom';

import ContentManager from '../../../ContentManager/ContentManager';

const SlideBarContentManagerContainer = () => {
  const [queryPath, setQueryPath] = useRecoilState(sideBarQueryPath);
  const isHovered = useRecoilValue(isHoveredAtom);
  const [loading, setLoading] = useState(false);

  const [isDragging, setIsDragging] = useRecoilState(isDraggingAtom);

  useEffect(() => {
    if (!queryPath) {
      setQueryPath('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const root = FILES.substring(1);

  const queryOptions = { variables: { path: queryPath } };
  const { error, data, refetch } = useQuery(
    FIND_ALL_CONTENT_FILES_BY_PATH,
    queryOptions,
  );

  useEffect(() => {
    if (!data) setLoading(true);
  }, [data]);

  const files = data?.findAllContentFilesByPath;

  if (error) {
    return <ErrorMessage error={error} />;
  }

  return (
    <ContentActionProvider
      path={queryPath}
      root={root}
      files={files}
      fetchAll={refetch}
      maxSize={5242880}
    >
      <ContentManager
        isHovered={isHovered}
        isDragging={isDragging}
        setIsDragging={setIsDragging}
        queryPath={queryPath}
        setQueryPath={setQueryPath}
        root={root}
        files={(files && Object.values(files)) || []}
        loading={loading}
        setLoading={setLoading}
        hasPaddingBottom={false}
      />
    </ContentActionProvider>
  );
};

export default memo(SlideBarContentManagerContainer);
