import { Children, cloneElement, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';

import useFormatMessage from '../hooks/useFormatMessage';

const CopyClipboard = ({ value, children }) => {
  const t = useFormatMessage();

  const [copied, setCopied] = useState(false);
  const child = Children.only(children);

  const onClick = () => {
    setCopied(true);
    copy(value);
  };

  useEffect(() => {
    setCopied(false);
  }, [value]);

  return cloneElement(child, {
    onClick,
    children: copied ? <div>{t('global.copied')}!</div> : child.props.children,
  });
};

export default CopyClipboard;
