import { useEffect, useRef } from 'react';

const useScrollTopRefRef = (trigger) => {
  const scrollRef = useRef();

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }
  }, [scrollRef, trigger]);

  return scrollRef;
};

export default useScrollTopRefRef;
